
export enum InstitutionTypes {
  University = 'university',
  UniversityAbroad = 'universityAbroad',
  SchoolOfEngineering = 'schoolOfEngineering',
  SchoolOfEngineeringAbroad = 'schoolOfEngineeringAbroad',
  Yeshiva = 'yeshiva',
  YeshivaAbroad = 'yeshivaAbroad',
  YeshivatHesder = 'yeshivatHesder',
  Midrasha = 'midrasha',
  PreAcademicPreparatory = 'preAcademicPreparatory',
  OurPreAcademicPreparatory = 'ourPreAcademicPreparatory',
  PremilitaryCollege = 'premilitaryCollege',
  College = 'college',
  CollegeAbroad = 'collegeAbroad',
  GirlsCollege = 'girlsCollege',
  GirlsSeminar = 'girlsSeminar',
  Integration = 'integration',
  NursingSchool = 'nursingSchool',
  Other = 'other',
}

export class StudentInstitution {
  name: string;
  type: InstitutionTypes;
  country?: string;
  city?: string;
  startYear: number;
  endYear?: number;
}
