
export enum HatamaStatus {
  MinimumPsychometryGradeForExemption = 'minimumPsychometryGradeForExemption',
  MinimumBagrutAverageGradeForExemption = 'minimumBagrutAverageGradeForExemption',
  MissingMinimumRequirements = 'missingMinimumRequirements',
  NotMeetTheAdmissionRequirements = 'notMeetTheAdmissionRequirements',
  MeetTheAdmissionRequirements = 'meetTheAdmissionRequirements',
}

export class Hatama {
  finalGrade?: number;
  hasHatama: boolean;
  status: HatamaStatus;
}

export class HatamaModel {
  subjects: BagrutSubjectModel[];
  gradeType: GradeTypes;
  grade?: number;
}

export enum GradeTypes {
  Psychometry = 'psychometry',
  Til = 'til'
}

export class BagrutSubject {
  id: number;
  subjectName: string;
  bonus4Units: number;
  bonus5Units: number;
  bonusMinGrade: number;
}

export interface BagrutSubjectModel {
  subjectId: number;
  grade: number;
  units: number;
}
