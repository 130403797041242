import { Component, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";

import {
  DormitoryService,
  DormitoryModel,
  DormitoryRegistrationModel,
  RegistrationUserProfile,
  StudentDormitoryModel,
  DormType,
} from "@jct/api";
import { HttpStatusResult } from "@jct/core";
import { StateFactory } from "@jct/ui";

@Component({
  selector: "dormitory-registration",
  templateUrl: "./dormitory-registration.html",
})
export class DormitoryRegistrationComponent {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private dormitoryService: DormitoryService,
    private userProfile: RegistrationUserProfile
  ) {}

  @Output()
  registered = new EventEmitter<StudentDormitoryModel>();

  @Input()
  dormitoryOptions: DormitoryModel[];

  dormTypes: DormType[];
  isDormTypes: boolean;

  registerState = this.sf.create();

  get gender() {
    return this.userProfile.gender;
  }

  form = this.fb.group({
    dormId: [0, [Validators.required, Validators.min(1)]],
    dormTypeId: [null],
    isConfirmedContract: [
      { value: null, disabled: true },
      [Validators.required],
    ],
    comment: [{ value: null, disabled: true }],
  });

  ngOnInit() {
    this.form.get("dormId").valueChanges.subscribe((dormId: number) => {
      if (dormId > 0) {
        this.form.get("comment").enable();
        this.form.get("isConfirmedContract").enable();
      } else {
        this.form.get("comment").disable();
        this.form.get("isConfirmedContract").disable();
      }

      this.dormTypes = this.dormitoryOptions.filter(
        (x) => x.id == dormId
      )[0].dormTypes;
      this.isDormTypes = this.dormTypes.length > 0;
    });
  }

  downloadReceiptLink(dormId: number) {
    return this.dormitoryService.unsafeDownloadReceiptLink(dormId);
  }

  async register() {
    if (this.registerState.isInProcess) {
      return;
    }

    await this.registerState.inProcess();

    const model = <DormitoryRegistrationModel>this.form.value;
    const registerResult = await this.dormitoryService.registerAsync(model);

    if (!registerResult.succeeded) {
      this.registerState.failed(registerResult);
      return;
    }

    const reloadResult = await this.dormitoryService.getStudentDormitoryAsync();

    if (reloadResult instanceof HttpStatusResult) {
      this.registerState.failed(reloadResult);
      return;
    }

    await this.registerState.completed();
    this.registered.emit(reloadResult);
  }
}
