
<a mat-button
  i18n-matTooltip
  matTooltip="שלח מייל"
  [href]="trustedUrl"
  target="_blank"
  [color]="color">
  <mat-icon>mail_outline</mat-icon>&nbsp;

  <span>
    <ng-content></ng-content>
  </span>
</a>
