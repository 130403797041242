
import { Component, ViewChild, AfterViewInit, ViewEncapsulation, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';

import { Authenticator, Application, ProgressIndicator, HostEnvironment } from '@jct/core';
import { AccountService } from '@jct/api';

import { TimeoutDialogComponent } from '../timeout-dialog';
import { AppLayoutService, MenuService } from '@jct/ui/lib/services';
import { LogoutDialog } from '../logout-dialog';
import { Subscription } from 'rxjs';
import { PageLayout } from '@jct/localization';

@Component({
  selector: 'ui-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  exportAs: 'uiAppLayout',
  encapsulation: ViewEncapsulation.None,
})
export class AppLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  private _subscription: Subscription;

  constructor(
    public appLayout: AppLayoutService,
    public pageLayout: PageLayout,
    public application: Application,
    public environment: HostEnvironment,
    private account: AccountService,
    private menuService: MenuService,
    private authenticator: Authenticator,
    public progressIndicator: ProgressIndicator,
    private dialog: MatDialog)
  { }

  async ngOnInit() {
    this._subscription = this.authenticator.notifyBeforeTimeout
      .subscribe(() => this.dialog.open(TimeoutDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        direction: this.pageLayout.layout,
      }));

    if (/rv\:11\.0/.test(navigator.userAgent)) {
      document.head.classList.add('ie');
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  @HostBinding('class')
  get isSidebarOpened() {
    if (this.menuService && this.menuService.hasSidebar) {
      return 'sidebar-opened';
    }

    return '';
  }

  @ViewChild('menu')
  menu!: MatSidenav;

  private _logoutDialogOpened = false;

  ngAfterViewInit() {
    this.menuService.setSidenav(this.menu);

    window.addEventListener('keydown', event => {
      let key = event.key?.toUpperCase();

      switch (key) {
        case 'ESCAPE':
          if (this.menuService.isOpen) {
            this.menuService.close();
          }
          break;

        case 'F10':
          if (!this.menuService.isOpen) {
            this.menuService.open();
          }
          break;
      }
    });

    window.addEventListener('keyup', event => {
      let key = event.key?.toUpperCase();

      switch (key) {
        case 'ESCAPE':
          if (this.menuService.isOpen) {
            this.menuService.close();
          }
          else {
            if (this.authenticator.isAuthenticated && this._logoutDialogOpened === false) {
              this._logoutDialogOpened = true;

              const dialogRef = this.dialog.open(LogoutDialog, {
                disableClose: true,
                hasBackdrop: true,
                direction: this.pageLayout.layout,
              });

              dialogRef.beforeClosed().subscribe(() => {
                this._logoutDialogOpened = false;
              });
            }
          }
          event.stopImmediatePropagation();
          event.preventDefault();
          break;

        case 'F10':
          if (!this.menuService.isOpen) {
            this.menuService.open();
          }
          break;
      }
    });
  }

  async logout() {
    await this.account.logout();
    this.authenticator.logout();
  }
}
