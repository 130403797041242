
import { Injectable, Injector } from '@angular/core';

import {
  Uploader,
  Controller,
  HttpGet,
  resultOf,
  HttpDelete,
  FromRoute,
  HostEnvironment,
  Produce,
  HttpStatusResult,
  SuccessTestCase,
  EntityNotExistsResult,
  FailedTestCase,
  SuccessResult,
  UnauthorizedResult
} from '@jct/core';

import { FAKE_FILE_REQUESTS, FAKE_STUDENT_FILES } from './files.test';
import { FileRequest, StudentFile, StudentFileUpload } from './files.types';

@Injectable({ providedIn: 'root' })
@Controller('files')
export class FilesService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private uploader: Uploader<StudentFileUpload>)
  {
    this.uploader.relativeUrl = 'files/upload';
  }

  get baseUrl() {
    return `${this.environment.apiServerUrl}/files`;
  }

  @HttpGet('requests')
  @SuccessTestCase(FAKE_FILE_REQUESTS, 'קבלת הקבצים')
  @Produce(FileRequest, true)
  getFileRequestsAsync() {
    return resultOf<FileRequest[]>();
  }

  @HttpGet()
  @SuccessTestCase(FAKE_STUDENT_FILES, 'קבלת קבצים עבור הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentFile, true)
  getStudentRegistrationFilesAsync() {
    return resultOf<StudentFile[]>();
  }

  @HttpGet('additional')
  @SuccessTestCase(FAKE_STUDENT_FILES, 'קבלת קבצים עבור הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentFile, true)
  getStudentAdditionalFilesAsync() {
    return resultOf<StudentFile[]>();
  }

  upload(model: StudentFileUpload) {
    return this.uploader.upload<StudentFile>(model);
  }

  @HttpDelete('{studentFileId}')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(UnauthorizedResult)
  @Produce(HttpStatusResult)
  deleteStudentAdditionalFileAsync(
    @FromRoute('studentFileId')
    studentFileId: number) {
    return resultOf();
  }

  @HttpGet('mandatory')
  @SuccessTestCase(true)
  @SuccessTestCase(false)
  @FailedTestCase(UnauthorizedResult)
  @Produce('boolean')
  isStudentUploadMandatoryFiles() {
    return resultOf<boolean>();
  }
}
