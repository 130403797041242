
<div container *ngIf="paymentType">
  <meta-title i18n>
    התשלום בוטל
  </meta-title>

  <section *ngIf="paymentType === 'standing-order'">
    <h2 i18n>ההוראת קבע בוטלה במערכת</h2>
    <p i18n>
      אנו מבטלים את ההוראת קבע ...
    </p>
  </section>

  <section *ngIf="paymentType != 'standing-order'">
    <h2 i18n>התשלום בוטל במערכת</h2>
    <p i18n>
      אנו מבטלים את פעולת התשלום ...
    </p>
  </section>

  <mat-progress-spinner
    color="primary"
    diameter="30"
    strokeWidth="3"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
