import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export const WITHOUT_EMIT_EVENT = {
  emitEvent: false
};

export interface ValidationError {
  name: string;
  type: any;
}

export function extractErrors(array: UntypedFormArray) {
  const errors: string[] = [];
  for (let row of <UntypedFormGroup[]>array.controls) {
    for (let key in row.controls) {
      const control = row.controls[key];

      if (control.errors !== null) {
        for (let error in control.errors) {
          errors.push(`${key}-${error}`);
        }
      }
    }
  }

  return errors.distinct();
}
