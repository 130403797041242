import { HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { RouteDescriptor } from '@jct/core';


import { HttpMethod } from '../api/types';
import { Map } from '../common';
import { ResponseMethod } from './types';

export class HttpContext<T = any> {
  private _method: HttpMethod;
  private _url: string;
  private _route: RouteDescriptor;
  private _hasResponse = false;
  private _response?: HttpResponse<T>|null;
  private _hasError = false;
  private _error: HttpErrorResponse|null;
  private _responseMethod = ResponseMethod.NotYetDetermined;

  constructor(
    method: HttpMethod,
    url: string,
    route: RouteDescriptor)
  {
    this._method = method;
    this._url = url;
    this._route = route;
    this.items = {};
  }

  get method() { return this._method; }
  get url() { return this._url; }
  get urlWithParams() { return this.request?.urlWithParams || this._url; }
  get requestBody() { return this.request?.body || null; }
  get route() { return this._route; }
  get action() { return this._route.action || null; }
  get hasResponse() { return this._hasResponse; }
  get response() { return this._response || null; }
  get hasError() { return this._hasError; }
  get error() { return this._error || null; }

  get responseMethod() { return this._responseMethod; }
  get fromServer() { return this._responseMethod === ResponseMethod.FromServer; }
  get fromCache() { return this._responseMethod === ResponseMethod.FromCache; }
  get generated() { return this._responseMethod === ResponseMethod.Generated; }

  request: HttpRequest<T>;
  items: Map<any>;

  setResponse(response: HttpResponse<T>) {
    if (!this._hasResponse) {
      this._hasResponse = true;
      this._response = response;
      this._responseMethod = ResponseMethod.FromServer;
    }
  }

  cachedResponse(response: HttpResponse<T>) {
    if (!this._hasResponse) {
      this._hasResponse = true;
      this._response = response;
      this._responseMethod = ResponseMethod.FromCache;
    }
  }

  generateResponse(response: HttpResponse<T>) {
    if (!this._hasResponse) {
      this._hasResponse = true;
      this._response = response;
      this._responseMethod = ResponseMethod.Generated;
    }
  }

  setResponseBody(body: any) {
    this._response = this._response.clone({
      body
    });
  }

  setError(error: HttpErrorResponse) {
    if (!this._hasError) {
      this._hasError = true;
      this._error = error;
      this._responseMethod = ResponseMethod.FromServer;
    }
  }

  generateError(error: HttpErrorResponse) {
    if (!this._hasError) {
      this._hasError = true;
      this._error = error;
      this._responseMethod = ResponseMethod.Generated;
    }
  }
}
