import { ProvideValue, TestDescription, TestBuilders } from '@jct/core';
import { BagrutSubject, Hatama, HatamaStatus } from './hatama.types';

const FINAL_GRADE_PROVIDER = TestBuilders.numberBetween(80, 99);

@TestDescription('ציון פסיכומטרי עובר')
export class FakeMinimumPsychometryGradeForExemption extends Hatama {
  status = HatamaStatus.MinimumPsychometryGradeForExemption;
  hasHatama = true;
}

@TestDescription('ציון התאמה עובר')
export class FakeMeetTheAdmissionRequirements extends Hatama {
  status = HatamaStatus.MeetTheAdmissionRequirements;
  zhasHatama = true;
  @ProvideValue(FINAL_GRADE_PROVIDER)
  finalGrade?: number;
}

@TestDescription('ציון בגרות עובר')
export class FakeMinimumBagrutAverageGradeForExemption extends Hatama {
  status = HatamaStatus.MinimumBagrutAverageGradeForExemption;
  hasHatama = true;
}

@TestDescription('חסרים ציוני בגרות או פסיכומטרי')
export class FakeMissingMinimumRequirements extends Hatama {
  status = HatamaStatus.MissingMinimumRequirements;
  hasHatama = false;
}

@TestDescription('ציון התאמה לא עובר')
export class FakeNotMeetTheAdmissionRequirements extends Hatama {
  status = HatamaStatus.NotMeetTheAdmissionRequirements;
  hasHatama = false;
}

export const FAKE_BAGRUT_SUBJECTS: BagrutSubject[] = [
  {
    id: 1,
    subjectName: 'תנ"ך לבי"ס כללי',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 2,
    subjectName: 'תנ"ך לבי"ס דתי',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 3,
    subjectName: 'אנגלית',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 4,
    subjectName: 'יהדות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 5,
    subjectName: 'תושב"ע',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 6,
    subjectName: 'תלמוד',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 8,
    subjectName: 'ספרות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 10,
    subjectName: 'עברית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 11,
    subjectName: 'הבעה עברית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 12,
    subjectName: 'לימודי ארץ ישראל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 13,
    subjectName: 'חבור עברי',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 15,
    subjectName: 'ידיעת העם והמדינה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 17,
    subjectName: 'צרפתית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 19,
    subjectName: 'פילוסופיה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 20,
    subjectName: 'מתמטיקה עד תשע"ו',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 21,
    subjectName: 'מחשבת ישראל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 22,
    subjectName: 'היסטוריה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 24,
    subjectName: 'חינוך',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 25,
    subjectName: 'מקצוע טכנולוגי א\'',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 26,
    subjectName: 'מקצוע טכנולוגי ב\'',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 27,
    subjectName: 'אומנות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 28,
    subjectName: 'מוסיקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 29,
    subjectName: 'תולדות עם ישראל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 31,
    subjectName: 'ידיעת העם והמדינה -- דתי',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 32,
    subjectName: 'מערכות אלקרו-אופטיות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 33,
    subjectName: 'אלקטרו-אופטיקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 34,
    subjectName: 'אזרחות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 35,
    subjectName: 'אופטיקה יישומית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 36,
    subjectName: 'פיסיקה',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 37,
    subjectName: 'כימיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 38,
    subjectName: 'מחשבת ישראל לבי"ס דתי',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 39,
    subjectName: 'כימיה ביולוגיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 41,
    subjectName: 'חינוך גופני',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 42,
    subjectName: 'יסודות תורת המחשב',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 43,
    subjectName: 'ביולוגיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 44,
    subjectName: 'מדעי החיים והחקלאות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 45,
    subjectName: 'ביולוגיה חקלאית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 46,
    subjectName: 'חקלאות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 47,
    subjectName: 'מיכון חקלאי ומערכות',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 48,
    subjectName: 'מיקרוביולוגיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 49,
    subjectName: 'תע"י ואזרחות',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 50,
    subjectName: 'תרבות המערב',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 51,
    subjectName: 'תולדות האמנות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 52,
    subjectName: 'אלקטרוניקה ומחשבים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 53,
    subjectName: 'אלקטרוניקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 54,
    subjectName: 'תרמודינמיקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 55,
    subjectName: 'מכניקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 56,
    subjectName: 'פסיכולוגיה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 57,
    subjectName: 'גיאוגרפיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 58,
    subjectName: 'רוסית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 59,
    subjectName: 'ניהול משאבי אנוש',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 61,
    subjectName: 'מינהל וכלכלה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 62,
    subjectName: 'כלכלה ומינהל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 63,
    subjectName: 'כלכלה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 64,
    subjectName: 'חשבונאות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 65,
    subjectName: 'סוציולוגיה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 66,
    subjectName: 'מדעי המדינה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 67,
    subjectName: 'חשבונאות מתקדמת',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 75,
    subjectName: 'לשון',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 80,
    subjectName: 'תורת החשמל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 81,
    subjectName: 'תכנון ותכנות מערכות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 82,
    subjectName: 'מבוא למחשבים וענ"א',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 83,
    subjectName: 'אלקטרוניקה כללית ותקשורת',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 84,
    subjectName: 'מבוא למחשבים ותכנות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 85,
    subjectName: 'סרטוט טכני',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 86,
    subjectName: 'מחשבים ומערכות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 87,
    subjectName: 'מחשוב ובקרה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 90,
    subjectName: 'ביוטכנולוגיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 91,
    subjectName: 'פרסית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 98,
    subjectName: 'מערכות חשמל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 99,
    subjectName: 'מדעי מחשב',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 100,
    subjectName: 'טכנולוגיה מוכללת',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 101,
    subjectName: 'מדעי החברה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 102,
    subjectName: 'ניתוח ועיצוב מערכות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 103,
    subjectName: 'ישומים מנהליים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 104,
    subjectName: 'אמהרית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 106,
    subjectName: 'מערכות אלקטרוניות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 110,
    subjectName: 'מערכות ביוטכנולוגיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 111,
    subjectName: 'כימיה טכנולוגית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 112,
    subjectName: 'גרמנית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 115,
    subjectName: 'עבודות גמר',
    bonus4Units: 12.50,
    bonus5Units: 25.00,
    bonusMinGrade: 60
  },
  {
    id: 116,
    subjectName: 'מעבדה במחשבים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 117,
    subjectName: 'תקשורת למחשבים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 120,
    subjectName: 'טכנולוגיה של הדפוס',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 122,
    subjectName: 'מערכות תעופה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 124,
    subjectName: 'מערכות פיקוח ובקרה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 126,
    subjectName: 'תכנון פרקי מכונות',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 127,
    subjectName: 'הלכה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 128,
    subjectName: 'מקצוע בין תחומי',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 129,
    subjectName: 'גרפיקה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 200,
    subjectName: 'איטלקית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 201,
    subjectName: 'בקרה במכונות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 203,
    subjectName: 'מערכות מכונאות רכב',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 204,
    subjectName: 'צילום',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 205,
    subjectName: 'אדריכלות',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 206,
    subjectName: 'חוק ומשפט',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 207,
    subjectName: 'רובוטיקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 208,
    subjectName: 'תקשורת',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 209,
    subjectName: 'ניהול הייצור',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 302,
    subjectName: 'ערבית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 303,
    subjectName: 'הסטוריה דתי (כולל תע"י)',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 304,
    subjectName: 'לימודי הסביבה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 305,
    subjectName: 'אמנות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 306,
    subjectName: 'תיאטרון',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 307,
    subjectName: 'גיאולוגיה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 308,
    subjectName: 'אמנות (הקולנוע)',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 309,
    subjectName: 'דת האיסלם',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 310,
    subjectName: 'שפות זרות א\' ו ב\'',
    bonus4Units: 0.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 311,
    subjectName: 'תוכניות מיוחדות (מפמ"רים)',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 312,
    subjectName: 'הולנדית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 313,
    subjectName: 'אנגלית תרגום',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 314,
    subjectName: 'עיצוב',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 315,
    subjectName: 'עיצוב חזותי',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 317,
    subjectName: 'חינוך לקריירה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 318,
    subjectName: 'מחוללי ישומי מעבדה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 319,
    subjectName: 'יהדות מורחבת (לוסטיג)',
    bonus4Units: 0.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 320,
    subjectName: 'יידיש',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 321,
    subjectName: 'משנה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 322,
    subjectName: 'יסודות החשבונאות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 330,
    subjectName: 'בינה מלאכותית ומער\' מומחה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 331,
    subjectName: 'בקרה ומחשבים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 332,
    subjectName: 'חשמל',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 333,
    subjectName: 'מדע חישובי',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 334,
    subjectName: 'מדעי ההנדסה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 335,
    subjectName: 'מדעי החיים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 336,
    subjectName: 'מדעי הטכנולוגיה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 337,
    subjectName: 'מחשבים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 338,
    subjectName: 'מכשור בקרה ומחשבים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 339,
    subjectName: 'מכשור ובקרה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 340,
    subjectName: 'מכשירים ופיקוד',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 341,
    subjectName: 'מערכות בקרה ממוחשבת',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 342,
    subjectName: 'מערכות מכשור ובקרה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 343,
    subjectName: 'מערכות סיב"מ',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 345,
    subjectName: 'מערכות ופיקוד',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 346,
    subjectName: 'מערכות תוכנה וחומרה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 347,
    subjectName: 'מערכות תיב"מ',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 348,
    subjectName: 'מערכות תיכון וייצור',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 349,
    subjectName: 'מערכות תקשורת ומיתוג',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 350,
    subjectName: 'ניהול',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 351,
    subjectName: 'ניתוח מערכות וארגון קבצים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 352,
    subjectName: 'תכנון מערכות מנהליים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 353,
    subjectName: 'תעשייה כימית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 355,
    subjectName: 'תכנון יישומיים מנהליים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 357,
    subjectName: 'בקרת תהליכים',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 358,
    subjectName: 'טלקומוניקציה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 359,
    subjectName: 'מדעי הסביבה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 360,
    subjectName: 'מטאורולוגיה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 361,
    subjectName: 'מכונות חקלאיות וטרקטורים',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 362,
    subjectName: 'מכטרוניקה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 363,
    subjectName: 'מכניקה הנדסית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 364,
    subjectName: 'המטוס ומערכותיו',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 365,
    subjectName: 'המסוק ומערכותיו',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 366,
    subjectName: 'עם ועולם',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 367,
    subjectName: 'פרקי מכונות וחוזק חומרים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 368,
    subjectName: 'קירור ומיזוג אוויר',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 369,
    subjectName: 'תיירות',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 370,
    subjectName: 'תכנון הנדסי של מבנים',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 371,
    subjectName: 'חישוב סטטי וקונסטרוקציות',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 372,
    subjectName: 'מכונות חום ותרמודינמיקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 373,
    subjectName: 'מנועי מטוסים ותרמודינמיקה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 374,
    subjectName: 'תקשורת המונים',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 375,
    subjectName: 'תקשורת וחברה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 376,
    subjectName: 'אוריינות אקדמית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 377,
    subjectName: 'תורת הסיעוד',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 378,
    subjectName: 'מדעי הבריאות א\'',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 379,
    subjectName: 'כתיבה מדעית',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 380,
    subjectName: 'הפקות בתקשורת',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 900,
    subjectName: 'מידע הבריאות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 901,
    subjectName: 'מערכות מידע ממוחשבות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 902,
    subjectName: 'תשתיות מחשוב ובקרה',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 903,
    subjectName: 'מערכות תקשוב',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 905,
    subjectName: 'ניהול התפעול',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 906,
    subjectName: 'שיווק וקידום מכירות',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 907,
    subjectName: 'פסיכולוגיה התפתחותית',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 908,
    subjectName: 'יישומי מחשב',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 909,
    subjectName: 'אמנות בתיאטרון',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 910,
    subjectName: 'מדעי הטבע',
    bonus4Units: 10.00,
    bonus5Units: 20.00,
    bonusMinGrade: 60
  },
  {
    id: 911,
    subjectName: 'תולדות הערבים והאסלאם',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 912,
    subjectName: 'מערכות הלבשה',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 913,
    subjectName: 'מוסיקה - רסיטל',
    bonus4Units: 0.00,
    bonus5Units: 0.00,
    bonusMinGrade: 60
  },
  {
    id: 914,
    subjectName: 'מתמטיקה',
    bonus4Units: 12.50,
    bonus5Units: 35.00,
    bonusMinGrade: 60
  }
];
