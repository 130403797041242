import { HostBinding, AfterContentInit, ElementRef, Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'meta-keywords',
  template: '<ng-content></ng-content>',
})
export class MetaKeywords implements AfterContentInit {
  private _keywords = '';

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() { return true; }

  @HostBinding('attr.aria-hidden')
  get ariaHidden() { return true; }

  constructor(
    private elementRef: ElementRef<Element>,
    private meta: Meta) { }

  ngAfterContentInit() {
    this._keywords = this.elementRef.nativeElement.textContent.trim();

    this.meta.updateTag({
      content: this._keywords,
    }, 'name="keywords"');
  }

  get keywords() {
    return this._keywords;
  }
}
