
<div container>
  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <h2 i18n class="mb-15">
      אנו מברכים אותך על החלטתך ללמוד במרכז האקדמי לב
      <sub>
        קמפוס לב | קמפוס טל | קמפוס לוסטיג | מח"ר טל תבונה
      </sub>
    </h2>

    <form
      [formGroup]="form"
      novalidate
      autocomplete="off"
      (submit)="identify()"
    >
      <mat-radio-group formControlName="method">
        <mat-label i18n>
          בחר פרט זיהוי עבורך
        </mat-label>

        <mat-radio-button [value]="identifyMethod.IdNumber" i18n>
          תעודת זהות
        </mat-radio-button>

        <mat-radio-button [value]="identifyMethod.Passport" i18n>
          מספר דרכון
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="fill">
        <mat-label i18n>
          {method, select,
            idNumber {תעודת הזהות}
            passport {מספר הדרכון}}
          שלך
        </mat-label>

        <input matInput
          [type]="form.value.method == 'idNumber' ? 'number' : 'text'"
          required
          formControlName="key"
          [autofocus]="loadState"
          maxlength="10">

        <mat-hint i18n>
          עליך לוודא
          ש{method, select,
            idNumber {תעודת הזהות}
            passport {מספר הדרכון}}
          שלך ולא של משהו אחר
        </mat-hint>

        <mat-error i18n>
          אנא הזן מספר
          {method, select,
            idNumber {תעודת זהות}
            passport {דרכון}}
          תקין
        </mat-error>
      </mat-form-field>

      <ui-progress-button
        color="primary"
        [disabled]="form.invalid"
        [state]="identifyState"
        i18n>
        {identifyState.value, select,
          inProcess {מתחילים}
          other {התחל}}
      </ui-progress-button>

      <ui-alert type="warning" *visible="identifyState.error?.isInvalidModel">
        <div ui-body i18n>
          {method, select,
            idNumber {תעודת הזהות}
            passport {מספר הדרכון}}
          שהזנת אינה תקינה
        </div>
      </ui-alert>

      <ui-error [state]="identifyState"
        [ignoreList]="[400]">
      </ui-error>
    </form>

    <mat-divider></mat-divider>

    <section>
      <p>
        <ui-link to="/reset-process" i18n>
          שכחת את הסיסמה שלך?
        </ui-link>
      </p>

      <p>
        <ui-link to="/calculator" i18n>
          רוצה לדעת מהו ציון התאמתך?
        </ui-link>
      </p>

      <p>
        <ui-link to="https://www.jct.ac.il/guide/2023-24/" i18n>
          חוברת המדריך לנרשם
        </ui-link>
      </p>
    </section>
  </section>
</div>
