import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MaterialModule } from "@jct/material";
import { CoreModule } from "@jct/core";
import { L10nModule } from "@jct/localization";
import { UiModule } from "@jct/ui";

import {
  TestCasesProvider,
  TestInterceptorProvider,
} from "./test-cases-provider";
import { SelectTestCasesDialog } from "./select-test-cases-dialog";
import { FormDebugger, FormDebuggerToolbar } from "./form-debugger";
import { TestRoutingModule } from "./test-routing.module";
import { CreditGuardTestPage } from "./credit-guard-test-page";

const EXPORTED_COMPONENTS = [
  SelectTestCasesDialog,
  FormDebugger,
  FormDebuggerToolbar,
  CreditGuardTestPage,
];

const EXPORTED_SERVICES = [TestCasesProvider, TestInterceptorProvider];

@NgModule({
  declarations: [...EXPORTED_COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    UiModule.forRoot(),
    CoreModule.forRoot(),
    MaterialModule.forRoot(),
    L10nModule.forRoot(),
    TestRoutingModule,
  ],
  exports: [...EXPORTED_COMPONENTS],
  providers: [...EXPORTED_SERVICES],
})
export class TestModule {
  static forRoot(enabled: boolean = true): ModuleWithProviders<TestModule> {
    return {
      ngModule: TestModule,
      providers: enabled ? [...EXPORTED_SERVICES] : [],
    };
  }
}
