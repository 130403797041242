
<div container>
  <a [attr.href]="downloadLink"
    target="_blank"
    mat-raised-button
    color="primary">
    <mat-icon>cloud_download</mat-icon>
    &nbsp;&nbsp;
    <span i18n>אישור נרשם</span>
  </a>
</div>
