
import { MetaDescription } from './meta-description';
import { MetaKeywords }from './meta-keywords';
import { MetaTitle } from './meta-title';
import { MetaApplicationName } from './meta-application-name';
import { PageTitleService } from './page-title.service';
import { SINGLETON_SERVICE } from '../common';

export * from './meta-description';
export * from './meta-keywords';
export * from './meta-title';
export * from './meta-application-name';
export * from './page-title.service';

export const META_COMPONENTS = [
  MetaApplicationName,
  MetaDescription,
  MetaKeywords,
  MetaTitle,
];

export const META_SERVICES = [
  {
    provide: SINGLETON_SERVICE,
    useClass: PageTitleService,
    multi: false,
  },
];
