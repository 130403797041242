
import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { SUPPORTED_LANGUAGES } from '../core';
import { Language } from '../language';
import { Localization } from '../localization.service';

@Component({
  templateUrl: './language-selector.component.html',
  providers: [Localization],
})
export class LanguageSelectorComponent {
  constructor(
    private localization: Localization,
    private bottomSheetRef: MatBottomSheetRef)
  { }

  setLanguage(language: Language) {
    this.bottomSheetRef.afterDismissed().subscribe(() => {
      this.localization.change(language);
    });

    this.bottomSheetRef.dismiss();
  }

  get supportedLanguages() {
    return SUPPORTED_LANGUAGES;
  }

  get currentLanguage() {
    return this.localization.current;
  }
}
