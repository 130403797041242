import { Gender } from '@jct/localization';

export enum AcademicStudyTypes {
  Mechina = 2,
  FirstDegree = 3,
  SecondDegree = 4,
  CompletionYear = 5,
  Diploma = 6
}

export enum Institutes {
  Lev = 1,
  Tal = 2,
  Nave = 3,
  Lustig = 5,
  Lev_Mechina = 6,
  Mahar_Tal = 34
}

export class StudyTrack {
  academicStudyTypeId: number;
  academicStudyTypeName?: string;
  instituteId: number;
  instituteName?: string;
  gender: Gender;
  departmentId: number;
  departmentName?: string;
  trackId: number;
  trackName?: string;
  academicYear: number;
  academicYearTitle: string;
}

export class StudentYearlyStudyTracks {
  tracks?: number[];
  academicYear: number;
  academicStudyTypeId: number;
}
