
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { Gender } from '@jct/localization';
import { StateFactory } from '@jct/ui';

import {
  ProfileService,
  BagrutGradesService,
  StudentBagrutGrade,
  BagrutSubject,
  DefaultBagrutSubjects,
  RegistrationStates,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
} from '@jct/api';
import { extractErrors, HttpStatusResult } from '@jct/core';

interface TableRow {
  bagrutSubjectId?: number;
  subject?: string;
  units?: number;
  grade?: number;
  bonus?: number;
  finalGrade?: number;
}

const defaultSubjects: StudentBagrutGrade[] = [
  {bagrutSubjectId: DefaultBagrutSubjects.אזרחות, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.אנגלית, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.הבעה_עברית, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.הסטוריה, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.מחשבת_ישראל_דתי, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.מתמטיקה, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.ספרות, units: null, grade: null, bonus: null, finalGrade: null},
  {bagrutSubjectId: DefaultBagrutSubjects.תנך, units: null, grade: null, bonus: null, finalGrade: null}
];

const DisableEmitEvent = { emitEvent: false };

@Component({
  selector: 'bagrut-tab.page',
  templateUrl: './bagrut-tab.component.html',
})
export class BagrutTabComponent implements OnInit {
  @Output()
  done = new EventEmitter<any>();

  constructor(
    private bagrutGradesService: BagrutGradesService,
    private registrationNavigator: RegistrationNavigator,
    private profileService: ProfileService,
    private fb: UntypedFormBuilder,
    private sf: StateFactory)
  { }


  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns: string[] = ['bagrut-subject', 'units', 'grade', 'bonus', 'final-grade', 'actions'];

  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup = this.fb.group({ 'bagrutGrades': this.rows });

  bagrutSubjects: BagrutSubject[] = [];

  loadState = this.sf.create();
  saveState = this.sf.create();

  showError = false;

  get returnStudent() {
    return this.profileService.profile?.state == RegistrationStates.ReturnStudent;
  }

  get gender(): Gender {
    return this.profileService.profile?.gender;
  }

  get unitsCount() {
    return this.rows.controls
    .filter(x => x.value.units)
    .map(x => x.value.units)
    .reduce((total, num) => total + num, 0);
  }

  get average() {
    return this.unitsCount > 0 ? this.rows.controls
    .filter(x => x.value.grade && x.value.units)
    .map(x => x.value.grade * x.value.units)
    .reduce((total, num) => total + num, 0) / this.unitsCount : 0;
  }

  get weightedAverage() {
    return this.unitsCount > 0 ? this.rows.controls
      .filter(x => x.value.finalGrade && x.value.units)
      .map(x => x.value.finalGrade * x.value.units)
      .reduce((total, num) => total + num, 0) / this.unitsCount : 0;
  }

  get uncompleted() {
    let uncompleted = this.rows.controls
    .filter(x =>
      x.value.bagrutSubjectId &&
      (x.value.units && !x.value.grade ||
      !x.value.units && x.value.grade));

    return uncompleted.length > 0;
  }

  get canSubmit() {
    return !this.uncompleted && !this.form.invalid;
  }

  async loadBagrutSubjectsAsync() {
    let result = await this.bagrutGradesService.loadBagrutSubjectsAsync();

    if (result instanceof HttpStatusResult) {
      return;
    }

    this.bagrutSubjects = result;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    let result  = await this.bagrutGradesService.getStudentBagrutGradesAsync();
    let data: StudentBagrutGrade[];

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }

      data = defaultSubjects;
    }
    else {
      data = <StudentBagrutGrade[]>result;
    }

    await this.loadBagrutSubjectsAsync();

    data.forEach(x => this.createRow(x));

    this.dataSource.next(this.rows.controls);
    this.addEmptyRow();

    this.loadState.completed();
  }

  addEmptyRow() {
    if (!this.hasEmptyRow) {

      this.createRow();
      this.dataSource.next(this.rows.controls);
    }
  }

  subjectName(bagrutSubjectId: number): string {
    let subject = this.bagrutSubjects
    .find(x => x.id == bagrutSubjectId);

    return subject != undefined ? subject.subjectName : null;
  }

  removeBagrutGrade(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }

  canRemoveBagrutGrade(row: UntypedFormGroup) {
    return row.value.bagrutSubjectId > 0 &&
    !this.returnStudent;
  }

  filterSubjects(row: TableRow) {
    let takenSubjects = this.rows.controls
      .filter(x => x.value.bagrutSubjectId)
      .map(x => x.value.bagrutSubjectId);

    if (row.bagrutSubjectId > 0) {
      let index = takenSubjects.indexOf(row.bagrutSubjectId);

      if (index >= 0) {
        takenSubjects.splice(index, 1);
      }
    }

    let availableSubjects = this.bagrutSubjects
      .sort((a, b) => a.subjectName.localeCompare(b.subjectName))
      .filter(x => takenSubjects.indexOf(x.id) < 0);

    if (row.subject) {
      return availableSubjects
        .filter(x => x.subjectName.indexOf(row.subject) >= 0)
        .slice(0, 20);
    }

    return availableSubjects.slice(0, 20);
  }

  get errorList() {
    return extractErrors(this.rows);
  }

  private disableRow(row: UntypedFormGroup) {
    row.controls['units'].disable(DisableEmitEvent);
    row.controls['grade'].disable(DisableEmitEvent);
  }

  private createRow(data?: StudentBagrutGrade) {
    const config = {
      'bagrutSubjectId': [data? data.bagrutSubjectId: null],
      'subject': [data? this.bagrutSubjects.find(x => x.id == data.bagrutSubjectId).subjectName: null],
      'units': [{ value: data ? data.units : null, disabled: !data }, [Validators.required, Validators.min(1), Validators.max(15)]],
      'grade': [{ value: data ? data.grade : null, disabled: !data }, [Validators.required, Validators.min(0), Validators.max(100)]],
      'bonus': [{ value: data ? data.bonus : null, disabled: !data }],
      'finalGrade': [{ value: data ? data.finalGrade : null, disabled: !data }],
    };

    const row = this.fb.group(config);

    this.rows.push(row);

    row.controls['subject'].valueChanges.subscribe((value: string) => {
      if (value && value.trim().length > 0) {
        let item = this.bagrutSubjects.find(x => x.subjectName == value);

        if (item) {
          row.patchValue({
            bagrutSubjectId: item.id,
            emptyRow: false,
          }, DisableEmitEvent);

          row.enable(DisableEmitEvent);
          this.addEmptyRow();

          return;
        }
        else {
          row.patchValue({
            bagrutSubjectId: null,
            bonus: null,
            finalGrade: null,
          }, DisableEmitEvent);
        }
      }
      else {
        row.patchValue({
          bagrutSubjectId: null,
          emptyRow: true,
          units: null,
          grade: null,
          bonus: null,
          finalGrade: null,
        }, DisableEmitEvent);
      }

      this.disableRow(row);
      // this.removeLastEmptyRow();
    });

    row.valueChanges.subscribe((data: TableRow) => {
      if (data.bagrutSubjectId > 0) {
        row.enable(DisableEmitEvent);
        this.addEmptyRow();
      }

      let bonus = this.computeBonus(data);

      row.patchValue({
        bonus: bonus,
        finalGrade: bonus + data.grade,
      }, DisableEmitEvent);
    });
  }

  private get hasEmptyRow() {
    return this.rows.controls.findIndex(x => x.value.bagrutSubjectId == null) >= 0;
  }

  private computeBonus(row: TableRow) {
    if (!row || !row.bagrutSubjectId)
      return 0;

    let bagrutSubjectId = row.bagrutSubjectId;
    let grade = row.grade || 0;
    let units = row.units || 0;

    let subject = this.bagrutSubjects
      .find(x => x.id == bagrutSubjectId);

    let bonus = grade >= subject.bonusMinGrade ?
      (units == 4 ? subject.bonus4Units :
        units >= 5 ? subject.bonus5Units : 0) : 0;

    return bonus;
  }

  async saveAsync() {
    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();
    this.form.markAllAsTouched();

    if (!this.canSubmit) {
      this.showError = true;
      await this.saveState.completed();
      return;
    }

    let studentBagrutGrades = this.form.value.bagrutGrades
      .filter((x: TableRow) =>
        x.bagrutSubjectId &&
        x.units &&
        x.grade)
      .map(x => <StudentBagrutGrade>{
        bagrutSubjectId: x.bagrutSubjectId,
        units: x.units,
        grade: x.grade,
        bonus: x.bonus,
        finalGrade: x.finalGrade
      });

    let model = studentBagrutGrades as StudentBagrutGrade[];
    let result = await this.bagrutGradesService.saveStudentBagrutGradesAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    this.profileService.update<RegistrationProfile>({
      currentStage: RegistrationStages.ציוני_בגרות,
    });

    await this.saveState.completed();
    this.registrationNavigator.navigateToNextStage();
  }

  async skip() {
    this.registrationNavigator.navigateToNextStage();
  }
}
