
<a mat-button
  i18n-matTooltip
  matTooltip="התקשר"
  [href]="trustedUrl"
  target="_blank"
  [color]="color">
  <mat-icon>call</mat-icon>&nbsp;

  <span>
    <ng-content></ng-content>
  </span>
</a>
