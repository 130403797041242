
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessTestCase,
  EntityNotExistsResult,
  SuccessResult,
  Cache,
  InvalidModelResult,
  FailedTestCase,
  SuccessTestCaseProvider,
  HttpStatusResult
} from '@jct/core';

import { StudentPreviousDegree, TypesListItem } from './previous-degree.type';
import { FAKE_DEGREE_TRACK, FAKE_DEGREE_TYPE, STUDENT_DEGREE_TRACKS_PROVIDER } from './previous-degree.test';

@Injectable({providedIn: 'root'})
@Controller('previous-degree')
export class PreviousDegreeService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet('degree-types')
  @Cache('30d')
  @SuccessTestCase(FAKE_DEGREE_TYPE)
  @Produce(TypesListItem, true)
  loadDegreeTypesAsync()
  {
    return resultOf<TypesListItem[]>();
  }

  @HttpGet('degree-tracks')
  @Cache('30d')
  @SuccessTestCase(FAKE_DEGREE_TRACK)
  @Produce(TypesListItem, true)
  loadDegreeTracksAsync()
  {
    return resultOf<TypesListItem[]>();
  }

  @UseState()
  @HttpGet()
  @SuccessTestCaseProvider(STUDENT_DEGREE_TRACKS_PROVIDER, 'קבלת תארים קודמים של הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentPreviousDegree, true)
  getStudentPreviousDegreesAsync()
  {
    return resultOf<StudentPreviousDegree[]>();
  }

  @UseState()
  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentPreviousDegreesAsync(
    @FromBody('studentDegrees') degrees: StudentPreviousDegree[])
  {
    return resultOf();
  }
}
