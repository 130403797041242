import { LetterInfo } from './letter-info';

export function lottery(letters: LetterInfo[]) {
  let winner: string = null;

  if (letters[0].incidence) {
    let domain = letters.reduce(
      (accumulator, current) => accumulator + current.incidence,
      0);

    let charset = letters.map(x => x.letter.repeat(Math.floor(x.incidence / domain * 256))).join('');

    winner = charset[Math.floor(Math.random() * charset.length)];
  }
  else {
    let charset = letters.map(x => x.letter).join('');

    winner = charset[Math.floor(Math.random() * charset.length)];
  }

  return letters.find(x => x.letter == winner);
}

export function padding(value: number, count: number = 2) {
  return ('' + value).padStart(count, '0');
}
