
// Types

import { Role } from "../account";

//import * as internal from "stream";

export class  DataForCheckbox {
  nameIdReturn:string;
  nameColSelected:string;
}

// export class  DataForOpenDialog {
//   dialogName:string;
//   idName:string;
// }

export class  Param {
  name:string;
  value:string;
}

export class  ParamWithChecked {
  name:string;
  value:string;
  opt1:boolean;
  opt2:boolean
}

export enum ChairmanOrMember {
  Chairman = 'chairman',
  Member = 'member',
}

export class OtherCommittee{
  committeeName:string;
  chairmanOrMember:string;
}

export enum Operation {
  view = 1,
  edit = 2,
  delete =3,
  contactAs =4
}

export interface GridAction {
  action: number,
  row: any,
  isGrant? :boolean
}

export class LersUser {
  userId: number;
  username: string;
  userTypeName: string;
  userTypeId: number;
  email: string;
  firstName: string;
  lastName:string;
  title:string;
  accessIpaddresses:string;
  hasAccessRight:boolean;
  role:Role

  /**
   *
   */
  constructor(
    userId: number,
    username: string,
    userTypeName: string,
    userTypeId: number,
    email: string,
    firstName: string,
    lastName:string,
    title:string,
    accessIpaddresses:string,
    hasAccessRight:boolean,
    role:Role
  ) {

    this.userId = userId;
    this.username = username;
    this.userTypeName = userTypeName;
    this.userTypeId = userTypeId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.title = title;
    this.accessIpaddresses = accessIpaddresses;
    this.hasAccessRight = hasAccessRight;
    this.role = role;
  }
}



