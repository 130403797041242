import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { RegistrationNavigator, RegistrationUserProfile } from '@jct/api';

@Injectable({ providedIn: 'root' })
export class PayedRegistrationFeeGuard  {
  constructor(
    private profile: RegistrationUserProfile,
    private navigator: RegistrationNavigator) { }

  private guard(url: string) {
    if (!this.profile.isPayedRegistrationFee) {
      this.navigator.navigateToCurrentStage();
      return false;
    }

    return true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }
}
