
import { Component, OnInit, ViewChild } from '@angular/core';

import { UploadResult, EMPTY_UPLOAD_RESULT, FileFamily, Uploader, HttpStatusResult } from '@jct/core';
import { FilesService, StudentFile, StudentFileUpload } from '@jct/api';
import { StateFactory, SnackBarComponent } from '@jct/ui';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";


const MAX_FILE_SIZE = 10485760;
const OTHER_FILE_TYPE = 35;

@Component({
  selector: 'additional-files-tab.page',
  templateUrl: './additional-files-tab.component.html',
})
export class AdditionalFilesTabComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private sf: StateFactory,
    private uploadService: FilesService,
    private uploader: Uploader<StudentFileUpload>,
    private snackBar: MatSnackBar)
  { }

  loadState = this.sf.create();
  saveState = this.sf.create();

  form = this.fb.group({
    description: [null, [ Validators.required]],
  });

  progress: UploadResult<StudentFile | HttpStatusResult> = null;
  studentAdditionalFiles: StudentFile[];
  showAddFileOption: boolean = false;

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.uploadService.getStudentAdditionalFilesAsync();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed();
      return;
    }

    this.studentAdditionalFiles = result;
    this.loadState.completed();
  }

  showAddFileForm() {
    this.showAddFileOption = true;
  }

  cancel() {
    this.showAddFileOption = false;
    this.form.reset();
  }

  downloadPath(file: StudentFile) {
    return `${this.uploadService.baseUrl}/${file.id}`;
  }

  async deleteFile(file: StudentFile) {
    if (!this.saveState.isInProcess) {
      await this.saveState.inProcess();

      const result = await this.uploadService.deleteStudentAdditionalFileAsync(file.id);

      if (!result.succeeded) {
        this.saveState.failed(result);
        return;
      }

      const index = this.studentAdditionalFiles.indexOf(file);

      this.studentAdditionalFiles.splice(index, 1);

      this.fileDeletedMessage.show();
      await this.saveState.completed();
    }
  }

  @ViewChild('fileUploadedMessage')
  fileUploadedMessage!: SnackBarComponent;

  @ViewChild('fileDeletedMessage')
  fileDeletedMessage!: SnackBarComponent;

  async upload(fileInput: HTMLInputElement) {
     if (fileInput.files.length === 0) {
       return;
     }

    await this.saveState.inProcess();
    this.form.disable();

    const fileFamily = [FileFamily.Image, FileFamily.Document];
    const validateResult = this.uploader.validate(
      fileInput.files[0],
      MAX_FILE_SIZE,
      fileFamily);

    if (!validateResult.succeeded) {
      this.saveState.failed(validateResult);
      return;
    }

    this.progress = EMPTY_UPLOAD_RESULT;

    const model = <StudentFileUpload>{
      fileType: OTHER_FILE_TYPE,
      description: this.form.value.description,
      file: fileInput.files[0],
    };

    this.uploadService.upload(model)
      .subscribe(async progress => {
        this.progress = progress;
        const result = progress.result;
        if (result instanceof HttpStatusResult) {
          this.snackBar.open(
            "ארעה שגיאה במהלך שמירת הנתונים, נסה שנית",
            "אישור",
            {
              duration: 5000,
              panelClass: ["warn-snack-bar"],
            }
          );
          this.saveState.failed(result);
          return;
        }
        else if (progress.done) {
          this.studentAdditionalFiles.unshift(result);
          this.form.enable();
          this.cancel();

          this.fileUploadedMessage.show();
          await this.saveState.completed();
         }
        // this.progress = progress;

        // if (progress.done) {
        //   const result = progress.result;

        //   if (result instanceof HttpStatusResult) {
        //     this.saveState.failed(result);
        //     return;
        //   }

        //   this.studentAdditionalFiles.unshift(result);
        //   this.form.enable();
        //   this.cancel();

        //   this.fileUploadedMessage.show();
        //   await this.saveState.completed();
        // }
      });
  }
}
