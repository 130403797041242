
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Authenticator, Application } from '@jct/core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private authenticator: Authenticator,
    private application: Application)
  { }

  private guard(url: string) {
    if (this.authenticator.isAuthenticated) {
      return true;
    }

    this.authenticator.logout(true, url);

    return false;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }
}
