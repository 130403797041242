
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  FromRoute,
  HttpPost,
  FromBody,
  Produce,
  SuccessTestCase,
  SuccessResult,
  SuccessTestCaseProvider,
  HttpStatusResult,
  FailedTestCase,
  VerificationFailedResult,
  UnauthorizedResult,
  InvalidModelResult
} from '@jct/core';

import { CREDIT_GUARD_TEST_PAYMENT_PROVIDER } from './payment.test';

import { DiscountReasonEnum, RegistrationFeeDetails } from './payment.types';

@Injectable({ providedIn: 'root'})
@Controller('registration-fee')
export class RegistrationFeeService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet()
  @SuccessTestCaseProvider(() => (<RegistrationFeeDetails>{
    baseAmount: 400,
    calculatedAmount: 300,
    discounts: [
      {
        discountAmount: 100,
        discountReason: DiscountReasonEnum.הנחת_רישום_מקוון,
      },
    ],
  }), 'קבלת פרטי דמי רישום')
  @Produce(RegistrationFeeDetails)
  getPaymentDetailsAsync() {
    return resultOf<RegistrationFeeDetails>();
  }

  @HttpPost('discount')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  enterOpenDayCodeAsync(
    @FromBody('code') code: string) {
    return resultOf();
  }

  @HttpPost()
  @SuccessTestCase(CREDIT_GUARD_TEST_PAYMENT_PROVIDER(), 'קבלת קישור לתשלום ב-CreditGuard')
  @FailedTestCase(UnauthorizedResult)
  @Produce('string')
  beginPaymentProcessViaCGAsync() {
    return resultOf<string>();
  }

  @HttpPost('{transactionId}/success')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(VerificationFailedResult)
  @Produce(HttpStatusResult)
  endPaymentAsync(
    @FromRoute('transactionId') transactionId: string,
    @FromBody('token') token: string) {
   return resultOf();
  }

  @HttpPost('{transactionId}/failed')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(VerificationFailedResult)
  @Produce(HttpStatusResult)
  receiveFailedPaymentAsync(
    @FromRoute('transactionId') transactionId: string) {
    return resultOf();
  }

  @HttpPost('{transactionId}/cancel')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(VerificationFailedResult)
  @Produce(HttpStatusResult)
  cancelPaymentAsync(
    @FromRoute('transactionId') transactionId: string) {
    return resultOf();
  }
}
