
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title i18n>
        מידע על המחשבון
      </mat-panel-title>
    </mat-expansion-panel-header>

    <article>
      <p i18n>
        במערכת שלפניך ניתן לחשב את ציון ההתאמה, המהווה מרכיב מרכזי (אם כי לא יחידי) בשיקולים לקבלתך ללימודים<br>
        במרכז האקדמי לב.
      </p>

      <p>
        <span i18n>ציון ההתאמה מבוסס על ממוצע הבגרות המשוקלל</span>
        (<span i18n>שדרך חישובו מוסברת </span>
        <ui-link to="https://www.jct.ac.il/guide/2023-24/" i18n>
          במדריך לנרשם
        </ui-link>)
        <br>
        <span i18n>ועל אחד משני הציונים, ציון פסיכומטרי או ציון מבחן תיל.</span>
      </p>

      <p>
        <span i18n>ציון ההתאמה מחושב ע"פ הנוסחה הבאה</span>:
        <br>
        (0.02 X
        <strong i18n>פסיכומטרי</strong>&nbsp;
        <span i18n>או</span>
        0.019 X
        <strong i18n>תיל</strong>)
        + (0.35 X
        <strong i18n>ממוצע בגרות משוקלל מיטבי</strong>)
        + 35.703 =
        <strong i18n>ציון ההתאמה</strong>
      </p>

      <strong i18n>התיחסות לבונוסים</strong>

      <p i18n>
        ההנחיות להלן, מיועדות לחישוב ממוצע בגרות של בעל תעודת בגרות ישראלית
        <strong>ברמה של 4 יחידות ומעלה בציון 60 לפחות</strong>
        במקצועות הבאים:
      </p>

      <div class="table-responsive mat-elevation-z2">
        <table>
          <thead>
            <tr>
              <th i18n>מקצועות</th>
              <th i18n>
                <abbr i18n-matTooltip matTooltip="יחידות לימוד">יח"ל</abbr>
              </th>
              <th i18n>נק' בונוס</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td rowspan="2" i18n>מתמטיקה</td>
              <td>5</td>
              <td>35</td>
            </tr>

            <tr>
              <td>4</td>
              <td>12.5</td>
            </tr>

            <tr>
              <td rowspan="2" i18n>
                אנגלית,
                טכנולוגיה מוכללת,
                פיסיקה,
                תושב"ע,
                תלמוד,
                תנ"ך
              </td>
              <td>5</td>
              <td>25</td>
            </tr>

            <tr>
              <td>4</td>
              <td>12.5</td>
            </tr>

            <tr>
              <td rowspan="2" i18n>
                אופטיקה יישומית,
                אזרחות,
                אלקטרו-אופטיקה,
                אלקטרוניקה ומחשבים,
                אלקטרוניקה ותקשורת,
                אלקטרוניקה,
                אמנות,
                אמנות הקולנוע,
                אמנות התיאטרון,
                ביוטכנולוגיה,
                ביולוגיה חקלאית,
                ביולוגיה,
                בינה מלאכותית ומער' מומחה,
                בקרה ומחשבים,
                גיאוגרפיה,
                הבעה עברית,
                היסטוריה,
                חקלאות,
                חשבונאות,
                חשמל,
                יישומים מנהליים,
                כימיה טכנולוגית,
                כימיה,
                כלכלה,
                כלכלה ומנהל,
                לימודי א"י,
                מדע חישובי,
                מדעי הבריאות*,
                מדעי ההנדסה,
                מדעי החיים והחקלאות,
                מדעי החיים,
                מדעי הטבע,
                מדעי הטכנולוגיה,
                מדעי המחשב,
                מדעי הסביבה,
                מוסיקה,
                מחשבים ומערכות,
                מחשוב ובקרה,
                מחשבת ישראל,
                מנהל וכלכלה,
                מיקרוביולוגיה,
                מכניקה הנדסית,
                מכשור בקרה ומחשבים,
                מכשור ובקרה,
                מכשירים ופיקוד,
                מערכות אלקטרו-אופטיות,
                מערכות אלקטרוניות,
                מערכות ביו-טכנולוגיות,
                מערכות בקרה ממוחשבות,
                מערכות חשמל,
                מערכות מידע ממוחשבות,
                מערכות מכשור ובקרה,
                מערכות סיב"מ,
                מערכות פיקוד ובקרה,
                מערכות פיקוד,
                מערכות תוכנה וחומרה,
                מערכות תיב"מ,
                מערכות תיכון וייצור,
                מערכות תקשוב,
                מערכות תקשורת ומיתוג,
                ניהול משאבי אנוש,
                ניהול הייצור,
                ניהול התפעול,
                ניתוח מערכות וארגון קבצים,
                ספרות,
                פסיכולוגיה התפתחותית*,
                שיווק וקידום מכירות,
                תולדות האמנות,
                תולדות עם ישראל,
                תורת החשמל,
                תורת הסיעוד*,
                תכנון הנדסי של מבנים,
                תכנון ותכנות מערכות,
                תכנון יישומים מנהליים,
                תכנון מערכות מנהליים,
                תעשייה כימית,
                תקשורת,
                תקשורת המונים
              </td>
              <td>5</td>
              <td>20</td>
            </tr>

            <tr>
              <td>4</td>
              <td>10</td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="3" i18n>
                * עבור החוג לסיעוד בלבד
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <p i18n>
        לצורך השימוש במערכת רצוי להצטייד בתעודת הבגרות, ציון הפסיכומטרי/תיל.<br>
        הציון המחושב מאפשר לדעת האם הנך עומד ברף ציון ההתאמה<br>
        וכן מאפשר לך לערוך סימולציה על סיכוייך להתקבל במידה ותשפר את נתוניך (בגרות או פסיכומטרי)
      </p>

      <strong i18n>
        זכור,<br>
        החישוב משמש מידע למועמד בלבד ואינו מבטיח קבלה.<br>
        כמו כן מערכת זו אינה מהווה תחליף לרישום.
      </strong>

      <p i18n>
        על מנת להירשם עליך להגיש טפסי רישום עם המסמכים הדרושים למדור מידע ורישום תלמידים, לעבור ראיון אישי.<br>
        ובסיום התהליך בהצלחה, תקבל מכתב קבלה.
      </p>

      <p>
        <span i18n>
          מועמד יקר שלום,
          ציון ההתאמה הינו שקלול ממוצע הבגרות וציון הפסיכומטרי או מבחן מקביל.
        </span>
        <br>
        <span i18n>
          ציון ההתאמה הקובע לקבלה למרכז האקדמי לב לכל המחלקות הינו 79 ומעלה.<br>
          בחלק מהמחלקות ניתן להתקבל על סמך ממוצע בגרות גבוה 106 ומעלה <br>
          ובתנאי שיש לך את ספי הקבלה הנדרשים בבגרות באנגלית ובמתמטיקה,<br>
          סיווג רמה באנגלית  ועברת ראיון קבלה אישי.<br>
          פרטים נוספים על ספי הקבלה לחוגי הלימוד השונים מופיעים באתר הרישום בקישור
        </span>

        <ui-link to="https://www.jct.ac.il/%d7%9e%d7%95%d7%a2%d7%9e%d7%93%d7%99%d7%9d" i18n>
          "מידע לנרשמים"
        </ui-link>.
        <br>
        <span i18n>
          להלן חישוב המהווה כלי עזר בלבד, אינו מחליף את הרישום הפורמאלי ואינו מהווה אישור קבלה.
        </span>
      </p>

      <p i18n>
        מטרת הדף הנה לחשב עבורך את ציון ההתאמה ואינו מתייחס לכל ספי הקבלה הנדרשים.
      </p>
    </article>
  </mat-expansion-panel>
</mat-accordion>
