
import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'get',
  pure: false
})
export class GetValuePipe implements PipeTransform {
  transform(form: UntypedFormGroup, fieldName: string): any {
    let field = form.get(fieldName);

    if (field) {
      return field.value;
    }

    return null;
  }
}
