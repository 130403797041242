
export enum DiscountReasonEnum {
  הנחת_רישום_מקוון = 'הנחת_רישום_מקוון',
  הנחת_תלמיד_חוזר = 'הנחת_תלמיד_חוזר',
  הנחת_יום_פתוח = 'הנחת_יום_פתוח',
  הנחת_נרשם_למכינה = 'הנחת_נרשם_למכינה',
}

export interface PaymentWindowMessage {
  action: 'success' | 'failed' | 'cancel';
  transactionId: string;
  token?: string;
  isStandingOrder?: boolean;
}

export enum PaymentTypes {
  Amiram = "amiram-test",
  StandingOrder = "standing-order",
  RegistrationFee = "registration-fee",
  Voucher = "voucher",
  Dormitory = "dormitory",
  Donation = "donation",
}

export interface LandingPagePaymentData {
  action: 'success' | 'failed' | 'cancel';
  transactionId: string;
  token?: string;
  paymentType: PaymentTypes;
}

export class RegistrationFeeDetails {
  baseAmount: number;
  discounts: RegistrationFeeDiscountModel[];
  calculatedAmount: number;
}

export interface RegistrationFeeDiscountModel {
  discountReason: DiscountReasonEnum;
  discountAmount: number;
}
