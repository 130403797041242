
<div container>

  <ui-error [state]="loadState"></ui-error>

  <form
    [formGroup]="form"
    autocomplete="off"
    *visible="loadState.isCompleted"
    novalidate>

    <span i18n>ניגשתי / אגש למבחן: </span>
    <mat-radio-group formControlName="generalExamType">
      <mat-radio-button [value]="GeneralExamTypes.Psychometry" i18n>פסיכומטרי</mat-radio-button>
      <mat-radio-button [value]="GeneralExamTypes.Til" i18n>תיל</mat-radio-button>
      <mat-radio-button [value]="GeneralExamTypes.Sat" i18n>SAT</mat-radio-button>
      <mat-radio-button [value]="GeneralExamTypes.Act" i18n>ACT</mat-radio-button>
      <mat-radio-button [value]="GeneralExamTypes.Meimad" i18n>מימד</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="fill">
      <mat-label i18n>תאריך הבחינה</mat-label>

      <input matInput
        [matDatepicker]="psychoTestPicker"
        formControlName="generalExamDate">

      <mat-hint i18n>
        תאריך בפורמט DD/MM/YYYY
      </mat-hint>

      <mat-datepicker-toggle matSuffix [for]="psychoTestPicker">
      </mat-datepicker-toggle>

      <mat-datepicker #psychoTestPicker
        [touchUi]="appLayout.isMobile">
      </mat-datepicker>

      <mat-error i18n>תאריך לא תקין</mat-error>
    </mat-form-field>

    <span i18n>ניגשתי / אגש למבחן באנגלית: </span>
    <mat-radio-group formControlName="englishExamType">
        <mat-radio-button [value]="EnglishExamTypes.Amir" i18n>אמיר</mat-radio-button>
        <mat-radio-button [value]="EnglishExamTypes.Amiram" i18n>אמירם</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="fill">
      <mat-label i18n>תאריך הבחינה באנגלית</mat-label>

      <input matInput
        [matDatepicker]="englishTestPicker"
        formControlName="englishExamDate">

      <mat-datepicker-toggle matSuffix
        [for]="englishTestPicker">
      </mat-datepicker-toggle>

      <mat-datepicker #englishTestPicker
        [touchUi]="appLayout.isMobile">
      </mat-datepicker>

      <mat-error i18n>תאריך לא תקין</mat-error>
    </mat-form-field>

    <span i18n>הערות חשובות:</span>
    <ul>
      <li i18n>
        יש לצרף מסמך בסוף תהליך הרישום, במידה ויש לך ציון.
      </li>
      <li i18n>
        חובה על סטודנט שמתקבל על סמך ציון מבחן תיל להגיש סיווג רמה באנגלית עד ליום
        הראשון ללימודים בסמסטר אלול. להלן במסמכי רשות בטבלה למטה.
      </li>
      <li i18n>
        לנוחיותך, מבחני אמיר"ם יתקיימו בתשלום בקמפוסים השונים החל מחודש אפריל. הודעה מסודרת
        על רישום לבחינה תשלח לכל המתקבלים.
      </li>
    </ul>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [form]="form"
      [state]="saveState"
      (progress)="submit()"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>
