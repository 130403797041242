import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { guid } from '@jct/core';
import { Direction, PageLayout } from '@jct/localization';

const CURRENT_YEAR = new Date().getFullYear();
const ALL_YEARS_PROVIDER = () => {
  const count = 10;
  const allYears: number[] = [];

  for (let index = 0; index < count; index++) {
    allYears.push(CURRENT_YEAR + index);
  }

  return allYears;
};

@Component({
  selector: "credit-guard-test.page",
  templateUrl: "./credit-guard-test-page.html",
})
export class CreditGuardTestPage implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private pageLayout: PageLayout,
    private activatedRoute: ActivatedRoute
  ) {}

  allYears: number[] = ALL_YEARS_PROVIDER();
  allMonths: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  form = this.fb.group({
    idNumber: [null],
    creditCard: [null],
    expYear: [null],
    expMonth: [null],
    token: [guid()],
    txId: [guid()],
    cvv: [null, [Validators.required, Validators.minLength(3)]],
    exp: [
      null,
      [Validators.required, CreditGuardTestPage.expiredDateValidator],
    ],
  });

  isStandingOrder = false;
  paymentType='donation';

  layout: Direction = "rtl";

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isStandingOrder = params.standingOrder === "true";
      this.layout = params.layout || "rtl";
      this.pageLayout.rtl = this.layout === "rtl";
    });

    this.form.get("expYear").valueChanges.subscribe((value) => {
      const expMonth = this.form.get("expMonth").value;

      if (expMonth) {
        const year = ("" + value).padStart(4);
        const month = ("" + expMonth).padStart(2, "0");

        this.form.patchValue({
          exp: `${month}${year}`,
        });
      }
    });

    this.form.get("expMonth").valueChanges.subscribe((value) => {
      const expYear = this.form.get("expYear").value;

      if (expYear) {
        const year = ("" + expYear).padStart(4);
        const month = ("" + value).padStart(2, "0");

        this.form.patchValue({
          exp: `${month}${year}`,
        });
      }
    });
  }

  submit() {
    const queryParams =
      `token=${this.form.get("token").value}` +
      `&txId=${this.form.get("txId").value}` +
      `&standingOrder=${this.isStandingOrder}` +
      `&paymentType=${this.paymentType}`;

    //localhost:4005/payments/redirect/cancel?paymentType=donation&txId=901e4ef6-5b5e-4252-9ecc-a4a8e603bdbb&uniqueID=1234-5783-20230315090737&lang=HE
    // http: 
    this.router.navigateByUrl(
      `/payments/redirect/success?${queryParams}`
    );
  }

  cancel() {
    const queryParams =
      `&txId=${this.form.get("txId").value}` +
      `&standingOrder=${this.isStandingOrder}` +
      `&paymentType=${this.paymentType}`;;

    this.router.navigateByUrl(`/payments/redirect/cancel?${queryParams}`);
  }

  failed() {
    const queryParams =
      `&txId=${this.form.get("txId").value}` +
      `&standingOrder=${this.isStandingOrder}` +
      `&paymentType=${this.paymentType}`;;

    this.router.navigateByUrl(`/payments/redirect/failed?${queryParams}`);
  }

  static expiredDateValidator(control: AbstractControl) {
    const value = control.value as string;
    const month = parseInt(value?.substr(0, 2));
    const year = parseInt(value?.substr(2, 4));
    const someDate = new Date();
    const now = someDate.getTime();

    if (year > 0 && month > 0) {
      someDate.setFullYear(year, month - 1, 1);
    }

    if (month > 0 && year > 0 && someDate.getTime() > now) {
      return null;
    } else {
      return { invalidExpiredDate: true };
    }
  }
}
