
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { TimeSpan } from '@jct/core/system';

import { HttpStatusResult, Authenticator } from '@jct/core';
import { AccountService, ConfigureTfaModel } from '@jct/api';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'configure-tfa-page',
  templateUrl: './configure-tfa-page.html',
})
export class ConfigureTfaPage implements OnInit {
  private _timer: any;

  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private authenticator: Authenticator,
    private router: Router)
  { }

  loadState = this.sf.create();
  startState = this.sf.create();
  state: any;

  form = this.fb.group({
    token: [''],
  });

  async ngOnInit() {
    await this.loadState.inProcess();
    this.state = this.authenticator.loadState();

    if (!this.state) {
      this.reset();
      return;
    }

    if (!this.state['token']) {
      this.reset();
    }
    else {
      this.form.patchValue({
        token: this.state.token,
      });

      const tokenLifeTime = TimeSpan.parse(this.state.tokenLifeTime);

      this._timer = setTimeout(() => {
        this.reset();
      }, tokenLifeTime.totalSeconds * 1000);

      this.loadState.completed(10);
    }
  }

  ngOnDestroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  reset() {
    this.loadState.quit();
    this.authenticator.clearState();
    this.router.navigate(['/admin/login']);
  }

  async start() {
    if (!this.startState.isInProcess) {
      this.form.disable();
      this.startState.inProcess();

      const model = this.form.value as ConfigureTfaModel;
      const result = await this.accountService.configure(model);

      if (result instanceof HttpStatusResult) {
        this.form.enable();
        this.startState.failed(result);
        return;
      }

      this.authenticator.storeState({ ...this.state, ...result });

      await this.router.navigate(['/admin/display-qrcode']);
      await this.startState.completed();
    }
  }
}
