
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const XSMALL_DEVICE_SCREEN = 600;
export const SMALL_DEVICE_SCREEN = 1024;
export const MEDIUM_DEVICE_SCREEN = 1440;
export const LARGE_DEVICE_SCREEN = 1920;

export type Layouts = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
export type Themes = 'logo-purple';

export interface MediaQuery {
  min?: number;
  max?: number;
  name: Layouts;
}

export const SUPPORTED_MEDIA_QUERY: MediaQuery[] = [
  {
    max: XSMALL_DEVICE_SCREEN - 0.01,
    name: 'xsmall',
  },
  {
    min: XSMALL_DEVICE_SCREEN,
    max: SMALL_DEVICE_SCREEN - 0.01,
    name: 'small',
  },
  {
    min: SMALL_DEVICE_SCREEN,
    max: MEDIUM_DEVICE_SCREEN - 0.01,
    name: 'medium',
  },
  {
    min: MEDIUM_DEVICE_SCREEN,
    max: LARGE_DEVICE_SCREEN - 0.01,
    name: 'large',
  },
  {
    min: LARGE_DEVICE_SCREEN,
    name: 'large',
  },
];

@Injectable({
  providedIn: 'root'
})
export class AppLayoutService {
  private _layout: Layouts;
  private _subject = new Subject<Layouts>();

  constructor() {
    for (let media of SUPPORTED_MEDIA_QUERY) {
      let minQuery = media.min ? `(min-width: ${media.min}px)` : '';
      let maxQuery = media.max ? `(max-width: ${media.max}px)` : '';
      let query = '';

      if (minQuery && maxQuery) {
        query = `${minQuery} and ${maxQuery}`;
      }
      else if (minQuery) {
        query = minQuery;
      }
      else {
        query = maxQuery;
      }

      let mql = window.matchMedia(query);

      mql.addListener((e: MediaQueryListEvent) => {
        if (e.matches) {
          this._layout = media.name;
          this._subject.next(media.name);
        }
      });

      if (mql.matches) {
        this._layout = media.name;
      }
    }
  }

  get layoutChanges(): Observable<Layouts> {
    return this._subject;
  }

  get layout(): Layouts {
    return this._layout;
  }

  get isXSmall() {
    return this._layout == 'xsmall';
  }

  get isSmall() {
    return this._layout == 'small';
  }

  get isMobile() {
    return this._layout == 'xsmall' ||
      this._layout == 'small';
  }

  get isMedium() {
    return this._layout == 'medium';
  }

  get isTablet() {
    return this._layout == 'medium';
  }

  get isDesktop() {
    return this._layout == 'large' ||
      this._layout == 'xlarge';
  }

  get isLarge() {
    return this._layout == 'large';
  }

  get isXLarge() {
    return this._layout == 'xlarge';
  }
}
