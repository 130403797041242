export class Column {
  display: string;
  variable: string;
  filter: string;
}

export class HebrewAcademicYearsModel {
  academicYearId: number;
  academicYearName: string;
}

export class TestTimeType {
  id: number;
  testTimeTypeName: string;
  testTimeTypeEnglishName?: string;
  testTimeTypeShortName: string;
}

export class Semester {
  id: number;
  semesterName: string;
  semesterShortName: string;
  englishName: string;
}

