
export class TypesListItem {
  id: number;
  name: string;
};

export class StudentPreviousDegree {
  degreeTypeId: number;
  degreeTrackId: number;
  average: number;
};
