
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../guards/auth.guard';
import { StudentComponent } from './student.component';
import { AmiramTestPage } from './+amiram-test';
import { ConfirmationPageComponent } from './+confirmation';
import { DormitoryPageComponent } from './+dormitory';
import { InterviewPageComponent } from './+interview';
import { StandingOrderPage } from './+standing-order';
import { VouchersPage } from './+vouchers';
import { AcceptedStateGuard } from '../guards/accepted-state.guard';

export const STUDENT_ROUTES: Routes = [
  {
    path: '',
    component: StudentComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'amiram-test',
        component: AmiramTestPage,
      },
      {
        path: 'confirmation',
        component: ConfirmationPageComponent,
        canActivate: [AcceptedStateGuard],
      },
      {
        path: 'dormitory',
        component: DormitoryPageComponent,
        canActivate: [AcceptedStateGuard],
      },
      {
        path: 'interview',
        component: InterviewPageComponent,
      },
      {
        path: 'standing-order',
        component: StandingOrderPage,
        canActivate: [AcceptedStateGuard],
      },
      {
        path: 'vouchers',
        component: VouchersPage,
        canActivate: [AcceptedStateGuard],
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(STUDENT_ROUTES)
  ],
  exports: [RouterModule]
})
export class StudentRoutingModule { }
