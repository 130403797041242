
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { RegistrationNavigator, RegistrationUserProfile } from '@jct/api';

@Injectable({
  providedIn: 'root',
})
export class StudyTrackGuard  {
  constructor(
    private profile: RegistrationUserProfile,
    private navigator: RegistrationNavigator)
  { }

  private guard(url: string) {
    if (this.profile?.academicStudyType > 0) {
      return true;
    }

    this.navigator.navigateToCurrentStage();
    return false;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }
}
