import { Type } from '@angular/core';

import { Map } from '../common';
import { HttpMethod } from './types';
import '../extensions';

import {
  RouteDescriptor,
  ActionDescriptor,
  FieldDescriptor,
  ClassDescriptor,
  ControllerDescriptor,
} from './descriptors';

const INTERNAL_ID_NAME = '_id';

export namespace Console {
  const ERROR_STYLE = 'font-size:14px;background:#933;color:#fff;border-radius:4px;padding:4px 10px 4px 4px;';
  export const error = (message: string, ...parameters: any[]) =>
    console.log('%c❌ ' + message + parameters.map(x => ' ' + x).join(''), ERROR_STYLE);

  const WARNING_STYLE = 'font-size:14px;background:#e72;color:#fff;border-radius:4px;padding:4px 10px 4px 4px;';
  export const warning = (message: string, ...parameters: any[]) =>
    console.log('%c⚠️ ' + message + parameters.map(x => ' ' + x).join(''), WARNING_STYLE);

  const DEBUG_STYLE = 'font-size:14px;background:#27e;color:#fff;border-radius:4px;padding:4px 10px 4px 4px;';
  export const debug = (message: string, ...parameters: any[]) =>
    console.log('%c🛠️ ' + message + parameters.map(x => ' ' + x).join(''), DEBUG_STYLE);
}

export namespace ApiDescriptionProvider {
  const keys: {[name: string]: number} = {};

  function getKey(type: any, family: string): number {
    let key = <number|null>type[INTERNAL_ID_NAME] || null;

    if (key === null) {
      keys[family] = keys[family] || 1;
      key = keys[family]++;
      type[INTERNAL_ID_NAME] = key;
    }

    return key;
  }

  //#region State

  let _states: Map<{ state: any, expired: number }> = {};

  function _stateKey(
    templatePath: string)
  {
    if (templatePath.startsWith('/')) {
      templatePath = templatePath.substring(1);
    }

    if (templatePath.endsWith('/')) {
      templatePath = templatePath.substring(0, templatePath.length - 1);
    }

    return `${templatePath}`;
  }

  export function getState(templatePath: string) {
    const stateKey = _stateKey(templatePath);
    const stateObject = _states[stateKey];

    if (!stateObject) {
      return null;
    }

    const { expired, state } = stateObject;

    if (expired && expired > Date.now() && state) {
      return state;
    }

    return null;
  }

  export function setState(templatePath: string, state?: any) {
    const stateKey = _stateKey(templatePath);

    _states[stateKey] = {
      state,
      expired: new Date().addHours(24).getTime()
    };
  }

  export function clearAllStates() {
    _states = {};
  }

  //#endregion

  //#region Route

  const _routes: Map<RouteDescriptor> = {};

  export function normalizePath(templatePath: string) {
    return templatePath.trim('/');
  }

  export function combineTemplatePath(...templatePaths: string[]) {
    return templatePaths
      .filter(x => typeof x === 'string' && x.trim().length > 0)
      .map(x => normalizePath(x.trim()))
      .join('/');
  }

  function _routeKey(
    httpMethod: HttpMethod,
    templatePath: string)
  {
    return `${httpMethod}_${templatePath.trim('/')}`;
  }

  export function getRouteDescriptor(httpMethod: HttpMethod, templatePath: string) {
    const routeKey = _routeKey(httpMethod, templatePath);
    let route = _routes[routeKey];

    if (!route) {
      route = new RouteDescriptor(routeKey, httpMethod, templatePath);
      _routes[routeKey] = route;
    }

    return route;
  }

  function searchRoutes(partialKey: string): RouteDescriptor[] {
    let matches: RouteDescriptor[] = [];

    for (let key in _routes) {
      if (key.startsWith(partialKey)) {
        matches.push(_routes[key]);
      }
    }

    return matches;
  }

  export function matchRoute(httpMethod: HttpMethod, relativeUrl: string): RouteDescriptor|null {
    let queryStringStart = relativeUrl.indexOf('?');

    if (queryStringStart > 0) {
      relativeUrl = relativeUrl.substring(0, queryStringStart);
    }

    relativeUrl = normalizePath(relativeUrl);

    let segments = relativeUrl.split('/');
    let matches = searchRoutes(`${httpMethod}_${segments[0]}`);
    let exact = matches.filter(x => x.templatePath === relativeUrl);

    if (exact.length > 0) {
      return exact[0];
    }

    matches = matches.filter(x => {
      let path = x.templatePath;
      let queryStringStart = path.indexOf('?');

      if (queryStringStart > 0) {
        path = path.substring(0, queryStringStart);
      }

      path = normalizePath(path);

      let parts = path
        .split('/')
        .filter(x => x?.trim().length > 0);

      if (parts.length === segments.length) {
        let compareTemplate = parts
          .map((part, index) => part.startsWith('{') && part.endsWith('}') ? segments[index] : part)
          .join('/');

        return compareTemplate === relativeUrl;
      }

      return false;
    });

    if (matches.length === 0) {
      Console.warning(`no found any route for request ${httpMethod} ${relativeUrl}`);
      return null;
    }

    return matches[0];
  }

  //#endregion

  //#region Class

  const _classes: Map<ClassDescriptor> = {};

  export function getClassDescriptor(classType: Type<any>) {
    const classId = getKey(classType, 'classes');

    let _class = _classes[classId];

    if (!_class) {
      _class = new ClassDescriptor(classId, classType);
      _classes[classId] = _class;
    }

    return _class;
  }

  export function setFieldDescriptor(
    setter: (descriptor: FieldDescriptor) => void,
    type: any,
    fieldName: string)
  {
    const _class = getClassDescriptor(type.constructor);

    if (_class.fieldsMap[fieldName]) {
      setter(_class.fieldsMap[fieldName]);
    }
    else {
      let field = new FieldDescriptor(fieldName);
      setter(field);
      _class.fields.push(field);
      _class.fieldsMap[fieldName] = field;
    }
  }

  //#endregion

  //#region Controller

  const _controllers: Map<ControllerDescriptor> = {};

  export function getControllerDescriptor(controllerType: Type<any>) {
    if (!controllerType.name) {
      Console.warning('controllerType invalid for:', controllerType);
    }

    const controllerId = getKey(controllerType, 'controllers');
    let controller = _controllers[controllerId];

    if (!controller) {
      controller = new ControllerDescriptor(controllerId, controllerType);
      _controllers[controllerId] = controller;
    }

    return controller;
  }

  //#endregion

  //#region Action

  const _actions: Map<ActionDescriptor> = {};

  export function getActionDescriptor(controllerType: Type<any>, actionName: string) {
    const controller = getControllerDescriptor(controllerType);
    const actionKey = controller.controllerId + ':' + actionName;

    let action = _actions[actionKey];

    if (!action) {
      action = new ActionDescriptor(actionKey, controllerType, actionName);
      _actions[actionKey] = action;

      controller.actions.push(action);
      controller.actionsMap[actionName] = action;

      action.controller = controller;
    }

    return action;
  }

  //#endregion

  export function getAllMaps() {
    return {
      actions: _actions,
      routes: _routes,
      controllers: _controllers,
      classes: _classes,
      get states() { return _states },
      set states(value: any) { _states = value; },
    };
  }
}
