import { Injectable, Injector } from "@angular/core";

import {
  Controller,
  HttpGet,
  FromQueryString,
  Cache,
  resultOf,
  FromRoute,
  SuccessTestCase,
  Produce,
} from "@jct/core";

import {
  HebrewAcademicYearsModel,
  Semester,
  TestTimeType,
} from "../../shared/shared.types";

import {
  FAKE_COUNTRIES,
  FAKE_CITIES,
  FAKE_STREETS,
  FAKE_ZIP_CODE,
} from "./information.test";

@Injectable({ providedIn: "root" })
@Controller("common")
@Cache("30d")
export class CommonService {
  constructor(protected injector: Injector) {}

  @HttpGet("country", { reportProgress: false })
  @SuccessTestCase(FAKE_COUNTRIES, "קבלת שמות ארצות")
  @Produce("string", true)
  searchCountryNameAsync(@FromQueryString("query") query: string) {
    return resultOf<string[]>();
  }

  @HttpGet("city", { reportProgress: false })
  @SuccessTestCase(FAKE_CITIES, "קבלת שמות ערים בארץ")
  @Produce("string", true)
  searchCityNameAsync(@FromQueryString("query") query: string) {
    return resultOf<string[]>();
  }

  @HttpGet("city/{cityName}/street", { reportProgress: false })
  @SuccessTestCase(FAKE_STREETS, "קבלת שמות רחובות")
  @Produce("string", true)
  searchStreetNameAsync(
    @FromRoute("cityName") cityName: string,
    @FromQueryString("query") query: string
  ) {
    return resultOf<string[]>();
  }

  @HttpGet("zipcode", { reportProgress: false })
  @SuccessTestCase(FAKE_ZIP_CODE, "קבלת מיקוד לפי כתובת")
  @Produce("string")
  getZipCodeAsync(
    @FromQueryString("cityName") cityName: string,
    @FromQueryString("streetName") streetName: string,
    @FromQueryString("houseNumber") houseNumber: number
  ) {
    return resultOf<string>();
  }

  @HttpGet("getTwoHebrewConsecutiveYears/{currentYearId}")
  @Produce(HebrewAcademicYearsModel, true)
  getTwoHebrewConsecutiveYears(
    @FromRoute("currentYearId") currentYear: number
  ) {
    return resultOf<HebrewAcademicYearsModel[]>();
  }

  @HttpGet("getTestTimeTypes")
  @Produce(TestTimeType, true)
  getTestTimeTypes() {
    return resultOf<TestTimeType[]>();
  }

  @HttpGet("getSemesters")
  @Produce(Semester, true)
  getSemesters() {
    return resultOf<Semester[]>();
  }
}
