
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountService, GeneratePasswordState, ProfileService, RegistrationNavigator } from '@jct/api';
import { Authenticator, HttpStatusResult} from '@jct/core';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'generate.page',
  templateUrl: './generate.page.html',
})
export class GeneratePage implements OnInit {
  constructor(
    private sf: StateFactory,
    private route: ActivatedRoute,
    private auth: Authenticator,
    private navigator: RegistrationNavigator,
    private profileService: ProfileService,
    private accountService: AccountService)
  { }

  loadState = this.sf.create();
  result: GeneratePasswordState;

  async ngOnInit() {
    await this.loadState.inProcess();

    const token = this.route.snapshot.queryParamMap.get('token');
    const result = await this.accountService.generatePassword({ token });

    if (result instanceof HttpStatusResult) {
      this.loadState.failed(result);
      return;
    }

    this.result = result;
    this.loadState.completed();
  }

  async login() {
    await this.auth.authenticate(this.result);
    await this.profileService.loadRegistrationProfile();
    await this.navigator.navigateToCurrentStage();
  }
}
