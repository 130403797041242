
import { Component, ViewEncapsulation } from '@angular/core';

import { SUPPORTED_LANGUAGES } from '../core';
import { Language } from '../language';
import { Localization } from '../localization.service';
import { PageLayout } from '../page-layout';

@Component({
  selector: 'language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
  providers: [Localization],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageMenuComponent {
  constructor(
    public pageLayout: PageLayout,
    private localization: Localization)
  { }

  setLanguage(language: Language) {
    this.localization.change(language);
  }

  get supportedLanguages() {
    return SUPPORTED_LANGUAGES;
  }

  get currentLanguage() {
    return this.localization.current;
  }

  get isEnabled() {
    return this.localization.enabled;
  }
}
