
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { StateFactory } from '@jct/ui';
import {
  AntiforgeryService,
  AccountService,
  ResetPasswordModel
} from '@jct/api';
import { Application, HttpStatusResult } from '@jct/core';

@Component({
  selector: 'identify-form.page',
  templateUrl: './identify-form.html',
})
export class IdentifyForm implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private af: AntiforgeryService,
    private accountService: AccountService,
    private application: Application,
    private router: Router,
    private activatedRoute: ActivatedRoute)
  { }

  loadState = this.sf.create();
  identifyState = this.sf.create();

  emailAddress: string;

  form = this.fb.group({
    key: [''],
    method: ['idNumber'],
    passwordGeneratorRelativePath: [this.application.passwordGeneratorPath],
  });

  get method() {
    return this.form.get('method').value;
  }

  async ngOnInit() {
    await this.loadState.inProcess();
    let result = await this.af.generateAsync();

    if (!result.succeeded) {
      this.loadState.failed(result);
      return;
    }

    this.form.get('method').valueChanges.subscribe(val => {
      this.form.get('key').reset('');
      this.identifyState.clearError();
    });

    await this.loadState.completed();
  }

  async identify() {
    if (!this.identifyState.isInProcess) {
      this.form.disable({ emitEvent: false });
      this.identifyState.inProcess();

      let model = <ResetPasswordModel>this.form.value;
      const result = await this.accountService.resetPassword(model);

      if (result instanceof HttpStatusResult) {
        this.form.enable({emitEvent: false});
        this.identifyState.failed(result);
        return;
      }
      this.emailAddress = result;

      await this.router.navigate(['/reset-process/get-reset-link'], {
        queryParams: { emailAddress: this.emailAddress },
        skipLocationChange: true,
      });

      this.identifyState.completed();
    }
  }
}
