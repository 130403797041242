
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UploadPageComponent } from './upload-page.component';
import { AuthGuard } from '../guards/auth.guard';
import { RegistrationFilesTabComponent } from './+registration-files';
import { AdditionalFilesTabComponent } from './+additional-files';

export const UPLOAD_MODULE_ROUTES: Routes = [
  {
    path: 'upload',
    redirectTo: '/upload/registration-files',
    pathMatch: 'full'
  },
  {
    path: '',
    component: UploadPageComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'registration-files',
        pathMatch: 'full'
      },
      {
        path: 'registration-files',
        component: RegistrationFilesTabComponent,
      },
      {
        path: 'additional-files',
        component: AdditionalFilesTabComponent,
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(UPLOAD_MODULE_ROUTES)
  ],
  exports: [RouterModule]
})
export class UploadRoutingModule { }
