
import { Component, OnInit } from '@angular/core';

import { AmiramService, StudentAmiramTestModel } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'student-amiram-tests',
  templateUrl: './student-amiram-tests.html',
})
export class StudentAmiramTests implements OnInit {
  constructor(
    private sf: StateFactory,
    private amiramService: AmiramService)
  { }

  loadState = this.sf.create();
  studentAmiramTests: StudentAmiramTestModel[];
  displayedColumns = ['date', 'hour', 'campus', 'action'];

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.amiramService.getStudentCurrentAmiramTestsAsync();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed(result);
      return;
    }

    this.studentAmiramTests = result;

    this.loadState.completed();
  }

  downloadPath(studentTest: StudentAmiramTestModel) {
    return this.amiramService.certificateLink(studentTest);
  }
}
