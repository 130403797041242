
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  FromQueryString,
  resultOf,
  Produce
} from '@jct/core';
import { LdapStatisticsModel } from '../shared';

@Injectable({ providedIn: 'root' })
@Controller('dashboard')
export class DashboardService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet('ldap')
  @Produce(LdapStatisticsModel, true)
  getLdapStatisticsAsync() {
    return resultOf<LdapStatisticsModel[]>();
  }
}
