
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
  Uploader,
  Controller,
  HostEnvironment,
  HttpGet,
  FromRoute,
  resultOf,
} from '@jct/core';

import {  LecturerDialogData } from './files.types';

@Injectable({ providedIn: 'root' })
@Controller('Files')
export class LersFilesService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private uploader: Uploader<LecturerDialogData>,
    private sanitizer: DomSanitizer) {
  }

  //for download files
  get baseUrl() {
    return `${this.environment.apiServerUrl}/Files/Download`;
  }

  certificateLink(formId:number) {
    return this.sanitizer.bypassSecurityTrustUrl(
      `${this.baseUrl}/${formId}`);
  }

  get formRelativeURL(){
    return 'Files/Upload'
  }

  upload(model: LecturerDialogData) {
    return this.uploader.upload<LecturerDialogData>(model, this.formRelativeURL);
  }

}
