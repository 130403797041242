
export enum GeneralExamTypes {
  Psychometry = 'psychometry',
  Til = 'til',
  Sat = 'sat',
  Act = 'act',
  Meimad = 'meimad'
}

export enum EnglishExamTypes {
  Amir = 'amir',
  Amiram = 'amiram'
}

export class StudentEntranceExams {
  id: number;
  generalExamType?: GeneralExamTypes;
  generalExamDate?: Date;
  englishExamType?: EnglishExamTypes;
  englishExamDate?: Date;
}

