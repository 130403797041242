import { HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TestBuilders } from '@jct/core';
import { FAKE_BAGRUT_SUBJECTS } from '../../hatama';
import { StudentBagrutGrade } from './bagrut-grades.types';

export const FAKE_STUDENT_BAGRUT_GRADES = [];

export const STUDENT_BAGRUT_GRADES_PROVIDER = () => {
  const bagrutSubjectIdProvider = TestBuilders.oneOf(FAKE_BAGRUT_SUBJECTS.map(x => x.id));
  const unitsProvider = TestBuilders.numberBetween(1, 5);
  const gradeProvider = TestBuilders.numberBetween(10,100);
  const bonusProvider = TestBuilders.oneOf([0,12.5,25]);

  const count = TestBuilders.numberBetween(1, 7)();
  const result: StudentBagrutGrade[] = [];

  for (let index = 0; index < count; index++) {
    const item = new StudentBagrutGrade();

    item.bagrutSubjectId = <number>bagrutSubjectIdProvider();
    item.units = <number>unitsProvider();
    item.grade = <number>gradeProvider();
    item.bonus = <number>bonusProvider();
    item.finalGrade = item.grade + item.bonus;
    result.push(item);
  }

  return result;
};
