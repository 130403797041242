<ui-alert *visible="hasError; transition 'slide'" [type]="alertType">
  <div ui-body>
    <strong *ngIf="status >= 400" i18n>
      אופס..., אירעה שגיאה, בדוק את הנתונים ונסה שנית
    </strong>

    <strong *ngIf="status < 400" i18n>
      אירעה שגיאה במערכת
    </strong>

    <div class="mb-10"></div>
    <span i18n *ngIf="status >= 500">
      במקרה הצורך פנה למערכות מידע לצורך טיפול בתקלה
    </span>
    <ui-phone-link>02-6751152</ui-phone-link>
    <ui-mail-link>support@jct.ac.il</ui-mail-link>
  </div>
</ui-alert>

