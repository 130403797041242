
import {
  HostEnvironment,
  Map,
  CACHING_FEATURE,
  LOGGING_FEATURE
} from '../../@jct/core';

import {
  MULTIPLE_LOCALIZATION_FEATURE
} from '../../@jct/localization';

import {
  TEST_CASES_PROVIDER_FEATURE
} from '../../@jct/test';

export const hostEnvironment = HostEnvironment.create({
  environmentName: 'Development',
  apiServerUrl: 'http://localhost:5001',
  applicationUrl: 'http://localhost:4003',
});

export const appSettings: Map<any> = {
  [CACHING_FEATURE]: true,
  [LOGGING_FEATURE]: true,
  [TEST_CASES_PROVIDER_FEATURE]: false,
  [MULTIPLE_LOCALIZATION_FEATURE]: true,
};
