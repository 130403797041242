
<nav mat-tab-nav-bar color="accent" updateLayout dir>
  <a mat-tab-link
    routerLink="/fill-in/previous-education/high-school"
    i18n>
    תיכון
  </a>

  <a mat-tab-link
    routerLink="/fill-in/previous-education/bagrut"
    i18n>
    בגרות
  </a>

  <a mat-tab-link
    routerLink="/fill-in/previous-education/entrance-exams"
    i18n>
    פסיכומטרי וסיווג רמה באנגלית
  </a>

  <a mat-tab-link
    routerLink="/fill-in/previous-education/institutions"
    i18n>
    מוסדות על תיכוניים
  </a>

  <a mat-tab-link
    routerLink="/fill-in/previous-education/diploma"
    i18n>
    דיפלומה
  </a>

  <a mat-tab-link
    routerLink="/fill-in/previous-education/previous-degrees"
    i18n>
    תארים קודמים
  </a>
</nav>

<section class="tab-place-holder">
  <div container>
    <ui-alert type="info">
      <div ui-body i18n>
        ניתן לעבור בין כל הטאבים של לימודים קודמים,
        אין חובת מילוי של כל הטפסים
      </div>
    </ui-alert>
  </div>

  <router-outlet></router-outlet>
</section>
