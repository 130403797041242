
<div container *visible="loadState.isCompleted">
  <ui-alert type="info">
    <div ui-body i18n>
      כאן ניתן להעלות מסמכים נוספים כפי רצונך
    </div>
  </ui-alert>

  <form
   [formGroup]="form"
   autocomplete="off"
   novalidate
   class="mb-10"
  >
    <button mat-raised-button
      color="primary"
      *visible="!showAddFileOption"
      (click)="showAddFileForm()">
      <mat-icon>add</mat-icon>&nbsp;
      <span i18n>הוספת מסמך חדש</span>
    </button>

    <mat-card class="mb-10" *visible="showAddFileOption">
      <section ui-body>
        <mat-form-field>
          <mat-label i18n>תיאור המסמך</mat-label>

          <textarea matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            formControlName="description"
            required></textarea>
        </mat-form-field>

        <input #fileInput
          type="file"
          (change)="upload(fileInput)"
          aria-hidden="true"
          class="cdk-visually-hidden" />

        <mat-progress-bar mode="determinate"
          *visible="saveState.isInProcess"
          [value]="progress?.percents || 0">
        </mat-progress-bar>

        <button mat-raised-button
          color="primary"
          class="mt-10"
          (click)="fileInput.click()"
          *visible="!saveState.isInProcess"
          [disabled]="form.invalid"
          type="button">
          <mat-icon>attach_file</mat-icon>
          <span i18n>העלאת מסמך</span>
        </button>&nbsp;

        <button mat-raised-button
          (click)="cancel()"
          *visible="!saveState.isInProcess"
          type="button">
          <span i18n>ביטול</span>
        </button>
      </section>
    </mat-card>
  </form>

  <mat-card *ngFor="let file of studentAdditionalFiles" class="mb-10">
    <section ui-body>
      <h4 class="mb-10">{{ file?.description }}</h4>

      <a
        [href]="downloadPath(file)"
        target="_blank"
        mat-raised-button
        color="accent">
        <mat-icon>cloud_download</mat-icon>
        &nbsp;
        <span i18n>הורדת המסמך</span>
      </a>&nbsp;

      <button mat-raised-button
        color="warn"
        type="button"
        (click)="deleteFile(file)">
        <mat-icon>delete</mat-icon>
        <span i18n>מחיקת המסמך</span>
      </button>
    </section>
  </mat-card>
</div>

<ui-snack-bar [duration]="2000" #fileUploadedMessage>
  <ui-snack-bar-message i18n>
    המסמך נשמר בהצלחה
  </ui-snack-bar-message>

  <ui-snack-bar-action i18n>
    אישור
  </ui-snack-bar-action>
</ui-snack-bar>

<ui-snack-bar [duration]="2000" #fileDeletedMessage>
  <ui-snack-bar-message i18n>
    המסמך נמחק בהצלחה
  </ui-snack-bar-message>

  <ui-snack-bar-action i18n>
    אישור
  </ui-snack-bar-action>
</ui-snack-bar>
