
import { Injectable } from '@angular/core';

import { fromPeriodString, Map } from '../common';
import { HttpMethod, ApiDescriptionProvider } from '../api';

interface ICacheItem {
  expired: number;
  data: any;
}

export const CACHING_FEATURE = 'caching';
const CACHE_STORAGE_KEY = 'caching';

@Injectable({ providedIn: 'root' })
export class CacheService {
  private _cacheDictionary: Map<ICacheItem> = {};

  constructor() {
    this._load();
  }

  restore(httpMethod: HttpMethod, relativeUrl: string): any|null {
    let route = ApiDescriptionProvider.matchRoute(httpMethod, relativeUrl);

    if (!route) {
      return null;
    }

    let key = `${httpMethod}_${relativeUrl}`;

    if (this.exists(key)) {
      return this.get(key);
    }

    return null;
  }

  store(httpMethod: HttpMethod, relativeUrl: string, data: any|null) {
    let route = ApiDescriptionProvider.matchRoute(httpMethod, relativeUrl);

    if (!route?.action?.cache) {
      return;
    }

    let period = route.action.cache.period;
    let key = `${httpMethod}_${relativeUrl}`;

    this.set(key, data, period);
  }

  clear() {
    ApiDescriptionProvider.clearAllStates();
    this._cacheDictionary = {};
    this._save();
  }

  get<T>(key: string): T | null {
    let notBefore = Date.now();
    let item = this._cacheDictionary[key];

    if (item && item!.expired && notBefore < item!.expired) {
      return item.data;
    }

    return null;
  }

  set<T>(key: string, item: T, period?: string) {
    period = period || '1h';

    this._cacheDictionary[key] = {
      data: item,
      expired: Date.now() + fromPeriodString(period),
    };

    this._save();
  }

  exists(key: string) {
    let notBefore = Date.now();
    let item = this._cacheDictionary[key];

    return item && item!.expired && notBefore < item!.expired;
  }

  private _load() {
    this._cacheDictionary = JSON.parse(localStorage.getItem(CACHE_STORAGE_KEY)) || {};
  }

  private _save() {
    let json = JSON.stringify(this._cacheDictionary);

    localStorage.setItem(CACHE_STORAGE_KEY, json);
  }
}
