
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessResult,
  SuccessTestCase,
  EntityNotExistsResult,
  FailedTestCase,
  SuccessTestCaseProvider,
  InvalidModelResult,
  HttpStatusResult,
} from '@jct/core';

import { StudyTrack, StudentYearlyStudyTracks } from './study-track.types';
import { FAKE_STUDY_TRACKS, STUDENT_YEARLY_STUDY_TRACKS_PROVIDER } from './study-track.test';
import { RegistrationProfile } from '../profile';

@Injectable({providedIn: 'root'})
@Controller('study-track')
export class StudyTrackService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet()
  @UseState()
  @SuccessTestCase(FAKE_STUDY_TRACKS, 'קבלת רשימת כל המסלולים')
  @Produce(StudyTrack, true)
  getStudyTracksAsync() {
    return resultOf<StudyTrack[]>();
  }

  @HttpGet('student')
  @SuccessTestCaseProvider(STUDENT_YEARLY_STUDY_TRACKS_PROVIDER, 'קבלת רשימת המסלולים המעודפים על הסטודנט')
  @SuccessTestCase(EntityNotExistsResult, 'לא קיימים מסלולים מועדפים לסטודנט')
  @Produce(StudentYearlyStudyTracks)
  getStudentYearlyStudyTracksAsync() {
    return resultOf<StudentYearlyStudyTracks>();
  }

  @HttpGet('main')
  @SuccessTestCase(EntityNotExistsResult, 'לא קיים מסלול לימודים עבור התלמיד')
  @Produce(StudyTrack)
  getMainStudentStudyTrackAsync() {
    return resultOf<StudyTrack>();
  }

  @HttpPost('student')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentYearlyStudyTracksAsync(
    @FromBody('model')
    model: StudentYearlyStudyTracks) {
    return resultOf();
  }

  @HttpPost('start-over')
  @Produce(RegistrationProfile)
  startOverAsync() {
    return resultOf<RegistrationProfile>();
  }
}
