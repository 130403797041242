
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApiModule } from '@jct/api';
import { L10nModule, LOCALE_PROVIDER } from '@jct/localization';
import { CoreModule, environmentProvider, Application } from '@jct/core';
import { UiModule } from '@jct/ui';
import { TestModule } from '@jct/test';

import { hostEnvironment, appSettings } from '../environments/environment';
import { PublicAppModule } from './public-app';
import { PrivateAppModule } from './private-app';
//import { PublicFrameModule } from './public-frame';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    L10nModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    AppRoutingModule,
    PublicAppModule,
    PrivateAppModule,
    //PublicFrameModule,
    ServiceWorkerModule.register(
      'ngsw-worker.js',
      { enabled: hostEnvironment.isProduction() }
    ),
  ],
  providers: [
    LOCALE_PROVIDER,
    environmentProvider(hostEnvironment),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(application: Application) {
    application.config({
      applicationName: 'registration',
      version: '3.1.6',
      loginPath: '/start',
      passwordGeneratorPath: '/reset-process/generate',
      settings: {
        ...appSettings,
        publicRoutes: [
          '/start/*',
          '/contact/*',
          '/calculator/*',
          '/reset-process/*',
          '/timeout/*',
          '/not-found/*',
        ],
      },
    });
  }
}


declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}