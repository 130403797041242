
<div container *ngIf="paymentType">
  <meta-title i18n #title>
    התשלום התקבל
  </meta-title>

  <section *ngIf="paymentType === 'standing-order'">
    <h2 i18n>ההוראת קבע התקבלה במערכת</h2>
    <p i18n>
      אנו בודקים כעת האם ההוראת קבע נקלטה בהצלחה ...
    </p>
  </section>

  <section *ngIf="paymentType != 'standing-order'">
    <h2 i18n>התשלום התקבל במערכת</h2>
    <p i18n>
      אנו בודקים כעת האם התשלום עבר בהצלחה ...
    </p>
  </section>

  <mat-progress-spinner
    color="primary"
    diameter="30"
    strokeWidth="3"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
