import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TestBuilders } from '@jct/core';
import { FAKE_CITIES, FAKE_COUNTRIES } from '../../information/information.test';
import { InstitutionTypes, StudentInstitution } from './previous-institution.type';

export const PREVIOUS_INSTITUTION = () => {
  const typeProvider = TestBuilders.oneOf([InstitutionTypes.College, InstitutionTypes.Other, InstitutionTypes.SchoolOfEngineering]);
  const nameProvider = TestBuilders.oneOf(FAKE_INSTITUTIONS.map(x => x.institutionName));
  const countryProvider = TestBuilders.oneOf(FAKE_COUNTRIES);
  const cityProvider = TestBuilders.oneOf(FAKE_CITIES);
  const startYearProvider = TestBuilders.numberBetween(1990, 2010);
  const endYearProvider = TestBuilders.numberBetween(2010, 2015);

  const count = TestBuilders.numberBetween(1, 2)();
  const result: StudentInstitution[] = [];

  for (let index = 0; index < count; index++) {
    const item = new StudentInstitution();

    item.type = <InstitutionTypes>typeProvider();
    item.name = <string>nameProvider();
    item.startYear = <number>startYearProvider();
    item.endYear = <number>endYearProvider();
    item.city = <string>cityProvider();
    item.country = <string>countryProvider();
    // item.name = <string>nameProvider();

    result.push(item);
  }

  return result;
};

export const INSTITUTIONS_PROVIDER = (injector: Injector, request: HttpRequest<any>) => {
  const params = new HttpParams({
    fromString: request.urlWithParams.split('?')[1]
  });

  const query = params.get('query') || '';
  const resultSize = parseInt(params.get('resultSize')) || 10;

  return FAKE_INSTITUTIONS
    .map(x => x.institutionName)
    .filter(x => x.includes(query))
    .filter((x, index) => index < resultSize);
};

export const FAKE_INSTITUTIONS = [
  {
    "institutionName": "ישיבת הר המור",
    "institutionTypeID": 5
  },
  {
    "institutionName": "המכללה בירושלים",
    "institutionTypeID": 12
  },
  {
    "institutionName": "ישיבה גדולה במונטריאול",
    "institutionTypeID": 6
  },
  {
    "institutionName": "ישיבת איתרי",
    "institutionTypeID": 5
  },
  {
    "institutionName": "ארץ חפץ",
    "institutionTypeID": 11
  },
  {
    "institutionName": "מכינה קד\"א מכון לב",
    "institutionTypeID": 10
  },
  {
    "institutionName": "מכינה טכניון",
    "institutionTypeID": 9
  },
  {
    "institutionName": "מכינה קד\"א אוניברסיטה",
    "institutionTypeID": 9
  },
  {
    "institutionName": "כפר אברהם-הנדסאי",
    "institutionTypeID": 3
  },
  {
    "institutionName": "מעלה אדומים",
    "institutionTypeID": 12
  },
  {
    "institutionName": "תפארת שרגא",
    "institutionTypeID": 5
  },
  {
    "institutionName": "ישיבת מדברה כעדן-מצפה רמון ",
    "institutionTypeID": 5
  },
  {
    "institutionName": "ישיבת המאיר",
    "institutionTypeID": 7
  },
  {
    "institutionName": "מכללה למנהל ",
    "institutionTypeID": 12
  },
  {
    "institutionName": "ישיבת מיר",
    "institutionTypeID": 5
  },
  {
    "institutionName": "אוניברסיטה בחו\"ל (ארה\"ב)",
    "institutionTypeID": 2
  },
  {
    "institutionName": "אוניברסיטה בחו\"ל (אירופה)",
    "institutionTypeID": 2
  },
  {
    "institutionName": "מרכז להכשרה טכנולוגית בירושלים",
    "institutionTypeID": 255
  },
  {
    "institutionName": "מכללת אתגר",
    "institutionTypeID": 3
  },
  {
    "institutionName": "ישיבת קדומים",
    "institutionTypeID": 5
  },
  {
    "institutionName": "מעלה אליהו",
    "institutionTypeID": 5
  },
  {
    "institutionName": "מכינה קדם צבאית ראש פינה",
    "institutionTypeID": 11
  },
  {
    "institutionName": "מכללת הגליל המערבי - בי\"ס להנדסאים",
    "institutionTypeID": 3
  },
  {
    "institutionName": "המכללה הטכנולוגית עמל ע\"ש י. אלון",
    "institutionTypeID": 3
  },
  {
    "institutionName": "מכינת הדור",
    "institutionTypeID": 10
  },
  {
    "institutionName": "המכללה למינהל - תל אביב",
    "institutionTypeID": 12
  },
  {
    "institutionName": "טורו קולג",
    "institutionTypeID": 12
  },
  {
    "institutionName": "מכללת בזק",
    "institutionTypeID": 3
  },
  {
    "institutionName": "ישיבת שיח",
    "institutionTypeID": 5
  },
  {
    "institutionName": "ליפשיץ מורים",
    "institutionTypeID": 12
  },
  {
    "institutionName": "תל אביב - ישיבה גבוהה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "מכללת הדסה",
    "institutionTypeID": 12
  },
  {
    "institutionName": "ישיבת הכנסת הגדולה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "אוניברסיטת אריאל",
    "institutionTypeID": 1
  },
  {
    "institutionName": "קריה אקדמית",
    "institutionTypeID": 255
  },
  {
    "institutionName": "ישיבת ההסדר נהרדעה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "המרכז האקדמי לב",
    "institutionTypeID": 1
  },
  {
    "institutionName": "אור עציון-גבוהה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "אור עציון-הסדר",
    "institutionTypeID": 7
  },
  {
    "institutionName": "אור עציון - מכינה קדם צבאית",
    "institutionTypeID": 11
  },
  {
    "institutionName": "ברכה משה-הסדר",
    "institutionTypeID": 7
  },
  {
    "institutionName": "ברכת משה גבוהה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "דימונה-גבוהה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "דימונה-הסדר",
    "institutionTypeID": 7
  },
  {
    "institutionName": "הגולן",
    "institutionTypeID": 7
  },
  {
    "institutionName": "הכותל",
    "institutionTypeID": 7
  },
  {
    "institutionName": "הר עציון-גבוהה",
    "institutionTypeID": 5
  },
  {
    "institutionName": "הר עציון-הסדר",
    "institutionTypeID": 7
  },
  {
    "institutionName": "נווה דקלים - ימית",
    "institutionTypeID": 7
  },
  {
    "institutionName": "ישיבה ללא לימודי חול",
    "institutionTypeID": 5
  }
];

export const FAKE_INSITUTION_TYPE =[
  {
    "ID": 1,
    "InstitutionTypeName": "אוניברסיטה",
    "GenderID": null
  },
  {
    "ID": 2,
    "InstitutionTypeName": "אוניברסיטה חו\"ל",
    "GenderID": null
  },
  {
    "ID": 3,
    "InstitutionTypeName": "בי\"ס להנדסאים",
    "GenderID": null
  },
  {
    "ID": 4,
    "InstitutionTypeName": "בי\"ס להנדסאים חו\"ל",
    "GenderID": null
  },
  {
    "ID": 5,
    "InstitutionTypeName": "ישיבה גבוהה",
    "GenderID": 1
  },
  {
    "ID": 6,
    "InstitutionTypeName": "ישיבה גבוהה חו\"ל",
    "GenderID": null
  },
  {
    "ID": 7,
    "InstitutionTypeName": "ישיבת הסדר",
    "GenderID": 1
  },
  {
    "ID": 8,
    "InstitutionTypeName": "מדרשה",
    "GenderID": 2
  },
  {
    "ID": 9,
    "InstitutionTypeName": "מכינה קד\"א",
    "GenderID": null
  },
  {
    "ID": 10,
    "InstitutionTypeName": "מכינה קד\"א בג\"ט",
    "GenderID": 1
  },
  {
    "ID": 11,
    "InstitutionTypeName": "מכינה קדם צבאית",
    "GenderID": 1
  },
  {
    "ID": 12,
    "InstitutionTypeName": "מכללה",
    "GenderID": null
  },
  {
    "ID": 13,
    "InstitutionTypeName": "מכללה חו\"ל",
    "GenderID": null
  },
  {
    "ID": 14,
    "InstitutionTypeName": "מכללה לבנות",
    "GenderID": 2
  },
  {
    "ID": 15,
    "InstitutionTypeName": "סמינר לבנות",
    "GenderID": 2
  },
  {
    "ID": 16,
    "InstitutionTypeName": "שילוב",
    "GenderID": 1
  },
  {
    "ID": 17,
    "InstitutionTypeName": "בי\"ס לאחיות",
    "GenderID": null
  },
  {
    "ID": 255,
    "InstitutionTypeName": "אחר",
    "GenderID": null
  }
];
