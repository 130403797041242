
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PAGES_COMPONENTS_ROUTES } from '@jct/ui';

import { PublicAppComponent } from './public-app.component';
import { ContactPage } from './+contact';
import { START_MODULE_ROUTES } from './+start';
import { RESET_MODULE_ROUTES } from './+reset-process';
import { CALCULATOR_MODULE_ROUTES } from './+calculator';
import { ClearCachePage } from '@jct/core';

export const PUBLIC_APP_ROUTES: Routes = [
  {
    path: '',
    component: PublicAppComponent,
    children: [
      {
        path: 'start',
        children: START_MODULE_ROUTES,
      },
      {
        path: 'reset-process',
        children: RESET_MODULE_ROUTES,
      },
      {
        path: 'calculator',
        children: CALCULATOR_MODULE_ROUTES,
      },
      {
        path: 'contact',
        component: ContactPage,
      },
      ...PAGES_COMPONENTS_ROUTES,
      {
        path: 'clear-cache',
        component: ClearCachePage,
      },
      {
        path: '',
        redirectTo: '/start/identify',
        pathMatch: 'full',
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(PUBLIC_APP_ROUTES)],
  exports: [RouterModule]
})
export class PublicAppRoutingModule { }
