
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgressIndicator {
  private _state = false;
  private _counter = 0;
  private _subject = new Subject<boolean>();

  get locker() { return this._counter; }

  get observable(): Observable<boolean> {
    return this._subject;
  }

  inProgress() {
    if (this._counter === 0 && !this._state) {
      this._state = true;

      setTimeout(() => {
        this._subject.next(true);
      });
    }

    this._counter++;
  }

  done() {
    if (this._counter > 0) {
      this._counter--;
    }

    if (this._counter === 0 && this._state) {
      this._state = false;

      setTimeout(() => {
        this._subject.next(false);
      });
    }
  }
}
