
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { L10nModule } from '@jct/localization';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { ConfirmPage } from './+confirm';
import { IdentifyForm } from './+identify';
import { VerifyForm } from './+verify';
import { RegisterForm } from './+register';

const EXPORTED_COMPONENTS = [
  ConfirmPage,
  IdentifyForm,
  VerifyForm,
  RegisterForm,
];

export const START_MODULE_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'identify',
    pathMatch: 'full'
  },
  {
    path: 'confirm',
    component: ConfirmPage,
  },
  {
    path: 'identify',
    component: IdentifyForm,
  },
  {
    path: 'verify',
    component: VerifyForm,
  },
  {
    path: 'register',
    component: RegisterForm,
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  declarations: EXPORTED_COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    UiModule.forRoot(),
    ApiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
  ],
})
export class StartModule { }
