import { Component, ElementRef, HostBinding } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'meta-application-name',
  template: '<ng-content></ng-content>',
})
export class MetaApplicationName {
  private ApplicationName = '';

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() { return true; }

  @HostBinding('attr.aria-hidden')
  get ariaHidden() { return true; }

  constructor(
    private elementRef: ElementRef<Element>,
    private meta: Meta)
  { }

  ngAfterContentInit() {
    this.ApplicationName = this.elementRef.nativeElement
      .textContent.trim();

    this.meta.updateTag({
      content: this.ApplicationName,
    }, 'name="application-name"');
  }

  get applicationName() {
    return this.ApplicationName;
  }
}
