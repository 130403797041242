import { ElementRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnimationCssProperties } from '../components';
import { guid, Map } from '@jct/core';

const UnusedCssProperties = [
  'parentRule',
  'length',
  'getPropertyPriority',
  'getPropertyValue',
  'item',
  'removeProperty',
  'setProperty',
];

const configMutationObserver: MutationObserverInit = {
  childList: true,
  subtree: true,
};

const isValid = (property: string) => !UnusedCssProperties.includes(property);
const convert = (match: string) => match[1].toUpperCase();
const normalize = (str: string) => str.replace(/\-[a-z]{1}/g, convert);

export interface CallbackData {
  events: string[];
  element: HTMLElement | HTMLDocument;
  callback: (event: Event) => void;
}

export interface CssDeclaration {
  [property: string]: string;
}

export interface CssRule {
  selector: string;
  declarations: CssDeclaration;
}

export interface AnimationOptions {
  story?: Map<AnimationCssProperties>;
  end?: () => void;
  start?: () => void;
  iteration?: () => void;
  duration?: number | string;
  delay?: number | string;
  count?: number | 'infinite';
  direction?: 'normal' | 'reverse' | 'alternate' | 'alternate-reverse';
  timing?: 'ease' | 'linear' | 'ease-in'| 'ease-out' | 'ease-in-out' | string;
  fill?: 'none' | 'forwards' | 'backwards' | 'both' | 'initial' | 'inherit';
  state?: 'paused' | 'running' | 'initial' | 'inherit';
  disappear?: boolean;
}


export namespace DomApi {
  interface CallbackData {
    events: string[];
    element: HTMLElement | HTMLDocument;
    callback: (event: Event) => void;
  }

  const globalEvents: CallbackData[] = [];
  const store: Map<any> = {};

  const configMutationObserver: MutationObserverInit = {
    childList: true,
    subtree: true,
  };

  const defaultAnimationOptions: AnimationOptions = {
    duration: 1000,
    delay: 0,
    count: 1,
    direction: 'normal',
    timing: 'ease-in-out',
    fill: 'forwards',
    state: 'running',
    disappear: false,
  };

  const ANIMATION_END_EVENT = 'webkitAnimationEnd animationend';
  const ANIMATION_START_EVENT = 'webkitAnimationStart animationstart';
  const ANIMATION_ITERATION_EVENT = 'webkitAnimationIteration animationiteration';

  const extractor = (value: string) => {
    const index = value.search(/[a-z\%]/);
    const unit = index > 0 ? value.substr(index) : '';
    const number = parseFloat(index > 0 ? value.substr(0, index) : value);
    return {unit, number};
  }

  const timeParser = (time: number | string) => {
    if (typeof time === 'number') {
      return (parseInt('' + time) < time) ? (time + 's') : (time + 'ms');
    }
    return time;
  };

  const upperCase = (match: string) => match[1].toUpperCase();
  const normalizeCssProperty = (str: string) => str.replace(/\-[a-z]{1}/g, upperCase);
  const ruleBuilder = (rule: CssRule) => {
    let content = '';
    content += rule.selector + '{';
    for (let property in rule.declarations) {
      const value = rule.declarations[property];
      content += `${property}:${value};`;
    }
    content += '}';
    return content;
  };

  const InternalIdentifierName = '$iid';

  export class Warper {
    constructor(
      public element: HTMLElement)
    { }

    data(key: string): any;
    data(key: string, value: any): Warper;
    data(key: string, value?: any): any | Warper {
      if (this.isEmpty) {
        return typeof value === 'undefined' ? {} : this;
      }

      if (typeof value === 'undefined') {
        return data(this.element, key);
      }

      data(this.element, key, value);

      return this;
    }

    removeData(key: string): Warper {
      if (this.isEmpty) {
        return this;
      }

      removeData(this.element, key);

      return this;
    }

    //#region selector

    parent() {
      if (this.element) {
        this.element = this.element.parentElement;
      }
      return this;
    }

    firstChild() {
      if (this.element) {
        this.element = <HTMLElement>this.element.firstElementChild;
      }
      return this;
    }

    lastChild() {
      if (this.element) {
        this.element = <HTMLElement>this.element.lastElementChild;
      }
      return this;
    }

    query(selector: string) {
      if (this.element) {
        this.element = this.element.querySelector(selector);
      }
      return this;
    }

    queryAll(selector: string): Warper[] {
      if (this.isEmpty) {
        return [];
      }

      let elements = this.element.querySelectorAll<HTMLElement>(selector);

      if (elements.length > 0) {
        let map: Warper[] = [];

        elements.forEach(element => map.push(new Warper(element)));

        return map;
      }

      return [];
    }

    observe(selector: string): Observable<Warper> {
      if (!this.element) {
        return of(this);
      }

      let element = this.element.querySelector<HTMLElement>(selector);

      if (element) {
        return of(new Warper(element));
      }

      return new Observable((observer) => {
        const mutation = new MutationObserver(() => {
          let element = this.element.querySelector<HTMLElement>(selector);

          if (element) {
            observer.next(new Warper(element));
          }
        });

        mutation.observe(this.element, configMutationObserver);
      });
    }

    observeAll(selector: string): Observable<Warper[]> {
      if (this.isEmpty) {
        return of([]);
      }

      let elements = this.element.querySelectorAll<HTMLElement>(selector);

      if (elements.length > 0) {
        let map: Warper[] = [];
        elements.forEach(element => map.push(new Warper(element)));
        return of(map);
      }

      return new Observable((observer) => {
        const mutation = new MutationObserver(() => {
          let elements = this.element.querySelectorAll<HTMLElement>(selector);

          if (elements.length > 0) {
            let map: Warper[] = [];
            elements.forEach(element => map.push(new Warper(element)));
            observer.next(map);
          }
        });

        mutation.observe(this.element, configMutationObserver);
      });
    }

    //#endregion

    get clientTop() {
      return this.element?.clientTop || 0;
    }

    get clientLeft() {
      return this.element?.clientLeft || 0;
    }

    get clientWidth() {
      return this.element?.clientWidth || 0;
    }

    get clientHeight() {
      return this.element?.clientHeight || 0;
    }

    get isEmpty() {
      return !this.element;
    }

    attr(name: string): string;
    attr(name: string, value: string): Warper;
    attr(name: string, value?: string): string | Warper {
      if (typeof value === 'undefined') {
        return this.isEmpty ? '' : attr(this.element, name);
      }
      !this.isEmpty && attr(this.element, name, value);
      return this;
    }

    css(property: string): string;
    css(property: string, value: string): Warper;
    css(properties: string[]): string[];
    css(properties: CssDeclaration): Warper;
    css(property: string | string[] | CssDeclaration, value?: string): string | string[] | Warper {
      if (this.isEmpty) {
        if (typeof value === 'undefined') {
          return Array.isArray(property) ? [] : typeof property === 'object' ? this : '';
        }
        return this;
      }

      const result = Array.isArray(property) ?
        css(this.element, property) :
        typeof property === 'object' ?
        css(this.element, property) : css(this.element, property, value);

      if (typeof result !== 'undefined') {
        return result;
      }

      return this;
    }

    text(): string
    text(value: string): Warper
    text(value?: string): string | Warper {
      if (typeof value === 'undefined') {
        return this.isEmpty ? '' : text(this.element);
      }
      !this.isEmpty && text(this.element, value);
      return this;
    }

    html(): string
    html(value: string): Warper
    html(value?: string): string | Warper {
      if (typeof value === 'undefined') {
        return this.isEmpty ? '' : html(this.element);
      }
      !this.isEmpty && html(this.element, value);
      return this;
    }

    hasClass(name: string): boolean {
      return this.isEmpty ? false : hasClass(this.element, name);
    }

    addClass(classNames: string): Warper {
      if (!this.isEmpty) {
        addClass(this.element, classNames);
      }
      return this;
    }

    removeClass(classNames: string): Warper {
      if (!this.isEmpty) {
        removeClass(this.element, classNames);
      }
      return this;
    }

    animate(options: AnimationOptions): Warper {
      if (!this.isEmpty) {
        animate(this.element, options);
      }
      return this;
    }

    //#region events

    on(eventName: string, callback: (event: Event) => void): Warper {
      if (!this.isEmpty) {
        on(this.element, eventName, callback);
      }
      return this;
    }

    once(eventName: string, callback: (event: Event) => void): Warper {
      if (!this.isEmpty) {
        once(this.element, eventName, callback);
      }
      return this;
    }

    off(eventName: string): Warper {
      if (!this.isEmpty) {
        off(this.element, eventName);
      }
      return this;
    }

    //#endregion
  }

  export function getInternalKey(element: HTMLElement) {
    if (!element) {
      return '';
    }

    let iid = element[InternalIdentifierName];

    if (!iid) {
      iid = guid();
      element[InternalIdentifierName] = iid;
      store[iid] = {};
    }

    return iid;
  }

  export function getStore(element: HTMLElement): any {
    const key = getInternalKey(element);

    if (!key) {
      return {};
    }

    return store[key];
  }

  export function data(element: HTMLElement, key: string): any;
  export function data(element: HTMLElement, key: string, value: any): void;
  export function data(element: HTMLElement, key: string, value?: any): any | void {
    const iid = getInternalKey(element);

    if (!iid) {
      return typeof value === 'undefined' ? {} : undefined;
    }

    if (typeof value === 'undefined') {
      return store[iid] ? store[iid][key] : {};
    }

    store[iid] = (store[iid] || {})[key] = value;
  }

  export function hadData(element: HTMLElement, key: string) {
    if (!element) {
      return false;
    }

    const iid = getInternalKey(element);

    return typeof store[iid][key] !== 'undefined';
  }

  export function removeData(element: HTMLElement, key: string) {
    if (!element) {
      return;
    }

    const iid = getInternalKey(element);

    if (typeof store[iid][key] !== 'undefined') {
      delete store[iid][key];
    }
  }

  export function warp(element: HTMLElement) {
    return new Warper(element);
  }

  export function on(element: HTMLElement, eventName: string, callback: (event: Event) => void) {
    if (!element || !eventName || !callback) {
      return;
    }

    let events = eventName.split(' ')
      .filter(x => x.trim().length > 0);

    const innerCallback = (ev: Event) => {
      if (ev.target === element) {
        callback(ev);
      }
    };

    globalEvents.push({
      events,
      element,
      callback: innerCallback,
    });

    events.forEach(name => element.addEventListener(name, innerCallback, false));
  }

  export function once(element: HTMLElement, eventName: string, callback: (event: Event) => void) {
    if (!element || !eventName || !callback) {
      return;
    }

    let events = eventName.split(' ')
      .filter(x => x.trim().length > 0);

    const innerCallback = (ev: Event) => {
      if (ev.target === element) {
        callback(ev);
        off(element, eventName);
      }
    };

    globalEvents.push({
      events,
      element,
      callback: innerCallback,
    });

    events.forEach(name => element.addEventListener(name, innerCallback, false));
  }

  export function off(element: HTMLElement, eventName: string) {
    if (!element || !eventName) {
      return;
    }

    eventName
      .split(' ')
      .filter(x => x.trim().length > 0)
      .forEach(name => {
        let index = globalEvents.findIndex(x => x.events.includes(name) && x.element === element);

        if (index < 0) {
          return;
        }

        let data = globalEvents[index];

        element.removeEventListener(name, data.callback, false);

        data.events.splice(data.events.findIndex(x => x === name), 1);

        if (data.events.length == 0) {
          globalEvents.splice(index, 1);
        }
      });
  }

  export function height(element: HTMLElement) {
    return computeCssProperty(element, 'height');
  }

  export function width(element: HTMLElement) {
    return computeCssProperty(element, 'width');
  }

  export function removeAttr(element: Element, name: string): void {
    if (!element || !name) {
      return;
    }

    if (element['removeAttribute']) {
      element.removeAttribute(name);
    }
    else {
      element[name] = null;
    }
  }

  export function attr(element: HTMLElement, name: string): string;
  export function attr(element: HTMLElement, name: string, value: string): void;
  export function attr(element: HTMLElement, name: string, value?: string): void | string {
    if (!element || !name) {
      return;
    }

    let hasValue = !!value?.trim();

    if (hasValue) {
      if (element['setAttribute']) {
        element.setAttribute(name, value);
      }
      else {
        element[name] = value;
      }

      return;
    }

    if (element['getAttribute']) {
      return element.getAttribute(name) || '';
    }

    return element[name] || '';
  }

  export function text(element: HTMLElement): string
  export function text(element: HTMLElement, value: string): void
	export function text(element: HTMLElement, value?: string): string | void {
    if (!element) {
      return '';
    }

    if (typeof value === 'undefined') {
      return element.textContent.trim();
    }

    element.textContent = value;
  }

  export function html(element: HTMLElement): string
  export function html(element: HTMLElement, value: string): void
	export function html(element: HTMLElement, value?: string): string | void {
    if (!element) {
      return '';
    }

    if (typeof value === 'undefined') {
      return element.innerHTML.trim();
    }

    element.innerHTML = value;
  }

  export function addStyle(identifier: string, rule: string): void;
  export function addStyle(identifier: string, rule: CssRule): void;
  export function addStyle(identifier: string, rule: CssRule | string): void {
    if (document.head.querySelector(`style[id="${identifier}"]`)) {
      return;
    }

    const style = document.createElement('style');

    attr(style, 'id', identifier);

    let content = '';

    if (typeof rule === 'object') {
      for (let property in rule) {
        content += ` ${property}: ${rule[property]};`;
      }
    }
    else {
      content = rule.trim();
    }

    text(style, content.trim());

    document.head.appendChild(style);
  }

  export function appendStyle(identifier: string, rule: string): void;
  export function appendStyle(identifier: string, rule: CssRule): void;
  export function appendStyle(identifier: string, rules: CssRule[]): void;
  export function appendStyle(identifier: string, rule: string | CssRule | CssRule[]): void {
    const style = <HTMLStyleElement>document.head.querySelector(`style[id="${identifier}"]`);

    if (!style) {
      return;
    }

    let styleContent = '';

    if (typeof rule === 'object') {
      if (Array.isArray(rule)) {
        const rules = rule;
        for (let rule of rules) {
          styleContent += ruleBuilder(rule);
        }
      }
      else {
        styleContent = ruleBuilder(rule);
      }
    }
    else {
      styleContent = rule.trim();
    }

    text(style);

    document.head.appendChild(style);
  }

  export function removeStyle(identifier: string) {
    const style = document.head.querySelector(`style[id="${identifier}"]`);

    if (style) {
      document.head.removeChild(style);
    }
  }

  export function css(element: HTMLElement, property: string): string;
  export function css(element: HTMLElement, property: string, value: string): void;
  export function css(element: HTMLElement, properties: string[]): string[];
  export function css(element: HTMLElement, properties: {[name: string]: string}): void;
	export function css(element: HTMLElement, property: string | string[] | {[name: string]: string}, value?: string): string | string[] | void {
    if (typeof property === 'string') {
      if (!element) {
        return typeof value === 'string' ? undefined : '';
      }

      let cssProperty = normalizeCssProperty(property);

      if (typeof value === 'undefined') {
        return element.style[cssProperty];
      }

      element.style[cssProperty] = value;
      return;
    }

    if (Array.isArray(property)) {
      if (!element) {
        return [];
      }

      return property
        .map(x => normalizeCssProperty(x))
        .map(x => element.style[x]);
    }

    if (!element) {
      return;
    }

    for (let key in property) {
      let cssProperty = normalizeCssProperty(key);
      let val = property[key];

      element.style[cssProperty] = val.trim().length > 0 ? val: '';
    }
  }

  export function hasClass(element: HTMLElement, name: string) {
    if (!element) {
      return false;
    }

    let classNames = attr(element, 'class');

    return classNames.includes(name);
  }

  export function computeCssProperty(element: HTMLElement, property: string, percentage: number = 100) {
    if (!element) {
      return '';
    }

    let style: CSSStyleDeclaration;

    if (hadData(element, '$computedStyleObject')) {
      style = data(element, '$computedStyleObject');
    }
    else {
      style = window.getComputedStyle(element);
      data(element, '$computedStyleObject', style);
    }

    const propertyValue = <string>style[property];

    if (percentage < 100) {
      const { number, unit } = extractor(propertyValue);

      return (percentage === 0 ? 0 : (number / percentage * 100)) + unit;
    }

    return propertyValue;
  }

  interface AnimationInfo {
    keySize: number;
    rule: string;
    length: number;
    key: string;
  }

  const animationKeys: Map<AnimationInfo> = {};
  let animationKeyIndex = 1;

  function estimateHash(options: AnimationOptions, keyframe: string) {
    const rule = `keyframe:{${keyframe}};` +
      `duration:${timeParser(options.duration)};`+
      `delay:${timeParser(options.delay)};`+
      `count:${options.count};`+
      `direction:${options.direction};`+
      `timing:${options.timing};`+
      `fill:${options.fill}};`;

    const hash = btoa(rule);

    if (!animationKeys[hash]) {
      animationKeys[hash] = {
        keySize: hash.length,
        rule: rule,
        length: rule.length,
        key: 'animate-' + animationKeyIndex++,
      };
    }

    return animationKeys[hash].key;
  }

  function buildKeyFrame(element: HTMLElement, frames: Map<AnimationCssProperties>) {
    let keyframe = '';

    for (let frameKey in frames) {
      const frame = frames[frameKey];
      keyframe += isNaN(parseInt(frameKey)) ? frameKey : frameKey + '%';
      keyframe += '{';
      for (let propertyKey in frame) {
        const property = normalizeCssProperty(propertyKey);
        let value = frame[propertyKey];
        if (typeof value === 'string' && value.endsWith('%')) {
          value = computeCssProperty(element, property, parseInt(value.substr(0,value.length-1)));
        }
        keyframe += `${property}:${value};`;
      }
      keyframe += '}';
    }

    return keyframe;
  }

  export function has(selector: string): boolean {
    return document.querySelectorAll(selector).length > 0;
  }

  let removeOldAnimationsStart = false;

  export function removeOldAnimations() {
    if (!removeOldAnimationsStart) {
      removeOldAnimationsStart = true;

      setInterval(() => {
        const nodes = document.querySelectorAll('style[id^=animate-]');

        for (let index = 0; index < nodes.length; index++) {
          const node = <Element>nodes.item(index);
          const key = node.getAttribute('id');

          if (!document.querySelector('.' + key)) {
            document.head.removeChild(node);
          }
        }
      }, 10000);
    }
  }

  export function animate(element: HTMLElement, options: AnimationOptions) {
    options = Object.assign({}, defaultAnimationOptions, options);

    removeOldAnimations();

    const keyframe = buildKeyFrame(element, options.story);
    const key = estimateHash(options, keyframe);

    if (!has(`#${key}`)) {
      const rule = `@keyframes ${key}{${keyframe}}` +
        `.${key}{` +
        //`overflow:hidden;`+
        `animation-name:${key};`+
        `animation-duration:${timeParser(options.duration)};`+
        `animation-delay:${timeParser(options.delay)};`+
        `animation-iteration-count:${options.count};`+
        `animation-direction:${options.direction};`+
        `animation-timing-function:${options.timing};`+
        `animation-fill-mode:${options.fill}};`;

      addStyle(key, rule);
    }

    //css(element, 'overflow', 'auto');

    once(element, ANIMATION_START_EVENT, ev => {
      if (options.start) {
        options.start();
      }
    });

    on(element, ANIMATION_ITERATION_EVENT, ev => {
      if (options.iteration) {
        options.iteration();
      }
    });

    once(element, ANIMATION_END_EVENT, ev => {
      if (options.end) {
        options.end();
      }

      removeStyle(key);

      if (!options.disappear) {
        removeClass(element, key);

        if (options.iteration) {
          off(element, ANIMATION_ITERATION_EVENT);
        }
      }
    });

    addClass(element, key);
    //css(element, 'overflow', '');
  }

  export function addClass(element: HTMLElement, classNames: string) {
    if (!element) {
      return;
    }

    let current = attr(element, 'class');
    let all = current.split(' ').filter(x => x.trim().length > 0);
    let added = classNames.split(' ').filter(x => !all.includes(x));

    attr(element, 'class', all.concat(added).join(' '));
  }

  export function removeClass(element: HTMLElement, classNames: string) {
    if (!element) {
      return;
    }

    let removed = classNames.split(' ').filter(x => x.trim().length > 0);
    let current = attr(element, 'class');
    let all = current.split(' ').filter(x => !removed.includes(x));

    attr(element, 'class', all.join(' '));
  }
}

export class DomElement {
  private static _globalEvents: CallbackData[] = [];

  constructor(
    public element: HTMLDocument | HTMLElement | null)
  { }

  on(eventName: string, callback: (event: Event) => void) {
    if (!this.element) {
      return this;
    }

    let events = eventName.split(' ');

    const innerCallback = (ev: Event) => {
      if (ev.target === this.element) {
        callback(ev);
      }
    };

    DomElement._globalEvents.push({
      events,
      element: this.element,
      callback: innerCallback,
    });

    events.forEach(name => this.element.addEventListener(name, innerCallback, false));

    return this;
  }

  once(eventName: string, callback: (event: Event) => void) {
    if (!this.element) {
      return this;
    }

    let events = eventName.split(' ');

    const innerCallback = (ev: Event) => {
      if (ev.target === this.element) {
        callback(ev);
        this.off(eventName);
      }
    };

    DomElement._globalEvents.push({
      events,
      element: this.element,
      callback: innerCallback,
    });

    events.forEach(name => this.element.addEventListener(name,innerCallback, false));

    return this;
  }

  off(eventName: string) {
    if (!this.element) {
      return this;
    }

    eventName.split(' ').forEach(name => {
      let index = DomElement._globalEvents.findIndex(x => x.events.includes(name) && x.element === this.element);

      if (index < 0) {
        return;
      }

      let data = DomElement._globalEvents[index];

      this.element.removeEventListener(name, data.callback, false);

      data.events.splice(data.events.findIndex(x => x === name), 1);

      if (data.events.length == 0) {
        DomElement._globalEvents.splice(index, 1);
      }
    });

    return this;
  }

  removeAttr(name: string): DomElement {
    if (!this.element) {
      return this;
    }

    DomApi.removeAttr(<Element>this.element, name);

    return this;
  }

  attr(name: string): string;
  attr(name: string, value: string): DomElement;
  attr(name: string, value?: string): DomElement | string {
    let hasValue = !!value?.trim();

    if (!this.element) {
      if (hasValue) {
        return this;
      }

      return '';
    }

    if (hasValue) {
      if (this.element['setAttribute']) {
        (<Element>this.element).setAttribute(name, value);
      }
      else {
        this.element[name] = value;
      }

      return this;
    }

    if (this.element['getAttribute']) {
      return (<Element>this.element).getAttribute(name) || '';
    }

    return this.element[name] || '';
  }

  css(css: string): string;
  css(css: string, value: string): DomElement;
  css(css: string[]): string[];
  css(css: {[name: string]: string}): DomElement;
	css(css: string | string[] | {[name: string]: string}, value?: string): string | string[] | DomElement {
    if (typeof css === 'string') {
      if (!this.element) {
        if (typeof value === 'string') {
          return this;
        }
        return '';
      }

      let property = normalize(css);

      if (typeof value === 'undefined') {
        return isValid(property) ? <string>(<HTMLElement>this.element).style[property] : '';
      }

      if (isValid(property)) {
        (<HTMLElement>this.element).style[property] = value;
      }

      return this;
    }

    if (Array.isArray(css)) {
      if (!this.element) {
        return [];
      }

      return css
        .map(x => normalize(x))
        .filter(x => !UnusedCssProperties.includes(x))
        .map(x => <string>(<HTMLElement>this.element).style[x]);
    }

    if (!this.element) {
      return this;
    }

    for (let key in css) {
      let property = normalize(key);
      let val = css[key];

      if (!isValid(property)) {
        continue;
      }

      if (val) {
        (<HTMLElement>this.element).style[property] = val;
      }
      else {
        (<HTMLElement>this.element).style[property] = '';
      }
    }

    return this;
  }

  get clientTop(): number {
    return this.element ? (<HTMLElement>this.element).clientTop : 0;
  }

  get clientLeft(): number {
    return this.element ? (<HTMLElement>this.element).clientLeft : 0;
  }

  get clientWidth(): number {
    return this.element ? (<HTMLElement>this.element).clientWidth : 0;
  }

  get clientHeight(): number {
    return this.element ? (<HTMLElement>this.element).clientHeight : 0;
  }

  hasClass(name: string) {
    if (!this.element) {
      return false;
    }

    let className = (<Element>this.element).getAttribute('class');

    return className.includes(name);
  }

  addClass(classNames: string) {
    if (!this.element) {
      return this;
    }

    let current = this.attr('class');
    let all = current.split(' ');
    let added = classNames.split(' ').filter(x => !all.includes(x));

    this.attr('class', all.concat(added).join(' '));

    return this;
  }

  removeClass(classNames: string) {
    if (!this.element) {
      return this;
    }

    let removed = classNames.split(' ');
    let current = this.attr('class');
    let all = current.split(' ').filter(x => !removed.includes(x));

    this.attr('class', all.join(' '));

    return this;
  }

	text() {
    if (!this.element) {
      return '';
    }

    return this.element.textContent.trim();
  }

	html() {
    if (!this.element) {
      return '';
    }

    return (<Element>this.element).innerHTML.trim();
  }

  observeQuery<E extends HTMLElement = HTMLElement>(selector: string): Observable<DomElement> {
    if (!this.element) {
      return of(this);
    }

    let element = this.element.querySelector<E>(selector);

    if (element) {
      return of(new DomElement(element));
    }

    return new Observable((observer) => {
      const mutation = new MutationObserver(() => {
        let element = this.element.querySelector<E>(selector);

        if (element) {
          observer.next(new DomElement(element));
        }
      });

      mutation.observe(this.element, configMutationObserver);
    });
  }

  query<E extends HTMLElement = HTMLElement>(selector: string): DomElement {
    if (!this.element) {
      return this;
    }

    let element = this.element.querySelector<E>(selector);

    return new DomElement(element);
  }

  observeQueryAll<E extends HTMLElement = HTMLElement>(selector: string): Observable<DomElement[]> {
    if (!this.element) {
      return of([]);
    }

    let elements = this.element.querySelectorAll<E>(selector);

    if (elements.length > 0) {
      let map: DomElement[] = [];
      elements.forEach(element => map.push(new DomElement(element)));
      return of(map);
    }

    return new Observable((observer) => {
      const mutation = new MutationObserver(() => {
        let elements = this.element.querySelectorAll<E>(selector);

        if (elements.length > 0) {
          let map: DomElement[] = [];
          elements.forEach(element => map.push(new DomElement(element)));
          observer.next(map);
        }
      });

      mutation.observe(this.element, configMutationObserver);
    });
  }

  queryAll<E extends HTMLElement = HTMLElement>(selector: string): DomElement[] {
    if (!this.element) {
      return [];
    }

    let elements = this.element.querySelectorAll<E>(selector);

    if (elements.length > 0) {
      let map: DomElement[] = [];

      elements.forEach(element => map.push(new DomElement(element)));

      return map;
    }

    return [];
  }

  get empty() {
    return !this.element;
  }

  get parent() {
    if (!this.element) {
      return this;
    }

    return new DomElement(this.element.parentElement);
  }
}

export function domAccessor(element: HTMLElement | HTMLDocument | ElementRef<HTMLElement> | DomElement): DomElement {
  if (element instanceof DomElement) {
    return element;
  }

  if (element instanceof ElementRef) {
    return new DomElement(element.nativeElement);
  }

  return new DomElement(element);
}
