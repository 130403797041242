import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { timer } from "rxjs";

import {
  PaymentTypes,
  AmiramService,
  StandingOrderService,
  VouchersService,
  RegistrationFeeService,
  DonationService,
  ProfileService,
  RegistrationStages,
} from "@jct/api";

@Component({
  templateUrl: "./success-page.component.html",
})
export class SuccessPageComponent implements OnInit {
  constructor(
    private router: Router,
    private profileService: ProfileService,
    private activatedRoute: ActivatedRoute,
    private amiramService: AmiramService,
    private standingOrderService: StandingOrderService,
    private vouchersService: VouchersService,
    private registrationFeeService: RegistrationFeeService,
    private donationService: DonationService
  ) {}

  paymentType: PaymentTypes;

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      let queryString = this.router.parseUrl(this.router.url).queryParamMap;

      const paymentType = <PaymentTypes>queryString.get("paymentType");
      const transactionId = queryString.get("txId");
      const token = queryString.get("token");

      this.paymentType = paymentType;

      this.success(paymentType, transactionId, token);
    });
  }

  async success(paymentType: string, transactionId: string, token: string) {
    switch (paymentType) {
      case PaymentTypes.Amiram:
        await this.amiramService.endPaymentAsync(transactionId, token);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/amiram-test");
        break;

      case PaymentTypes.RegistrationFee:
        await this.registrationFeeService.endPaymentAsync(transactionId, token);
        await timer(4000).toPromise();
        this.profileService.update({ currentStage: RegistrationStages.תשלום });
        await this.router.navigateByUrl("/registration-status");
        break;

      case PaymentTypes.Voucher:
        await this.vouchersService.endPaymentAsync(transactionId, token);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/vouchers");
        break;

      case PaymentTypes.Dormitory:
        await this.vouchersService.endPaymentAsync(transactionId, token);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/dormitory");
        break;

      case PaymentTypes.StandingOrder:
        await this.standingOrderService.endPaymentAsync(transactionId, token);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/standing-order");
        break;

      case PaymentTypes.Donation:
        this.donationService.successPaymentComplete = true;
        await this.donationService.endPaymentAsync(transactionId, token);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/thank-you");
        break;
    }
  }
}
