import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { debounceTime } from "rxjs/operators";

import {
  HOUSE_NUMBER_PATTERN,
  HttpStatusResult,
  PHONE_NUMBER_PATTERN,
  ZIP_CODE_PATTERN,
} from "@jct/core";
import { StateFactory } from "@jct/ui";

import {
  PersonService,
  PersonContact,
  CommonService,
  ContactTypes,
  RegistrationUserProfile,
  RegistrationNavigator,
  RegistrationStages,
} from "@jct/api";

const ISRAEL_COUNTRY = "ישראל";

@Component({
  selector: "contact-details-tab",
  templateUrl: "./contact-details-tab.component.html",
  host: {
    class: "page",
  },
})
export class ContactDetailsTabComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private userProfile: RegistrationUserProfile,
    private personService: PersonService,
    private registrationNavigator: RegistrationNavigator,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {}

  @Output()
  saved = new EventEmitter();

  @Input()
  enableCopy: boolean = false;

  loadState = this.sf.create();
  saveState = this.sf.create();
  copyState = this.sf.create();

  get gender() {
    return this.userProfile?.gender;
  }

  get contactType() {
    return this.activatedRoute.parent.routeConfig.path == "personal"
      ? ContactTypes.Self
      : ContactTypes.Parents;
  }

  get isSelfContactType() {
    return this.contactType == ContactTypes.Self;
  }

  form = this.fb.group({
    cellularPhone: [null, [Validators.pattern(PHONE_NUMBER_PATTERN)]],
    email: [null, [Validators.email]],
    country: [null],
    city: [null],
    street: [null],
    houseNumber: [null, [Validators.pattern(HOUSE_NUMBER_PATTERN)]],
    zip: [null, [Validators.pattern(ZIP_CODE_PATTERN)]],
    pob: [null],
  });

  async ngOnInit() {
    await this.loadState.inProcess();

    const result =
      this.contactType === ContactTypes.Self
        ? await this.personService.getContactAsync()
        : await this.personService.getRelativeContactAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    } else {
      this.form.patchValue(result);
    }

    this.form
      .get("houseNumber")
      .valueChanges.pipe(debounceTime(500))
      .subscribe(async (value) => {
        const { country, city, street } = <PersonContact>this.form.value;

        if (
          country?.trim() === ISRAEL_COUNTRY &&
          city?.trim().length > 0 &&
          street?.trim().length > 0 &&
          value?.trim().length > 0
        ) {
          const result = await this.commonService.getZipCodeAsync(
            city,
            street,
            value
          );

          if (typeof result === "string") {
            this.form.patchValue({
              zip: result,
            });
          }
        }
      });

    await this.loadState.completed();
  }

  async copyAddress() {
    if (this.copyState.isInProcess) {
      return;
    }

    await this.copyState.inProcess();

    const result = await this.personService.getContactAsync();

    if (result instanceof HttpStatusResult) {
      this.copyState.failed(result);
      return;
    }

    const { city, country, houseNumber, street, zip } = result;
    this.form.patchValue({ city, country, houseNumber, street, zip });

    await this.copyState.completed();
  }

  async submit() {
    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();

    const model = this.form.value as PersonContact;
    const result =
      this.contactType === ContactTypes.Self
        ? await this.personService.saveContactAsync(model)
        : await this.personService.saveRelativeContactAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    const stage =
      this.contactType === ContactTypes.Self
        ? RegistrationStages.פרטי_התקשרות
        : RegistrationStages.פרטי_התקשרות_הורים;

    this.registrationNavigator.setCurrentStage(stage);
    await this.saveState.completed();
    this.saved.emit();
    await this.registrationNavigator.navigateToNextStage();
  }
}
