import { AbstractControl, UntypedFormArray, ValidationErrors } from '@angular/forms';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';

import { StateFactory } from '@jct/ui/lib/services';

export interface TableRow {
  newRow: boolean;
  complete: boolean;
  empty: boolean;
}

export function getCompletedRows<T extends TableRow>(formArray: UntypedFormArray) {
  return formArray.controls
    .map(x => (<T>x.value))
    .filter(x => x.complete);
}

export function getUncompletedRows<T extends TableRow>(formArray: UntypedFormArray) {
  return formArray.controls
    .map(x => (<T>x.value))
    .filter(x => !x.newRow && !x.complete && !x.empty);
}

export function hasNewRow(formArray: UntypedFormArray) {
  return formArray.controls
    .map(x => x.value as TableRow)
    .some(x => x.newRow);
}

export const validateRow = (fieldName: string) => (control: AbstractControl): ValidationErrors | null => {
  const row = control?.value as TableRow || null;

  if (row && !row.newRow && !row[fieldName]) {
    return {
      [fieldName]: {
        required: true,
      },
    };
  }

  return null;
};

export const validateField = (fieldName: string, includeParent: boolean = true) => (control: AbstractControl): ValidationErrors | null => {
  const row = control?.parent?.value as TableRow || null;
  const field = control?.value || null;

  if (includeParent) {
    if (row && !row.newRow && (!row[fieldName] && !field)) {
      return {
        required: true
      };
    }
  }
  else {

    if (row && !row.newRow && control && !field) {
      return {
        required: true
      };
    }
  }

  return null;
};

export async function waitForDataTable(stateFactory: StateFactory, accessor: () => MatTable<any>) {
  const uiState = stateFactory.create();

  await uiState.inProcess();

  const timer = setInterval(async () => {
    const dataTable = accessor();

    if (dataTable._getRenderedRows(dataTable._rowOutlet).length > 0) {
      clearInterval(timer);
      await uiState.completed(150);
    }
  }, 250);
}
