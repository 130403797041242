
<div container *visible="loadState.isCompleted; transition 'route'">
  <h4 i18n>להלן המועדים בהם תוכל להיבחן</h4>

  <p i18n>
    שים לב כי המועדים המוצגים הינם החל ממחר,
    ואינם כוללים תאריך בטווח של פחות מחודש מהמועד הקודם אליו נרשמת.
  </p>

  <div class="table-responsive mat-elevation-z2"
       *visible="availableAmiramTests.length > 0">
    <table mat-table
           [dataSource]="availableAmiramTests"
           [ui-column-width]="[130,100,120,170]">

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef i18n>
          תאריך
        </th>

        <td mat-cell *matCellDef="let row;">
          {{ row?.testStartDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="hour">
        <th mat-header-cell *matHeaderCellDef i18n>
          שעה
        </th>

        <td mat-cell *matCellDef="let row;">
          {{ row?.testStartDate | date: 'HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="campus">
        <th mat-header-cell *matHeaderCellDef i18n>
          קמפוס
        </th>

        <td mat-cell *matCellDef="let row;">
          {{ row?.instituteName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let row; let i = index">
          <ui-progress-button
            color="primary"
            [state]="payStates[i]"
            (progress)="payAsync(row, i)"
            i18n>
            לביצוע הרישום
          </ui-progress-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <ui-alert type="info" *visible="availableAmiramTests.length === 0">
    <section ui-body i18n>
      לא נמצאו נתונים
    </section>
  </ui-alert>
</div>
