
<meta-title i18n>
  הסיסמה שלך
</meta-title>

<div container>
  <ui-error [state]="loadState" [ignoreList]="[401,400]"></ui-error>

  <section *visible="loadState.isCompleted">
    <p i18n>
      שלום לך,
      <br>
      סיסמתך למערכת הרישום היא:
    </p>

    <section class="password-font mb-10">
      {{ result?.newGeneratedPassword }}
    </section>

    <p i18n>
      עליך לשמור את הסיסמא לכניסה חוזרת, מומלץ לצלם או לרשום.
      <br>
      שמרו את הסיסמה שלכם במקום בטוח!
    </p>

    <div class="mt-10" *ngIf="result?.canLogin">
      <button mat-raised-button
        (click)="login()"
        color="primary"
        type="button"
        i18n>
        הכנסו למערכת
      </button>
    </div>
  </section>

  <ui-alert type="warning" *visible="loadState.error?.isInvalidToken || loadState.error?.isInvalidModel">
    <h2 ui-header i18n>
      פג תוקף קישור זה
    </h2>

    <p ui-body>
      <span i18n>
        תוקף הקישור לאיפוס הסיסמה שלך פג.<br>
        על מנת לאפס את הסיסמה שלך עבור לעמוד
      </span>
      <ui-link to="/reset-process" i18n>איפוס סיסמה שנשכחה</ui-link>
    </p>
  </ui-alert>
</div>
