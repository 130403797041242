
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Gender } from '@jct/localization';
import { StateFactory, ProgressButtonComponent } from '@jct/ui';
import { HttpStatusResult } from '@jct/core';
import { StatementsService, RegistrationNavigator, RegistrationStages, RegistrationUserProfile } from '@jct/api';

interface Row {
  id: number;
  agreement: boolean;
  required: boolean;
}

@Component({
  selector: 'general-statements-tab.page',
  templateUrl: './general-statements-tab.component.html',
})
export class GeneralStatementsTabComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private statementsService: StatementsService,
    private registrationNavigator: RegistrationNavigator,
    private userProfile: RegistrationUserProfile,
    private fb: UntypedFormBuilder)
  { }

  loadState = this.sf.create();
  saveState = this.sf.create();

  rows = this.fb.array([]);
  form = this.fb.group({
    'statements': this.rows,
  });

  @ViewChild(MatSelectionList)
  selectionList!: MatSelectionList;

  @ViewChild(ProgressButtonComponent)
  progressButton!: ProgressButtonComponent;

  showError = false;

  get gender(): Gender {
    return this.userProfile.gender;
  }

  get hasAllStatementsChecks() {
    return this.rows.controls
      .map(x => <Row>x.value)
      .every(x => !x.required || x.agreement && x.required);
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    const result = await this.statementsService.getStudentStatementsAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      result.forEach(statement => {
        this.rows.push(this.fb.group({
          id: [statement.id, []],
          description: [statement.description, []],
          agreement: [statement.hasAgreed || false, statement.required ? [Validators.required]: []],
          required: [statement.required || false, []],
        }));
      });
    }

    await this.loadState.completed();
  }

  async submit() {
    if (!this.hasAllStatementsChecks) {
      this.showError = true;

      document.querySelector<HTMLElement>('.mat-checkbox.ng-invalid input')
        .focus({preventScroll: false});

      return;
    }

    if (!this.saveState.isInProcess) {
      await this.saveState.inProcess();

      const agreeStatements = this.rows.controls
        .map(x => <Row>x.value)
        .filter(x => x.agreement)
        .map(x => x.id);

      const result = await this.statementsService.saveStudentStatementsAsync(agreeStatements);

      if (!result.succeeded) {
        this.saveState.failed(result);
        return;
      }

      this.registrationNavigator.setCurrentStage(RegistrationStages.הצהרות_ואישורים);

      await this.saveState.completed();
      await this.registrationNavigator.navigateToNextStage();
    }
  }
}
