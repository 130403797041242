
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Authenticator } from '@jct/core';

@Component({
  templateUrl: 'timeout-dialog.component.html',
})
export class TimeoutDialogComponent implements OnInit, OnDestroy {
  private _seconds: number;
  private _timer: any = null;

  constructor(
    private authenticator: Authenticator,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TimeoutDialogComponent>)
  { }

  get seconds() { return this._seconds; }

  async ngOnInit() {
    this._seconds = this.authenticator.remainderTokenLifetime;

    this._timer = setInterval(() => {
      this._countDown();
    }, 1000);
  }

  ngOnDestroy() {
    this._clearTimer();
  }

  stay() {
    this.dialogRef.close();

    setTimeout(async () => {
      await this.authenticator.refreshToken();
    });
  }

  logMeOut() {
    this.dialogRef.close();

    setTimeout(() => {
      this.authenticator.logout(true);
    });
  }

  private _countDown() {
    this._seconds--;
    this.cd.markForCheck();

    if (this._seconds <= 0) {
      this.dialogRef.close();
    }
  }

  private _clearTimer() {
    if (this._timer !== null) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }
}
