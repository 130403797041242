
import { Component } from '@angular/core';

import { L10nService } from '@jct/localization';
import { AppLayoutService } from '@jct/ui';
import { PageTitleService } from '@jct/core';

@Component({
  templateUrl: './public.component.html',
})
export class PublicComponent {
  constructor(
    public appLayout: AppLayoutService,
    public pageTitle: PageTitleService,
    public l10n: L10nService)
  { }
}
