import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Controller, FromBody, FromRoute, HostEnvironment, HttpGet, HttpPost, Produce, resultOf, Uploader } from '@jct/core';
import { Observable } from 'rxjs';
import { Category } from '.';
import { BasicUserDetails, LecturersAppointments, LecturersArticles, LecturersGrants } from '../lecturers';
import { LecturerArticleData, LecturerDialogData } from '../upload-files';
import { ActionDefination } from './action.types';


@Injectable({ providedIn: 'root' })
@Controller('Action')
export class ActionService {

  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private uploader: Uploader<LecturerArticleData>,
    private sanitizer: DomSanitizer) {
  }


  //for download files
  get baseUrl() {
    return `${this.environment.apiServerUrl}/Action/Download`;
  }

  get articlesRelativeUrl(){
    return 'Action/SaveArticles';
  } 

  //פונקציה השומרת את הנתונים שהמרצה הכניס בכל אחד מעמודי ה-action
  @HttpPost('SaveAction')
  @Produce('string')
  saveAction(@FromBody() model: any) {
    return resultOf<string>();
  }
  saveArticals(model: LecturerArticleData[]) {
    return this.uploader.uploadMultupleFiles<LecturerArticleData>(model,this.articlesRelativeUrl);
  }

  uploadArticle(model: LecturerArticleData) {
    return this.uploader.upload<LecturerArticleData>(model,this.articlesRelativeUrl);
  }
  
  certificateLink(fileId:number) {
    return this.sanitizer.bypassSecurityTrustUrl(
      `${this.baseUrl}/${fileId}`);
  }

  //פונקציה השומרת את הנתונים שהמרצה הכניס בתוך דיאלוג ללא העלאת קובץ
  @HttpPost('SaveLecturerAction')
  // @SuccessTestCase(LoggedInState)
  // @FailedTestCase(InvalidModelResult, 'כתובת המייל לא תקינה')
  saveLecturerAction(@FromBody() model: LecturerDialogData) {
    return resultOf<any>();
  }

  @HttpGet('DeleteLecturerAction/{id}')
  @Produce('string')
  deleteLecturerAction(@FromRoute('id') id: number) {
    return resultOf<string>();
  }

  @HttpGet('deleteArticle/{articleId}')
  @Produce('string')
  deleteArticle(@FromRoute('articleId') articleId: number) {
    return resultOf<string>();
  }

  @HttpPost('SaveLecturerAppointment')
  @Produce('string')
  saveLecturerAppointment(@FromBody() lecturersAppointments: LecturersAppointments) {
    return resultOf<string>();
  }

  @HttpGet('DeleteLecturerAppointment/{id}')
  @Produce('string')
  deleteLecturerAppointment(@FromRoute('id') id: number) {
    return resultOf<string>();
  }

  @HttpGet('GetActionDefination')
  @Produce(ActionDefination, true)
  getActionDefination() {
    return resultOf<ActionDefination[]>();
  }

  @HttpGet('getArticleById/{id}')
  @Produce(LecturersArticles, false)
  getArticleById(@FromRoute('id') id: number) {
    return resultOf<LecturersArticles>();
  }

  @HttpGet('SaveDepartmentHead/{id}')
  @Produce(BasicUserDetails)
  saveDepartmentHead(@FromRoute('id') departmentHeadId: number) {
    return resultOf<BasicUserDetails>();
  }


  @HttpGet('GetDepartmentHeads')
  @Produce(BasicUserDetails, true)
  getDepartmentHeads() {
    return resultOf<BasicUserDetails[]>();
  }

  @HttpGet('DeleteDepartmentHeads/{id}')
  @Produce("boolean")
  deleteDepartmentHeads(@FromRoute('id') departmentHeadId: number) {
    return resultOf<boolean>();
  }

  @HttpGet("getCategories")
  getCategories() {
    return resultOf<Category[]>();
  }

  @HttpGet("getLecturerGrants")
  getLecturerGrants() {
    return resultOf<LecturersGrants[]>();
  }

  @HttpGet('deleteLecturerGrant/{lecturersActionId}')
  @Produce('string')
  deleteLecturerGrant(@FromRoute('lecturersActionId') lecturersActionId:number){
    return resultOf<string>();
  }

}
