import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewContainerRef,
} from "@angular/core";
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
} from "@angular/forms";

import { HostEnvironment } from "@jct/core";
import { FormDebuggerToolbar } from "./form-debugger-toolbar";

export const formDirectiveProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => FormDebugger),
};

@Directive({
  selector: "[formGroup]",
  providers: [formDirectiveProvider],
  host: {
    "(submit)": "onSubmit($event)",
    "(reset)": "onReset()",
    class: "form-debugger",
  },
  exportAs: "formDebugger",
})
export class FormDebugger
  extends FormGroupDirective
  implements OnInit, OnDestroy
{
  private _componentRef: ComponentRef<FormDebuggerToolbar>;

  constructor(
    @Optional() @Self() @Inject(NG_VALIDATORS) _validators: any[],
    @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) _asyncValidators: any[],
    private environment: HostEnvironment,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(_validators, _asyncValidators);
  }

  @Input("formGroup")
  form: UntypedFormGroup = null!;

  async ngOnInit() {
    if (!this.environment.isProduction()) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          FormDebuggerToolbar
        );
      this._componentRef =
        this.viewContainerRef.createComponent<FormDebuggerToolbar>(
          componentFactory
        );
      this._componentRef.instance.form = this.form;
    }
  }

  ngOnDestroy() {
    this._componentRef?.destroy();
    this._componentRef = null;
  }
}
