<ui-error [state]="loadState"></ui-error>

<div container *visible="loadState.isCompleted">
  
  <section *ngIf="isFirstDegree">

    <strong  *ngIf="isFemale">
      <section *ngIf="isNursingTrack" i18n>
        הנך מועמדת לחוג לסיעוד.<br />
        קישור לקביעת ראיון יישלח אלייך לאחר העלאת כל מסמכי הרישום ובדיקת נתונייך ע"י מדור מידע ורישום.<br /><br />
      </section>
      <section *ngIf="!isNursingTrack" i18n>
        הראיונות בקמפוס טל ובקמפוס תבונה מתקיימים באופן פרונטלי בקמפוס<br />
        טל – רחוב בית הדפוס 7 קומה 3<br />
        תבונה – רחוב בית הדפוס 9 קומה 1<br /><br />
      </section>
    </strong>
  
    <strong *ngIf="isMale">
      <section *ngIf="!isNursingTrack" i18n>
        ראיון אישי הינו חלק מתהליך הקבלה. הראיונות מתקיימים באופן פרונטלי על ידי רבני בית המדרש.<br />
        קמפוס לב - רחוב הועד הלאומי 21 בירושלים. <br />
        מומלץ להגיע לפני הראיון למשרד הרישום הממוקם בבניין ישראל קומה 4.<br /><br />
      </section>
      <section *ngIf="isNursingTrack" i18n>
        הנך מועמד לחוג לסיעוד.<br/>
        לאחר העלאת כל מסמכי הרישום ובדיקת נתוניך,<br/>
        מזכירת המחלקה תיצור עמך קשר לקביעת מועד לראיון אישי<br/><br/>
      </section>
    </strong>
  </section>

  <section *ngIf="isPreparatory">
    <span i18n>
      הנך {gender, select, male {מועמד} female {מועמדת}} למכינה.<br />
      מידע לגבי קביעת ראיון יישלח אליך לאחר העלאת כל מסמכי הרישום ובדיקת נתוניך ע"י מזכירות המכינה.<br />
    </span>
  </section>

  <section *ngIf="isSecondDegree">
    <span i18n>
      הנך {gender, select, male {מועמד} female {מועמדת}} לתואר שני.<br />
      ראיון אישי הינו חלק מתהליך הקבלה. מדור מידע ורישום יעמוד עמך בקשר לאחר בדיקת נתוניך והרקע הלימודי שלך.<br />
    </span>
  </section>

  <section>
    <span  *ngIf="!isFinishUploadMandatoryFiles" i18n>
      {gender, select, male {תוכל} female {תוכלי}} לקבוע ראיון רק לאחר העלאת כל מסמכי הרישום הדרושים.<br />
    </span>
    <span i18n>
      להעלאת מסמכים יש ללחוץ
      <ui-link to="/upload/registration-files"> כאן </ui-link>
    </span>
    <br />
    <span i18n>בהצלחה!</span><br/><br/>
  </section>

  <section *ngIf="displayScheduleInterview">
    <p>
      <span i18n>אם אינך מצליח לראות את תוכן החלון </span>    
      <ui-link to="https://www.tor4you.co.il/site.asp?siteid=4028&sid=8C1DF800FE176DB2" i18n>
        לחץ כאן
      </ui-link>
    </p>

    <iframe src="https://www.tor4you.co.il/site.asp?siteid=4028&sid=8C1DF800FE176DB2" scrolling="no" width="340"
      height="570" frameborder="0">
    </iframe>
  </section>

</div>