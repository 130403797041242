import { ProvideValue, TestDescription } from '../api';
import { TestBuilders } from '../common';
import { TokenSettings } from './authenticator.types';

const ID_TOKEN_LIFETIME = '00:20:00';
const ID_TOKEN_EXP = TestBuilders.minutesFromNow(20);

@TestDescription('הארכת זמן טוקן קיים')
export class FakeTokenSettings extends TokenSettings {
  @ProvideValue(ID_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(ID_TOKEN_EXP)
  tokenExpiredOn?: string;
}
