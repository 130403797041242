import { EnvironmentInjector } from "@angular/core";
import {
  Directive,
  Input,
  ComponentRef,
} from "@angular/core";
import {
  RouterOutlet,
  ActivatedRoute,
} from "@angular/router";
import { ActionState } from "@jct/ui/lib/common";

import { DomApi } from "@jct/ui/lib/services";
import { AnimationType, AnimationsMap } from "./animations";

@Directive({
  selector: "router-view",
  exportAs: "routerView",
})
export class RouterView extends RouterOutlet {
  private _fragment = document.createDocumentFragment();
  private _rootElement: HTMLElement;

  constructor() {
    super();
  }

  @Input()
  animationType: AnimationType = "route";

  private get _activated(): ComponentRef<any> | null {
    return this["activated"];
  }
  private get _activatedElement(): HTMLElement {
    return this._activated?.location?.nativeElement;
  }

  private async _enter() {
    const width = this._rootElement.clientWidth;
    const height = this._rootElement.clientHeight;
    const element = DomApi.warp(this._activatedElement);

    element
      .css({
        display: "block",
        position: "fixed",
        width: width + "px",
        height: height + "px",
        "z-index": "100",
      })
      .animate({
        duration: 550,
        story: AnimationsMap[this.animationType].enter,
        end: () => {
          element.css({
            display: "",
            position: "",
            width: "",
            height: "",
            "z-index": "",
          });
        },
      });
  }

  private _leave(container: HTMLElement) {
    const leaveElement = this._fragment.lastChild;

    container.appendChild(leaveElement);

    const width = this._rootElement.clientWidth;
    const height = this._rootElement.clientHeight;
    const element = DomApi.warp(<HTMLElement>leaveElement);

    element
      .css({
        display: "block",
        position: "fixed",
        width: width + "px",
        height: height + "px",
        "z-index": "90",
      })
      .animate({
        story: AnimationsMap[this.animationType].leave,
        duration: 450,
        disappear: true,
        end: () => {
          container.removeChild(leaveElement);
        },
      });
  }

  detach(): ComponentRef<any> {
    const clonedNode = this._activatedElement.cloneNode(true);

    this._removeIdAttributes(clonedNode);
    this._fragment.appendChild(clonedNode);

    const container = this._activatedElement.parentElement;
    const detachResult = super.detach();

    this._leave(container);
    return detachResult;
  }

  attach(ref: ComponentRef<any>, activatedRoute: ActivatedRoute) {
    super.attach(ref, activatedRoute);
    this._enter();
  }

  deactivate(): void {
    this._rootElement = document.querySelector('main[role="main"]');
    if (this._activated) {
      const container = this._activatedElement.parentElement;
      const clonedNode = this._activatedElement.cloneNode(true);

      this._removeIdAttributes(clonedNode);
      this._fragment.appendChild(clonedNode);

      super.deactivate();
      this._leave(container);
    }
  }

  activateWith(
    activatedRoute: ActivatedRoute,
    injector: EnvironmentInjector | null
  ) {
    this._rootElement = document.querySelector('main[role="main"]');
    super.activateWith(activatedRoute, injector);
    this._enter();
  }

  private _removeIdAttributes(node: Node) {
    DomApi.removeAttr(<Element>node, "id");
    const children = Array.from(node.childNodes);

    for (let child of children) {
      this._removeIdAttributes(child);
    }
  }
}
