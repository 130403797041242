
import { Component, ContentChildren, QueryList, AfterContentInit, ViewEncapsulation } from '@angular/core';

import { MenuService, AppLayoutService } from '@jct/ui/lib/services';

import { MenuItemComponent } from './menu-item.component';

@Component({
  selector: 'ui-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideMenuComponent implements AfterContentInit {
  constructor(
    private menuService: MenuService,
    public appLayout: AppLayoutService)
  { }

  @ContentChildren(MenuItemComponent)
  items!: QueryList<MenuItemComponent>;

  ngAfterContentInit() {
    let items = this.items.toArray();

    this.menuService.registerMenu(items);
  }
}
