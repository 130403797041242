import { Injectable } from '@angular/core';
import { Column } from './shared.types';

@Injectable({
  providedIn: 'root'
})
export class ExportCSVService {

  constructor() { }

  exportToCSV(listData:any[],columns:Column[],fileName: string='') {
    let exportcsv: any[] = [];
    (<any[]>JSON.parse(JSON.stringify(listData))).forEach(x => {
      var obj = new Object();
      // var frmt = new Format();
      for (var i = 0; i < columns.length; i++) {
        //let transfrmVal = this.transform(x[columns[i].variable], columns[i].filter);
        var variables=columns[i].variable.split(".");
        var columnValue=x;
        variables.forEach(v => {
          columnValue=columnValue[v];
        });
        obj[columns[i].display] = columnValue==undefined?"":columns[i].filter=="boolean"? columnValue==true?"כן":"לא":columnValue;
      }
      exportcsv.push(obj);
    }
    );
    this.downloadcsv(exportcsv, fileName);
  }

  downloadcsv(data: any, exportFileName: string) {
    var csvData = this.convertToCSV(data);

    var blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, this.createFileName(exportFileName))
    } else {
      var universalBOM = "\uFEFF";

      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvData));
        link.setAttribute("download", this.createFileName(exportFileName));
        
        //link.style = "visibility:hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  convertToCSV(objarray: any) {
    var array = typeof objarray != 'object' ? JSON.parse(objarray) : objarray;

    var str = '';
    var row = "";

    for (var index in objarray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
        if(typeof array[i][index] === 'string' && this.isNumeric(array[i][index])){
          line += array[i][index]+ "\t";
        }
        else{
        line += JSON.stringify(array[i][index]);
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  createFileName(exportFileName: string): string {
    var date = new Date();
    return (exportFileName +
      date.toLocaleDateString() + "_" +
      date.toLocaleTimeString()
      + '.csv')
  }

   isNumeric(value: string | number): boolean {
    return /^-?\d+$/.test(value as string);
  }
}
