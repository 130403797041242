
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'is',
  pure: false
})
export class IsValuePipe implements PipeTransform {
  transform(value: any, compareWith: any): boolean {
    return value == compareWith;
  }
}
