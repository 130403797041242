
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PAGES_COMPONENTS_ROUTES } from '@jct/ui';
import { ClearCachePage } from '@jct/core';

import { PublicComponent } from './public.component';
import { LoginPage } from './+login';
import { ConfigureTfaPage } from './+configure-tfa';
import { ValidateTfaPage } from './+validate-tfa';
import { DisplayQrCodePage } from './+display-qrcode';

export const PUBLIC_ROUTES: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: 'login',
        component: LoginPage,
      },
      {
        path: 'configure-tfa',
        component: ConfigureTfaPage,
      },
      {
        path: 'validate-tfa',
        component: ValidateTfaPage,
      },
      {
        path: 'display-qrcode',
        component: DisplayQrCodePage,
      },
      ...PAGES_COMPONENTS_ROUTES,
      {
        path: 'clear-cache',
        component: ClearCachePage,
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(PUBLIC_ROUTES)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
