import { Injectable, Injector } from "@angular/core";
import { Controller, HttpGet, Produce, resultOf } from "@jct/core";
import {
  ExamSyncDatesWithTomax,
  TomaxBarcodesModel,
  TomaxSyncSpecialDates,
} from "./tomax.types";

@Injectable({
  providedIn: "root",
})
@Controller("tomax")
export class TomaxService {
  constructor(protected injector: Injector) {}

  @HttpGet("getTomaxBarcodes")
  @Produce(TomaxBarcodesModel, true)
  getTomaxBarcodes() {
    return resultOf<TomaxBarcodesModel[]>();
  }

  @HttpGet("getExamSyncDatesWithTomax")
  @Produce(ExamSyncDatesWithTomax, false)
  getExamSyncDatesWithTomax() {
    return resultOf<ExamSyncDatesWithTomax>();
  }

  @HttpGet("getTomaxSyncSpecialDates")
  @Produce(TomaxSyncSpecialDates, false)
  getTomaxSyncSpecialDates() {
    return resultOf<TomaxSyncSpecialDates>();
  }
}
