
<div class="ui-debugger-modal-container" *visible="!appLayout.isMobile">
  <section class="ui-debugger-modal mat-elevation-z4" *visible="displayState; transition 'bounce'">
    <strong>מצב הטופס</strong>
    <pre>{{ formState }}</pre>
  </section>

  <section class="ui-debugger-modal mat-elevation-z4" *visible="displayModel; transition 'bounce'">
    <strong>מודל</strong>
    <pre>{{ formValue }}</pre>
  </section>
</div>

<section class="mat-elevation-z2 form-debugger-toolbar" *visible="!appLayout.isMobile">
  <h3>כלי פיתוח</h3>

  <mat-slide-toggle [(ngModel)]="displayModel">
    הצג מודל
  </mat-slide-toggle>

  <mat-slide-toggle [(ngModel)]="displayState">
    הצג מצב הטופס
  </mat-slide-toggle>
</section>
