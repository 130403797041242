
export namespace Diagnostic {
  let from = 0;

  export function start() {
    console.debug('DIAGNOSTIC TOOL: start measure');

    from = Date.now();
  }

  export function snapshot(message?: string) {
    if (message) {
      console.debug('DIAGNOSTIC TOOL:', message, '(take ' + (Date.now() - from) + 'ms)');
    }
    else {
      console.debug('DIAGNOSTIC TOOL:', '(take ' + (Date.now() - from) + 'ms)');
    }
  }
}
