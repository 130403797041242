
<ui-error [state]="loadState"></ui-error>

<div container *visible="loadState.isCompleted">
  <mat-card class="mb-10">
    <h3 ui-header i18n>
      {gender, select,
        male {הנך רשום למסלול הלימודים הבא}
        female {הנך רשומה למסלול הלימודים הבא}
      }
    </h3>

    <div ui-body>
      <table class="mb-10">
        <tbody>
          <tr>
            <th i18n style="width: 150px;">סוג מסלול לימודים</th>
            <td>{{ mainStudentStudyTrack.academicStudyTypeName }}</td>
          </tr>

          <tr>
            <th i18n>מכון</th>
            <td>{{ mainStudentStudyTrack.instituteName }}</td>
          </tr>

          <tr>
            <th i18n>מחלקה</th>
            <td>{{ mainStudentStudyTrack.departmentName }}</td>
          </tr>

          <tr>
            <th i18n>חוג</th>
            <td>{{ mainStudentStudyTrack.trackName }}</td>
          </tr>

          <tr>
            <th i18n>שנה</th>
            <td>{{ mainStudentStudyTrack.academicYearTitle }}</td>
          </tr>
        </tbody>
      </table>

      <p class="mb-10">
        <span i18n>מצב הרשמה</span>:
        <strong>
          {{ registrationStatus?.statusName }}
        </strong>
      </p>

      <markdown>
        {{ registrationStatus?.webMessage }}
      </markdown>
    </div>
  </mat-card>

  <mat-card>
    <div ui-body>
      <p i18n class="mb-10">
        {gender, select,
          male { שים לב - }
          female { שימי לב - }
        }
      </p>
      <p i18n class="mb-10">
        {gender, select,
          male { אם הינך סטודנט במכינה הצריך להירשם }
          female { אם הינך סטודנטית במכינה וצריכה להירשם }
        }
        לשנה הבאה לתואר ראשון או שנרשמת ולא התקבלת (למשל בגין אי עמידה בתנאי הקבלה) וכעת שיפרת נתונים,
        עומדת בפניך אפשרות להירשם לשנה"ל הבאה.
        </p>

        <p i18n class="mb-10">
          {gender, select,
            male {בלחיצה על הכפתור "הרשם לשנה הבאה" הנך מתחיל הרשמה מחדש}
            female {בלחיצה על הכפתור "הרשמי לשנה הבאה" הנך מתחילה הרשמה מחדש}
          }
        </p>

      <ui-error [state]="registerState"></ui-error>

      <ui-progress-button
        color="accent"
        [state]="registerState"
        (progress)="register()"
        i18n>
        {gender, select,
          male {הרשם לשנה הבאה}
          female {הרשמי לשנה הבאה}
        }
      </ui-progress-button>
    </div>
  </mat-card>
</div>
