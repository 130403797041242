
import { Component, OnInit, ViewChild } from '@angular/core';

import {
  RegistrationFeeService,
  RegistrationFeeDetails,
  RegistrationFeeDiscountModel,
  DiscountReasonEnum,
  RegistrationUserProfile,
  RegistrationStates,
  RegistrationNavigator,
  StudyTrackService,
  StudyTrack
} from '@jct/api';

import { StateFactory } from '@jct/ui';
import { Router } from '@angular/router';
import { HttpStatusResult } from '@jct/core';

@Component({
  selector: 'registration-fee.page',
  templateUrl: './registration-fee-page.component.html',
})
export class RegistrationFeePageComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private registrationFeeService: RegistrationFeeService,
    private studyTrackService: StudyTrackService,
    private profile: RegistrationUserProfile,
    private navigator: RegistrationNavigator,
    private router: Router)
  { }

  paymentDetails: RegistrationFeeDetails;
  code: string = null;
  mainStudentStudyTrack: StudyTrack;
  isTrackOpenToRegistration:boolean = false;

  loadState = this.sf.create();
  enterCodeState = this.sf.create();
  beginPaymentState = this.sf.create();

  get gender() {
    return this.profile.gender;
  }

  async ngOnInit() {
    if (this.profile.isPayedRegistrationFee) {
      await this.router.navigate(['registration-status']);
      return;
    }

    await this.loadState.inProcess();

    //#region load Payment details
    const result = await this.registrationFeeService.getPaymentDetailsAsync();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed(result);
      return;
    }

    this.paymentDetails = result;

    //#region show registration details
    const mainStudentStudyTrack = await this.studyTrackService.getMainStudentStudyTrackAsync();

    if (mainStudentStudyTrack instanceof HttpStatusResult) {
      this.loadState.failed(mainStudentStudyTrack);
      return;
    }

    this.mainStudentStudyTrack = mainStudentStudyTrack;

    //#region chack if study-track open for registration
    const studyTracks = await this.studyTrackService.getStudyTracksAsync();

    if (studyTracks instanceof HttpStatusResult) {
      this.loadState.failed(studyTracks);
      return;
    }

    const studentStudyTracks = await this.studyTrackService.getStudentYearlyStudyTracksAsync();

    if (studentStudyTracks instanceof HttpStatusResult) {
      if (!studentStudyTracks.isEntityNotExists) {
        this.loadState.failed(studentStudyTracks);
        return;
      }
    }

    if ('tracks' in studentStudyTracks) {
      const { academicStudyTypeId, academicYear, tracks } = studentStudyTracks;
      this.isTrackOpenToRegistration = studyTracks.map(x => x.trackId).includes(tracks[0]);
    }
    await this.loadState.completed();
  }

  showEnterCode() {
    const discountReason = this.paymentDetails?.discounts
      .map(x => x.discountReason);

    return !discountReason?.includes(DiscountReasonEnum.הנחת_יום_פתוח);
  }

  explainDiscount(paymentDiscount: RegistrationFeeDiscountModel) {
    switch (paymentDiscount.discountReason) {
      case DiscountReasonEnum.הנחת_יום_פתוח:
        return 'הנחת יום פתוח';
      case DiscountReasonEnum.הנחת_נרשם_למכינה:
        return 'הנחת נרשם למכינה';
      case DiscountReasonEnum.הנחת_רישום_מקוון:
        return 'הנחת רישום מקוון';
      case DiscountReasonEnum.הנחת_תלמיד_חוזר:
        return 'הנחת תלמיד חוזר';
    }
  }

  get isPaid() {
    return this.profile.state === RegistrationStates.PaidOnThisYear;
  }

  async enterOpenDayCode() {
    if (!this.enterCodeState.isInProcess) {
      await this.enterCodeState.inProcess();

      const result = await this.registrationFeeService.enterOpenDayCodeAsync(this.code);

      if (!result.succeeded) {
        this.enterCodeState.failed(result);
        return;
      }

      await this.enterCodeState.completed();
      await this.ngOnInit();
    }
  }

  async payAsync() {
    if (!this.beginPaymentState.isInProcess) {
      await this.beginPaymentState.inProcess();

      const result = await this.registrationFeeService.beginPaymentProcessViaCGAsync();

      if (result instanceof HttpStatusResult) {
        this.beginPaymentState.failed(result);
        return;
      }

      location.href = result;
    }
  }
}
