
export class VoucherComponentModel {
  description: string;
  amount: number;
  isSummerCourseMandatoryForStudent: boolean;
}

export class VoucherModel {
  voucherIdentity: string;
  amount: number;
  isPaid: boolean;
  isReceiptAvailable: boolean;
  components: VoucherComponentModel[];
  numPayments: number;
}
