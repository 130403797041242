import { Injectable, Injector } from '@angular/core';

import { ApiInterceptor, API_INTERCEPTORS } from './types';
import { HttpContext } from './http-context';

export abstract class HttpHandler {
  abstract initial(): void;
  abstract configRequest<T>(context: HttpContext<T>): void;
  abstract beginResponse<T>(context: HttpContext<T>): Promise<boolean>;
  abstract endResponse<T>(context: HttpContext<T>): Promise<boolean>;
  abstract handleError<T>(context: HttpContext<T>): Promise<boolean>;
}

@Injectable({ providedIn: 'root' })
export class HttpHandlerImpl implements HttpHandler {
  private interceptors: ApiInterceptor[] = [];

  constructor(
    private injector: Injector)
  { }

  initial() {
    if (this.interceptors.length === 0) {
      let idx = 0;

      const interceptors = this.injector.get(API_INTERCEPTORS, []);

      this.interceptors = interceptors
        .filter((x, index) => interceptors.indexOf(x) === index)
        .map(x => {
          x.order = typeof x.order === 'undefined' ? idx++ : x.order;
          return x;
        })
        .sort((a, b) => a.order - b.order);
    }
  }

  configRequest<T>(context: HttpContext<T>) {
    this.interceptors
      .filter(x => !!x.config && x.enabled)
      .forEach(x => {
        x.config(context);
      });
  }

  async beginResponse<T>(context: HttpContext<T>): Promise<boolean> {
    const interceptors = this.interceptors
      .filter(x => !!x.begin && x.enabled);

    for (let interceptor of interceptors) {
      await interceptor.begin(context);
      if (context.hasResponse) {
        return true;
      }
    }

    return false;
  }

  async endResponse<T>(context: HttpContext<T>): Promise<boolean> {
    let hasResponse = false;

    const interceptors = this.interceptors
      .filter(x => !!x.end && x.enabled)
      .reverse();

    for (let interceptor of interceptors) {
      await interceptor.end(context);
      if (context.response) {
        hasResponse = true;
      }
    }

    return hasResponse;
  }

  async handleError<T>(context: HttpContext<T>): Promise<boolean> {
    let hasResponse = false;

    const interceptors = this.interceptors
      .filter(x => !!x.error && x.enabled)
      .reverse();

    for (let interceptor of interceptors) {
      await interceptor.error(context);
      if (context.response) {
        hasResponse = true;
      }
    }

    return hasResponse;
  }
}
