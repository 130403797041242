import { TestDescription } from './decorators';

export interface HttpStatusParameter {
  statusText: string;
  status: number;
}

export function isHttpStatusResult(data: any): data is HttpStatusParameter {
  return data &&
    typeof data.statusText === 'string' &&
    typeof data.status === 'number';
}

export class HttpStatusResult {
  statusText: string|null = null;
  status: number = 0;

  is(compare: HttpStatusParameter) {
    return this.statusText === compare.statusText &&
      this.status === compare.status;
  }

  get hasConnectivity() {
    return !this.is(new ServerUnavailableResult()) &&
      !this.is(new OfflineResult()) &&
      !this.is(new GatewayTimeoutResult());
  }

  get succeeded() {
    return this.status >= 200 && this.status < 300;
  }

  // 2xx server errors
  get isSuccess() { return this.is(new SuccessResult()); }
  get isOk() { return this.is(new OkResult()); }
  get isCreated() { return this.is(new CreatedResult()); }
  get isAccepted() { return this.is(new AcceptedResult()); }
  get isNoContent() { return this.is(new NoContentResult()); }

  // 4xx server errors
  get isBadRequest() { return this.is(new BadRequestResult()); }
  get isInvalidModel() { return this.is(new InvalidModelResult()); }
  get isInvalidParameter() { return this.is(new InvalidParameterResult()); }
  get isMissingParameter() { return this.is(new MissingParameterResult()); }
  get isInvalidClient() { return this.is(new InvalidClientResult()); }
  get isVerificationFailed() { return this.is(new VerificationFailedResult()); }
  get isEntityAlreadyExists() { return this.is(new EntityAlreadyExistsResult()); }
  get isEntityNotExists() { return this.is(new EntityNotExistsResult()); }
  get isInvalidToken() { return this.is(new InvalidTokenResult()); }
  get isTooManyRequests() { return this.is(new TooManyRequestsResult()); }
  get isFileNotExists() { return this.is(new FileNotExistsResult()); }
  get isForbidden() { return this.is(new ForbiddenResult()); }
  get isUnauthorized() { return this.is(new UnauthorizedResult()); }
  get isInvalidJsonFormat() { return this.is(new InvalidJsonFormatResult()); }
  get isInvalidXmlFormat() { return this.is(new InvalidXmlFormatResult()); }
  get isRequirementsMismatch() { return this.is(new RequirementsMismatchResult()); }
  get isUnsupportedTokenClaim() { return this.is(new UnsupportedTokenClaimResult()); }
  get isNotFound() { return this.is(new NotFoundResult()); }
  get isDirectoryNotExists() { return this.is(new DirectoryNotExistsResult()); }
  get isConflict() { return this.is(new ConflictResult()); }
  get isPayloadTooLarge() { return this.is(new PayloadTooLargeResult()); }
  get isFileTooLong() { return this.is(new FileTooLongResult()); }
  get isUnsupportedMediaType() { return this.is(new UnsupportedMediaTypeResult()); }
  get isNotSupportedFileType() { return this.is(new NotSupportedFileTypeResult()); }
  get isUnprocessableEntity() { return this.is(new UnprocessableEntityResult()); }

  // 5xx server errors
  get isDbInternalError() { return this.is(new DbInternalErrorResult()); }
  get isNotImplemented() { return this.is(new NotImplementedResult()); }
  get isInternalError() { return this.is(new InternalErrorResult()); }
  get isInternalServerError() { return this.is(new InternalServerErrorResult()); }
  get isOperationFailed() { return this.is(new OperationFailedResult()); }
  get isProtectorServiceError() { return this.is(new ProtectorServiceErrorResult()); }
  get isGatewayTimeout() { return this.is(new GatewayTimeoutResult()); }
  get isServiceUnavailable() { return this.is(new ServiceUnavailableResult()); }

  // client errors
  get isServerUnavailable() { return this.is(new ServerUnavailableResult()); }
  get isOffline() { return this.is(new OfflineResult()); }
}

//#region 2xx server errors

@TestDescription('הצלחה')
export class OkResult extends HttpStatusResult {
  statusText = 'ok';
  status = 200;
}

@TestDescription('הצלחה')
export class SuccessResult extends HttpStatusResult {
  statusText = 'success';
  status = 200;
}

@TestDescription('המשאב המבוקש נוצר בהצלחה בשרת')
export class CreatedResult extends HttpStatusResult {
  statusText = 'created';
  status = 201;
}

@TestDescription('הבקשה התקבלה בשרת')
export class AcceptedResult extends HttpStatusResult {
  statusText = 'accepted';
  status = 202;
}

@TestDescription('הבקשה התקבלה בשרת אך לא נשלח תוכן')
export class NoContentResult extends HttpStatusResult {
  statusText = 'no content';
  status = 204;
}

//#endregion

//#region 4xx server errors

@TestDescription('הבקשה לא תקינה')
export class BadRequestResult extends HttpStatusResult {
  statusText = 'bad request';
  status = 400;
}

@TestDescription('הלקוח הזין נתונים לא תקפים')
export class InvalidModelResult extends BadRequestResult {
  statusText = 'invalid model';
  status = 400;
}

@TestDescription('פרמטר לא תקין')
export class InvalidParameterResult extends BadRequestResult {
  statusText = 'invalid parameter';
  status = 400;
}

@TestDescription('פרמטר חסר')
export class MissingParameterResult extends BadRequestResult {
  statusText = 'missing parameter';
  status = 400;
}

@TestDescription('אין הרשאה לגשת למשאב המבוקש בשרת')
export class UnauthorizedResult extends HttpStatusResult {
  statusText = 'unauthorized';
  status = 401;
}

@TestDescription('אין הרשאה לגשת למשאב המבוקש')
export class InvalidClientResult extends HttpStatusResult {
  statusText = 'invalid client';
  status = 401;
}

@TestDescription('הסיסמה לא תקינה או שהמשתמש לא קיים')
export class VerificationFailedResult extends HttpStatusResult {
  statusText = 'verification failed';
  status = 401;
}

@TestDescription('טוקן התחברות לא תקין')
export class InvalidTokenResult extends HttpStatusResult {
  statusText = 'invalid token';
  status = 401;
}

@TestDescription('לא ניתן לבצע את הפעולה עקב חוסר בדרישות')
export class RequirementsMismatchResult extends HttpStatusResult {
  statusText = 'requirements mismatch';
  status = 401;
}

@TestDescription('אחת מהדרישות בטוקן לא נתמכת')
export class UnsupportedTokenClaimResult extends HttpStatusResult {
  statusText = 'unsupported token claim';
  status = 401;
}

@TestDescription('למשתמש אין מספיק הרשאות כדי לבצע את הפעולה')
export class ForbiddenResult extends HttpStatusResult {
  statusText = 'forbidden';
  status = 403;
}

@TestDescription('המשאב המבוקש לא קיים בשרת')
export class NotFoundResult extends HttpStatusResult {
  statusText = 'not found';
  status = 404;
}

@TestDescription('השאב המבוקש לא קיים במערכת')
export class EntityNotExistsResult extends HttpStatusResult {
  statusText = 'entity not exists';
  status = 404;
}

@TestDescription('התיקייה המבוקשת לא קיימת בשרת')
export class DirectoryNotExistsResult extends HttpStatusResult {
  statusText = 'directory not exists';
  status = 404;
}

@TestDescription('הקובץ המבוקש לא קיים בשרת')
export class FileNotExistsResult extends HttpStatusResult {
  statusText = 'file not exists';
  status = 404;
}

@TestDescription('קיים כבר משאב כזה בשרת')
export class ConflictResult extends HttpStatusResult {
  statusText = 'conflict';
  status = 409;
}

@TestDescription('קיים כבר נתון כזה בשרת')
export class EntityAlreadyExistsResult extends HttpStatusResult {
  statusText = 'entity already exists';
  status = 409;
}

@TestDescription('המשאב גדול מדי עבור השרת')
export class PayloadTooLargeResult extends HttpStatusResult {
  statusText = 'payload too large';
  status = 413;
}

@TestDescription('הקובץ גדול מדי')
export class FileTooLongResult extends HttpStatusResult {
  statusText = 'file too long';
  status = 413;
}

@TestDescription('סוג המדיה שהתקבל לא נתמך בשרת')
export class UnsupportedMediaTypeResult extends HttpStatusResult {
  statusText = 'unsupported media type';
  status = 415;
}

@TestDescription('סוג הקובץ אינו נתמך ע"י המערכת')
export class NotSupportedFileTypeResult extends HttpStatusResult {
  statusText = 'not supported file type';
  status = 415;
}

@TestDescription('השרת נתקל במשאב שלא ניתן לפענוח')
export class UnprocessableEntityResult extends HttpStatusResult {
  statusText = 'unprocessable entity';
  status = 422;
}

@TestDescription('פורמט JSON לא תקין')
export class InvalidJsonFormatResult extends HttpStatusResult {
  statusText = 'invalid json format';
  status = 422;
}

@TestDescription('פורמט XML לא תקין')
export class InvalidXmlFormatResult extends HttpStatusResult {
  statusText = 'invalid xml format';
  status = 422;
}

@TestDescription('חסימה בעקבות בקשות רבות מדי לשרת')
export class TooManyRequestsResult extends HttpStatusResult {
  statusText = 'too many requests';
  status = 429;
}

//#endregion

//#region 5xx server errors

@TestDescription('שגיאה פנימית בשרת')
export class InternalServerErrorResult extends HttpStatusResult {
  statusText = 'internal server error';
  status = 500;
}

@TestDescription('שגיאה פנימית בבסיס הנתונים')
export class DbInternalErrorResult extends HttpStatusResult {
  statusText = 'db internal error';
  status = 500;
}

@TestDescription('שגיאה כללית')
export class InternalErrorResult extends HttpStatusResult {
  statusText = 'internal error';
  status = 500;
}

@TestDescription('הפעולה נכשלה')
export class OperationFailedResult extends HttpStatusResult {
  statusText = 'operation failed';
  status = 500;
}

@TestDescription('שגיאה בשירות ההצפנה בשרת')
export class ProtectorServiceErrorResult extends HttpStatusResult {
  statusText = 'protector service error';
  status = 500;
}

@TestDescription('הפעולה המובקשת לא ממומשת בשרת')
export class NotImplementedResult extends HttpStatusResult {
  statusText = 'not implemented';
  status = 501;
}

@TestDescription('השרת אינו זמין לבצע את הבקשה')
export class ServiceUnavailableResult extends HttpStatusResult {
  statusText = 'service unavailable';
  status = 503;
}

@TestDescription('פג תוקף הבקשה בשער הכניסה')
export class GatewayTimeoutResult extends HttpStatusResult {
  statusText = 'gateway timeout';
  status = 504;
}

//#endregion

//#region client errors

@TestDescription('השרת לא זמין')
export class ServerUnavailableResult extends HttpStatusResult {
  statusText = 'server unavailable model';
}

@TestDescription('הלקוח ללא חיבור לאינטרנט')
export class OfflineResult extends HttpStatusResult {
  statusText = 'offline';
}

//#endregion

export type HttpResultPromise<T = any> = Promise<HttpStatusResult | T>;

export function resultOf<T = HttpStatusResult>() {
  return Promise.resolve<HttpStatusResult | T>(new NotImplementedResult());
}
