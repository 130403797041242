import { TestBuilders } from '@jct/core';
import { StudentPreviousDegree, TypesListItem } from './previous-degree.type';

export const DEGREE_TYPE_PROVIDER = () =>{
  return FAKE_DEGREE_TYPE.map(x => x.id);
}

export const DEGREE_TRACK_PROVIDER = () =>{
  return FAKE_DEGREE_TRACK.map(x => x.id);
}

export const STUDENT_DEGREE_TRACKS_PROVIDER =() => {
  const degreeTypeProvider = TestBuilders.oneOf(FAKE_DEGREE_TYPE.map(x => x.id));
  const degreeTrackProvider = TestBuilders.oneOf(FAKE_DEGREE_TRACK.map(x => x.id));
  const averageProvider = TestBuilders.numberBetween(0, 100);

  const count = TestBuilders.numberBetween(1, 2)();
  const result: StudentPreviousDegree[] = [];

  for (let index = 0; index < count; index++) {
    const item = new StudentPreviousDegree();

    item.degreeTypeId = <number>degreeTypeProvider();
    item.degreeTrackId = <number>degreeTrackProvider();
    item.average = <number>averageProvider();

    result.push(item);
  }

  return result;
}

export const FAKE_DEGREE_TYPE: TypesListItem[] = [
  {
    "id": 4,
    "name": "B. OPTPM"
  },
  {
    "id": 5,
    "name": "B. PT"
  },
  {
    "id": 1,
    "name": "B.A"
  },
  {
    "id": 17,
    "name": "B.Des."
  },
  {
    "id": 18,
    "name": "B.E.M.S."
  },
  {
    "id": 2,
    "name": "B.Ed"
  },
  {
    "id": 19,
    "name": "B.Mus"
  },
  {
    "id": 20,
    "name": "B.O.T"
  },
  {
    "id": 13,
    "name": "B.S.N"
  },
  {
    "id": 21,
    "name": "B.S.W"
  },
  {
    "id": 6,
    "name": "B.Sc"
  },
  {
    "id": 3,
    "name": "B.Tech"
  },
  {
    "id": 7,
    "name": "Dr."
  },
  {
    "id": 12,
    "name": "L.L.B"
  },
  {
    "id": 11,
    "name": "L.L.M"
  },
  {
    "id": 29,
    "name": "M. Optom"
  },
  {
    "id": 8,
    "name": "M.A"
  },
  {
    "id": 22,
    "name": "M.Arc."
  },
  {
    "id": 23,
    "name": "M.B.A."
  },
  {
    "id": 24,
    "name": "M.D."
  },
  {
    "id": 25,
    "name": "M.E."
  },
  {
    "id": 26,
    "name": "M.E.M."
  },
  {
    "id": 10,
    "name": "M.Ed"
  },
  {
    "id": 27,
    "name": "M.F.A"
  },
  {
    "id": 28,
    "name": "M.H.A."
  },
  {
    "id": 30,
    "name": "M.P.H."
  },
  {
    "id": 31,
    "name": "M.P.T."
  },
  {
    "id": 32,
    "name": "M.S.W."
  },
  {
    "id": 9,
    "name": "M.Sc"
  },
  {
    "id": 14,
    "name": "בוגר בחשבונאות"
  },
  {
    "id": 16,
    "name": "בוגר בחשבונאות+C.P.A"
  },
  {
    "id": 15,
    "name": "בוגר בחשבונאות+השלמה"
  }
];

export const FAKE_DEGREE_TRACK: TypesListItem[] = [
  {
    "id": 43,
    "name": "אדריכלות"
  },
  {
    "id": 44,
    "name": "אומנות"
  },
  {
    "id": 1,
    "name": "אופטומטריה"
  },
  {
    "id": 45,
    "name": "בריאות הציבור"
  },
  {
    "id": 5,
    "name": "ה. אלקטרואופטיקה"
  },
  {
    "id": 6,
    "name": "ה. אלקטרוניקה"
  },
  {
    "id": 7,
    "name": "ה. חשמל"
  },
  {
    "id": 41,
    "name": "ה. מכונות"
  },
  {
    "id": 8,
    "name": "ה. מערכות תקשורת"
  },
  {
    "id": 9,
    "name": "ה. תוכנה/ מחשבים"
  },
  {
    "id": 10,
    "name": "ה. תעשיה וניהול"
  },
  {
    "id": 2,
    "name": "הנדסה אזרחית"
  },
  {
    "id": 3,
    "name": "הנדסה רפואית"
  },
  {
    "id": 4,
    "name": "הנדסת חומרים"
  },
  {
    "id": 11,
    "name": "חינוך"
  },
  {
    "id": 13,
    "name": "חינוך + ת.הוראה"
  },
  {
    "id": 12,
    "name": "חינוך מיוחד"
  },
  {
    "id": 14,
    "name": "חשבונאות"
  },
  {
    "id": 16,
    "name": "חשבונאות + רו\"ח"
  },
  {
    "id": 15,
    "name": "חשבונאות + שנת השלמה"
  },
  {
    "id": 17,
    "name": "יהדות"
  },
  {
    "id": 18,
    "name": "כימיה חישובית"
  },
  {
    "id": 19,
    "name": "מדעי ההתנהגות"
  },
  {
    "id": 20,
    "name": "מדעי החברה"
  },
  {
    "id": 21,
    "name": "מדעי החברה/ כלכלה"
  },
  {
    "id": 22,
    "name": "מדעי החברה/ לוגיסטיקה"
  },
  {
    "id": 23,
    "name": "מדעי החיים"
  },
  {
    "id": 24,
    "name": "מדעי הטבע"
  },
  {
    "id": 25,
    "name": "מדעי המוח"
  },
  {
    "id": 26,
    "name": "מדעי המחשב"
  },
  {
    "id": 46,
    "name": "מוסיקה"
  },
  {
    "id": 27,
    "name": "מנהל עסקים"
  },
  {
    "id": 28,
    "name": "מנהל עסקים וכלכלה"
  },
  {
    "id": 29,
    "name": "משפטים"
  },
  {
    "id": 30,
    "name": "מתמטיקה"
  },
  {
    "id": 31,
    "name": "ניהול ושיווק טכנולוגיה"
  },
  {
    "id": 32,
    "name": "ניהול מערכות בריאות ותיירות"
  },
  {
    "id": 33,
    "name": "סיעוד"
  },
  {
    "id": 47,
    "name": "עבודה סוציאלית"
  },
  {
    "id": 48,
    "name": "עיצוב/ קולנוע/ תקשורת"
  },
  {
    "id": 34,
    "name": "פיזיוטרפיה"
  },
  {
    "id": 35,
    "name": "פיזיקה"
  },
  {
    "id": 49,
    "name": "ריפוי בעיסוק"
  },
  {
    "id": 36,
    "name": "רפואה"
  },
  {
    "id": 50,
    "name": "רפואת חירום"
  },
  {
    "id": 37,
    "name": "רפואת שיניים"
  },
  {
    "id": 38,
    "name": "ת. ראשון כללי"
  },
  {
    "id": 40,
    "name": "ת. שני חינוך"
  },
  {
    "id": 39,
    "name": "ת. שני כללי"
  },
  {
    "id": 42,
    "name": "ת. שני משפטים"
  }
];
