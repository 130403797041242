
export enum ContactTypes {
  Self = 'self',
  Parents = 'parents'
}

export enum OccupationTypes {
  Employee = 'employee',
  SelfEmployed = 'selfEmployed',
  Studying = 'studying',
  Passed = 'passed',
  Pension = 'pension',
  PartiallyEmployed = 'partiallyEmployed',
  Unemployed = 'unemployed',
}

export enum RelationTypes {
  Spouse = 'spouse',
  Father = 'father',
  Mother = 'mother',
  Son = 'son',
  Brother = 'brother',
  Sister = 'sister',
  Daughter = 'daughter',
  Boyfriend = 'boyfriend',
  Girlfriend = 'girlfriend',
}

export enum FamilyStates {
  Single = 'single',
  Married = 'married',
  Divorced = 'divorced',
  Widow = 'widow',
}

export enum CitizenshipTypes {
  Citizen = 'citizen',
  Tourist = 'tourist',
  TemporaryResident = 'temporaryResident',
  NewImmigrant = 'newImmigrant',
  ReturnedResident = 'returnedResident',
}

export enum ServiceExemptionReasons {
  עתודה_אקדמית = 'עתודה_אקדמית',
  עתודה_ב = 'עתודה_ב',
  רפואית = 'רפואית',
  מתחת_לגיל_גיוס = 'מתחת_לגיל_גיוס',
  אזרח_חוץ = 'אזרח_חוץ',
  עתודה_קרבית = 'עתודה_קרבית',
  עתודה_חרדית = 'עתודה_חרדית',
  מטעמי_דת = 'מטעמי_דת',
  אמיץ = 'אמיץ',
  נתיב = 'נתיב',
  אופק = 'אופק',
  חלמיש = 'חלמיש',
  אקדמיזציה_חרדית = 'אקדמיזציה_חרדית',
  פטור = 'פטור',
  חוסר_התאמה = 'חוסר_התאמה',
  הנדסאי_קריית_נוער = 'הנדסאי_קריית_נוער',
  תורתו_אומנותו = 'תורתו_אומנותו'
}

export enum VoluntaryServiceTypes {
  DidNotServe = 'didNotServe',
  ArmyCompulsoryService = 'armyCompulsoryService',
  ArmyPermanentService = 'armyPermanentService',
  YeshivatHesder = 'yeshivatHesder',
  AbroadVoluntary = 'abroadVoluntary',
  Nahal = 'nahal',
  NationalService = 'nationalService',
  StageB = 'stageB',
}

export class PersonContact {
  id: number;
  personId: number;
  contactType: ContactTypes;
  // address
  country?: string;
  state?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
  pob?: string;
  zip?: string;
  // contact info
  email?: string;
  noSpam?: boolean;
  homePhone?: string;
  workPhone?: string;
  cellularPhone?: string;
  smsDisabled: boolean;
}

export class PersonDetails {
  gender?: string;
  firstName: string;
  lastName: string;
  englishFirstName: string;
  englishLastName: string;
  birthDate?: string;
  familyState?: FamilyStates;
  marriageDate?: string;
  childrenNumber?: number;
  birthCountry?: string;
  citizenship?: CitizenshipTypes;
  aliyaDate?: string;
  aliyaCountry?: string;
  voluntaryServiceType: VoluntaryServiceTypes;
  serviceExemptionReason?: ServiceExemptionReasons;
  workPlace?: string;
  role?: string;
}

export class PersonRelative {
  relationType: RelationTypes;
  firstName: string;
  lastName: string;
  birthCountry?: string;
  educationYears?: number;
  occupationType?: OccupationTypes;
  workPlace?: string;
  jobDescription?: string;
  workPhone?: string;
}
