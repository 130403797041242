
<div container>
  <ui-error [state]="loadState"></ui-error>

  <form [formGroup]="form"
    autocomplete="off"
    novalidate
    *visible="loadState.isCompleted"
    (submit)="calculate()">

    <bagrut-table [subjects]="subjects" [form]="form">
    </bagrut-table>

    <section *visible="loadState.isCompleted">
      <mat-radio-group formControlName="gradeType">
        <mat-label i18n>אנא ביחרו סוג ציון</mat-label>
        <mat-radio-button [value]="'psychometry'" i18n>פסיכומטרי</mat-radio-button>
        <mat-radio-button [value]="'til'" i18n>תיל</mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="fill" class="no-hint">
        <mat-label i18n>
          אנא הזן ציון
          {gradeType, select,
            psychometry {פסיכומטרי}
            other {תיל}}
        </mat-label>

        <input matInput
          formControlName="grade"
          type="number"
          maxlength="3"
          max="999"
          min="0">
      </mat-form-field>

      <ui-progress-button
        color="primary"
        [disabled]="form.invalid"
        [state]="calculateState">
        <span i18n>
          {calculateState.value, select,
            inProcess {מחשב ציון התאמה}
            other {חשב ציון התאמה}
          }
        </span>
      </ui-progress-button>
    </section>

    <ui-alert *visible="result && calculateState.isCompleted; transition 'slide'" [type]="alertType">
      <div ui-header i18n *ngIf="result?.hasHatama">
        סיכוייך לקבלה טובים
      </div>

      <div ui-body *ngIf="!result?.hasHatama || result?.finalGrade > 0">
        <p *ngIf="hasStatus('MissingMinimumRequirements')" i18n>
          עלך להזין נתוני בגרות במתמטיקה ואנגלית ולפחות 21 יח"ל
        </p>

        <p *ngIf="result?.finalGrade > 0">
          <strong i18n>ציון ההתאמה שלך הוא</strong>:
          {{ result?.finalGrade | number:'1.0-2' }}
        </p>

        <p i18n *ngIf="hasStatus('NotMeetTheAdmissionRequirements')">
          ציון ההתאמה שלך קטן מ-79,
          <br>
          אנו ממליצים לך לפנות למדור מידע ורישום על מנת לקבל ייעוץ כיצד תוכל לשפר את סיכוייך להתקבל ללימודים.
          <br>
          בטלפון 1-700-50-32-32
        </p>
      </div>
    </ui-alert>

    <ui-error [state]="calculateState"></ui-error>
  </form>

  <mat-divider *visible="loadState.isCompleted"></mat-divider>

  <explanation-part *visible="loadState.isCompleted"></explanation-part>
</div>
