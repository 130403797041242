
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';

import { BagrutSubject } from '@jct/api';
import { BehaviorSubject } from 'rxjs';
import { StateFactory } from '@jct/ui';

export interface SubjectRow {
  emptyRow: boolean;
  subject?: string;
  selectedSubject?: BagrutSubject;
  units?: number;
  grade?: number;
  bonus?: number;
  finalGrade?: number;
}

const DISABLED_EVENTS = { emitEvent: false };

@Component({
  selector: 'bagrut-table',
  templateUrl: './bagrut-table.html',
  exportAs: 'bagrutTable',
  host: {
    'style': 'display:flex'
  },
})
export class BagrutTable {
  private _allSubjects: BagrutSubject[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private sf: StateFactory)
  { }

  @Input()
  form: UntypedFormGroup;

  @Input()
  subjects: BagrutSubject[];

  loadState = this.sf.create();
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['subject', 'units', 'grade', 'bonus', 'finalGrade', 'actions'];

  get rows(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('subjects');
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    this._allSubjects = this.subjects;
    this.addEmptyRow();

    await this.loadState.completed(150);
  }

  get totalUnits() {
    return this.rows.controls.length > 0 ? this.rows.controls
      .filter(x => x.value.units)
      .map(x => x.value.units)
      .reduce((total, num) => total + num, 0) : 0;
  }

  get average() {
    return this.totalUnits > 0 ? this.rows.controls
      .filter(x => x.value.units && x.value.grade)
      .map(x => x.value.units * x.value.grade)
      .reduce((total, num) => total + num, 0) / this.totalUnits : 0;
  }

  get weightedAverage() {
    return this.totalUnits > 0 ? this.rows.controls
      .filter(x => x.value.units && x.value.finalGrade)
      .map(x => x.value.units * x.value.finalGrade)
      .reduce((total, num) => total + num, 0) / this.totalUnits : 0;
  }

  removeRow(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }

  canRemoveRow(row: UntypedFormGroup) {
    return !row.value.emptyRow;
  }

  filterSubjects(row: SubjectRow) {
    let takenSubjects = this.rows.controls
      .filter(x => x.value.selectedSubject)
      .map(x => x.value.selectedSubject.id);

    if (row.selectedSubject) {
      let index = takenSubjects.indexOf(row.selectedSubject.id);

      if (index >= 0) {
        takenSubjects.splice(index, 1);
      }
    }

    let availableSubjects = this._allSubjects
      .sort((a, b) => a.subjectName.localeCompare(b.subjectName))
      .filter(x => takenSubjects.indexOf(x.id) < 0);

    if (row.subject) {
      return availableSubjects
        .filter(x => x.subjectName.indexOf(row.subject) >= 0)
        .slice(0, 20);
    }

    return availableSubjects.slice(0, 20);
  }

  private get hasEmptyRow() {
    return this.rows.controls.some(x => x.value.emptyRow);
  }

  private disableRow(row: UntypedFormGroup) {
    row.controls['units'].disable(DISABLED_EVENTS);
    row.controls['grade'].disable(DISABLED_EVENTS);
  }

  private addEmptyRow() {
    if (!this.hasEmptyRow) {
      const EMPTY_ROW = {
        'emptyRow': true,
        'subject': null,
        'selectedSubject': null,
        'units': null,
        'grade': null,
        'bonus': null,
        'finalGrade': null,
      };

      let row = this.fb.group(EMPTY_ROW);

      this.disableRow(row);
      this.rows.push(row);

      row.controls['subject'].valueChanges.subscribe((value: string) => {
        if (value && value.trim().length > 0) {
          let item = this._allSubjects.find(x => x.subjectName == value);

          if (item) {
            row.patchValue({
              selectedSubject: item,
              emptyRow: false,
            }, DISABLED_EVENTS);

            row.enable(DISABLED_EVENTS);
            this.addEmptyRow();

            return;
          }
          else {
            row.patchValue({
              selectedSubject: null,
              bonus: null,
              finalGrade: null,
            }, DISABLED_EVENTS);
          }
        }
        else {
          row.patchValue({
            selectedSubject: null,
            emptyRow: true,
            units: null,
            grade: null,
            bonus: null,
            finalGrade: null,
          }, DISABLED_EVENTS);
        }

        this.disableRow(row);
        this.removeLastEmptyRow();
      });

      row.valueChanges.subscribe((changes: SubjectRow) => {
        if (!changes.emptyRow) {
          if (changes.units > 0 && changes.grade > 0) {
            let units = changes.units;
            let bonus = 0;

            if (changes.grade >= changes.selectedSubject.bonusMinGrade) {
              bonus = units >= 5 ? changes.selectedSubject.bonus5Units :
              units >= 4 ? changes.selectedSubject.bonus4Units : 0;
            }

            row.patchValue({
              bonus, finalGrade:
              bonus + changes.grade,
            }, DISABLED_EVENTS);
          }
        }
      });

      this.dataSource.next(this.rows.controls);
    }
  }

  private removeLastEmptyRow() {
    let emptyRows = this.rows.controls.filter(x => x.value.emptyRow);

    if (emptyRows.length > 1) {
      let last = emptyRows[emptyRows.length - 1];
      let index = this.rows.controls.indexOf(last);

      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }
}
