import { Component, Input, ViewEncapsulation } from "@angular/core";

import { AppLayoutService } from "@jct/ui/lib/services";
import { ActionState } from "@jct/ui/lib/common";
import { CardTypes } from "../card";

@Component({
  selector: "ui-error",
  templateUrl: "./error.html",
  styleUrls: ["./error.scss"],
  exportAs: "uiError",
  encapsulation: ViewEncapsulation.None,
})
export class UiError {
  constructor(public appLayout: AppLayoutService) {}

  @Input("with-padding")
  withPadding: boolean = true;

  @Input()
  state?: ActionState;

  @Input()
  ignoreList?: number[] = [];

  get hasError() {
    return this.error !== null && !this.ignoreList.includes(this.error.status);
  }

  get error() {
    return this.state.error || null;
  }

  get status() {
    return this.error?.status;
  }

  get statusTest() {
    const statusText = this.error?.statusText;

    if (!statusText) {
      return "";
    }

    return statusText.replace(/\s/g, "_").toUpper();
  }

  get alertType(): CardTypes {
    if (this.status >= 400 && this.status < 500) {
      return "warning";
    }

    if (this.status >= 200 && this.status < 300) {
      return "success";
    }

    return "error";
  }
}
