
<div container [attr.dir]="layout">
  <form
    [formGroup]="form"
    novalidate
    autocomplete="off"
    (submit)="submit()"
  >
    <h4>טופס בדיקות לדימוי תשלום מול חברת Credit Guard</h4>

    <mat-form-field appearance="fill">
      <mat-label>
        תעודת זהות של בעל הכרטיס
      </mat-label>

      <input matInput
        required
        formControlName="idNumber"
        maxlength="20">

      <mat-error>
        הזינו מספר תעודת זהות תקין
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>
        מספר כרטיס אשראי
      </mat-label>

      <input matInput
        required
        formControlName="creditCard"
        maxlength="20">

      <mat-error>
        הזינו מספר כרטיס אשראי תקין
      </mat-error>
    </mat-form-field>

    <mat-form-group>
      <mat-form-field appearance="fill" style="width: 140px;">
        <mat-label>
          שנה
        </mat-label>

        <mat-select formControlName="expYear" required>
          <mat-option *ngFor="let year of allYears" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>

        <mat-error>
          חובה להזין שנה
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 140px;">
        <mat-label>
          חודש
        </mat-label>

        <mat-select formControlName="expMonth" required>
          <mat-option *ngFor="let month of allMonths" [value]="month">
            {{ month }}
          </mat-option>
        </mat-select>

        <mat-error>
          חובה להזין חודש
        </mat-error>
      </mat-form-field>
    </mat-form-group>

    <mat-form-field appearance="fill">
      <mat-label>
        CVV - 3 ספרות אחרונות בגב הכרטיס
      </mat-label>

      <input matInput
        required
        formControlName="cvv"
        maxlength="4">

      <mat-error>
        הזינו מספר CVV תקין
      </mat-error>
    </mat-form-field>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="form.invalid">
      תשלום
    </button>&nbsp;

    <button
      type="button"
      (click)="cancel()"
      mat-raised-button>
      ביטול
    </button>&nbsp;

    <button
      type="button"
      (click)="failed()"
      mat-raised-button
      color="accent">
      התשלום נכשל
    </button>
  </form>
</div>
