import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CREDIT_GUARD_TEST_PAYMENT_URL } from '@jct/api';

import { CreditGuardTestPage } from './credit-guard-test-page';

export const TEST_ROUTES: Routes = [
  {
    path: CREDIT_GUARD_TEST_PAYMENT_URL.trim('/'),
    component: CreditGuardTestPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(TEST_ROUTES)
  ],
  exports: [RouterModule]
})
export class TestRoutingModule { }
