
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PUBLIC_APP_ROUTES } from './public-app';
import { PRIVATE_APP_ROUTES } from './private-app';
//import { PUBLIC_FRAME_ROUTES } from './public-frame';
import { ADMIN_APP_ROUTES } from './admin-app';

export const APP_ROUTES: Routes = [
  ...PRIVATE_APP_ROUTES,
  //...PUBLIC_FRAME_ROUTES,
  ...PUBLIC_APP_ROUTES,
  ...ADMIN_APP_ROUTES,
  {
    path: '**',
    redirectTo: '/not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
