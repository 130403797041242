
import { ElementRef, Directive, Input, OnInit } from '@angular/core';
import 'rxjs';

import { domAccessor } from '@jct/ui/lib/services';

@Directive({
  selector: '[ui-column-width]',
  exportAs: 'uiColumnWidth',
})
export class ColumnWidth implements OnInit {
  private _element = domAccessor(this.elementRef);

  constructor(
    private elementRef: ElementRef<HTMLElement>)
  { }

  async ngOnInit() {
    this.updateColumns();
  }

  updateColumns() {
    let tableWidth = 0;

    for (let index = 0; index < this.columnWidth.length; index++) {
      let width = this.columnWidth[index];
      let value = `${width}px`;
      let innerValue = `${width - 24}px`;

      tableWidth += width;

      this._element
        .observeQueryAll(`tbody > tr > td:nth-of-type(${index+1})`)
        .subscribe(cells => cells.forEach(cell => {
          cell.css({
            'min-width': value,
            'width': value,
            'box-sizing': 'border-box',
          });

          cell.query(`mat-form-field.mat-form-field`)
            .css({
              'min-width': innerValue,
              'width': innerValue,
              'box-sizing': 'border-box',
            });
        }));

      this._element
        .queryAll(`tr > th:nth-of-type(${index+1}), tr > td:nth-of-type(${index+1})`)
        .forEach(cell => cell.css({
          'min-width': value,
          'width': value,
          'box-sizing': 'border-box',
        }));
    }

    this._element
      .css({
        'min-width': `${tableWidth}px`,
      });

    this._element.parent
      .css({
        'max-width': `${tableWidth + 14}px`,
      });
  }

  @Input('ui-column-width')
  columnWidth: number[] = [];
}
