
import { HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import {
  ClassDescriptor,
  TestCaseDescriptor
} from '@jct/core';

import {
  lottery,
  LetterInfo,
  HebrewLettersInfo,
  HebrewNonVowelsLettersInfo,
  HebrewVowelsLettersInfo,
  EnglishLettersInfo,
  EnglishNonVowelsLettersInfo,
  EnglishVowelsLettersInfo
} from '../common';

const HEBREW_MIN_SIZE = 2;
const HEBREW_MAX_SIZE = 8;

const ENGLISH_MIN_SIZE = 3;
const ENGLISH_MAX_SIZE = 10;

@Injectable({ providedIn: 'root' })
export class TestCasesProvider {
  constructor(
    private injector: Injector)
  { }

  supplyTestCase(testCase: TestCaseDescriptor, request: HttpRequest<any>) {
    const descriptor = testCase.classDescriptor;

    if (testCase.value) {
      return testCase.value
    }

    if (testCase.provider) {
      return testCase.provider(this.injector, request);
    }

    return this._generateData(descriptor);
  }

  private _generateData(descriptor: ClassDescriptor) {
    let data = new descriptor.classType();

    for (let field of descriptor.fields) {
      if (typeof field.value !== 'undefined') {
        data[field.name] = field.value;
        continue;
      }

      if (typeof field.valueProvider !== 'undefined') {
        data[field.name] = field.valueProvider();
        continue;
      }
    }

    return data;
  }

  word(size: number, locale: string) {
    let word: LetterInfo[] = [];

    if (locale == 'he') {
      let current = lottery(HebrewLettersInfo);

      for (let letters = 0; letters < size; letters++) {
        word.push(current);

        if (current.isVowels) {
          current = lottery(HebrewNonVowelsLettersInfo);
        }
        else {
          if (letters - 2 >= 0 && !word[letters - 2].isVowels) {
            current = lottery(HebrewVowelsLettersInfo);
          }
          else {
            if (Math.random() >= 0.5) {
              current = lottery(HebrewVowelsLettersInfo);
            }
            else {
              current = lottery(HebrewNonVowelsLettersInfo);
            }
          }
        }
      }
    }
    else {
      let current = lottery(EnglishLettersInfo);

      for (let letters = 0; letters < size; letters++) {
        word.push(current);

        if (current.isVowels) {
          current = lottery(EnglishNonVowelsLettersInfo);
        }
        else {
          current = lottery(EnglishVowelsLettersInfo);
        }
      }
    }

    return word[0].letter.toUpperCase() +
      word.filter((_x, index) => index > 0 && index < word.length - 1)
        .map(x => x.letter)
        .join('') +
      (word[word.length - 1].suffix || word[word.length - 1].letter);
  }

  words(size: number, locale: string, separator: string = ' ') {
    let sentence = '';

    for (let words = 0; words < size; words++) {
      let wordSize = 0;

      if (locale == 'he') {
        wordSize = Math.round(HEBREW_MIN_SIZE + Math.random() * (HEBREW_MAX_SIZE - HEBREW_MIN_SIZE));
      }
      else {
        wordSize = Math.round(ENGLISH_MIN_SIZE + Math.random() * (ENGLISH_MAX_SIZE - ENGLISH_MIN_SIZE));
      }

      sentence += this.word(wordSize, locale) + separator;
    }

    sentence = sentence.trim();

    return sentence;
  }

  sentences(size: number, locale: string, separator: string = '. ') {
    let paragraph = '';

    for (let sentences = 0; sentences < size; sentences++) {
      let sentenceSize = Math.round(2 + Math.random() * 15);
      let sentence = this.words(sentenceSize, locale);

      paragraph += sentence[0].toUpperCase() + sentence.substring(1).toLowerCase() + separator;
    }

    return paragraph.trim();
  }

  paragraphs(size: number, locale: string, separator: string = '\n\r') {
    let text = '';

    for (let paragraphs = 0; paragraphs < size; paragraphs++) {
      let paragraphSize = Math.round(3 + Math.random() * 15);

      text += this.sentences(paragraphSize, locale) + separator;
    }

    return text.trim();
  }
}
