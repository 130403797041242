
import { Injectable, Injector } from '@angular/core';

import {
  HttpGet,
  Controller,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessTestCase,
  HttpStatusResult,
  SuccessTestCaseProvider,
  EntityNotExistsResult,
  SuccessResult,
  InvalidModelResult,
  FailedTestCase,
  UnauthorizedResult
} from '@jct/core';

import {  FAKE_GENERAL_STATEMENT, FAKE_HEALTH_STATEMENT_PROVIDER } from './statement.test';
import { StudentCriminalRecord, StudentHealthStatement, StudentStatement } from './statement.types';

@Injectable({ providedIn: 'root' })
@Controller('statements')
export class StatementsService {
  constructor(
    protected injector: Injector)
  { }

  @UseState()
  @HttpGet('health')
  @SuccessTestCaseProvider(FAKE_HEALTH_STATEMENT_PROVIDER, 'קבלת הצהרות עבור הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentHealthStatement)
  getStudentHealthStatementAsync() {
    return resultOf<StudentHealthStatement>();
  }

  @UseState()
  @HttpPost('health')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentHealthStatementAsync(
    @FromBody('healthStatement')
    healthStatement: StudentHealthStatement) {
    return resultOf();
  }

  @UseState()
  @HttpGet('criminal-record')
  @FailedTestCase(UnauthorizedResult)
  @Produce(StudentCriminalRecord)
  getStudentCriminalRecordAsync() {
    return resultOf<StudentCriminalRecord>();
  }

  @UseState()
  @HttpPost('criminal-record')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentCriminalRecordAsync(
    @FromBody('criminalRecords')
    criminalRecords: StudentCriminalRecord) {
    return resultOf();
  }

  @UseState()
  @HttpGet()
  @SuccessTestCase(FAKE_GENERAL_STATEMENT, 'קבלת הצהרות עבור הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentStatement, true)
  getStudentStatementsAsync() {
    return resultOf<StudentStatement[]>();
  }

  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentStatementsAsync(
    @FromBody('studentStatements')
    statements: number[]) {
    return resultOf();
  }
}
