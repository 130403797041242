
<div container>
  <ui-error [state]="loadState"></ui-error>

  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate
    *visible="loadState.isCompleted"
    (submit)="submit()"
  >
    <div formArrayName="statements">
      <div *ngFor="let control of rows.controls; let n = index" class="mb-10">
        <section [formGroupName]="n">
          <markdown class="m-0">
            {{ control?.value?.description }}
          </markdown>

          <mat-checkbox
            formControlName="agreement"
            [required]="control?.value?.required"
            i18n>
            {gender, select,
              male {הנני מאשר}
              female {הנני מאשרת}
            }
            <strong *ngIf="control?.value?.required"
              [ngStyle]="{'color': control.invalid ? 'red' : '#000'}">
              (* חובה להצהיר)
            </strong>
          </mat-checkbox>

          <mat-divider class="mt-10"></mat-divider>
        </section>
      </div>
    </div>

    <ui-alert type="warning" *visible="showError">
      <div ui-body i18n>
        חובה לסמן את כל ההצהרות
      </div>
    </ui-alert>

    <ui-progress-button
      #progressButton
      color="primary"
      [state]="saveState"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>
