
import { Optional, Self, Injector, Component, ViewEncapsulation, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { HttpStatusResult, Filtering } from '@jct/core';
import { PreviousInstitutionService, RegistrationUserProfile } from '@jct/api';
import { AutocompleteFieldComponent } from '@jct/ui';

@Component({
  selector: 'institution-field',
  template: AutocompleteFieldComponent.Template,
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: MatFormFieldControl,
    useExisting: AutocompleteFieldComponent
  }],
})
export class InstitutionFieldComponent extends AutocompleteFieldComponent {
  constructor(
    injector: Injector,
    @Optional() @Self() ngControl: NgControl,
    private institutionService: PreviousInstitutionService,
    private profile: RegistrationUserProfile)
  {
    super(injector, ngControl);
  }

  @Input()
  institutionType: number;

  get gender() {
    return this.profile?.gender;
  }

  async callApiAsync(query: string): Promise<string[]> {
    if (this.institutionType == undefined || this.institutionType == 0){
      return [];
    }

    let result = await this.institutionService.searchInstitutionsAsync(
      new Filtering(20, query), this.institutionType);

    if (result instanceof HttpStatusResult) {
      return [];
    }

    return result;
  }
}
