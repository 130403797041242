
<div container>
  <meta-title i18n>
    הגדרת אימות דו שלבי
  </meta-title>

  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <form
      [formGroup]="form"
      novalidate
      autocomplete="off"
      (submit)="start()"
    >
      <p i18n>
        בשלב הבא עליך להפעיל אימות דו שלבי<br/>
        מומלץ להשתמש באחת מאפליקציות האימות הבאות:<br/>
      </p>

      <p dir="ltr" style="text-align: center;">
        <ui-link to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">
          Google Authenticator
        </ui-link>

        <ui-link to="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US">
          Microsoft Authenticator
        </ui-link>
      </p>

      <ui-progress-button
        color="primary"
        [disabled]="form.invalid"
        [state]="startState"
        i18n>
        הפעל אימות דו שלבי
      </ui-progress-button>&nbsp;

      <button mat-raised-button
        type="button"
        color="pirmary"
        (click)="reset()"
        i18n>
        ביטול
      </button>

      <ui-error [state]="startState">
      </ui-error>
    </form>
  </section>
</div>
