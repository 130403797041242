
<div container>

  <ui-error [state]="loadState"></ui-error>

  <form [formGroup]="form"
   *visible="loadState.isCompleted"
   autocomplete="off">
    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        formArrayName="degrees"
        [ui-column-width]="[200,250,100,50]">

        <ng-container matColumnDef="degree-type">
          <th mat-header-cell *matHeaderCellDef i18n>
            סוג התואר
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field>
              <mat-select formControlName="degreeTypeId" required>
                <mat-option [value]="0" i18n>
                  {gender, select, male {בחר} female {בחרי}}
                  סוג תואר
                </mat-option>
                <mat-option *ngFor="let type of degreeTypes" [value]="type.id">{{ type?.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="degree-track-name">
          <th mat-header-cell *matHeaderCellDef i18n>
            חוג
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field>
              <mat-select formControlName="degreeTrackId" required>
                <mat-option [value]="0" i18n>
                  {gender, select, male {בחר} female {בחרי}}
                  חוג
                </mat-option>
                <mat-option *ngFor="let track of degreeTracks" [value]="track.id">{{ track?.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="average">
          <th mat-header-cell *matHeaderCellDef i18n>
            ממוצע
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="number" matInput
              formControlName="average"
              placeholder="ממוצע"
              i18n-placeholder
              (click)="addEmptyRow()"
              required>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <button mat-icon-button
              type="button"
              [matTooltip]="deleteButtonTooltip.text"
              (click)="removeDegree(index)"
              *visible="canRemoveDegree(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      </table>
    </div>

    <ui-alert type="warning" *visible="showError && errorList.length > 0">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngFor="let error of errorList">
          <li *ngIf="error == 'degreeTrackId-required' || error == 'degreeTrackId-min'" i18n>
            נא הזן חוג
          </li>
          <li *ngIf="error == 'average-required'" i18n>
            נא הזן ממוצע
          </li>
          <li *ngIf="error == 'average-min'" i18n>
            נא הזן ממוצע גדול שווה ל-0
          </li>
          <li *ngIf="error == 'average-max'" i18n>
            נא הזן ממוצע קטן שווה ל-100
          </li>
        </ng-container>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button color="primary"
      [state]="saveState"
      (progress)="saveAsync()"
      i18n>
        {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term #deleteButtonTooltip
  i18n>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>
