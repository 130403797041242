import { Injectable, Injector } from '@angular/core';
import { Controller, FromRoute, HttpGet, resultOf } from '@jct/core';

@Injectable({
  providedIn: 'root'
})
@Controller("student")

export class StudentService {

  constructor(
    protected injector: Injector,
  ) { }

  @HttpGet("getStudentDetailes/{ownerKeyNumber}")
  getStudentDetailes(@FromRoute("ownerKeyNumber") ownerKeyNumber: number) {
    return resultOf<any>();
  }
}
