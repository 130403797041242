
import { Injectable, Provider } from '@angular/core';

import { ApplicationOptions } from './application-options';

@Injectable({ providedIn: 'root' })
export class Application {
  private _options: ApplicationOptions;

  get applicationName() { return this._options?.applicationName; }
  get version() { return this._options?.version; }
  get loginPath() { return this._options?.loginPath; }
  get passwordGeneratorPath() { return this._options?.passwordGeneratorPath; }
  get author() { return this._options?.author; }
  get settings() { return this._options?.settings || {}; }

  config(options?: ApplicationOptions) {
    this._options = options;
  }
}

export const ApplicationProvider: Provider = {
  provide: Application,
  useValue: new Application(),
};
