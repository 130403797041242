
import { Component } from '@angular/core';
import { RegistrationNavigator } from '@jct/api';

@Component({
  selector: 'personal.page',
  templateUrl: './personal-page.component.html',
})
export class PersonalPageComponent {
  constructor(public navigator: RegistrationNavigator) {}
}
