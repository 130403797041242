import {
  Component,
  Optional,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Directionality } from "@angular/cdk/bidi";

import { MenuItemComponent } from "./menu-item.component";
import { MenuService, domAccessor } from "@jct/ui/lib/services";

@Component({
  selector: "ui-menu-content",
  templateUrl: "./menu-content.component.html",
})
export class MenuContentComponent implements AfterViewInit {
  constructor(
    @Optional() private parent: MenuItemComponent,
    private menuService: MenuService,
    private dir: Directionality
  ) {}

  ngAfterViewInit() {
    this.parent.title = this.content ? domAccessor(this.content).text() : "";
    if (this.isOpen) this.open();
  }

  get disabled() {
    return this.parent.disabled;
  }

  @Input()
  icon: string;

  @Input()
  isOpen: boolean;

  @ViewChild("content")
  content!: ElementRef<HTMLElement>;

  get depth() {
    let depth = 0;
    let next = this.parent.parent;

    if (!next) {
      return 0;
    }

    while (!!next) {
      next = next.parent;
      depth++;
    }

    return depth;
  }

  get depthClass() {
    return this.depth > 0 ? `level-${this.depth}` : "";
  }

  get actualIcon() {
    if (!this.parent.children) {
      return this.icon;
    }

    return this.dir.value == "rtl" ? "chevron_left" : "chevron_right";
  }

  get link() {
    return this.parent.link;
  }

  get route() {
    return this.parent.route;
  }

  get type() {
    if (this.parent.children?.length > 0) {
      return "menu";
    }

    if (this.parent.action.observers.length > 0) {
      return "action";
    }

    if (this.parent.link?.length > 0) {
      return "link";
    }

    return "route";
  }

  get openInNew(): boolean {
    return !this.parent.link?.startsWith(location.origin);
  }

  async navigateTo() {
    await this.parent.navigateTo();
  }

  previous() {
    this.menuService.focusPrev(this.parent);
  }

  next() {
    this.menuService.focusNext(this.parent);
  }

  close() {
    this.menuService.closeItem(this.parent);
  }

  open() {
    this.menuService.navigateTo(this.parent);
  }
}
