
import { Component, OnInit } from '@angular/core';

import { VouchersService, VoucherModel } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'vouchers.page',
  templateUrl: './vouchers-page.html',
})
export class VouchersPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private vouchersService: VouchersService)
  { }

  loadState = this.sf.create();

  paidVouchers: VoucherModel[] = [];
  vouchersForPayment: VoucherModel[] = [];
  currentVoucher: VoucherModel = null;

  get thisComponent() { return this; }

  async ngOnInit() {
    await this.loadState.inProcess();

    const paidVouchersResult = await this.vouchersService.getPaidVouchersAsync();
    const vouchersForPaymentResult = await this.vouchersService.getVouchersForPaymentAsync();

    if (paidVouchersResult instanceof HttpStatusResult) {
      this.loadState.failed(paidVouchersResult);
      return;
    }

    if (vouchersForPaymentResult instanceof HttpStatusResult) {
      this.loadState.failed(vouchersForPaymentResult);
      return;
    }

    this.paidVouchers = paidVouchersResult;
    this.vouchersForPayment = vouchersForPaymentResult;

    await this.loadState.completed();
  }

  resetPayment() {
    this.currentVoucher = null;
  }
}
