
export const PHONE_NUMBER_PATTERN = /^[\d\-\+\s\(\)]{7,}$/;
export const EMAIL_PATTERN = /^[a-z][\d\-\.\_\w]*\@[a-z][\d\-\_\w]*(\.[a-z][\d\-\_\w]*)*$/i;
export const HEBREW_NAME_PATTERN = /^[א-ת\s\.]{2,}$/;
export const SHORT_HEBREW_NAME_PATTERN = /^[א-ת\s\.]{0,}$/;
export const ENGLISH_NAME_PATTERN = /^[a-zA-Z\s\.]{3,}$/;
export const SHORT_ENGLISH_NAME_PATTERN = /^[a-zA-Z\s\.]{0,}$/;
export const ZIP_CODE_PATTERN = /^[\w\d]{4,10}$/;
export const HOUSE_NUMBER_PATTERN = /^[\w\d\/\\\sא-ת]{1,}$/;
export const YEAR_PATTERN = /^[\d]{4,4}$/;

export const hebrewNameExtractor = (value: string) => value.replace(/\.+/g, '').replace(/\s+/g, ' ').trim();
export const englishNameExtractor = (value: string) => value.replace(/\.+/g, '').replace(/\s+/g, ' ').trim();
export const emailExtractor = (value: string) => value.trim().toLowerCase();
export const zipCodeExtractor = (value: string) => value.trim().toUpperCase();
export const phoneNumberExtractor = (value: string) => value.replace(/[\+\-\s\(\)]+/g, '');
export const houseNumberExtractor = (value: string) => value.replace(/\s+/g, ' ').trim();
