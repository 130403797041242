
<div container *visible="loadState.isCompleted; transition 'route'">
  <h4 i18n>להלן המועדים אליהם נרשמת</h4>

  <section *visible="studentAmiramTests.length > 0"
    class="table-responsive mat-elevation-z2">
    <table mat-table
      [dataSource]="studentAmiramTests"
      [ui-column-width]="[120,100,100,170]">

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
          תאריך
        </th>

        <td mat-cell *matCellDef="let row;">
          {{ row?.testStartDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="hour">
        <th mat-header-cell *matHeaderCellDef i18n>
          שעה
        </th>

        <td mat-cell *matCellDef="let row;">
          {{ row?.testStartDate | date: 'HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="campus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header i18n>
          קמפוס
        </th>

        <td mat-cell *matCellDef="let row;">
          {{ row?.instituteName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let row;">
          <a [href]="downloadPath(row)"
            target="_blank"
            mat-raised-button
            color="primary">
            <mat-icon>cloud_download</mat-icon>
            &nbsp;&nbsp;
            <span i18n>הורדת האישור</span>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </section>

  <ui-alert type="info" *visible="studentAmiramTests.length === 0">
    <section ui-body i18n>
      לא נמצאו נתונים
    </section>
  </ui-alert>
</div>
