import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'getError',
  pure: false
})
export class GetErrorPipe implements PipeTransform {
  transform(form: UntypedFormGroup, fieldName: string): string {
    let field = form.get(fieldName);

    if (field && field.errors) {
      for (let error in field.errors) {
        return error;
      }
    }

    return '';
  }
}
