
<term #tooltip i18n>
  שינוי פריסת עמוד {layout, select, rtl {מימין לשמאל} ltr {משמאל לימין}}
</term>

<button mat-icon-button
  type="button"
  [matTooltip]="tooltip.text"
  *ngIf="isEnabled"
  [attr.aria-label]="tooltip.text"
  (click)="toggleRtl()">
  <mat-icon>{{ isRtl ? 'format_textdirection_r_to_l' : 'format_textdirection_l_to_r' }}</mat-icon>
</button>
