
import { Optional, Self, Injector, Component, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { HttpStatusResult, Filtering } from '@jct/core';
import { HighSchoolService } from '@jct/api';
import { AutocompleteFieldComponent } from '@jct/ui';

@Component({
  selector: 'high-school-field',
  template: AutocompleteFieldComponent.Template,
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: MatFormFieldControl,
    useExisting: AutocompleteFieldComponent
  }],
})
export class HighSchoolFieldComponent extends AutocompleteFieldComponent {
  constructor(
    injector: Injector,
    @Optional() @Self() ngControl: NgControl,
    private highSchoolService: HighSchoolService)
  {
    super(injector, ngControl);
  }

  async callApiAsync(query: string): Promise<string[]> {
    let result = await this.highSchoolService.searchHighSchoolsAsync(new Filtering(100, query));

    if (result instanceof HttpStatusResult) {
      return [];
    }

    return result;
  }
}
