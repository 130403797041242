
import { Injectable, Injector } from '@angular/core';

import {
  Filtering,
  Controller,
  HttpGet,
  Cache,
  resultOf,
  HttpPost,
  FromBody,
  FromQueryString,
  FromRoute,
  UseState,
  Produce,
  SuccessTestCase,
  EntityNotExistsResult,
  SuccessResult,
  SuccessTestCaseProvider,
  InvalidModelResult,
  FailedTestCase,
  HttpStatusResult
} from '@jct/core';

import { INSTITUTIONS_PROVIDER, PREVIOUS_INSTITUTION } from './previous-institution.test';
import { StudentInstitution } from './previous-institution.type';


@Injectable({providedIn: 'root'})
@Controller('previous-institution')
export class PreviousInstitutionService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet('/search/{type}')
  @Cache('30d')
  @SuccessTestCaseProvider(INSTITUTIONS_PROVIDER)
  @SuccessTestCase(EntityNotExistsResult)
  @Produce('string', true)
  searchInstitutionsAsync(
    @FromQueryString('filtering') filter: Filtering,
    @FromRoute('type') type: number)
  {
    return resultOf<string[]>();
  }

  @UseState()
  @HttpGet()
  @SuccessTestCase(PREVIOUS_INSTITUTION(), 'קבלת מוסדות על תיכוניים של הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentInstitution, true)
  getStudentInstitutionsAsync() {
    return resultOf<StudentInstitution[]>();
  }

  @UseState()
  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentInstitutionsAsync(
    @FromBody('studentInstitutions') institutions: StudentInstitution[])
  {
    return resultOf();
  }
}
