import { HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';

import { TestProvider, TestBuilders } from '@jct/core';
import { VoucherComponentModel, VoucherModel } from './vouchers.types';

export const VOUCHERS_FOR_PAYMENT_PROVIDER: TestProvider<VoucherModel[]> = (injector: Injector, request: HttpRequest<any>) => {
  const voucherIdentityProvider = TestBuilders.identity({ onlyNumbers: true, size: 6 });
  const manyOfNoneCourse = TestBuilders.manyOf(FAKE_VOUCHER_COMPONENTS.filter(x => !x.isSummerCourseMandatoryForStudent), 2);
  const manyOfCourses = TestBuilders.manyOf(FAKE_VOUCHER_COMPONENTS.filter(x => x.isSummerCourseMandatoryForStudent), 2);
  const randomProvider = TestBuilders.numberBetween(1, 2);

  const model: VoucherModel[] = [];

  const noneCourses = manyOfNoneCourse();
  const nonCoursesAmount = noneCourses.reduce((a, c) => a + c.amount, 0);

  model.push({
    amount: nonCoursesAmount,
    components: [...noneCourses],
    isPaid: false,
    isReceiptAvailable: false,
    numPayments: nonCoursesAmount < 300 ? 1 : nonCoursesAmount >= 300 && nonCoursesAmount < 3000 ? 3 : 4,
    voucherIdentity: voucherIdentityProvider(),
  });

  if (randomProvider() === 1) {
    const courses = manyOfCourses();
    const coursesAmount = courses.reduce((a, c) => a + c.amount, 0);

    model.push({
      amount: coursesAmount,
      components: [...courses],
      isPaid: false,
      isReceiptAvailable: false,
      numPayments: coursesAmount < 300 ? 1 : coursesAmount >= 300 && coursesAmount < 3000 ? 3 : 4,
      voucherIdentity: voucherIdentityProvider(),
    });
  }

  return model;
};

export const PAID_VOUCHERS_PROVIDER: TestProvider<VoucherModel[]> = (injector: Injector, request: HttpRequest<any>) => {
  const voucherIdentityProvider = TestBuilders.identity({ onlyNumbers: true, size: 6 });
  const manyOfNoneCourse = TestBuilders.manyOf(FAKE_VOUCHER_COMPONENTS.filter(x => !x.isSummerCourseMandatoryForStudent), 2);
  const manyOfCourses = TestBuilders.manyOf(FAKE_VOUCHER_COMPONENTS.filter(x => x.isSummerCourseMandatoryForStudent), 2);
  const randomProvider = TestBuilders.numberBetween(1, 2);

  const model: VoucherModel[] = [];

  const noneCourses = manyOfNoneCourse();
  const nonCoursesAmount = noneCourses.reduce((a, c) => a + c.amount, 0);

  model.push({
    amount: nonCoursesAmount,
    components: [...noneCourses],
    isPaid: true,
    isReceiptAvailable: true,
    numPayments: nonCoursesAmount < 300 ? 1 : nonCoursesAmount >= 300 && nonCoursesAmount < 3000 ? 3 : 4,
    voucherIdentity: voucherIdentityProvider(),
  });

  if (randomProvider() === 1) {
    const courses = manyOfCourses();
    const coursesAmount = courses.reduce((a, c) => a + c.amount, 0);

    model.push({
      amount: coursesAmount,
      components: [...courses],
      isPaid: true,
      isReceiptAvailable: true,
      numPayments: coursesAmount < 300 ? 1 : coursesAmount >= 300 && coursesAmount < 3000 ? 3 : 4,
      voucherIdentity: voucherIdentityProvider(),
    });
  }

  return model;
};

export const FAKE_VOUCHER_COMPONENTS: VoucherComponentModel[] = [
  {
    amount: 2200,
    description: 'מקדמת שכ"ל',
    isSummerCourseMandatoryForStudent: false,
  },
  {
    amount: 500,
    description: 'מקדמות מעונות',
    isSummerCourseMandatoryForStudent: false,
  },
  {
    amount: 2500,
    description: 'מקדמת לימודי הוראת מדעים',
    isSummerCourseMandatoryForStudent: false,
  },
  {
    amount: 2500,
    description: 'מקדמת שכ"ל תואר שני',
    isSummerCourseMandatoryForStudent: false,
  },
  {
    amount: 650,
    description: 'קורס קדם במתמטיקה למהנדסים',
    isSummerCourseMandatoryForStudent: true,
  },
  {
    amount: 140,
    description: 'קורס הכנה בכימיה לסיעוד',
    isSummerCourseMandatoryForStudent: true,
  },
  {
    amount: 390,
    description: 'קורס אשנב למתמטיקה',
    isSummerCourseMandatoryForStudent: true,
  },
  {
    amount: 800,
    description: 'מתמטיקה קד"א למנע"ס',
    isSummerCourseMandatoryForStudent: true,
  },
  {
    amount: 260,
    description: 'קורס קדם במתמטיקה לסיעוד',
    isSummerCourseMandatoryForStudent: true,
  },
];
