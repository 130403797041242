
import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';

import { HttpStatusResult } from '@jct/core';

import {
  AmiramService,
  AmiramTestModel
} from '@jct/api';

import { StateFactory, ActionState } from '@jct/ui';

@Component({
  selector: 'available-amiram-tests',
  templateUrl: './available-amiram-tests.html',
})
export class AvailableAmiramTests implements OnInit {
  constructor(
    private sf: StateFactory,
    private amiramService: AmiramService)
  { }

  payStates: ActionState[] = [];

  @Output()
  onPayedNewAmiramTest = new EventEmitter();

  availableAmiramTests: AmiramTestModel[];

  loadState = this.sf.create();

  displayedColumns: string[] = ['date', 'hour', 'campus', 'action'];

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.amiramService.getAvailableAmiramTestsAsync();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed();
      return;
    }

    result.forEach(() => {
      this.payStates.push(this.sf.create());
    });

    this.availableAmiramTests = result;

    this.loadState.completed();
  }

  async payAsync(test: AmiramTestModel, index: number) {
    if (this.payStates[index].isInProcess) {
      return;
    }

    this.payStates[index].inProcess();

    let result = await this.amiramService.beginPaymentProcessViaCGAsync(test.id);

    if (result instanceof HttpStatusResult) {
      this.payStates[index].failed();
      return;
    }

    location.href = result;
  }
}
