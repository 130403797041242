
export namespace Enumerable {
  type Selector<T, S> = (item: Partial<T>) => S;
  type Predicate<T> = Selector<T, boolean>;
  type PartOf<T> = Partial<T> | string | number | boolean;
  type Mapper<T> = Selector<T, PartOf<T>>;

  function isEqual<T>(left: T, right: T): boolean {
    if (left === right) {
      return true;
    }

    if (typeof left !== typeof right) {
      return false;
    }

    if (typeof left === 'object') {
      if (left === null || right === null) {
        return false;
      }

      const keys = Object.keys(left)
        .concat(Object.keys(right))
        .filter((item, index, arr) => arr.indexOf(item) === index);

      for (let key of keys) {
        if (left.hasOwnProperty(key) && right.hasOwnProperty(key)) {
          if (!isEqual(left[key], right[key])) {
            return false;
          }
        }
        else {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  function contains<T>(array: T[], item: T) {
    return array.some(x => isEqual(x, item));
  }

  export class Linq<T> {
    private _sequence: Partial<T>[] = [];

    constructor(sequence: T[]) {
      this._sequence = sequence;
    }

    contains(item: Partial<T>) {
      return this._sequence.some(x => isEqual(item, x));
    }

    select<U>(selector: Selector<T, U>) {
      return new Linq<U>(this._sequence.map(selector));
    }

    where(predicate: Predicate<T>) {
      this._sequence = this._sequence.filter(predicate);
      return this;
    }

    any(predicate: Predicate<T>|null = null) {
      if (predicate === null) {
        return this._sequence.length > 0;
      }

      return this._sequence.some(predicate);
    }

    all(predicate: Predicate<T>) {
      return this._sequence.every(predicate);
    }

    concat(...arrays: Partial<T>[]) {
      this._sequence.push(...arrays);
      return this;
    }

    first(predicate: Predicate<T>|null = null, orDefault: T|null = null) {
      if (predicate === null) {
        return this._sequence[0] || orDefault;
      }

      for (let item of this._sequence) {
        if (predicate(item)) {
          return item;
        }
      }

      return orDefault;
    }

    last(predicate: Predicate<T>|null = null, orDefault: T|null = null) {
      if (predicate === null) {
        return this._sequence[this._sequence.length - 1] || orDefault;
      }

      for (let index = this._sequence.length; index > 0; index--) {
        const item = this._sequence[index];
        if (predicate(item)) {
          return item;
        }
      }

      return orDefault;
    }

    min(selector: Selector<T, number>): number {
      let value: number = null;

      this._sequence.forEach(item => {
        const current = selector(item);

        if (value === null || value > current) {
          value = current;
        }
      });

      return value;
    }

    max(selector: Selector<T, number>): number {
      let value: number = null;

      this._sequence.forEach(item => {
        const current = selector(item);

        if (value === null || value < current) {
          value = current;
        }
      });

      return value;
    }

    skip(count: number) {
      this._sequence.splice(0, count);
      return this;
    }

    take(count: number) {
      this._sequence = this._sequence.splice(0, count);
      return this;
    }

    distinct() {
      const exists: Partial<T>[] = [];

      this._sequence = this._sequence.filter(item => {
        if (contains(exists, item)) {
          return false;
        }
        exists.push(item);
        return true;
      });

      return this;
    }

    orderBy(field: keyof T) {
      this._sequence = this._sequence.sort((prev, next) => {
        const prevValue = prev[field];
        const nextValue = next[field];

        if (typeof prevValue === 'string' && typeof nextValue === 'string') {
          return prevValue.localeCompare(nextValue);
        }

        if (typeof prevValue === 'number' && typeof nextValue === 'number') {
          return prevValue - nextValue;
        }

        if (prevValue === null && nextValue === null) {
          return 0;
        }

        if (prevValue === undefined && nextValue === undefined) {
          return 0;
        }

        return 0;
      });

      return this;
    }

    orderByDesc(field: keyof T) {
      this._sequence = this._sequence.sort((prev, next) => {
        const prevValue = prev[field];
        const nextValue = next[field];

        if (typeof prevValue === 'string' && typeof nextValue === 'string') {
          return nextValue.localeCompare(prevValue);
        }

        if (typeof prevValue === 'number' && typeof nextValue === 'number') {
          return nextValue - prevValue;
        }

        if (prevValue === null && nextValue === null) {
          return 0;
        }

        if (prevValue === undefined && nextValue === undefined) {
          return 0;
        }

        return 0;
      });

      return this;
    }

    toArray() {
      return this._sequence;
    }
  }

  export function from<T>(array: T[]): Linq<T> {
    return new Linq<T>(array);
  }

  export function empty<T>(): Linq<T> {
    return new Linq<T>([]);
  }
}
