import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

import {
  Produce,
  Application,
  HttpPost,
  FromBody,
  Controller,
  resultOf,
  HttpGet,
  FromRoute,
  TokenSettings,
} from "@jct/core";

import { LoginModel, ProfileService } from "../../registration";
import { Role } from "./account.types";
import { AuthenticatorState } from "../../registration";

const TOKEN_SETTINGS_STORAGE_KEY = "tokenSettings";

@Injectable({ providedIn: "root" })
@Controller("account")
export class LersAccountService {
  constructor(
    protected injector: Injector,
    private profileService: ProfileService
  ) {}

  @HttpPost("login")
  @Produce(AuthenticatorState)
  login(@FromBody() model: LoginModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpGet("loginAsLecturer/{lecturerId}")
  loginAsLecturer(@FromRoute("lecturerId") lecturerId: number) {
    return resultOf<number>();
  }

  isAdmin() {
    if (this.profileService.profile.role == Role.Admin) return true;
    return false;
  }
}
