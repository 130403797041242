import { Directive, ElementRef, Input } from "@angular/core";

import { ActionState } from "@jct/ui/lib/common";

@Directive({
  selector: "[autofocus]",
})
export class AutofocusDirective {
  constructor(private elementRef: ElementRef<HTMLElement>) {}

  get element() {
    return this.elementRef.nativeElement;
  }

  @Input()
  set autofocus(state: ActionState | boolean | string) {
    if (state instanceof ActionState) {
      state.detector.subscribe((newState) => {
        if (newState === "completed") {
          setTimeout(() => {
            this.element.focus();
          }, 550);
        }
      });
    } else {
      if (state) {
        setTimeout(() => {
          this.element.focus();
        }, 250);
      }
    }
  }
}
