import {
  Component,
  Output,
  Input,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { ActionState } from "@jct/ui/lib/common";

@Component({
  selector: "ui-progress-button",
  templateUrl: "./progress-button.component.html",
  styleUrls: ["./progress-button.scss"],
  exportAs: "uiProgressButton",
  host: {
    class: "ui-progress-button",
  },
  encapsulation: ViewEncapsulation.None,
})
export class ProgressButtonComponent {
  @Output() progress = new EventEmitter();

  onClick() {
    if (!this.state.isInProcess) {
      this.progress.next();
    }
  }

  @Input()
  state: ActionState;

  @Input()
  color: string = "accent";

  @Input()
  disabled: boolean;

  @Input()
  type: string = "submit";

  @Input()
  form: UntypedFormGroup;
}
