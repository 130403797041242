
<div container>

  <ui-error [state]="loadState"></ui-error>

  <form [formGroup]="form"
    *visible="loadState.isCompleted"
    autocomplete="off">
    <article>
      <p i18n>
        במידה ולמדת במוסד תורני ובמוסד אקדמי, עליך לציין את שניהם. <br>
        לנרשמים לתואר שני: חובה למלא מוסד אקדמי בו למדת את התואר הראשון. <br>
        במידה והינך מעוניין ללמוד בתכנית נוה וגילך מעל 23, חובה עליך לציין את כל המוסדות התורניים בהם למדת מעל גיל 18.
      </p>
    </article>

    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        formArrayName="institutes"
        [ui-column-width]="[150,250,150,150,100,100,50]">

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef i18n>
            סוג המוסד
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field *ngIf="row.value">
              <!-- <mat-label #institutionTypeLabel.text></mat-label> -->
              <mat-select formControlName="type">
                <mat-option value i18n>
                  {gender, select, male {בחר} female {בחרי}}
                  סוג מוסד
                </mat-option>

                 <mat-option [value]="InstitutionTypes.University" i18n>אוניברסיטה</mat-option>
                 <mat-option [value]="InstitutionTypes.UniversityAbroad" i18n>אוניברסיטה חו"ל</mat-option>
                 <mat-option [value]="InstitutionTypes.SchoolOfEngineering" i18n>בי"ס להנדסאים</mat-option>
                 <mat-option [value]="InstitutionTypes.SchoolOfEngineeringAbroad" i18n>בי"ס להנדסאים חו"ל</mat-option>
                 <mat-option [value]="InstitutionTypes.Yeshiva" *ngIf="isMale" i18n>ישיבה גבוהה</mat-option>
                 <mat-option [value]="InstitutionTypes.YeshivaAbroad" *ngIf="isMale" i18n>ישיבה גבוהה חו"ל</mat-option>
                 <mat-option [value]="InstitutionTypes.YeshivatHesder" *ngIf="isMale" i18n>ישיבת הסדר</mat-option>
                 <mat-option [value]="InstitutionTypes.Midrasha" *ngIf="isFemale" i18n>מדרשה</mat-option>
                 <mat-option [value]="InstitutionTypes.PreAcademicPreparatory" i18n>מכינה קד"א</mat-option>
                 <mat-option [value]="InstitutionTypes.OurPreAcademicPreparatory" *ngIf="isMale" i18n>מכינה קד"א בג"ט</mat-option>
                 <mat-option [value]="InstitutionTypes.PremilitaryCollege" *ngIf="isMale" i18n>מכינה קדם צבאית</mat-option>
                 <mat-option [value]="InstitutionTypes.College" i18n>מכללה</mat-option>
                 <mat-option [value]="InstitutionTypes.CollegeAbroad" i18n>מכללה חו"ל</mat-option>
                 <mat-option [value]="InstitutionTypes.GirlsCollege" *ngIf="isFemale" i18n>מכללה לבנות</mat-option>
                 <mat-option [value]="InstitutionTypes.GirlsSeminar" *ngIf="isFemale" i18n>סמינר לבנות</mat-option>
                 <mat-option [value]="InstitutionTypes.Integration" *ngIf="isMale" i18n>שילוב</mat-option>
                 <mat-option [value]="InstitutionTypes.NursingSchool" i18n>בי"ס לאחיות</mat-option>
                 <mat-option [value]="InstitutionTypes.Other" i18n>אחר</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>
            שם המוסד
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <institution-field
            [label]="institutionNameLabel.text"
            [placeholder]="institutionNameLabel.text"
            [institutionType]="row.value.type"
            floatLabel="never"
            formControlName="name"
            appearance="legacy">
            </institution-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef i18n>
            ארץ
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <country-field
              [label]="countryLabel.text"
              [placeholder]="countryLabel.text"
              floatLabel="never"
              name="countryId"
              formControlName="country"
              appearance="legacy">
            </country-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef i18n>
            עיר
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <city-field
              [label]="cityLabel.text"
              [placeholder]="cityLabel.text"
              floatLabel="never"
              name="cityId"
              formControlName="city"
              appearance="legacy">
            </city-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="start-year">
          <th mat-header-cell *matHeaderCellDef i18n>
            שנת התחלה
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="number" matInput required
              formControlName="startYear"
              i18n-placeholder
              placeholder="שנת התחלה"
              (click)="addEmptyRow()">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="end-year">
          <th mat-header-cell *matHeaderCellDef i18n>
            שנת סיום
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never" >
              <input type="number" matInput
              i18n-placeholder
              placeholder="שנת סיום"
              formControlName="endYear">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <button mat-icon-button
              type="button"
              [matTooltip]="deleteButtonTooltip.text"
              (click)="removeInstitution(index)"
              *visible="canRemoveInstitution(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      </table>
    </div>

    <ui-alert type="warning" *visible="showError && errorList.length > 0">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngFor="let error of errorList">
          <li *ngIf="error == 'name-required'" i18n>
           חובה להזין שם מוסד
          </li>
          <li *ngIf="error == 'startYear-required'" i18n>
            חובה להזין שנת התחלה
          </li>
          <li *ngIf="error == 'startYear-pattern'" i18n>
            שנת התחלה לא בפורמט תקין
          </li>
          <li *ngIf="error == 'startYear-min'" i18n>
            שנת התחלה לא יכולה להיות קטנה מ1900
          </li>
          <li *ngIf="error == 'endYear-min'" i18n>
            שנת סיום לא יכולה להיות קטנה משנת התחלה
          </li>
          <li *ngIf="error == 'endYear-max'" i18n>
            שנת סיום לא יכולה להיות גדולה מהשנה הנוכחית
          </li>
        </ng-container>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button color="primary"
      [state]="saveState"
      (progress)="saveAsync()"
      i18n>
        {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term #institutionNameLabel
  i18n>
  {gender, select, male {הזן} female {הזיני}}
  שם מוסד
</term>

<term #institutionTypeLabel
  i18n>
  {gender, select, male {הזן} female {הזיני}}
  סוג מוסד
</term>

<term #cityLabel
  i18n>
  {gender, select, male {בחר} female {בחרי}}
  עיר
</term>

<term #countryLabel
  i18n>
  {gender, select, male {בחר} female {בחרי}}
  ארץ
</term>

<term #deleteButtonTooltip
  i18n>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>
