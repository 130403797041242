import { ProvideValue, TestDescription, TestBuilders } from '@jct/core';
import { AuthenticatorState, GeneratePasswordState, AuthenticatorStates } from './account.types';

export const VALIDATE_TOKEN_LIFETIME = '00:10:00';
export const VALIDATE_TOKEN_EXP = TestBuilders.minutesFromNow(10);
export const ID_TOKEN_LIFETIME = '00:20:00';
export const ID_TOKEN_EXP = TestBuilders.minutesFromNow(20);
export const PASSWORD_PROVIDER = () => TestBuilders.numberBetween(100000, 999999)() + '';
export const TOKEN_PROVIDER = TestBuilders.tokenized(32);
export const QR_CODE_PROVIDER = TestBuilders.tokenized(48);

@TestDescription('קבלת סיסמה חדשה עם אפשרות להתחברות')
export class CanLoginPasswordState extends GeneratePasswordState {
  @ProvideValue(PASSWORD_PROVIDER)
  newGeneratedPassword: string;
  @ProvideValue(true)
  canLogin: boolean;
  @ProvideValue(ID_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(ID_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('קבלת סיסמה חדשה ללא אפשרות להתחברות')
export class CanNotLoginPasswordState extends GeneratePasswordState {
  @ProvideValue(PASSWORD_PROVIDER)
  newGeneratedPassword: string;
  @ProvideValue(false)
  canLogin: boolean;
}

@TestDescription('זיהוי משתמש קיים ע"י סיסמה')
export class ShouldVerifyState extends AuthenticatorState {
  state = AuthenticatorStates.ShouldVerify;
  @ProvideValue(TOKEN_PROVIDER)
  token: string;
  @ProvideValue(VALIDATE_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(VALIDATE_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('הרשמה של משתמש חדש')
export class ShouldRegisterState extends AuthenticatorState {
  state = AuthenticatorStates.ShouldRegister;
  @ProvideValue(TOKEN_PROVIDER)
  token: string;
  @ProvideValue(VALIDATE_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(VALIDATE_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('המשתמש צריך לאשר הודעה שנשלחה אליו למייל')
export class ShouldConfirmByEmailState extends AuthenticatorState {
  state = AuthenticatorStates.ShouldConfirmByEmail;
}

@TestDescription('המשתמש צריך להגדיר אימות דו שלבי')
export class ShouldConfigureTwoFactorAuthenticationState extends AuthenticatorState {
  state = AuthenticatorStates.ShouldConfigureTwoFactorAuthentication;
  @ProvideValue(TOKEN_PROVIDER)
  token: string;
  @ProvideValue(VALIDATE_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(VALIDATE_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('המשתמש צריך לאמת קוד - אימות דו שלבי')
export class ShouldValidateTwoFactorAuthenticationState extends AuthenticatorState {
  state = AuthenticatorStates.ShouldValidateTwoFactorAuthentication;
  @ProvideValue(TOKEN_PROVIDER)
  token: string;
  @ProvideValue(VALIDATE_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(VALIDATE_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('המשתמש צריך להגדיר אימות דו שלבי')
export class ConfigureTwoFactorAuthenticationState extends AuthenticatorState {
  state = AuthenticatorStates.ConfigureTwoFactorAuthentication;
  @ProvideValue(TOKEN_PROVIDER)
  token: string;
  @ProvideValue(QR_CODE_PROVIDER)
  qrCode: string;
  @ProvideValue(VALIDATE_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(VALIDATE_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('התחברות מוצלחת למערכת')
export class LoggedInState extends AuthenticatorState {
  state = AuthenticatorStates.LoggedIn;
  @ProvideValue(ID_TOKEN_LIFETIME)
  tokenLifeTime?: string;
  @ProvideValue(ID_TOKEN_EXP)
  tokenExpiredOn?: string;
}

@TestDescription('יציאה מוצלחת מהמערכת')
export class LoggedOutState extends AuthenticatorState {
  state = AuthenticatorStates.LoggedOut;
}
