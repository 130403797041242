
// Types

//import * as internal from "stream";

export class Settings {
    settingKey:string;
    settingValue:string;
}

export class Score {
    actionKey:string;
    score:number;
    maxScore:number;
}


