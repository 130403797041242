import '@jct/core/extensions';

import { TestBuilders, ProvideValue, TestDescription } from '@jct/core';
import { AmiramTestModel, StudentAmiramTestModel } from './amiram.types';

export const GENERATED_KEY = TestBuilders.numberBetween(10000, 20000);
export const ONE_OF_INSTITUTES = TestBuilders.oneOf(['מכון לב', 'מכון טל', 'לוסטיג']);
export const GENERATED_DATES_FOR_AMIRAM_TESTS = TestBuilders.dateBetween(new Date().addDays(10), new Date().addDays(90))
export const GENERATED_DATES_FOR_STUDENT = TestBuilders.dateBetween(new Date(), new Date().addDays(60))

@TestDescription('מועדים זמינים של מבחני עמירם')
export class FakeAmiramTestModel extends AmiramTestModel {
  @ProvideValue(GENERATED_KEY)
  id: number;

  @ProvideValue(ONE_OF_INSTITUTES)
  instituteName: string;

  @ProvideValue(GENERATED_DATES_FOR_AMIRAM_TESTS)
  testStartDate: string;
}

@TestDescription('מועדים קיימים של מבחני עמירם שהתלמיד רשום')
export class FakeStudentAmiramTestModel extends StudentAmiramTestModel {
  @ProvideValue(GENERATED_KEY)
  amiramTestId: number;

  @ProvideValue(ONE_OF_INSTITUTES)
  instituteName: string;

  @ProvideValue(GENERATED_DATES_FOR_STUDENT)
  testStartDate: string;
}
