
import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'block.page',
  templateUrl: './block.page.html',
})
export class BlockPage {
  constructor(
    private location: Location)
  { }

  goBack() {
    this.location.back();
  }
}
