import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { timer } from "rxjs";

import {
  PaymentTypes,
  AmiramService,
  StandingOrderService,
  VouchersService,
  RegistrationFeeService,
  DonationService,
  DonationFaildModel,
} from "@jct/api";

@Component({
  selector: "failed.page",
  templateUrl: "./failed-page.component.html",
})
export class FailedPageComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private amiramService: AmiramService,
    private standingOrderService: StandingOrderService,
    private vouchersService: VouchersService,
    private registrationFeeService: RegistrationFeeService,
    private donationService: DonationService
  ) {}

  paymentType: PaymentTypes;
  transactionId: string;
  errorCode: string;
  errorText: string;

  async ngOnInit() {
    const queryString = this.router.parseUrl(this.router.url).queryParamMap;

    this.paymentType = <PaymentTypes>queryString.get("paymentType");
    this.transactionId = queryString.get("txId");
    this.errorCode = queryString.get("ErrorCode");
    this.errorText = queryString.get("ErrorText");

    this.failed();
  }

  goBack() {
    switch (this.paymentType) {
      case PaymentTypes.Amiram:
        this.router.navigateByUrl("/student/amiram-test");
        break;

      case PaymentTypes.RegistrationFee:
        this.router.navigateByUrl("/fill-in/registration-fee");
        break;

      case PaymentTypes.Voucher:
        this.router.navigateByUrl("/student/vouchers");
        break;

      case PaymentTypes.StandingOrder:
        this.router.navigateByUrl("/student/standing-order");
        break;
      case PaymentTypes.Donation:
        this.router.navigateByUrl("/donation-details");
        break;
    }
  }

  async failed() {
    switch (this.paymentType) {
      case PaymentTypes.Amiram:
        await this.amiramService.receiveFailedPaymentAsync(this.transactionId);
        break;

      case PaymentTypes.RegistrationFee:
        await this.registrationFeeService.receiveFailedPaymentAsync(
          this.transactionId
        );
        break;

      case PaymentTypes.Voucher:
        await this.vouchersService.receiveFailedPaymentAsync(
          this.transactionId
        );
        break;

      case PaymentTypes.StandingOrder:
        await this.standingOrderService.receiveFailedPaymentAsync(
          this.transactionId
        );
        break;

      case PaymentTypes.Donation:
        let failedModel = <DonationFaildModel>{
          transactionId: this.transactionId,
          errorCode:  Number(this.errorCode),
          errorText: this.errorText,
        };
        await this.donationService.receiveFailedPaymentAsync(failedModel);
        break;
    }
  }
}
