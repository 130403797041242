
<nav mat-tab-nav-bar color="accent" updateLayout dir>
  <a mat-tab-link
    routerLink="/fill-in/relatives/details"
    i18n>
    פרטי קרובים
  </a>

  <a mat-tab-link
    routerLink="/fill-in/relatives/contact"
    [disabled]="!navigator.canNavigateToUrl('/fill-in/relatives/contact')"
    i18n>
    פרטי התקשרות הורים
  </a>
</nav>

<section class="tab-place-holder">
  <router-outlet></router-outlet>
</section>
