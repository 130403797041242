import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpEvent, HttpErrorResponse, HttpXhrBackend, HttpProgressEvent, HttpEventType } from '@angular/common/http';
import { tap, catchError, filter, delay, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ApiDescriptionProvider } from '../api/api-description-provider';
import { HttpMethod } from '../api/types';
import { RequestOptions } from './types';
import { HttpContext } from './http-context';
import { HttpHandler } from './http-handler';
import { HostEnvironment } from '../environment';
import { generateIdNumber } from '../common';

export abstract class ApiClient {
  abstract get<T>(url: string, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract post<T>(url: string, body: any, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract postFiles<T>(url: string, body: any, progress: (event: HttpProgressEvent) => void): Observable<HttpResponse<T>>;
  abstract put<T>(url: string, body: any, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract patch<T>(url: string, body: any, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract head<T>(url: string, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract delete<T>(url: string, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract options<T>(url: string, options?: RequestOptions): Observable<HttpResponse<T>>;
  abstract jsonp<T>(url: string, options?: RequestOptions): Observable<HttpResponse<T>>;
}

@Injectable({ providedIn: 'root' })
export class ApiClientImpl implements ApiClient {
  constructor(
    hostEnvironment: HostEnvironment,
    private backend: HttpXhrBackend,
    private httpHandler: HttpHandler)
  {
    if (!hostEnvironment.isProduction()) {
      window['__API__'] = ApiDescriptionProvider.getAllMaps();
      window['generateIdNumber'] = generateIdNumber;
    }
  }

  private _executeRequest<T>(
    method: string,
    url: string,
    body?: any,
    options?: RequestOptions,
    progress?: (event: HttpProgressEvent) => void)
  {
    this.httpHandler.initial();

    const _method = <HttpMethod>method;
    const _url = url.replace(/^(?:\/\/|[^/]+)*\//, '');
    const route = ApiDescriptionProvider.matchRoute(_method, _url);

    if (route === null) {
      throw new Error('not found any route');
    }

    const context = new HttpContext<T>(_method, _url, route);

    options = options || {};
    options = {
      withCredentials: typeof options.withCredentials === 'undefined' ? true : options.withCredentials,
      reportProgress: typeof options.reportProgress === 'undefined' ? true : options.reportProgress,
      responseType: options.responseType || 'json',
    };

    context.request = new HttpRequest<T>(method, url, body, {
      withCredentials: options.withCredentials,
      reportProgress: options.reportProgress,
      responseType: options.responseType,
    });

    this.httpHandler.configRequest<T>(context);

    return of(delay(1))
      .pipe(
        mergeMap(async () => {
          await this.httpHandler.beginResponse<T>(context);
          return context.response;
        }),
        mergeMap((x) => {
          return x === null ? this.backend.handle(context.request) : of(x);
        }),
        tap(event => {
          if (progress && event.type === HttpEventType.UploadProgress) {
            const progressEvent = <HttpProgressEvent>event;
            progress(progressEvent);
          }
        }),
        catchError(async (response: HttpErrorResponse) => {
          context.setError(response);
          await this.httpHandler.handleError<T>(context);
          return context.response;
        }),
        filter((event: HttpEvent<any>) => event instanceof HttpResponse),
        mergeMap(async (response: HttpResponse<T>) => {
          context.setResponse(response);
          await this.httpHandler.endResponse<T>(context);
          return context.response;
        }),
      );
  }

  get<T>(url: string, options?: RequestOptions) {
    return this._executeRequest<T>('GET', url, undefined, options);
  }

  post<T>(url: string, body: any, options?: RequestOptions) {
    return this._executeRequest<T>('POST', url, body, options);
  }

  postFiles<T>(url: string, body: any, progress: (event: HttpProgressEvent) => void) {
    return this._executeRequest<T>('POST', url, body, {
      withCredentials: true,
      reportProgress: true,
      responseType: 'json',
    }, progress);
  }

  put<T>(url: string, body: any, options?: RequestOptions) {
    return this._executeRequest<T>('PUT', url, body, options);
  }

  patch<T>(url: string, body: any, options?: RequestOptions) {
    return this._executeRequest<T>('PATCH', url, body, options);
  }

  head<T>(url: string, options?: RequestOptions) {
    return this._executeRequest<T>('HEAD', url, undefined, options);
  }

  delete<T>(url: string, options?: RequestOptions) {
    return this._executeRequest<T>('DELETE', url, undefined, options);
  }

  options<T>(url: string, options?: RequestOptions) {
    return this._executeRequest<T>('OPTIONS', url, undefined, options);
  }

  jsonp<T>(url: string, options?: RequestOptions) {
    return this._executeRequest<T>('JSONP', url, undefined, options);
  }
}
