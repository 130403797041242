
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { StateFactory } from '@jct/ui';
import { Gender } from '@jct/localization';
import { HttpStatusResult } from '@jct/core';
import { StatementsService, RegistrationNavigator, RegistrationStages, StudentHealthStatement, RegistrationUserProfile } from '@jct/api';

@Component({
  selector: 'health-statement-tab.page',
  templateUrl: './health-statement-tab.component.html',
})

export class HealthStatementTabComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private statementsService: StatementsService,
    private registrationNavigator: RegistrationNavigator,
    private userProfile: RegistrationUserProfile)
  { }

  @ViewChild('healthform') healthform: ElementRef;

  loadState = this.sf.create();
  saveState = this.sf.create();
  showError = false;

  form = this.fb.group({
    hasHealthProblem: ['', Validators.required],
    healthProblemDescription: [''],
    problemRequiredSpecialConditions: [false],
    specialConditionsDescription: [''],
    needSpecialTestConditions: ['', Validators.required],
    specialTestConditionsDescription: [''],
    hadMentalTreatment: ['', Validators.required],
    mentalTreatmentDescription: [''],
    hasAgreed: [false, Validators.required],
    healthStatementDocumentation: ['']
  });

  get gender(): Gender {
    return this.userProfile.gender;
  }

  get nursingTrack(): boolean {
    return this.userProfile.enabledCriminalRecord;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    this.form.valueChanges.subscribe(values => {
      if (!values.hasHealthProblem) {
        this.form.controls['healthProblemDescription'].setErrors(null);
        this.form.controls['specialConditionsDescription'].setErrors(null);
      }

      if (!values.problemRequiredSpecialConditions) {
        this.form.controls['specialConditionsDescription'].setErrors(null);
      }

      if (!values.needSpecialTestConditions) {
        this.form.controls['specialTestConditionsDescription'].setErrors(null);
      }

      if (!values.hadMentalTreatment) {
        this.form.controls['mentalTreatmentDescription'].setErrors(null);
      }
    });

    const result = await this.statementsService.getStudentHealthStatementAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      this.form.patchValue(result);
    }

    this.loadState.completed();
  }

  async submit() {
    if (!this.saveState.isInProcess) {
      await this.saveState.inProcess();

      this.form.markAllAsTouched();

      if (this.form.invalid) {
        this.showError = true;
        this.saveState.failed();
        return;
      }

      this.form.controls['healthStatementDocumentation'].patchValue(this.healthform.nativeElement.textContent);
      const model = this.form.value as StudentHealthStatement;
      const result = await this.statementsService.saveStudentHealthStatementAsync(model);

      if (!result.succeeded) {
        this.saveState.failed(result);
        return;
      }

      this.registrationNavigator.setCurrentStage(RegistrationStages.הצהרת_בריאות);
      await this.saveState.completed();
      await this.registrationNavigator.navigateToNextStage();
    }
  }

  get isTourist() {
    // TODO: add isTourist field to user profile
    return false;
  }
}
