import { Type, Injector } from '@angular/core';



import { Map, ValueType, ValueProvider } from '../common';
import { ApiDescriptionProvider } from './api-description-provider';
import { HttpMethod, UrlBuilder, BodyBuilder, ActionMethodBuilder, ParameterPlacement } from './types';
import { Filtering } from '../common';  
import { HostEnvironment } from '../environment';
import { ApiClient } from '../api-client/api-client';
import { RequestOptions } from '../api-client/types';
import { Application } from '../application';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CacheOptions, Parameter, ProduceDescriptor, ProduceTypes, TestCaseDescriptor, TestProvider } from './descriptors';

function prepareRequest(
  thisArg: any,
  urlBuilder: UrlBuilder,
  bodyBuilder?: BodyBuilder,
  ...args: any[])
{
  const injector = <Injector>thisArg['injector'];
  const apiClient = injector.get(ApiClient);
  const http = injector.get(HttpClient);
  const environment = injector.get(HostEnvironment);
  const application = injector.get(Application);
  const url = environment.apiServerUrl + '/' + urlBuilder(application, ...args);
  const body = bodyBuilder ? bodyBuilder(...args) : null;

  return {
    apiClient, url, body, http
  };
}

function buildHttpGetAction(urlBuilder: UrlBuilder, options?: RequestOptions) {
  return async function(...args: any[]) {
    const { apiClient, url ,http} = prepareRequest(this, urlBuilder, null, ...args);

    const response = await apiClient
      .get<any>(url, options)
      .toPromise();

    // if (!window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
    //    http.get(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&msg=${url}`).toPromise();
    // }

    if (window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
      console.log(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&msg=${url}`);
    }

    return response.body;
  }; 
}

function buildHttpPostAction(urlBuilder: UrlBuilder, options?: RequestOptions, bodyBuilder?: BodyBuilder) {
  return async function(...args: any[]) {
    const { apiClient, url, body ,http} = prepareRequest(this, urlBuilder, bodyBuilder, ...args);

    const response = await apiClient
      .post<any>(url, body, options)
      .toPromise();

      // if (!window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
      //   await http.get(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&url=${url}`).toPromise();
      // }
  
      if (window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
        console.log(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&url=${url}`);
      }

    var a = response;
    return response.body;
  };
}

function buildHttpPutAction(urlBuilder: UrlBuilder, options?: RequestOptions, bodyBuilder?: BodyBuilder) {
  return async function(...args: any[]) {
    const { apiClient, url, body ,http} = prepareRequest(this, urlBuilder, bodyBuilder, ...args);

    const response = await apiClient
      .put<any>(url, body, options)
      .toPromise();

      // if (!window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
      //   await http.get(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&url=${url}`).toPromise();
      // }
  
      if (window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
        console.log(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&url=${url}`);
      }
    return response.body;
  };
}

function buildHttpPatchAction(urlBuilder: UrlBuilder, options?: RequestOptions, bodyBuilder?: BodyBuilder) {
  return async function(...args: any[]) {
    const { apiClient, url, body, http } = prepareRequest(this, urlBuilder, bodyBuilder, ...args);

    const response = await apiClient
      .patch<any>(url, body, options)
      .toPromise();

      // if (!window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
      //    await http.get(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&url=${url}`).toPromise();
      // }
  
      if (window.location.href.startsWith("http://localhost:") && (response.status == 504 || response.status == 0)){
        console.log(`https://it-account.jct.ac.il:12443/sendEmailRegistrationNotAvailable?status=${response.status}&url=${url}`);
      }
    return response.body;
  };
}

function buildHttpHeadAction(urlBuilder: UrlBuilder, options?: RequestOptions) {
  return async function(...args: any[]) {
    const { apiClient, url } = prepareRequest(this, urlBuilder, null, ...args);

    const response = await apiClient
      .head<any>(url, options)
      .toPromise();

    return response.body;
  };
}

function buildHttpDeleteAction(urlBuilder: UrlBuilder, options?: RequestOptions) {
  return async function(...args: any[]) {
    const { apiClient, url } = prepareRequest(this, urlBuilder, null, ...args);

    const response = await apiClient
      .delete<any>(url, options)
      .toPromise();

    return response.body;
  };
}

const httpMethodMap: Map<ActionMethodBuilder> = {
  'GET': buildHttpGetAction,
  'POST': buildHttpPostAction,
  'PUT': buildHttpPutAction,
  'PATCH': buildHttpPatchAction,
  'HEAD': buildHttpHeadAction,
  'DELETE': buildHttpDeleteAction,
};

const buildHttpMethodDecorator = (httpMethod: HttpMethod, templatePath?: string, options?: RequestOptions) => {
  return function (
    type: any,
    actionName: string,
    descriptor: PropertyDescriptor)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);
    const actionMethod = httpMethodMap[httpMethod];

    action.httpMethod = httpMethod;
    action.templatePath = templatePath ? ApiDescriptionProvider.normalizePath(templatePath) : undefined;
    action.cache = action.cache || action.controller.cache;
    action.requestOptions = options;

    const bodyBuilder = (...args: any[]): any => {
      const body = {};
      const bodyParameters = action.parameters
        .filter(x => x.placement === ParameterPlacement.Body);

      if (bodyParameters.length > 1) {
        bodyParameters.forEach(x => body[x.name] = args[x.index]);
      }
      else if (bodyParameters.length === 1) {
        const parameter = bodyParameters[0];
        const param = args[parameter.index];

        if (typeof param === 'object') {
          return param;
        }

        body[parameter.name] = param;
      }

      return body;
    };

    const urlBuilder = (...args: any[]): string => {
      const application = <Application>args.shift();

      let url = action.route.templatePath;

      const routeParameters = action.parameters
        .filter(x => x.placement === ParameterPlacement.Route);

      const queryStringParameters = action.parameters
        .filter(x => x.placement === ParameterPlacement.QueryString);

      if (routeParameters.length > 0) {
        routeParameters.forEach(x => url = url.replace(`{${x.name}}`, args[x.index]));
      }

      if (queryStringParameters.length > 0) {
        url += '?';

        queryStringParameters.forEach(x => {
          const parameter = args[x.index];

          if (parameter instanceof Filtering) {
            url += (parameter.actualQuery!.length > 0 ? `query=${parameter.actualQuery}&` : '') +
              (parameter.resultSize > 0 ? `resultSize=${parameter.resultSize}&` : '');
          }
          else {
            url += x.name + '=' + encodeURIComponent(args[x.index]) + '&';
          }
        });

        if (url.endsWith('&') || url.endsWith('?')) {
          url = url.substr(0, url.length - 1);
        }
      }

      if (url.includes('{@')) {
        url = url.replace(/\{\@([a-z]+)\}/gi, substr => {
          const key = substr.substring(2, substr.length - 1);
          return application[key] || application.settings[key] || '';
        });
      }

      return url;
    };

    descriptor.value = actionMethod(urlBuilder, action.requestOptions, bodyBuilder);
  };
}

function HttpGetDecorator(templatePath?: string, options?: RequestOptions) {
  return buildHttpMethodDecorator(
    HttpMethod.Get,
    templatePath,
    options);
}

function HttpDeleteDecorator(templatePath?: string, options?: RequestOptions) {
  return buildHttpMethodDecorator(
    HttpMethod.Delete,
    templatePath,
    options);
}

function HttpHeadDecorator(templatePath?: string, options?: RequestOptions) {
  return buildHttpMethodDecorator(
    HttpMethod.Head,
    templatePath,
    options);
}

function HttpPostDecorator(templatePath?: string, options?: RequestOptions) {
  return buildHttpMethodDecorator(
    HttpMethod.Post,
    templatePath,
    options);
}

function HttpPutDecorator(templatePath?: string, options?: RequestOptions) {
  return buildHttpMethodDecorator(
    HttpMethod.Put,
    templatePath,
    options);
}

function HttpPatchDecorator(templatePath?: string, options?: RequestOptions) {
  return buildHttpMethodDecorator(
    HttpMethod.Patch,
    templatePath,
    options);
}

function UseStateDecorator() {
  return function (
    type: any,
    actionName: string,
    descriptor: PropertyDescriptor)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);

    action.useState = true;
  };
}

function CacheDecorator(period: string = '24h') {
  return function (
    type: any,
    actionName?: string,
    descriptor?: PropertyDescriptor)
  {
    const cacheOptions = new CacheOptions(period);

    if (typeof actionName !== 'string' &&
      typeof descriptor !== 'object')
    {
      // Type Decorator

      const controller = ApiDescriptionProvider.getControllerDescriptor(type);
      controller.cache = cacheOptions;
      controller.actions.forEach(
        x => x.cache = x.cache || cacheOptions);
    }
    else {
      // Method Decorator

      const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);
      action.cache = cacheOptions;
    }
  };
}

function ProduceDecorator(produceType: Type<any>|ProduceTypes, enumerable: boolean = false) {
  return function (
    type: any,
    actionName: string,
    descriptor: PropertyDescriptor)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);
    const produceDescriptor = new ProduceDescriptor();

    if (typeof produceType === 'string') {
      produceDescriptor.produceType = produceType;
    }
    else {
      produceDescriptor.classType = produceType;
      produceDescriptor.classDescriptor = ApiDescriptionProvider.getClassDescriptor(produceType);
    }

    produceDescriptor.enumerable = enumerable;

    action.produce = produceDescriptor;
  };
}

function TestDescriptionDecorator(description: string) {
  return function(type: any) {
    const classDescriptor = ApiDescriptionProvider.getClassDescriptor(type);

    classDescriptor.description = description;
  };
}

function FailedTestCaseDecorator(classType: Type<any>, description?: string) {
  return function (
    type: any,
    actionName: string,
    descriptor: PropertyDescriptor)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);
    const _class = ApiDescriptionProvider.getClassDescriptor(classType);

    const testCaseDescriptor = new TestCaseDescriptor(
      classType,
      description || _class.description,
      false);

    testCaseDescriptor.classDescriptor = _class;

    action.descriptors.push(testCaseDescriptor);
  };
}

function SuccessTestCaseDecorator(classType: Type<any> | ValueType | any[], description?: string) {
  return function (
    type: any,
    actionName: string,
    descriptor: PropertyDescriptor)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);

    if (typeof classType === 'function') {
      const _class = ApiDescriptionProvider.getClassDescriptor(classType);

      const testCaseDescriptor = new TestCaseDescriptor(
        classType,
        description || _class.description,
        true);

      testCaseDescriptor.classDescriptor = _class;
      action.descriptors.push(testCaseDescriptor);
    }
    else {
      const testCaseDescriptor = new TestCaseDescriptor();

      testCaseDescriptor.description = description;
      testCaseDescriptor.value = classType;
      testCaseDescriptor.success = true;

      action.descriptors.push(testCaseDescriptor);
    }
  };
}

function SuccessTestCaseProviderDecorator<T = any>(provider: TestProvider<T>, description?: string) {
  return function (
    type: any,
    actionName: string,
    descriptor: PropertyDescriptor)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(type.constructor, actionName);
    const testCaseDescriptor = new TestCaseDescriptor();

    testCaseDescriptor.description = description;
    testCaseDescriptor.provider = provider;
    testCaseDescriptor.success = true;

    action.descriptors.push(testCaseDescriptor);
  };
}

function ProvideValueDecorator(value: ValueType | ValueProvider) {
  return function (classType: any, propertyKey: string) {
    if (typeof value === 'function') {
      ApiDescriptionProvider.setFieldDescriptor(
        descriptor => descriptor.valueProvider = value,
        classType,
        propertyKey);
    }
    else {
      ApiDescriptionProvider.setFieldDescriptor(
        descriptor => descriptor.value = value,
        classType,
        propertyKey);
    }
  };
}

function FromRouteDecorator(name: string) {
  return function(
    type: any,
    actionName: string,
    parameterIndex: number)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(
      type.constructor,
      actionName);

    const parameter = new Parameter(
      name,
      ParameterPlacement.Route,
      parameterIndex);

    action.parameters.push(parameter);
    action.parametersMap[parameterIndex] = parameter;
  };
}

function FromQueryStringDecorator(name: string) {
  return function(
    type: any,
    actionName: string,
    parameterIndex: number)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(
      type.constructor,
      actionName);

    const parameter = new Parameter(
      name,
      ParameterPlacement.QueryString,
      parameterIndex);

    action.parameters.push(parameter);
    action.parametersMap[parameterIndex] = parameter;
  };
}

function FromBodyDecorator(name?: string) {
  return function(
    type: any,
    actionName: string,
    parameterIndex: number)
  {
    const action = ApiDescriptionProvider.getActionDescriptor(
      type.constructor,
      actionName);

    const parameter = new Parameter(
      name || 'model',
      ParameterPlacement.Body,
      parameterIndex);

    action.parameters.push(parameter);
    action.parametersMap[parameterIndex] = parameter;
  };
}

function ControllerDecorator(templatePath?: string) {
  return function(type: any) {
    const controller = ApiDescriptionProvider.getControllerDescriptor(type);

    controller.templatePath = templatePath ? ApiDescriptionProvider.normalizePath(templatePath) : '';

    controller.actions.forEach(action => {
      const path = templatePath ?
        ApiDescriptionProvider.combineTemplatePath(templatePath, action.templatePath) :
        ApiDescriptionProvider.combineTemplatePath(action.templatePath);
      const route = ApiDescriptionProvider.getRouteDescriptor(action.httpMethod, path);

      action.cache = action.cache || controller.cache;
      action.route = route;
      route.action = action;
    });
  };
}

export {
  // general
  CacheDecorator as Cache,

  // parameters
  FromRouteDecorator as FromRoute,
  FromQueryStringDecorator as FromQueryString,
  FromBodyDecorator as FromBody,

  // action
  HttpGetDecorator as HttpGet,
  HttpDeleteDecorator as HttpDelete,
  HttpHeadDecorator as HttpHead,
  HttpPostDecorator as HttpPost,
  HttpPutDecorator as HttpPut,
  HttpPatchDecorator as HttpPatch,
  ProduceDecorator as Produce,
  UseStateDecorator as UseState,

  // field
  ProvideValueDecorator as ProvideValue,

  // test
  TestDescriptionDecorator as TestDescription,
  SuccessTestCaseDecorator as SuccessTestCase,
  FailedTestCaseDecorator as FailedTestCase,
  SuccessTestCaseProviderDecorator as SuccessTestCaseProvider,

  // controller
  ControllerDecorator as Controller,
};
