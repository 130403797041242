
<div container>
  <ui-error [state]="loadState">
  </ui-error>

  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate
    (submit)="submit()"
    *visible="loadState.isCompleted"
  >
    <p *visible="enableCopy">
      <span i18n style="font-size: 14px;">
        {gender, select,
          male {האם הנך גר עם ההורים?}
          female {האם הנך גרה עם ההורים?}
        }
      </span>
      <span>&nbsp;</span>

      <ui-error [state]="copyState"></ui-error>

      <ui-progress-button
        [state]="copyState"
        color="primary"
        (progress)="copyAddress()"
        i18n>
        {copyState.value, select,
          inProcess {מעתיק כתובת מגורים}
          other {העתק כתובת מגורים}}
      </ui-progress-button>
    </p>

    <mat-form-group>
      <mat-form-field appearance="fill">
        <mat-label i18n>טלפון נייד</mat-label>

        <input matInput type="tel"
          formControlName="cellularPhone"
          required>

        <mat-error>
          <ng-container *ngIf="form | hasError:'cellularPhone'" i18n>
            שדה חובה
          </ng-container>

          <ng-container *ngIf="form | hasError:'cellularPhone':'pattern'" i18n>
            מספר הטלפון אינו תקין
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>דוא"ל</mat-label>

        <input matInput type="email"
          formControlName="email"
          required={{isSelfContactType}}>

        <mat-error>
          <ng-container *ngIf="form | hasError:'email'" i18n>
            שדה חובה
          </ng-container>

          <ng-container *ngIf="form | hasError:'email':'email'" i18n>
            כתובת המייל אינה תקינה
          </ng-container>
        </mat-error>
      </mat-form-field>
    </mat-form-group>

    <mat-form-group>
      <country-field
        i18n-label
        label="ארץ"
        [placeholder]="countryPlaceholder.text"
        formControlName="country"
        appearance="fill">
      </country-field>

      <city-field
        i18n-label
        label="עיר"
        [placeholder]="cityPlaceholder.text"
        formControlName="city"
        appearance="fill">
      </city-field>
    </mat-form-group>

    <mat-form-group>
      <street-field
        i18n-label
        label="רחוב"
        [cityName]="form.value.city"
        [placeholder]="streetPlaceholder.text"
        formControlName="street"
        appearance="fill">
      </street-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>מספר בית</mat-label>

        <input matInput
          formControlName="houseNumber">
      </mat-form-field>
    </mat-form-group>

    <mat-form-field appearance="fill">
      <mat-label i18n>מיקוד</mat-label>

      <input matInput
        formControlName="zip">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label i18n>ת.ד.</mat-label>

      <input matInput
        formControlName="pob">
    </mat-form-field>

    <mat-divider></mat-divider>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [form]="form"
      [state]="saveState"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term i18n #countryPlaceholder>
  {gender, select, male {בחר} female {בחרי}}
  ארץ
</term>

<term i18n #cityPlaceholder>
  {gender, select, male {בחר} female {בחרי}}
  עיר
</term>

<term i18n #streetPlaceholder>
  {gender, select, male {בחר} female {בחרי}}
  רחוב
</term>
