
import { Component, OnInit } from '@angular/core';

import { Gender } from '@jct/localization';
import { AcademicStudyTypes, RegistrationUserProfile, FilesService, StudyTrackService } from '@jct/api';
import { StateFactory } from '@jct/ui';
import { HttpStatusResult } from '@jct/core';

@Component({
  selector: 'interview.page',
  templateUrl: './interview-page.html',
})
export class InterviewPageComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private filesService: FilesService,
    private profile: RegistrationUserProfile,
    private studyTrackService: StudyTrackService)
  { }

  loadState = this.sf.create();
  uploadedMandatoryFiles = false;
  isNursingTrack = false;

  get gender(): Gender {
    return this.profile?.gender;
  }
  get isFemale() {
    return this.profile?.gender === Gender.Female;
  }

  get isMale() {
    return this.profile?.gender === Gender.Male;
  }

  private get academicStudyTypeId() {
    return this.profile?.academicStudyType;
  }

  get isPreparatory() {
    return this.academicStudyTypeId === AcademicStudyTypes.Mechina;
  }

  get isFirstDegree() {
    return this.academicStudyTypeId === AcademicStudyTypes.FirstDegree;
  }

  get isSecondDegree() {
    return this.academicStudyTypeId === AcademicStudyTypes.SecondDegree;
  }

  get isFinishUploadMandatoryFiles() {
    return this.uploadedMandatoryFiles;
  }

  get displayScheduleInterview() {
    return !this.isPreparatory &&
      !this.isSecondDegree &&
      !this.isNursingTrack &&
      this.isFinishUploadMandatoryFiles;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.filesService.isStudentUploadMandatoryFiles();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed(result);
      return;
    }

    this.uploadedMandatoryFiles = result;

    const mainStudentStudyTrack = await this.studyTrackService.getMainStudentStudyTrackAsync();

    if (mainStudentStudyTrack instanceof HttpStatusResult) {
      this.loadState.failed(mainStudentStudyTrack);
      return;
    }
    
    this.isNursingTrack = mainStudentStudyTrack.departmentName.includes("סיעוד");

    this.loadState.completed();
  }
}
