
<div container>
  <ui-error [state]="loadState"></ui-error>

  <form [formGroup]="form"
    novalidate
    autocomplete="off"
    *visible="loadState.isCompleted"
    (submit)="saveAsync()">

    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        formArrayName="bagrutGrades"
        [ui-column-width]="[350,100,100,100,200,50]">

        <ng-container matColumnDef="bagrut-subject">
          <th mat-header-cell *matHeaderCellDef i18n>
            מקצוע
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never" class="no-hint">
              <mat-label i18n>בחרו מקצוע</mat-label>

              <input type="text"
                matInput
                [matAutocomplete]="auto"
                formControlName="subject">

              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option
                  *ngFor="let subject of filterSubjects(row.value)"
                  [value]="subject.subjectName">
                  {{ subject?.subjectName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="units">
          <th mat-header-cell *matHeaderCellDef
              i18n>
            יחידות לימוד
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <mat-label i18n>
                יחידות לימוד
              </mat-label>

              <input matInput type="number"
                     formControlName="units"
                     min="1"
                     max="15">
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef
              i18n>
            סה"כ: {{ unitsCount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grade">
          <th mat-header-cell *matHeaderCellDef
              i18n>
            ציון
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <mat-label i18n>
                ציון
              </mat-label>

              <input type="number" matInput
                     formControlName="grade"
                     min="0"
                     max="100">
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef
              i18n>
            ממוצע: {{ average | number:'1.0-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="bonus">
          <th mat-header-cell *matHeaderCellDef
              i18n>
            בונוס
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            {{ row?.value?.bonus | number:'1.0-2' }}
          </td>

          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="final-grade">
          <th mat-header-cell *matHeaderCellDef
              i18n>
            ציון סופי
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            {{ row?.value?.finalGrade | number:'1.0-2' }}
          </td>

          <td mat-footer-cell *matFooterCellDef
              i18n>
            ממוצע משוקלל: {{ weightedAverage | number:'1.0-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">

            <button mat-icon-button
                    type="button"
                    [matTooltip]="deleteTooltip.text"
                    (click)="removeBagrutGrade(index)"
                    *visible="canRemoveBagrutGrade(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>

          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
      </table>
    </div>

    <ui-alert type="warning" *visible="showError && errorList.length > 0">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngFor="let error of errorList">
          <li *ngIf="error == 'units-required'" i18n>
           חובה להזין יחידות לימוד
          </li>
          <li *ngIf="error == 'units-min'" i18n>
            נדרשת לפחות יחידת לימוד אחת
          </li>
          <li *ngIf="error == 'units-max'" i18n>
            מקסימום יחידות לימוד למקצוע יחיד 15
          </li>
          <li *ngIf="error == 'grade-required'" i18n>
           חובה להזין ציון
          </li>
          <li *ngIf="error == 'grade-min'" i18n>
            ציון מינימום הוא 0
          </li>
          <li *ngIf="error == 'grade-max'" i18n>
            ציון לא יכול להיות גדול מ-100
          </li>
        </ng-container>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <button
      mat-raised-button
      color="primary"
      (click)="skip()" i18n>
      דלג
    </button> &nbsp;

    <ui-progress-button
      color="primary"
      [state]="saveState"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term i18n #deleteTooltip>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>
