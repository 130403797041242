
import { Component, OnInit, ViewChild } from '@angular/core';

import { UploadProgress, EMPTY_UPLOAD_RESULT, Uploader, FileFamily, HttpStatusResult } from '@jct/core';
import { FilesService, FileRequest, StudentFile, StudentFileUpload } from '@jct/api';
import { StateFactory, ActionState, SnackBarComponent } from '@jct/ui';
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

const MAX_FILE_SIZE = 10485760;
const PASSPORT_IMAGE_FILE_ID = 2;

@Component({
  selector: 'registration-files-tab',
  templateUrl: './registration-files-tab.component.html',
  host: {
    'class': 'page',
  },
})
export class RegistrationFilesTabComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private filesService: FilesService,
    private uploader: Uploader<StudentFileUpload>,
    private snackBar: MatSnackBar
  ) { }

  loadState = this.sf.create();

  fileRequests: FileRequest[];
  progress: UploadProgress<StudentFile | HttpStatusResult> = {};
  saveState: { [key: number]: ActionState } = {};

  async ngOnInit() {
    await this.loadState.inProcess();

    const fileRequestsResult = await this.filesService.getFileRequestsAsync();
    const studentRegistrationFilesResult = await this.filesService.getStudentRegistrationFilesAsync();

    if (fileRequestsResult instanceof HttpStatusResult) {
      this.loadState.failed(fileRequestsResult);
      return;
    }

    if (studentRegistrationFilesResult instanceof HttpStatusResult) {
      this.loadState.failed(studentRegistrationFilesResult);
      return;
    }

    this.fileRequests = fileRequestsResult;
    let studentFiles = studentRegistrationFilesResult;

    this.fileRequests.forEach(x => {
      x.studentFile = studentFiles.find(f => f.fileType === x.fileType);
      this.saveState[x.fileType] = this.sf.create();
    });

    await this.loadState.completed();
  }

  downloadPath(request: FileRequest) {
    return `${this.filesService.baseUrl}/${request.studentFile.id}`;
  }

  isFileUploaded(request: FileRequest) {
    return !!request.studentFile;
  }

  showIsMandatory(request: FileRequest) {
    return request.isMandatory && !request.studentFile;
  }

  showProgressBar(file: FileRequest) {
    return !file.studentFile &&
      this.progress[file.fileType] &&
      !this.progress[file.fileType].done;
  }

  showUploadButton(file: FileRequest) {
    return !file.studentFile &&
      !this.saveState[file.fileType].isInProcess;
  }

  showUploadError(file: FileRequest) {
    return this.saveState[file.fileType].isFailed;
  }

  // TODO האם לאפשר העלאה בו זמנית של קבצים?
  isUploadButtonsDisabled() {
    return false;// Object.values(this.saveState).some(x => x.isInProcess);
  }

  @ViewChild('fileUploadedMessage')
  fileUploadedMessage!: SnackBarComponent;

  @ViewChild('errorFileUploadedMessage')
  errorFileUploadedMessage!: SnackBarComponent;

  async upload(fileRequest: FileRequest, fileInput: HTMLInputElement) {
    if (fileInput.files.length == 0) {
      return;
    }
    this.saveState[fileRequest.fileType].inProcess();

    const fileFamily = fileRequest.fileType == PASSPORT_IMAGE_FILE_ID ?
      [FileFamily.Image] :
      [FileFamily.Image, FileFamily.Document];

    const file = fileInput.files[0];
    const validateResult = this.uploader
      .validate(file, MAX_FILE_SIZE, fileFamily);

    if (!validateResult.succeeded) {
      this.saveState[fileRequest.fileType].failed(validateResult);
      this.errorFileUploadedMessage.show();
      return;
    }

    this.progress[fileRequest.fileType] = EMPTY_UPLOAD_RESULT;

    const model = <StudentFileUpload>{
      fileType: fileRequest.fileType,
      file: file
    };

    this.filesService
      .upload(model)
      .subscribe(progress => {
        this.progress[fileRequest.fileType] = progress;
        const result = progress.result;
        if (result instanceof HttpStatusResult) {
          this.snackBar.open(
            "ארעה שגיאה במהלך שמירת הנתונים, נסה שנית",
            "אישור",
            {
              duration: 5000,
              panelClass: ["warn-snack-bar"],
            }
          );
          this.saveState[fileRequest.fileType].failed(result);
          return;
        }
        else if (progress.done) {
          
          fileRequest.studentFile = result;

          this.fileUploadedMessage.show();

          this.saveState[fileRequest.fileType].completed();
        }
      });
  }
}
