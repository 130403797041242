
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessTestCase,
  SuccessResult,
  SuccessTestCaseProvider,
  FailedTestCase,
  EntityNotExistsResult,
  InvalidModelResult,
  HttpStatusResult
} from '@jct/core';
import { FAKE_ENTRANCE_EXAMS_PROVIDER } from './entrance-exams.test';
import { StudentEntranceExams } from './entrance-exams.types';


@Injectable({providedIn: 'root'})
@Controller('entrance-exams')
export class EntranceExamsService {
  constructor(
    protected injector: Injector)
  { }

  @UseState()
  @HttpGet()
  @SuccessTestCaseProvider(FAKE_ENTRANCE_EXAMS_PROVIDER, 'קבלת מבחנים לסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentEntranceExams)
  getEntranceExamsAsync()
  {
    return resultOf<StudentEntranceExams>();
  }

  @UseState()
  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveEntranceExamsAsync(
    @FromBody('entranceExams') entranceExams: StudentEntranceExams)
  {
    return resultOf();
  }
}
