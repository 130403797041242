import { Component, ViewEncapsulation, Input } from '@angular/core';

export type CardTypes = 'success' | 'error' | 'info' | 'warning' | undefined;

@Component({
  selector: '[ui-card]',
  template: `
    <mat-card [ngClass]="type">
      <ng-content></ng-content>
    </mat-card>
  `,
  styleUrls: ['./card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiCard {
  @Input()
  type: CardTypes;
}
