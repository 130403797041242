
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StateFactory } from '@jct/ui';

@Component({
  selector: 'get-reset-link.page',
  templateUrl: './get-reset-link.html',
})
export class GetResetLinkPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private route: ActivatedRoute)
  { }

  loadState = this.sf.create();
  _emailAddress: string;

  async ngOnInit() {
    await this.loadState.inProcess();

    this.route.queryParams.subscribe(async params => {
      this._emailAddress =  params['emailAddress'] || null;
    });

    this.loadState.completed(500);
  }
}
