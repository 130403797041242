
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'explanation-part',
  templateUrl: './explanation-part.html',
  styleUrls: ['./explanation-part.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExplanationPart { }
