
export enum FileFamily {
  Document,
  Spreadsheet,
  Slideshow,
  Image,
  Executable,
  Compressed,
  Text
}

export const FILE_FAMILIES: {[key: number]: string[]} = {
  [FileFamily.Compressed]: ['.zip', '.7z', '.rar', '.tar', '.tar.z', '.tar.bz2',],
  [FileFamily.Document]: ['.doc', '.docx', '.pdf', '.rtf',],
  [FileFamily.Spreadsheet]: ['.xls', '.xlsx', '.csv', '.tsv',],
  [FileFamily.Slideshow]: ['.ppt', '.pptx',],
  [FileFamily.Executable]: ['.exe', '.msi', '.com', '.dll',],
  [FileFamily.Image]: ['.png', '.bmp', '.gif', '.jpg', '.jpeg', '.gif', '.tiff', '.tif', '.ico' ],
  [FileFamily.Text]: ['.txt', '.csv', '.tsv', '.rm', '.xml', '.html', '.cs', '.js', '.css', '.scss', '.ts', '.svg', '.json', '.sql',
                      '.gitkeep', '.gitignore', '.editorconfig', '.gitattributes', '.csproj', '.sln', '.targets', '.props', '.cache', '.config',],
};
