import { Injector, Type } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { RequestOptions } from '../api-client';
import { Map, ValueType, ValueProvider } from '../common';

import {
  FieldTypes,
  HttpMethod,
  RangeMetadata,
  ParameterPlacement
} from './types';

export class CacheOptions {
  constructor(
    public period: string)
  { }
}

export const parseBoolean = (value: any) => {
  const valueAsString = (''+value).toLowerCase();
  return valueAsString != 'false' && valueAsString != '0' && valueAsString != '' && valueAsString != 'no';
};

export type ProduceTypes = 'string' | 'number' | 'boolean' | 'mutable';

export class ProduceDescriptor {
  classDescriptor: ClassDescriptor|null = null;
  classType: Type<any>|null = null;
  enumerable: boolean = false;
  produceType: ProduceTypes = 'mutable';

  producer(data: any) {
    switch (this.produceType) {
      case 'string':
        if (this.enumerable) {
          return Array.from<string>(data).map(item => '' + item);
        }
        else {
          return '' + data;
        }

      case 'number':
        if (this.enumerable) {
          return Array.from<number>(data).map(item => parseFloat('' + item));
        }
        else {
          return parseFloat('' + data);
        }

      case 'boolean':
        if (this.enumerable) {
          return Array.from<boolean>(data).map(item => parseBoolean(item));
        }
        else {
          return parseBoolean(data);
        }

      case 'mutable':
        if (this.classType === null) {
          if (this.enumerable) {
            return Array.from<any>(data);
          }
          return data;
        }

        if (this.enumerable) {
          const array = data as any[];
          const TypeConstructor = this.classType;

          return array.map(item => {
            const instance = new TypeConstructor();
            Object.assign(instance, item);
            return instance;
          });
        }
        else {
          const TypeConstructor = this.classType;
          const instance = new TypeConstructor();

          return Object.assign(instance, data);
        }
    }
  }
}

export class ControllerDescriptor {
  constructor(
    public controllerId: number,
    public controllerType: Type<any>)
  { }

  templatePath?: string;
  cache?: CacheOptions;

  actions: ActionDescriptor[] = [];
  actionsMap: Map<ActionDescriptor> = {};
}

export class Parameter {
  constructor(
    public name: string,
    public placement: ParameterPlacement,
    public index: number)
  { }
}

export class RouteDescriptor {
  constructor(
    public _id: string,
    public httpMethod: HttpMethod,
    public templatePath: string)
  { }

  action?: ActionDescriptor;
}

export interface IDescriptor {
  type: string;
}

export type TestProvider<T = any> = (injector: Injector, request: HttpRequest<any>) => T;

export class TestCaseDescriptor implements IDescriptor {
  type = 'TestCase';

  constructor(
    public classType?: Type<any>,
    public description?: string,
    public success: boolean = true)
  { }

  enumerable: boolean = false;
  classDescriptor?: ClassDescriptor;
  value?: ValueType | ValueType[];
  provider?: TestProvider;
}

export class ActionDescriptor {
  constructor(
    public _id: string,
    public controllerType: Type<any>,
    public actionName: string)
  { }

  httpMethod?: HttpMethod;
  templatePath?: string;
  requestOptions?: RequestOptions;

  parameters: Parameter[] = [];
  parametersMap: Map<Parameter> = {};

  produce?: ProduceDescriptor;
  cache?: CacheOptions;

  route?: RouteDescriptor;
  controller?: ControllerDescriptor;

  useState?: boolean;

  descriptors: IDescriptor[] = [];
}

export class FieldDescriptor {
  constructor(
    public name: string)
  { }

  value?: ValueType;
  valueProvider?: ValueProvider;
  type?: FieldTypes;
  key?: boolean;
  locale?: string;
  range?: RangeMetadata;
  enumOptions?: any[];

  classDescriptor?: ClassDescriptor;
}

export class ClassDescriptor {
  constructor(
    public classId: number,
    public classType: Type<any>)
  {
    this.className = classType.name;
  }

  className: string;
  fields: FieldDescriptor[] = [];
  fieldsMap: Map<FieldDescriptor> = {};
  description?: string;
}
