import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

import { Authenticator, ProgressIndicator } from "@jct/core";
import { ProfileService } from "../../registration";
import { Role } from "../account";

@Injectable({
  providedIn: "root",
})
export class LersNavigator {
  constructor(
    protected injector: Injector,
    private profileService: ProfileService,
    private router: Router,
    private authenticator: Authenticator
  ) {}

  redirectByRole() {
    try {
      let role = this.profileService.profile.role;

      if (!role) {
        this.router.navigateByUrl("/not-authorized");
      }

      switch (role) {
        case Role.Admin:
          this.router.navigateByUrl("/admin/lecturers");
          break;
        case Role.CommitteeMember:
          this.router.navigateByUrl("/data-from-maztal");
          break;
        case Role.DepartmentHead:
          this.router.navigateByUrl("/entering-scores");
          break;
        case Role.Viewer:
          this.router.navigateByUrl("/data-from-maztal");
          break;
        case Role.Lecturer:
          this.router.navigateByUrl("/data-from-maztal");
          break;
      }
    } catch (err) {
      this.authenticator.logout();
    }
  }
}
