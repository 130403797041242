
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { LanguageSelectorComponent } from './language-selector';
import { Localization } from './localization.service';
import { PageLayout } from './page-layout';
import { Application } from '@jct/core';

@Injectable({
  providedIn: 'root'
})
export class L10nService extends Localization {
  constructor(
    private bottomSheet: MatBottomSheet,
    private pageLayout: PageLayout,
    application: Application,
    @Inject(DOCUMENT) document: Document)
  {
    super(document, application);
  }

  openLanguageSelector() {
    this.bottomSheet.open(LanguageSelectorComponent, {
      autoFocus: true,
      restoreFocus: true,
      closeOnNavigation: true,
      direction: this.pageLayout.rtl ? 'rtl' : 'ltr',
    });
  }
}
