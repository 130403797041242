
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { L10nModule } from '@jct/localization';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { PrivateAppRoutingModule } from './private-app-routing.module';
import { PrivateAppComponent } from './private-app.component';
import { RegistrationStatusPage } from './+registration-status';

@NgModule({
  declarations: [
    RegistrationStatusPage,
    PrivateAppComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    PrivateAppRoutingModule,
  ],
})
export class PrivateAppModule { }
