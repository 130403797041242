
import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MaterialModule } from '@jct/material';
import { SINGLETON_SERVICE } from '@jct/core';

import { L10nService } from './l10n.service';
import { UpdateLayout } from './update-layout';
import { LanguageSelectorComponent } from './language-selector';
import { PageLayout, PageLayoutSelector } from './page-layout';
import { TermComponent } from './term';
import { LanguageMenuComponent } from './language-menu';
import { LocalizationInterceptorProvider } from './interceptor';

const EXPORTED_SERVICES = [
  {
    provide: SINGLETON_SERVICE,
    useClass: L10nService,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: PageLayout,
    multi: false,
  },
  LocalizationInterceptorProvider,
];

const EXPORTED_COMPONENTS = [
  LanguageSelectorComponent,
  LanguageMenuComponent,
  TermComponent,
  PageLayoutSelector,
  UpdateLayout,
];

@NgModule({
  providers: [...EXPORTED_SERVICES],
  declarations: [...EXPORTED_COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule.forRoot(),
  ],
  exports: [...EXPORTED_COMPONENTS],
})
export class L10nModule {
  static forRoot(): ModuleWithProviders<L10nModule> {
      return {
          ngModule: L10nModule,
          providers: EXPORTED_SERVICES,
      };
  }
}
