import { Injectable, Injector } from '@angular/core';
import { Controller, FromBody, FromRoute, HttpGet, HttpPost, Produce, resultOf } from '@jct/core';
import { Observable } from 'rxjs';
import { Score, Settings } from './settings.types';



@Injectable({ providedIn: 'root' })
@Controller('Settings')
export class SettingsService {

  constructor(protected injector: Injector) { }
    
  @HttpGet('GetSettings')
  @Produce(Settings,true)
  getSettings() {
    return resultOf<Settings[]>();
  }

  @HttpPost('SaveSystemSettings')
  @Produce("number")
  saveSystemSettings(@FromBody() settings:Settings[]) {
    return resultOf<number>();
  }

  @HttpGet('GetScore')
  @Produce(Score,true)
  getScore() {
    return resultOf<Score[]>();
  }

  @HttpPost('ChangeScore')
  @Produce("boolean")
  changeScore(@FromBody() score:Score) {
    return resultOf<boolean>();
  }
  
  
  
}
