import { EnglishExamTypes, GeneralExamTypes, StudentEntranceExams } from './entrance-exams.types';

export const FAKE_ENTRANCE_EXAMS_PROVIDER = () => {
var item = new StudentEntranceExams();
  item.id = 20;
  item.generalExamType = GeneralExamTypes.Psychometry;
  item.generalExamDate = new Date(2020,2,3);
  item.englishExamType = EnglishExamTypes.Amir;
  item.englishExamDate = new Date(2019,3,5);

  return item;
};
