
import { BlockPage } from './+block';
import { OfflinePage } from './+offline';
import { NotFoundPage } from './+not-found';
import { NotAuthorizedPage } from './+not-authorized';
import { ServerUnavailablePage } from './+server-unavailable';
import { TimeOutPage } from './+timeout';
import { Routes } from '@angular/router';

export * from './+block';
export * from './+offline';
export * from './+not-found';
export * from './+server-unavailable';
export * from './+timeout';
export * from "./+not-authorized";

export const PAGES_COMPONENTS = [
  BlockPage,
  OfflinePage,
  NotFoundPage,
  NotAuthorizedPage,
  ServerUnavailablePage,
  TimeOutPage,
];

export const PAGES_COMPONENTS_ROUTES: Routes = [
  {
    path: "block",
    component: BlockPage,
  },
  {
    path: "offline",
    component: OfflinePage,
  },
  {
    path: "not-found",
    component: NotFoundPage,
  },
  {
    path: "not-authorized",
    component: NotAuthorizedPage,
  },
  {
    path: "server-unavailable",
    component: ServerUnavailablePage,
  },
  {
    path: "timeout",
    component: TimeOutPage,
  },
];
