import { StudentDiplomaCourse } from './diploma.types';

export const FAKE_DIPLOMA_COURSE: StudentDiplomaCourse[] = [
  {
    courseName: "מחשבים",
    credits: 3,
    grade: 95
  },
  {
    courseName: "אומנות",
    credits: 5,
    grade: 82
  }
]
