
import { Component, ElementRef, AfterContentInit, HostBinding } from '@angular/core';
import { domAccessor } from '@jct/ui/lib/services';

@Component({
  selector: 'ui-snack-bar-message',
  template: '<ng-content></ng-content>',
  exportAs: 'uiSnackBarMessage',
})
export class SnackBarMessageComponent implements AfterContentInit {
  private _content = '';

  constructor(
    private elementRef: ElementRef<HTMLElement>)
  { }

  ngAfterContentInit() {
    setTimeout(() => {
      this._content = domAccessor(this.elementRef).text();
    }, 10);
  }

  get content() {
    return this._content;
  }

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() {
    return true;
  }

  @HostBinding('attr.aria-hidden')
  get ariaHidden() {
    return true;
  }
}
