
import { Component } from '@angular/core';

import { Authenticator, HostEnvironment, PageTitleService } from '@jct/core';
import { RegistrationNavigator, RegistrationUserProfile } from '@jct/api';
import { AppLayoutService } from '@jct/ui';
import { CULTURE_HEADER_NAME, L10nService } from '@jct/localization';

@Component({
  templateUrl: './private-app.component.html',
})
export class PrivateAppComponent {
  currentTitle = '';

  constructor(
    public appLayout: AppLayoutService,
    private environment: HostEnvironment,
    public l10n: L10nService,
    public pageTitle: PageTitleService,
    private registrationNavigator: RegistrationNavigator,
    private currentProfile: RegistrationUserProfile,
    public authenticator: Authenticator)
  {
    pageTitle.currentTitle.subscribe(x => this.currentTitle = x);
  }

  get downloadRegistrationConfirmationPath() {
    return `${this.environment.apiServerUrl}/confirmation?${CULTURE_HEADER_NAME}=${this.l10n.current.code}`;
  }

  isUrlEnabled(url: string) {
    return this.registrationNavigator.canNavigateToUrl(url);
  }

  isStudent() {
    return this.currentProfile.isPayedRegistrationFee;
  }

  isAccepted() {
    return this.currentProfile.isAccepted;
  }

  isUploadEnabled() {
    return this.currentProfile.academicStudyType > 0;
  }
}
