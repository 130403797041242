
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Application, HttpStatusResult, WITHOUT_EMIT_EVENT } from '@jct/core';
import { AntiforgeryService, AccountService, IdentifyModel, AuthenticatorStates, IdentifyMethod } from '@jct/api';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'identify-form.page',
  templateUrl: './identify-form.html',
})
export class IdentifyForm implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private af: AntiforgeryService,
    private accountService: AccountService,
    private router: Router,
    private application: Application,
    private activatedRoute: ActivatedRoute)
  { }

  loadState = this.sf.create();
  identifyState = this.sf.create();

  form = this.fb.group({
    key: [''],
    method: [IdentifyMethod.IdNumber],
    passwordGeneratorRelativePath: [this.application.passwordGeneratorPath],
  });

  params: Params;
  identifyMethod = IdentifyMethod;

  get method() {
    return this.form.get('method').value;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    const result = await this.af.generateAsync();

    if (!result.succeeded) {
      this.loadState.failed(result);
      return;
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });

    this.form.get('method').valueChanges.subscribe(val => {
      this.form.get('key').reset('');
      this.identifyState.clearError();
    });

    await this.loadState.completed();
  }

  async identify() {
    if (!this.identifyState.isInProcess) {
      this.form.disable(WITHOUT_EMIT_EVENT);
      await this.identifyState.inProcess(10);

      const model = this.form.value as IdentifyModel;
      const result = await this.accountService.identify(model);

      if (result instanceof HttpStatusResult) {
        this.form.enable(WITHOUT_EMIT_EVENT);
        this.identifyState.failed(result);
        return;
      }

      switch (result.state) {
        case AuthenticatorStates.ShouldVerify:
          await this.router.navigate(['/start/verify'], {
            queryParams: { ...this.params, ...result },
            skipLocationChange: true,
          });
          await this.identifyState.completed();
          break;

        case AuthenticatorStates.ShouldRegister:
          await this.router.navigate(['/start/register'], {
            queryParams: { ...this.params, ...result },
            skipLocationChange: true,
          });
          await this.identifyState.completed();
          break;

        case AuthenticatorStates.ShouldConfirmByEmail:
          let key = this.form.value.key;
          await this.router.navigate(['/start/confirm'], {

            queryParams: { ...this.params, ...result, key},
            skipLocationChange: true,
          });
          await this.identifyState.completed();
          break;
      }
    }
  }
}
