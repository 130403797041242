import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  HostBinding,
  Optional,
} from "@angular/core";

import { MenuService } from "@jct/ui/lib/services";

import { MenuItemComponent } from "./menu-item.component";
import { MenuItem } from "@jct/ui/lib/common";

@Component({
  selector: "ui-submenu",
  templateUrl: "./submenu.component.html",
})
export class SubmenuComponent implements AfterContentInit {
  private _items: MenuItemComponent[] = [];

  constructor(
    private menuService: MenuService,
    @Optional() private parent: MenuItemComponent
  ) {}

  get opened() {
    return this.parent.state != "close";
  }

  @ContentChildren(MenuItemComponent)
  items!: QueryList<MenuItemComponent>;

  @HostBinding("style.height")
  get height() {
    if (this._items.length > 0) {
      let first = this._items[0].parent;

      if (first.opened) {
        const { size, edges } = this.getActualSize(this._items);

        return 45 * size + edges + 3 + "px";
      }
    }

    return "0px";
  }

  @HostBinding("style.padding-top")
  get padding() {
    return "2px";
  }

  ngAfterContentInit() {
    this._items = this.items.toArray();
    this._items.forEach((x) => (x.parent = this.parent));

    this.menuService.registerMenu(this._items);
    this.parent.children = this._items;
  }

  private getActualSize(items: MenuItem[]) {
    let _size = 0;
    let _edges = 0;

    for (const item of items) {
      if (item.children && item.children.length > 0 && item.opened) {
        const { size, edges } = this.getActualSize(item.children);

        _size += size;
        _edges += edges;
      }
    }

    return { size: _size + items.length, edges: _edges };
  }
}
