import { Component, ApplicationRef, OnInit, Optional } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { NavigationStart, Router } from "@angular/router";
import * as parser from "ua-parser-js";
import "ua-parser-js";
import { ApiDescriptionProvider, CacheService } from "@jct/core";
import { filter, map } from "rxjs/operators";

const CHECKING_UPDATE_TIME_INTERVAL = 120 * 1000;

@Component({
  selector: "registration-app",
  template: ` <meta-application-name i18n> מערכת הרישום </meta-application-name>
    <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private appRef: ApplicationRef,
    private cache: CacheService,
    @Optional() private updates: SwUpdate | null,
    private router: Router
  ) {}

  private _updating = false;
  private _updateAvailable = false;

  async ngOnInit() {
    const res = parser(navigator.userAgent);
    const version = parseInt(res.browser.version.split(".")[0]);

    switch (res.browser.name) {
      case "Chrome":
      case "Edge":
        if (version < 87) {
          // TODO: redirect to /unsecure-browser
          console.warn("unsecure browser please change your browser!");
        }
        break;

      case "IE":
        if (version < 11) {
          // TODO: redirect to /unsecure-browser
          console.warn("unsecure browser please change your browser!");
        }
        break;

      case "Firefox":
        if (version < 78) {
          // TODO: redirect to /unsecure-browser
          console.warn("unsecure browser please change your browser!");
        }
        break;

      case "Safari":
        if (version < 14) {
          // TODO: redirect to /unsecure-browser
          console.warn("unsecure browser please change your browser!");
        }
        break;
    }

    if (this.updates && this.updates.isEnabled) {
      this.appRef.isStable.subscribe(() => {
        setInterval(
          () => this._checkUpdate(false),
          CHECKING_UPDATE_TIME_INTERVAL
        );

        this.updates.versionUpdates
          .pipe(
            filter(
              (evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"
            ),
            map((evt) => ({
              type: "UPDATE_AVAILABLE",
              current: evt.currentVersion,
              available: evt.latestVersion,
            }))
          )
          .subscribe(() => {
            this._updateAvailable = true;
          });

        this._checkUpdate(true);
      });
    }
  }

  private async _checkUpdate(immediateUpdate: boolean) {
    await this.updates.checkForUpdate();

    if (this._updateAvailable) {
      this._update(immediateUpdate);
    }
  }

  private async _update(immediateUpdate: boolean) {
    if (!this._updating) {
      this._updating = true;

      this.cache.clear();

      if (immediateUpdate) {
        this.updates.activateUpdate().then(() => document.location.reload());
      } else {
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.updates
              .activateUpdate()
              .then(() => document.location.reload());
          }
        });
      }
    }
  }
}
