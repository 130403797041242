
import { LOCALE_ID } from '@angular/core';

import '@angular/common/locales/global/he';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/fr';

import { Language } from './language';

export const SUPPORTED_LANGUAGES: Language[] = [
  {
    code: 'he',
    name: 'עברית',
    layout: 'rtl',
  },
  {
    code: 'en',
    name: 'English',
    layout: 'ltr',
  },
  // {
  //   code: 'fr',
  //   name: 'Français',
  //   layout: 'ltr',
  // },
];

export let DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES.find(
  x => x.code == 'he');

export function setDefaultLanguage(language: Language) {
  DEFAULT_LANGUAGE = language;
}

export let LOCALE_PROVIDER = { provide: LOCALE_ID, useValue: 'he' };

export const MULTIPLE_LOCALIZATION_FEATURE = 'multiple-localization';
