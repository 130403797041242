
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { BehaviorSubject } from 'rxjs';

import {
  PersonService,
  PersonRelative,
  RelationTypes,
  RegistrationStates,
  OccupationTypes,
  RegistrationNavigator,
  RegistrationStages,
  RegistrationUserProfile
} from '@jct/api';

import { Gender } from '@jct/localization';
import { StateFactory, TableRow, hasNewRow, waitForDataTable, getUncompletedRows, getCompletedRows, validateRow, validateField } from '@jct/ui';
import { extractErrors, HttpStatusResult, WITHOUT_EMIT_EVENT } from '@jct/core';

interface Row extends PersonRelative, TableRow { }

@Component({
  selector: 'relatives-details-tab.page',
  templateUrl: './relatives-details-tab.component.html',
})
export class RelativesDetailsTabComponent implements OnInit {
  constructor(
    private personService: PersonService,
    private userProfile: RegistrationUserProfile,
    private registrationNavigator: RegistrationNavigator,
    private sf: StateFactory,
    private fb: UntypedFormBuilder)
  { }

  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup = this.fb.group({ 'relatives': this.rows });
  dataSource = new BehaviorSubject<AbstractControl[]>(this.rows.controls);

  displayColumns: string[] = [
    'relation-type',
    'first-name',
    'last-name',
    'birth-country',
    'education-years',
    'occupation-type',
    'work-place',
    'job-description',
    'work-phone',
    'actions'
  ];

  loadState = this.sf.create();
  saveState = this.sf.create();
  showError = false;

  @ViewChild(MatTable)
  dataTable: MatTable<any>;

  get gender(): Gender {
    return this.userProfile.gender;
  }

  get errorList() {
    return extractErrors(this.rows);
  }

  get RelationTypes() { return RelationTypes; }
  get OccupationTypes() { return OccupationTypes; }

  get _completedRows() {
    return getCompletedRows(this.rows);
  }

  private get _uncompletedRows() {
    return getUncompletedRows(this.rows);
  }

  relativeGender(formGroup: UntypedFormGroup) {
    let row = formGroup.value;

    if (row.relationType === RelationTypes.Spouse) {
      if (this.gender === Gender.Male) {
        return Gender.Female;
      }
      else {
        return Gender.Male;
      }
    }

    if (row.relationType === RelationTypes.Boyfriend ||
      row.relationType === RelationTypes.Brother ||
      row.relationType === RelationTypes.Father ||
      row.relationType === RelationTypes.Son) {
      return Gender.Male;
    }

    return Gender.Female;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    const result = await this.personService.getRelativesAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      result.forEach(relative => {
        this._createRow(relative);
      });
    }

    this._addEmptyRow();

    await waitForDataTable(this.sf, () => this.dataTable);
    await this.loadState.completed();
  }

  canRemoveRow(row: UntypedFormGroup) {
    return !row.value.newRow &&
      this.userProfile.state !== RegistrationStates.ReturnStudent;
  }

  removeRow(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataTable.renderRows();
    }
  }

  async submit() {
    if (this._completedRows.length === 0 ||
      this._uncompletedRows.length > 0 ||
      this.errorList.length > 0) {
      this.showError = true;
      return;
    }

    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();

    const model = this.rows.controls
      .map(row => row.value as Row)
      .filter(row => !row.newRow)
      .map(row => (<PersonRelative>{ ...row, occupationType: row.occupationType || null }));

    const result = await this.personService.saveRelativesAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    this.registrationNavigator.setCurrentStage(RegistrationStages.פרטי_קרובים);

    await this.saveState.completed();
    await this.registrationNavigator.navigateToNextStage();
  }

  private _createRow(relative: PersonRelative|null = null) {
    const initValues: Row = {
      newRow: relative === null,
      complete: !!relative?.relationType && (!!relative?.firstName && !!relative?.lastName && !!relative?.workPhone),
      empty: !!relative?.relationType && (!relative?.firstName && !relative?.lastName && !!relative?.workPhone),
      relationType: relative?.relationType || <any>'',
      firstName: relative?.firstName || null,
      lastName: relative?.lastName || null,
      birthCountry: relative?.birthCountry || null,
      educationYears: relative?.educationYears || null,
      occupationType: relative?.occupationType || <any>'',
      workPlace: relative?.workPlace || null,
      jobDescription: relative?.jobDescription || null,
      workPhone: relative?.workPhone || null,
    };

    const row = this.fb.group({
      'newRow': [initValues.newRow],
      'complete': [initValues.complete],
      'empty': [initValues.empty],
      'relationType': [initValues.relationType],
      'firstName': [initValues.firstName, [validateField('firstName', false)]],
      'lastName': [initValues.lastName, [validateField('lastName', false)]],
      'birthCountry': [initValues.birthCountry],
      'educationYears': [initValues.educationYears],
      'occupationType': [initValues.occupationType],
      'workPlace': [initValues.workPlace],
      'jobDescription': [initValues.jobDescription],
      'workPhone': [initValues.workPhone, [validateField('workPhone', false)]],
    });

    const relationTypeField = row.get('relationType');
    const firstNameField = row.get('firstName');
    const lastNameField = row.get('lastName');
    const workPhoneField = row.get('workPhone');

    row.setValidators([
      validateRow('firstName'),
      validateRow('lastName'),
      validateRow('workPhone')]);

    relationTypeField.valueChanges.subscribe((relationType: RelationTypes|'') => {
      if (!relationType) {
        row.disable(WITHOUT_EMIT_EVENT);
        relationTypeField.enable(WITHOUT_EMIT_EVENT);
        row.patchValue({ empty: true }, WITHOUT_EMIT_EVENT);
      }
      else {
        row.patchValue({ newRow: false }, WITHOUT_EMIT_EVENT);

        setTimeout(() => {
          this._addEmptyRow();
        });

        row.enable(WITHOUT_EMIT_EVENT);
      }
    });

    firstNameField.valueChanges.subscribe((firstName: string) => {
      if (!firstName) {
        if (!lastNameField.value || !workPhoneField.value) {
          row.patchValue({ empty: true });
        }
        row.patchValue({ complete: false });
        row.markAllAsTouched();
      }
      else {
        row.patchValue({ complete: true, empty: false });
      }
    });

    lastNameField.valueChanges.subscribe((lastName: string) => {
      if (!lastName) {
        if (!firstNameField.value ||!workPhoneField.value) {
          row.patchValue({ empty: true });
        }
        row.patchValue({ complete: false });
        row.markAllAsTouched();
      }
      else {
        row.patchValue({ complete: true, empty: false });
      }
    });

    workPhoneField.valueChanges.subscribe((workPhoneField: string) => {
      if (!workPhoneField) {
        if (!firstNameField.value || !lastNameField.value) {
          row.patchValue({ empty: true });
        }
        row.patchValue({ complete: false });
        row.markAllAsTouched();
      }
      else {
        row.patchValue({ complete: true, empty: false });
      }
    });

    this.rows.push(row);

    if (this.dataTable) {
      this.dataTable.renderRows();
    }
  }

  private _addEmptyRow() {
    if (!hasNewRow(this.rows)) {
      this._createRow();
    }
  }
}
