
export class Filtering {
  constructor(
    public resultSize?: number,
    public query?: string)
  { }

  build() {
    let queryString = (this.query!.length > 0 ? `query=${this.actualQuery}&` : '') +
      (this.resultSize > 0 ? `resultSize=${this.resultSize}&` : '');

    if (queryString.length > 0) {
      queryString = '?' + queryString.substr(0, queryString.length - 1);
    }

    return queryString;
  }

  get actualQuery() {
    return encodeURIComponent(this.query);
  }
}
