
import { Injectable, Injector } from '@angular/core';

import {
  Produce,
  Cache,
  HttpGet,
  HttpPost,
  Controller,
  resultOf,
  FromBody,
  SuccessTestCase
} from '@jct/core';

import { Hatama, HatamaModel, BagrutSubject } from './hatama.types';
import {
  FAKE_BAGRUT_SUBJECTS,
  FakeMeetTheAdmissionRequirements,
  FakeMinimumBagrutAverageGradeForExemption,
  FakeMinimumPsychometryGradeForExemption,
  FakeMissingMinimumRequirements,
  FakeNotMeetTheAdmissionRequirements
} from './hatama.test';

@Injectable({ providedIn: 'root' })
@Controller('hatama')
export class HatamaService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet()
  @Cache('30d')
  @SuccessTestCase(FAKE_BAGRUT_SUBJECTS, 'קבלת נושאים לבגרות')
  @Produce(BagrutSubject, true)
  getBagrutSubjectsAsync() {
    return resultOf<BagrutSubject[]>();
  }

  @HttpPost()
  @SuccessTestCase(FakeMeetTheAdmissionRequirements)
  @SuccessTestCase(FakeMinimumBagrutAverageGradeForExemption)
  @SuccessTestCase(FakeMinimumPsychometryGradeForExemption)
  @SuccessTestCase(FakeMissingMinimumRequirements)
  @SuccessTestCase(FakeNotMeetTheAdmissionRequirements)
  @Produce(Hatama)
  calculateAsync(@FromBody() model: HatamaModel) {
    return resultOf<Hatama>();
  }
}
