
<mat-selection-list #list
  [multiple]="false"
  (selectionChange)="setLanguage(list.selectedOptions.selected[0].value)">
  <mat-list-option *ngFor="let language of supportedLanguages"
    [value]="language"
    [selected]="language.code == currentLanguage.code">
    {{ language?.name }}
  </mat-list-option>
</mat-selection-list>
