import { Injectable, Injector } from "@angular/core";

import { Produce, HttpGet, Controller, resultOf, FromRoute } from "@jct/core";

import { LersUser } from "./users.types";

@Injectable({ providedIn: "root" })
@Controller("Users")
export class UsersService {
  users: any;

  constructor(protected injector: Injector) {}

  @HttpGet("GetUsers")
  @Produce(LersUser, true)
  getUsers() {
    return resultOf<LersUser[]>();
  }

  @HttpGet("GetUser")
  @Produce(LersUser)
  getUser() {
    return resultOf<LersUser>();
  }

  @HttpGet("DeleteUser/{id}")
  @Produce("boolean")
  deleteUser(@FromRoute("id") userId: number) {
    return resultOf<boolean>();
  }
}
