
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { HttpStatusResult} from '@jct/core';

import {
  AntiforgeryService,
  HatamaModel,
  HatamaService,
  Hatama,
  BagrutSubjectModel,
  HatamaStatus,
  BagrutSubject,
} from '@jct/api';

import { StateFactory } from '@jct/ui';
import { SubjectRow } from './bagrut-table';

@Component({
  selector: 'calculator.page',
  templateUrl: './calculator-page.html',
})
export class CalculatorPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private af: AntiforgeryService,
    private hatamaService: HatamaService)
  { }

  subjects: BagrutSubject[] = [];
  loadState = this.sf.create();
  calculateState = this.sf.create();

  form = this.fb.group({
    'subjects': this.fb.array([]),
    'gradeType': ['psychometry'],
    'grade': [null]
  });

  result?: Hatama;
  errorResult: HttpStatusResult;

  async ngOnInit() {
    await this.loadState.inProcess();
    let result = await this.af.generateAsync();

    if (!result.succeeded) {
      this.loadState.failed(result);
      return;
    }

    let loadResult = await this.hatamaService.getBagrutSubjectsAsync();

    if (loadResult instanceof HttpStatusResult) {
      this.loadState.failed(loadResult);
      return;
    }

    this.subjects = loadResult;
    await this.loadState.completed();
  }

  get alertType() {
    return this.result?.hasHatama ? 'success' : this.result?.status === HatamaStatus.MissingMinimumRequirements ? 'error' : 'info'
  }

  hasStatus(status: string) {
    return this.result?.status === HatamaStatus[status];
  }

  get gradeType() {
    return this.form?.value?.gradeType;
  }

  async calculate() {
    if (!this.calculateState.isInProcess) {
      this.calculateState.inProcess();
      this.result = null;

      let subjects = [...<SubjectRow[]>this.form.value.subjects];
      let model = {...<HatamaModel>this.form.value};

      model.subjects = subjects
        .filter(x => !x.emptyRow && x.selectedSubject)
        .map(x => <BagrutSubjectModel>{
          subjectId: x.selectedSubject.id,
          grade: x.grade,
          units: x.units,
        });

      let result = await this.hatamaService.calculateAsync(model);

      if (result instanceof HttpStatusResult) {
        this.calculateState.failed(result);
        return;
      }

      this.calculateState.completed(500);
      this.result = result;
    }
  }
}
