import { VoucherModel } from '../vouchers';

export class StudentDormitoryModel {
  dormId: number;
  dormName: string;
  dormTypeName?: string;
  comment: string;
  voucherIdentity: string;
  paidVoucher?: VoucherModel;
  voucherForPayment?: VoucherModel;
}

export class DormitoryRegistrationModel {
  dormId: number;
  dormTypeId?: number;
  comment: string;
}

export class DormitoryModel {
  id: number;
  dormName: string;
  dormTypes?: DormType[];
}

export class DormType {
  id: number;
  dormId: number;
  dormTypeName :string;
  price: number;
  isActive: boolean;
}
