<div container>
  <h3 i18n>הרשמה למעונות</h3>

  <ui-alert type="info" *visible="dormitoryOptions?.length === 0">
    <section ui-body i18n>החוג אליו נרשמת אינו משתתף במעונות</section>
  </ui-alert>

  <section *visible="dormitoryOptions?.length > 0">
    <form
      [formGroup]="form"
      autocomplete="off"
      novalidate
      (submit)="register()"
    >
      <mat-form-field appearance="fill">
        <mat-label i18n>סוג המעונות</mat-label>

        <mat-select formControlName="dormId" required>
          <mat-option [value]="0" i18n>
            {gender, select, male {בחר} female {בחרי} other {בחרו}} את סוג
            המעונות
          </mat-option>

          <mat-option *ngFor="let dorm of dormitoryOptions" [value]="dorm.id">
            {{ dorm?.dormName }}
          </mat-option>
        </mat-select>

        <mat-error> חובה לבחור את סוג המעונות </mat-error>
      </mat-form-field>
      <section *ngIf="form | get: 'dormId'">
        <mat-form-field *ngIf="isDormTypes" appearance="fill">
          <mat-label i18n>סוג הדירה</mat-label>

          <mat-select formControlName="dormTypeId">
            <mat-option [value]="null" i18n>
              {gender, select, male {בחר} female {בחרי} other {בחרו}} את סוג
              הדירה
            </mat-option>

            <mat-option *ngFor="let type of dormTypes" [value]="type.id">
              <div dir="rtl">
                {{ type?.dormTypeName + "- " + type.price + " &#8362;" }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="mb-0 pb-0" appearance="fill">
          <mat-label i18n> בקשות לשיבוץ (מוגבלויות, חברים וכו'...) </mat-label>

          <textarea
            matInput
            formControlName="comment"
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="3"
          ></textarea>
        </mat-form-field>

        <div class="mb-10 p-r pb-15">
          <mat-checkbox formControlName="isConfirmedContract" required>
            <span i18n> קראתי את </span>

            <ui-link
              [disable]="form.value.dormId == 0"
              [to]="downloadReceiptLink(form.value.dormId)"
              i18n
            >
              תקנון המעונות
            </ui-link>

            <span i18n> ואני מסכים לכל האמור </span>
          </mat-checkbox>

          <mat-error
            class="mat-error mat-form-field-subscript-wrapper"
            *visible="form | hasError:'isConfirmedContract'"
            i18n
          >
            חובה להסכים לתקנון המעונות
          </mat-error>
        </div>

        <ui-error [state]="registerState"></ui-error>

        <ui-progress-button
          color="primary"
          [state]="registerState"
          [disabled]="form.invalid"
          i18n
        >
          הירשם
        </ui-progress-button>
      </section>
    </form>
  </section>
</div>
