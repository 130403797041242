<div container>
  <h4 i18n>
    {gender, select, male {הנך רשום למעונות} female {הנך רשומה למעונות} }
  </h4>

  <p>
    <strong i18n>סוג מעונות</strong>:
    <span>{{ studentDormitory?.dormName }}</span>

    <br />

    <span *ngIf="showDormType">
      <strong i18n>סוג הדירה</strong>:
      <span>{{ studentDormitory?.dormTypeName }}</span>

      <br />
    </span>

    <strong i18n>הבקשות שלך לשיבוץ</strong>:
    <span>{{ studentDormitory?.comment || 'לא קיימות בקשות' }}</span>
  </p>

  <ui-error [state]="updateState"></ui-error>

  <ui-progress-button
    color="primary"
    [state]="updateState"
    (click)="update()"
    i18n
  >
    עדכון הבקשות
  </ui-progress-button>

  <br />
  <br />

  <p>
    <span i18n>
      {gender, select, male {קרא בעיון את} female {קראי בעיון את} }
    </span>

    <ui-link [to]="unsafeDownloadReceiptLink(studentDormitory.dormId)" i18n>
      תקנון המעונות
    </ui-link>
  </p>

  <section *visible="studentDormitory?.dormId === 1">
    <h3 i18n>רישומך למעונות יובא לדיון</h3>

    <p i18n>
      לאחר קבלת התשובה מועדת הקבלה, האישור והשיבוץ הסופי למעונות מותנה במימוש
      קבלה מלא של הגשת
      <u>הוראת קבע חתומה ע"י הבנק</u>
      ,תשלום
      <u>מקדמת שכ"ל ומקדמת מעונות</u>
      וכן
      <u>חתימה על חוזה</u>
      בהתאם להנחיות מנהלת המעונות.<br />
      בהצלחה!
    </p>

    <span i18n>במידה ותוזמן לוועדת קבלה, עליך לצרף את המסמכים הבאים</span>:

    <ul>
      <li i18n>אישור מגורים עדכני</li>
      <li i18n>פרטי 2 ממליצים</li>
      <li i18n>אישור על שירות בצה''ל / לאומי / אזרחי</li>
      <li i18n>אישור לימודים בכולל</li>
      <li i18n>אישור מילואים (10 ימים בשנה, בשלוש השנים האחרונות)</li>
      <li i18n>ספח תעודת זהות המעיד על מס' הילדים</li>
    </ul>
  </section>

  <section *visible="studentDormitory?.dormId !== 1">
    <section *visible="studentDormitory.paidVoucher">
      <p i18n>
        השיבוץ הסופי למעונות וקבלת מפתח, מותנים במימוש קבלה מלא של הגשת
        <u>הוראת קבע חתומה ע"י הבנק, תשלום מקדמת שכ"ל ומקדמת מעונות</u>
        בהתאם להנחיות מנהלת המעונות. בהצלחה!
      </p>

      <p i18n>
        קבלת חדר תיעשה ביום ההיערכות בתחילת שנת הלימודים.
        <br />
        שיבוץ בחדרי המעונות נערך
        <strong>כשבוע</strong>
        לפני כניסתך למעונות ע"פ תחומי לימוד וגילאים.
        <br />
        <u>הודעת השיבוץ תישלח אליך במייל.</u>
        <br />
        מידע מפורט ומורחב יותר על המעונות קיים באתר
        <ui-link to="https://jct.ac.il"> jct.ac.il </ui-link>
        <br />
        לבירורים נוספים ניתן לפנות למנהלת המעונות במייל:
        <ui-link to="mailto:meonot@g.jct.ac.il"> meonot@g.jct.ac.il </ui-link>
      </p>

      <voucher [voucherDetails]="studentDormitory.paidVoucher"> </voucher>
    </section>

    <section *visible="!studentDormitory.paidVoucher">
      <p i18n>לידיעתך, על מנת שרישומך יובא לדיון, עליך לשלם מקדמת מעונות</p>

      <section *visible="!!studentDormitory.voucherForPayment">
        <voucher
          [voucherDetails]="studentDormitory.voucherForPayment"
          [parent]="thisComponent"
        >
        </voucher>
      </section>

      <ui-alert type="info" *visible="!studentDormitory.voucherForPayment">
        <section ui-body i18n>
          לא הונפק עבורך שובר, אנא פנה למדור חשבונות תלמידים
        </section>
      </ui-alert>
    </section>
  </section>
</div>
