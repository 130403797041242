
import { Component } from '@angular/core';

import { HostEnvironment } from '@jct/core';

@Component({
  selector: 'confirmation.page',
  templateUrl: './confirmation-page.html',
})
export class ConfirmationPageComponent {
  constructor(
    private environment: HostEnvironment)
  { }

  get downloadLink() {
    return `${this.environment.apiServerUrl}/confirmation`;
  }
}
