import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[disabled]',
})
export class UiDisabled {
  private _disabled = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: any) {
    this._disabled = !!value || value == 'true' || value == true;
  }

  @HostBinding('style')
  get style() {
    if (this._disabled) {
      return 'cursor: not-allowed;';
    }

    return '';
  }
}
