import { HttpStatusResult } from '../api';

export interface UploadResult<T> {
  percents: number;
  done: boolean;
  result?: T | HttpStatusResult;
}

export const EMPTY_UPLOAD_RESULT: UploadResult<any> = {
  percents: 0,
  done: false,
}
