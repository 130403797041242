import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
  Controller,
  HttpGet,
  HttpPost,
  FromBody,
  HostEnvironment,
  resultOf,
  Produce,
  HttpStatusResult,
  SuccessTestCase,
  SuccessResult,
} from '@jct/core';

import {
  DormitoryModel,
  DormitoryRegistrationModel,
  StudentDormitoryModel
} from './dormitory.types';

import {
  FakeStudentDormitoryModelBeforePayment,
  FakeDormitoryModel,
  FakeStudentDormitoryModelAfterPayment
} from './dormitory.test';

@Injectable({ providedIn: 'root' })
@Controller('dormitory')
export class DormitoryService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private sanitizer: DomSanitizer)
  { }

  downloadReceiptLink(dormId: number) {
    return this.sanitizer.bypassSecurityTrustUrl(
      `${this.environment.apiServerUrl}/dormitory/${dormId}`);
  }

  unsafeDownloadReceiptLink(dormId: number) {
    return `${this.environment.apiServerUrl}/dormitory/${dormId}`;
  }

  @HttpGet()
  @SuccessTestCase(FakeStudentDormitoryModelBeforePayment)
  @SuccessTestCase(FakeStudentDormitoryModelAfterPayment)
  @Produce(StudentDormitoryModel)
  getStudentDormitoryAsync() {
    return resultOf<StudentDormitoryModel>();
  }

  @HttpGet('options')
  @SuccessTestCase(FakeDormitoryModel)
  @Produce(DormitoryModel, true)
  getStudentDormitoryOptionsAsync() {
    return resultOf<DormitoryModel[]>();
  }

  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  registerAsync(
    @FromBody() model: DormitoryRegistrationModel) {
    return resultOf();
  }
}
