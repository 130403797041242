
<meta-title i18n>
  תוכן לא קיים
</meta-title>

<div container>
  <h2 i18n>
    הכתובת שחפשת לא קיימת במערכת
  </h2>

  <p i18n>
    אם נתקלתם בבעיה במערכת אנא צרו עמנו קשר
    <br>
    כדי שנוכל לעזור לכם.
  </p>

  <p>
    <ui-phone-link color="primary">02-6751152</ui-phone-link>
    <ui-mail-link color="primary">support@jct.ac.il</ui-mail-link>
  </p>
</div>
