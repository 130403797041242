
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';

import { Authenticator, HttpStatusResult, TokenSettings } from '@jct/core';
import { TimeSpan } from '@jct/core/system';

import {
  AccountService,
  ProfileService,
  AuthenticatorStates,
  RegistrationNavigator
} from '@jct/api';

import { StateFactory } from '@jct/ui';

@Component({
  selector: 'verify-form.page',
  templateUrl: './verify-form.html',
})
export class VerifyForm implements OnInit, OnDestroy {
  private _timer: any;
  private _redirect: string = null;

  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private authenticator: Authenticator,
    private profileService: ProfileService,
    private registrationNavigator: RegistrationNavigator,
    private route: ActivatedRoute,
    private router: Router)
  { }

  loadState = this.sf.create();
  verifyState = this.sf.create();

  form = this.fb.group({
    token: [''],
    password: ['']
  });

  async ngOnInit() {
    await this.loadState.inProcess();

    this.route.queryParams.subscribe(async params => {
      this._redirect =  params['redirect'] || null;

      if (!params['token']) {
        this.reset();
      }
      else {
        this.form.patchValue({
          token: params.token,
        });

        const tokenLifeTime = TimeSpan.parse(params.tokenLifeTime);

        this._timer = setTimeout(() => {
          this.reset();
        }, tokenLifeTime.totalSeconds * 1000);

        this.loadState.completed(10);
      }
    });
  }

  ngOnDestroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  reset() {
    this.loadState.quit();
    this.router.navigate(['/start/identify']);
  }

  async verify() {
    if (!this.verifyState.isInProcess) {
      this.form.disable();
      this.verifyState.inProcess();

      const result = await this.accountService.verify(this.form.value);

      if (result instanceof HttpStatusResult) {
        this.form.enable();
        this.verifyState.failed(result);
        return;
      }

      if (result.state === AuthenticatorStates.LoggedIn) {
        this.authenticator.authenticate(<TokenSettings>result);
        const loadProfileResult = await this.profileService.loadRegistrationProfile();

        if (loadProfileResult instanceof HttpStatusResult) {
          this.form.enable();
          this.verifyState.failed(loadProfileResult);
          return;
        }

        this.verifyState.completed();

        if (this._redirect && this.registrationNavigator.canNavigateToUrl(this._redirect)) {
          this.router.navigateByUrl(this._redirect);
        }
        else {
          await this.registrationNavigator.navigateToCurrentStage();
        }
      }
    }
  }
}
