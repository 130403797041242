
import { Component, OnInit } from '@angular/core';

import { RegistrationUserProfile, StandingOrderService, PaymentWindowMessage } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'standing-order.page',
  templateUrl: './standing-order-page.html',
})
export class StandingOrderPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private profile: RegistrationUserProfile,
    private standingOrderService: StandingOrderService)
  { }

  loadState = this.sf.create();
  beginPaymentState = this.sf.create();

  hasStandingOrder = false;
  hasAgreed = false;

  get gender() {
    return this.profile?.gender;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    const result = await this.standingOrderService.getPaymentDetailsAsync();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed(result);
      return;
    }

    this.hasStandingOrder = result;
    await this.loadState.completed();
  }

  async payAsync() {
    if (!this.beginPaymentState.isInProcess) {
      await this.beginPaymentState.inProcess();

      const beginResult = await this.standingOrderService.beginPaymentProcessViaCGAsync();

      if (beginResult instanceof HttpStatusResult) {
        this.beginPaymentState.failed(beginResult);
        return;
      }

      location.href = beginResult;
    }
  }
}
