
<div container>
  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <h3 i18n>
      שוברים לתשלום
    </h3>

    <voucher *ngFor="let voucher of vouchersForPayment"
      [voucherDetails]="voucher"
      [parent]="thisComponent">
    </voucher>

    <p *visible="vouchersForPayment.length === 0" i18n>
      לא קיימים שוברים לתשלום
    </p>

    <h3 i18n>
      שוברים ששולמו
    </h3>

    <voucher *ngFor="let voucher of paidVouchers"
      [voucherDetails]="voucher">
    </voucher>

    <p *visible="paidVouchers.length === 0" i18n>
      לא קיימים שוברים ששולמו
    </p>
  </section>
</div>
