
<a #anchorElement
  *ngIf="type == 'link'"
  [href]="link"
  [target]="openInNew ? '_blank' : '_self'"
  [class]="depthClass + ' ' + type"
  (keydown.space)="open()">
  <mat-icon *ngIf="actualIcon">{{ actualIcon }}</mat-icon>
  <span>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </span>
</a>

<a #anchorElement
  *ngIf="type == 'route'"
  [routerLink]="[disabled ? null: route]"
  (click)="navigateTo()"
  [class]="depthClass + ' ' + type"
  (keydown.space)="open()">
  <mat-icon *ngIf="actualIcon">{{ actualIcon }}</mat-icon>
  <span #content>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </span>
</a>

<a #anchorElement
  *ngIf="type != 'route' && type != 'link'"
  href="javascript:void(0)"
  (click)="navigateTo()"
  [class]="depthClass + ' ' + type"
  (keydown.space)="open()">
  <mat-icon *ngIf="actualIcon">{{ actualIcon }}</mat-icon>
  <span #content>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </span>
</a>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
