
<h1 mat-dialog-title i18n>
  ניתוק מהמערכת בשל חוסר פעילות
</h1>

<section mat-dialog-content>
  <p i18n>
    שמנו לב שלא בצעת שום פעולה במשך הרבה זמן,
    בשל שיקולי אבטחה ושמירה על הפרטיות שלך
    המערכת תנתק אותך אוטומטית בעוד כ-{{ seconds }} שניות
  </p>
</section>

<section mat-dialog-actions>
  <button mat-raised-button
    color="primary"
    type="button"
    (click)="stay()">
    <strong i18n>תנו לי להמשיך</strong>
  </button>&nbsp;&nbsp;

  <button mat-raised-button
    color="accent"
    type="button"
    (click)="logMeOut()">
    <strong i18n>תנתקו אותי</strong>
  </button>
</section>
