
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { L10nModule } from '@jct/localization';
import { TestModule } from '@jct/test';

import { CountryFieldComponent } from './country-field';
import { CityFieldComponent } from './city-field';
import { ContactDetailsTabComponent  } from './contact-details-tab';
import { StreetFieldComponent  } from './street-field';
import { hostEnvironment } from 'projects/registration-app/environments/environment';

// const COMPONENTS = [
//   CountryFieldComponent,
//   CityFieldComponent,
//   ContactDetailsTabComponent,
//   StreetFieldComponent,
// ];

@NgModule({
  declarations: [
    CountryFieldComponent,
    CityFieldComponent,
    ContactDetailsTabComponent,
    StreetFieldComponent,
  ],
  exports: [
    CountryFieldComponent,
    CityFieldComponent,
    ContactDetailsTabComponent,
    StreetFieldComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
  ],
})
export class SharedComponentsModule {}
