
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';

import { Gender } from '@jct/localization';
import { extractErrors, HttpStatusResult, YEAR_PATTERN } from '@jct/core';
import { StateFactory } from '@jct/ui';

import {
  PreviousInstitutionService,
  StudentInstitution,
  ProfileService,
  RegistrationStates,
  InstitutionTypes,
  RegistrationUserProfile,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
} from '@jct/api';

interface TableRow {
  type?: InstitutionTypes;
  name?: string;
  country?: string;
  city?: string;
  startYear?: number;
  endYear?: number;
}

const DisableEmitEvent = { emitEvent: false };

@Component({
  selector: 'institutions-tab.page',
  templateUrl: './institutions-tab.component.html',
})
export class InstitutionsTabComponent implements OnInit {
  @Output()
  done = new EventEmitter<any>();

  constructor(
    private previousInstitutionsService: PreviousInstitutionService,
    private registrationNavigator: RegistrationNavigator,
    private profile: RegistrationUserProfile,
    private profileService: ProfileService,
    private sf: StateFactory,
    private fb: UntypedFormBuilder)
  { }

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns: string[] = ['type', 'name', 'country', 'city', 'start-year', 'end-year', 'actions'];

  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup = this.fb.group({ 'institutes': this.rows });

  loadState = this.sf.create();
  saveState = this.sf.create();
  showError = false;

  filteredInstitutions: Observable<string[]>;

  get InstitutionTypes() { return InstitutionTypes; }

  get isMale() { return this.gender == Gender.Male; }
  get isFemale() { return this.gender == Gender.Female; }

  maxEndYear = new Date().getFullYear();

  private _setEndYearValidators(row: UntypedFormGroup, startYearValue: number|null) {
    const endYearValidators: ValidatorFn[] = [];

    endYearValidators.push(Validators.max(this.maxEndYear));
    if (startYearValue !== null) {
      endYearValidators.push(Validators.min(startYearValue));
    }

    row.get('endYear').setValidators(endYearValidators);
  }

  private createRow(data?: TableRow) {
    const config = {
      'type': [ data ? data.type : '' ],
      'name': [{ value: data ? data.name : null, disabled: !data }, Validators.required],
      'country': [{ value: data ? data.country : null, disabled: !data }],
      'city': [{ value: data ? data.city : null, disabled: !data }],
      'startYear': [{ value: data ? data.startYear : null, disabled: !data }, [
        Validators.required, Validators.pattern(YEAR_PATTERN), Validators.min(1900)]],
      'endYear': [{ value: data ? data.endYear : null, disabled: !data }, [
        Validators.min((data?.startYear || 1900)), Validators.max(this.maxEndYear)]],
    };

    const row = this.fb.group(config);

    this.rows.push(row);

    row.controls['type'].valueChanges.subscribe(async type => {
      row.patchValue({
        'name': ''
      }, DisableEmitEvent);
    });

    row.get('startYear').valueChanges.subscribe(value => {
      this._setEndYearValidators(row, value);
    });

    row.valueChanges.subscribe((data: TableRow) => {
      if (!!data.type) {
        row.enable(DisableEmitEvent);
        this.addEmptyRow();
      }
    });
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.previousInstitutionsService.getStudentInstitutionsAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      result.forEach(x => this.createRow(x));
    }

    this.dataSource.next(this.rows.controls);
    this.addEmptyRow();

    this.loadState.completed();
  }

  addEmptyRow() {
    if (!this.hasEmptyRow) {
      this.createRow();
      this.dataSource.next(this.rows.controls);
    }
  }

  private get hasEmptyRow() {
    return this.rows.controls.findIndex(x => x.value.type == 0) >= 0;
  }

  removeInstitution(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }

  get returnStudent() {
    return this.profile?.state == RegistrationStates.ReturnStudent;
  }

  canRemoveInstitution(row: UntypedFormGroup) {
    return !!row.value.type &&
    !this.returnStudent;
  }

  public get gender(): Gender {
    return this.profile?.gender;
  }

  get uncompleted() {
    let uncompleted = this.rows.controls
    .filter(x =>
      !!x.value.type &&
      (!x.value.name ||
      !x.value.startYear));

    return uncompleted.length > 0;
  }

  get errorList() {
    return extractErrors(this.rows);
  }

  get canSubmit() {
    return !this.uncompleted && !this.form.invalid;
  }

  async saveAsync() {
    if (this.saveState.isInProcess) {
      return;
    }

    this.form.markAllAsTouched();

    await this.saveState.inProcess();

    if (!this.canSubmit) {
      this.showError = true;
      await this.saveState.completed();
      return;
    }

    let studentInstitutions = this.form.value.institutes
      .filter(x => x.name && x.startYear);

    let model = studentInstitutions as StudentInstitution[];

    let result = await this.previousInstitutionsService.saveStudentInstitutionsAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    this.profileService.update<RegistrationProfile>({
      currentStage: RegistrationStages.מוסדות_על_תיכוניים,
    });

    await this.saveState.completed();

    this.registrationNavigator.navigateToNextStage();
  }
}
