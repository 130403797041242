
<div class="table-responsive mat-elevation-z2" [formGroup]="form"
  *visible="loadState.isCompleted; transition 'bounce'">
  <table mat-table
    [dataSource]="dataSource"
    formArrayName="subjects"
    [ui-column-width]="[260, 130, 120, 100, 180, 80]"
  >
    <!-- Subject Column -->
    <ng-container matColumnDef="subject">
      <th mat-header-cell *matHeaderCellDef i18n>
        מקצוע
      </th>

      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field floatLabel="never" class="no-hint">
          <mat-label i18n>בחרו מקצוע</mat-label>

          <input type="text"
            matInput
            [matAutocomplete]="auto"
            formControlName="subject">

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let subject of filterSubjects(row.value)"
              [value]="subject.subjectName">
              {{ subject?.subjectName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </td>

      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Units Column -->
    <ng-container matColumnDef="units">
      <th mat-header-cell *matHeaderCellDef i18n>
        יחידות לימוד
      </th>

      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field floatLabel="never" class="no-hint">
          <mat-label i18n>יחידות לימוד</mat-label>

          <input matInput
            formControlName="units"
            type="number"
            max="20"
            min="1"
            required
            autocomplete="off">
        </mat-form-field>
      </td>

      <td mat-footer-cell *matFooterCellDef>
        <span i18n>סה"כ</span>:
        {{ totalUnits }}
      </td>
    </ng-container>

    <!-- Grade Column -->
    <ng-container matColumnDef="grade">
      <th mat-header-cell *matHeaderCellDef i18n>
        ציון
      </th>

      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <mat-form-field floatLabel="never" class="no-hint">
          <mat-label i18n>ציון</mat-label>

          <input matInput
            formControlName="grade"
            type="number"
            max="100"
            min="1"
            required
            autocomplete="off">
        </mat-form-field>
      </td>

      <td mat-footer-cell *matFooterCellDef>
        <span i18n>ממוצע</span>:
        {{ average | number:'1.0-2' }}
      </td>
    </ng-container>

    <!-- Bonus Column -->
    <ng-container matColumnDef="bonus">
      <th mat-header-cell *matHeaderCellDef i18n>
        בונוס
      </th>

      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        {{ row?.value?.bonus | number:'1.0-2' }}
      </td>

      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- FinalGrade Column -->
    <ng-container matColumnDef="finalGrade">
      <th mat-header-cell *matHeaderCellDef i18n>
        ציון סופי
      </th>

      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        {{ row?.value?.finalGrade | number:'1.0-2' }}
      </td>

      <td mat-footer-cell *matFooterCellDef>
        <span i18n>ממוצע משוקלל</span>:
        {{ weightedAverage | number:'1.0-2' }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
        <button mat-icon-button
          type="button"
          i18n-matTooltip
          type="button"
          matTooltip="מחק שורה"
          (click)="removeRow(index)"
          [disabled]="!canRemoveRow(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>

      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayColumns"></tr>
  </table>
</div>
