import { InjectionToken } from '@angular/core';

import { HttpContext } from './http-context';

export type ResponseTypes = 'arraybuffer' | 'blob' | 'json' | 'text';

export interface ApiInterceptor {
  enabled: boolean;
  order?: number;

  config?<T = any>(context: HttpContext<T>): void;
  begin?<T = any>(context: HttpContext<T>): Promise<void>;
  error?<T = any>(context: HttpContext<T>): Promise<void>;
  end?<T = any>(context: HttpContext<T>): Promise<void>;
}

export const API_INTERCEPTORS = new InjectionToken<ApiInterceptor[]>('API_INTERCEPTORS');

export interface RequestOptions {
  headers?: {
      [header: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: ResponseTypes;
  withCredentials?: boolean;
}

export enum ResponseMethod {
  NotYetDetermined,
  FromServer,
  FromCache,
  Generated,
}
