
import { Component, ViewChild } from '@angular/core';

import { StudentAmiramTests } from './student-amiram-tests';

@Component({
  selector: 'amiram-test.page',
  templateUrl: './amiram-test.page.html',
})
export class AmiramTestPage {
  constructor()
  { }

  @ViewChild('studentAmiramTests', { static: true })
  studentAmiramTests: StudentAmiramTests;

  onPayedNewAmiramTest() {
    this.studentAmiramTests.ngOnInit();
  }
}
