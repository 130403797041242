
import { Host, OnInit, ElementRef, Directive, Inject, forwardRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatLegacyTabLink as MatTabLink, MatLegacyTabNav as MatTabNav } from '@angular/material/legacy-tabs';
import { PageLayout } from '@jct/localization';
import { MenuService } from '@jct/ui/lib/services';

@Directive({
  selector: '[matTabLink], [mat-tab-link]'
})
export class MatTabLinkExtension implements OnInit {
  constructor(
    @Host() public tabLink: MatTabLink,
    @Inject(forwardRef(() => MatTabNav)) private tabNav: MatTabNav,
    private router: Router,
    private pageLayout: PageLayout,
    private menuService: MenuService,
    private elementRef: ElementRef<HTMLElement>)
  { }

  async ngOnInit() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.tabLink.active = x.url.startsWith(this.routerLink);
      }
    });

    this.tabLink.active = this.router.url.startsWith(this.routerLink);

    this.pageLayout.layoutChange.subscribe(layout => {
      this._updateTabNav(true);
    });

    this.menuService.stateChange.subscribe(state => {
      this._updateTabNav(true);
    });

    this._updateTabNav();

    window.onresize = () => {
      this._updateTabNav();
    };
  }

  private _updateTabNav(immediate: boolean = false) {
    setTimeout(() => {
      if (immediate) {
        const index = this.tabNav.selectedIndex;
        this.tabNav._scrollToLabel(index)
        this.tabNav._alignInkBarToSelectedTab();
      }

      this.tabNav._alignInkBarToSelectedTab();
      this.tabNav.updatePagination();
      this.tabNav.updateActiveLink();
    }, 10);

    if (!immediate) {
      setTimeout(() => {
        const index = this.tabNav.selectedIndex;
        this.tabNav._scrollToLabel(index)
        this.tabNav._alignInkBarToSelectedTab();
        this.tabNav.updatePagination();
        this.tabNav.updateActiveLink();
      }, 500);
    }
  }

  get routerLink() {
    return this.elementRef.nativeElement.attributes
      .getNamedItem('routerlink').value;
  }
}
