
import { OnInit, OnDestroy, HostBinding, Input, ElementRef, Injector, Directive } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import {  MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { Subject } from 'rxjs';

export const DISABLE_EVENTS = { emitEvent: false };

@Directive({})
export abstract class BaseFieldComponent<T> implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<T> {
  static nextId = 0;

  inputControl: UntypedFormControl;

  private _onChange = (_: any) => {};
  private _onTouched = () => {};

  get stateChanges() { return this._stateChanges; }
  private _stateChanges = new Subject<void>();

  @HostBinding('id')
  get id() { return this._id; }
  private _id = `field-${BaseFieldComponent.nextId++}`;

  get focused() { return this._focused; }
  private _focused = false;

  get empty() {
    const { value } = this.inputControl;
    return !value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() { return this._errorState; }
  private _errorState = false;

//#region placeholder
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string = '';
//#endregion

//#region required
  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;
//#endregion

//#region disabled
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.inputControl.disable(DISABLE_EVENTS) : this.inputControl.enable(DISABLE_EVENTS);
    this.stateChanges.next();
  }
  private _disabled = false;
//#endregion

//#region value
  @Input()
  get value(): T | null {
    const { value } = this.inputControl;
    if (value) {
      return value;
    }
    return null;
  }
  set value(value: T | null) {
    this.inputControl.setValue(value || null);
  }
//#endregion

//#region appearance
  @Input()
  get appearance(): string { return this._appearance; }
  set appearance(value: string) {
    this._appearance = value;
    this.stateChanges.next();
  }
  _appearance: string = 'outline';
//#endregion

// #region label
  @Input()
  get label(): string { return this._label; }
  set label(value: string) {
    this._label = value;
    this.stateChanges.next();
  }
  _label: string = '';
// #endregion

//#region floatLabel
  @Input()
  get floatLabel(): string { return this._floatLabel; }
  set floatLabel(value: string) {
    this._floatLabel = value;
    this.stateChanges.next();
  }
  _floatLabel: string = 'auto';
//#endregion

//#region fontSize
  @Input()
  get fontSize(): string { return this._fontSize; }
  set fontSize(value: string) {
    this._fontSize = value;
    this.stateChanges.next();
  }
  private _fontSize: string = '14px';
//#endregion

  private _focusMonitor: FocusMonitor;
  private _elementRef: ElementRef<HTMLElement>;

  constructor(
    public injector: Injector,
    public ngControl: NgControl)
  {
    this._focusMonitor = injector.get<FocusMonitor>(FocusMonitor);
    this._elementRef = injector.get<ElementRef<HTMLElement>>(ElementRef);

    this.inputControl = new UntypedFormControl();

    this._focusMonitor.monitor(this._elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this._onTouched();
      }
      this._focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) { }

  onContainerClick(event: MouseEvent) { }

//#region ControlValueAccessor
  writeValue(value: T | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
//#endregion

  async ngOnInit() {
    this.inputControl.valueChanges
      .subscribe(x => {
        this._onChange(x);
        this.stateChanges.next();
      });
  }
}
