
import { Component } from '@angular/core';

import { AppLayoutService } from '@jct/ui/lib/services';

@Component({
  selector: "not-authorized.page",
  templateUrl: "./not-authorized.page.html",
})
export class NotAuthorizedPage {
  constructor(public appLayout: AppLayoutService) {}
}
