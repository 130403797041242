
import { Injectable } from '@angular/core';

import { Gender } from '@jct/localization';
import { ProfileService } from './profile.service';
import { RegistrationStages, RegistrationProfile } from './registration.types';

@Injectable({
  providedIn: 'root'
})
export class RegistrationUserProfile {
  constructor(
    private profileService: ProfileService)
  { }

  get exists() { return this.profileService.hasProfile; }

  get isPayedRegistrationFee() {
    if (!this.exists || !this.currentStage) {
      return false;
    }

    return this.currentStage === RegistrationStages.תשלום;
  }

  get isFemale() {
    return this._profile?.gender == Gender.Female;
  }

  get isMale() {
    return this._profile?.gender == Gender.Male;
  }

  get academicStudyType() { return this._profile?.academicStudyType; }
  get currentStage() { return this._profile?.currentStage; }
  get enabledCriminalRecord() { return this._profile?.enabledCriminalRecord; }
  get gender() { return this._profile?.gender; }
  get isAccepted() { return this._profile?.isAccepted || false; }
  get state() { return this._profile?.state; }
  get minAcademicYear() { return this._profile?.minAcademicYear; }

  get isUploadEnabled() {
    return this.academicStudyType > 0;
  }

  private get _profile(): RegistrationProfile {
    return this.profileService.profile;
  }
}
