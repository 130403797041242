
import { AnimationCssProperties } from './types';
import { Map } from '@jct/core';

const animationsList = [];

// bounceIn <-> bounceOut

const bounceIn: Map<AnimationCssProperties> = {
  0: {
    opacity: 0,
    transform: 'scale(0.3)',
  },
  50: {
    opacity: 0.9,
    transform: 'scale(1.1)',
  },
  80: {
    opacity: 1,
    transform: 'scale(0.89)',
  },
  100: {
    opacity: 1,
    transform: 'scale(1)',
  },
};

animationsList.push('bounceIn');

const bounceOut: Map<AnimationCssProperties> = {
  0: {
    opacity: 1,
    transform: 'scale(1)',
  },
  20: {
    transform: 'scale(0.9)',
  },
  40: {
    opacity: 0.9,
    transform: 'scale(1.1)',
  },
  60: {
    opacity: 0.6,
    transform: 'scale(0.9)',
  },
  80: {
    opacity: 0.3,
    transform: 'scale(0.4)',
  },
  100: {
    opacity: 0,
    transform: 'scale(0.2)',
  },
};

animationsList.push('bounceOut');

// enter <-> leave

const enter: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    height: '0%',
  },
  to: {
    opacity: 1,
    height: '100%',
  },
};

animationsList.push('enter');

const leave: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    height: '100%',
  },
  to: {
    opacity: 0,
    height: '0%',
  },
};

animationsList.push('leave');

const rotateIn: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    transform: 'rotateZ(-90deg)',
  },
  to: {
    opacity: 1,
    transform: 'rotateZ(0deg)',
  },
};

animationsList.push('rotateIn');

const rotateOut: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    transform: 'rotateZ(-0deg)',
  },
  to: {
    opacity: 0,
    transform: 'rotateZ(-90deg)',
  },
};

animationsList.push('rotateOut');

const curtainIn: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    transform: 'scaleX(0)',
  },
  to: {
    opacity: 1,
    transform: 'scaleX(100%)',
  },
};

animationsList.push('curtainIn');

const curtainOut: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    transform: 'scaleX(100%)',
  },
  to: {
    opacity: 0,
    transform: 'scaleX(0)',
  },
};

animationsList.push('curtainOut');

const routeIn: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    transform: 'translateY(100px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
};

animationsList.push('routeIn');

const routeOut: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  to: {
    opacity: 0,
    transform: 'translateY(100px)',
  },
};

animationsList.push('routeOut');

const slideInLeft: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    transform: 'translateX(-50%)',
  },
  to: {
    opacity: 1,
    transform: 'translateX(0)',
  },
};

animationsList.push('slideInLeft');

const slideOutLeft: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  to: {
    opacity: 0,
    transform: 'translateX(-50%)',
  },
};

animationsList.push('slideOutLeft');

const slideInRight: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    transform: 'translateX(50%)',
  },
  to: {
    opacity: 1,
    transform: 'translateX(0)',
  },
};

animationsList.push('slideInRight');

const slideOutRight: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    transform: 'translateX(0)',
  },
  to: {
    opacity: 0,
    transform: 'translateX(50%)',
  },
};

animationsList.push('slideOutRight');

const swing: Map<AnimationCssProperties> = {
  0: {
    opacity: 0,
    transform: 'rotate(-15deg)',
  },
  20: {
    transform: 'rotate(0deg)',
  },
  40: {
    transform: 'rotate(15deg)',
  },
  60: {
    transform: 'rotate(0deg)',
  },
  80: {
    transform: 'rotate(-15deg)',
  },
  100: {
    opacity: 1,
    transform: 'rotate(0deg)',
  },
};

animationsList.push('swing');

const slideDown: Map<AnimationCssProperties> = {
  from: {
    opacity: 0,
    transform: 'scaleY(0) translateX(100%)',
  },
  to: {
    opacity: 1,
    transform: 'scaleY(100%) translateX(0)',
  },
};

animationsList.push('slideDown');

const slideUp: Map<AnimationCssProperties> = {
  from: {
    opacity: 1,
    transform: 'scaleY(100%) translateX(0)',
  },
  to: {
    opacity: 0,
    transform: 'scaleY(0) translateX(100%)',
  },
};

animationsList.push('slideUp');

export interface Animation {
  enter: Map<AnimationCssProperties>;
  leave: Map<AnimationCssProperties>;
}

export type Transition = Map<AnimationCssProperties>;

export const AnimationsMap: Map<Animation> = {
  bounce: {
    enter: bounceIn,
    leave: bounceOut,
  },
  'default': {
    enter,
    leave,
  },
  'curtain': {
    enter: curtainIn,
    leave: curtainOut,
  },
  'rotate': {
    enter: rotateIn,
    leave: rotateOut,
  },
  'route': {
    enter: routeIn,
    leave: routeOut,
  },
  'slide': {
    enter: slideDown,
    leave: slideUp,
  },
  'slideLeft': {
    enter: slideInLeft,
    leave: slideOutLeft,
  },
  'slideRight': {
    enter: slideInRight,
    leave: slideOutRight,
  },
};

export const TransitionsMap: Map<Transition> = {
  bounceIn,
  bounceOut,
  enter,
  leave,
  curtainIn,
  curtainOut,
  rotateIn,
  rotateOut,
  routeIn,
  routeOut,
  swing,
  slideDown,
  slideUp,
  slideInLeft,
  slideOutLeft,
  slideInRight,
  slideOutRight,
};

export type AnimationType =
  'bounce' |
  'default' |
  'curtain' |
  'rotate' |
  'route' |
  'slide' | 'slideLeft' | 'slideRight';

export type TransitionType =
  'fadeIn' | 'fadeOut' |
  'enter' | 'leave' |
  'bounceIn' | 'bounceOut' |
  'rotateIn' | 'rotateOut' |
  'curtainIn' | 'curtainOut' |
  'routeIn' | 'routeOut' | 'swing' |
  'slideDown' | 'slideUp' |
  'slideInRight' | 'slideOutRight' | 'slideInLeft' | 'slideOutLeft';
