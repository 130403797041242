import { Injectable, Injector } from "@angular/core";

import {
  Controller,
  HttpGet,
  resultOf,
  FromRoute,
  HttpPost,
  FromBody,
  Produce,
  HttpStatusResult,
  HostEnvironment,
  ACCESS_CONTROL_ORIGIN_HEADER_NAME,
  APPLICATION_URL_HEADER_NAME,
} from "@jct/core";

import {
  StudentDetails,
  StudentNotebookModel,
  CourseDetailsWithGradePartsModel,
  StudentTestNotebook,
  StudentTestModel,
  RegistrationTestModel,
} from "./exams.types";

import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
@Controller("exam")
export class ExamsService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private http: HttpClient
  ) {}

  @HttpGet("getAllWrongTomaxNotebooks")
  @Produce("string", true)
  getAllWrongTomaxNotebooks() {
    return resultOf<string[]>();
  }

  @HttpGet("getCourseDetailsWithGradeParts/{ownerKeyNumber}")
  @Produce(CourseDetailsWithGradePartsModel, true)
  getCourseDetailsWithGradeParts(
    @FromRoute("ownerKeyNumber") ownerKeyNumber: number
  ) {
    return resultOf<CourseDetailsWithGradePartsModel[]>();
  }

  @HttpPost("getStudentCourseNotebooks")
  @Produce(StudentTestNotebook, true)
  getStudentCourseNotebooks(@FromBody() detailes: StudentDetails) {
    return resultOf<StudentTestNotebook[]>();
  }

  @HttpGet("getTestsForMoedB/{ownerKeyNumber}/{semesterId}/{academicYearId}")
  @Produce(StudentTestModel, true)
  getTestsForMoedB(
    @FromRoute("ownerKeyNumber") ownerKeyNumber: number,
    @FromRoute("semesterId") semesterId: number,
    @FromRoute("academicYearId") academicYearId: number
  ) {
    return resultOf<StudentTestModel[]>();
  }

  @HttpPost("transferTomaxNotebook")
  @Produce(HttpStatusResult)
  transferTomaxNotebook(@FromBody() model: StudentNotebookModel) {
    return resultOf();
  }

  @HttpPost("registerStudentToTest")
  @Produce(HttpStatusResult)
  registerStudentToTest(@FromBody() model: RegistrationTestModel) {
    return resultOf();
  }

  @HttpPost("transferExamNotebook")
  @Produce(HttpStatusResult)
  transferExamNotebook(@FromBody() model: StudentNotebookModel) {
    return resultOf();
  }

  get baseUrl() {
    return `${this.environment.apiServerUrl}/exam/download`;
  }

  public download(fileUrl: string) {
    return this.http.get(`${this.baseUrl}?fileUrl=${fileUrl}`, {
      headers: {
        [ACCESS_CONTROL_ORIGIN_HEADER_NAME]: "*",
        [APPLICATION_URL_HEADER_NAME]: this.environment.applicationUrl,
      },
      observe: "events",
      reportProgress: true,
      withCredentials: true,
      responseType: "blob",
    });
  }
}
