
import { Injectable } from '@angular/core';

import { HttpResultPromise, Controller, isHttpStatusResult } from '../api';

@Injectable({ providedIn: 'root' })
@Controller()
export class Profiler {
  get hasProfile(): boolean {
    let profile=localStorage.getItem('profile');
    if(profile)
      return true;
    return false;
  }

  get profile() {
    return JSON.parse(localStorage.getItem('profile'));
  }

  clear() {
    localStorage.removeItem('profile');
  }

  store(profile: any) {
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  async load<T = any>(loader: () => HttpResultPromise<T>) {
    const profile = await loader();

    if (!isHttpStatusResult(profile)) {
      this.store(profile);
    }

    return profile;
  }
}
