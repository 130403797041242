import { Injectable } from "@angular/core";
import { Subject, Observable, of, throwError } from "rxjs";

import { HostEnvironment } from "../environment";
import {
  ACCESS_CONTROL_ORIGIN_HEADER_NAME,
  ApiClient,
  APPLICATION_URL_HEADER_NAME,
} from "../api-client";
import { UploadModel } from "./upload-model";
import { UploadResult } from "./upload-result";
import { FileFamily, FILE_FAMILIES } from "./file-family";
import {
  HttpStatusResult,
  FileTooLongResult,
  InvalidModelResult,
  NotSupportedFileTypeResult,
  SuccessResult,
  ApiDescriptionProvider,
  HttpMethod,
} from "../api";
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from "@angular/common/http";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class Uploader<TModel extends UploadModel> {
  constructor(
    private httpClient: HttpClient,
    private environment: HostEnvironment
  ) {}

  relativeUrl = "";

  get uploadUrl() {
    return `${this.environment.apiServerUrl}/${this.relativeUrl}`;
  }

  upload<TResult>(
    model: TModel,
    relativeUrl: string = ""
  ): Observable<UploadResult<HttpStatusResult | TResult>> {
    const progress = new Subject<UploadResult<HttpStatusResult | TResult>>();
    const formData = new FormData();
    //const formData2 = new FormData();

    if (relativeUrl != "") this.relativeUrl = relativeUrl;

    if (model.file != null)
      formData.append("file", model.file, model.file.name);

    if (model.file2) formData.append("file2", model.file2, model.file2.name);

    for (let key in model) {
      if (key && model[key] && key != "file" && key != "file2") {
        formData.append(key, model[key]);
      }
    }

    this.httpClient
      .post<TResult>(this.uploadUrl, formData, {
        headers: {
          [ACCESS_CONTROL_ORIGIN_HEADER_NAME]: "*",
          [APPLICATION_URL_HEADER_NAME]: this.environment.applicationUrl,
        },
        observe: "events",
        reportProgress: true,
        withCredentials: true,
      })
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percents = Math.round((100 * event.loaded) / event.total);

            progress.next({
              percents: percents,
              done: false,
            });
          } else {
            if (event instanceof HttpResponse) {
              progress.next({
                percents: 100,
                done: true,
                result: event.body,
              });
            }
          }
        },
        (error) => {
          progress.next({
            percents: 100,
            done: false,
            result: new HttpStatusResult(),
          });
        }
      );
    return progress.asObservable();
  }

  uploadMultupleFiles<TResult>(
    model: TModel[],
    relativeUrl: string = ""
  ): Observable<UploadResult<HttpStatusResult | TResult>> {
    const progress = new Subject<UploadResult<HttpStatusResult | TResult>>();
    const formData = new FormData();

    if (relativeUrl != "") this.relativeUrl = relativeUrl;

    for (let i = 0; i < model.length; i++) {
      for (let key in model[i]) {
        if (key == "file") {
          if (model[i].file == null) {
            formData.append(`lecturerArticles[${i}].file`, null);
          } else {
            formData.append(
              `lecturerArticles[${i}].file`,
              model[i].file,
              model[i].file ? model[i].file?.name : null
            );
          }
        }
        if (
          key &&
          model[i][key] &&
          key != "file" &&
          model[i][key] &&
          key != "partnerCertificationFile"
        ) {
          formData.append(`lecturerArticles[${i}][${key}]`, model[i][key]);
        }
      }
    }

    this.httpClient
      .post<TResult>(this.uploadUrl, formData, {
        headers: {
          [ACCESS_CONTROL_ORIGIN_HEADER_NAME]: "*",
          [APPLICATION_URL_HEADER_NAME]: this.environment.applicationUrl,
        },
        observe: "events",
        reportProgress: true,
        withCredentials: true,
      })
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const percents = Math.round((100 * event.loaded) / event.total);

            progress.next({
              percents: percents,
              done: false,
            });
          } else {
            if (event instanceof HttpResponse) {
              progress.next({
                percents: 100,
                done: true,
                result: event.body,
              });
            }
          }
        },
        (error) => {
          progress.next({
            percents: 100,
            done: false,
            result: new HttpStatusResult(),
          });
        }
      );

    return progress.asObservable();
  }

  validate(
    file: File,
    maxFileSize: number,
    family: FileFamily[]
  ): HttpStatusResult {
    if (file.size === 0) {
      return new InvalidModelResult();
    }

    if (file.size > maxFileSize) {
      return new FileTooLongResult();
    }

    var array = file.name.split(".");
    let extension = array[array.length - 1];

    if (typeof extension === "undefined") {
      return new NotSupportedFileTypeResult();
    }

    extension = `.${extension.toLowerCase()}`;

    let fileExtensions: string[] = [];

    for (let flag of family) {
      const ext = FILE_FAMILIES[flag];

      if (ext) {
        fileExtensions.push(...ext);
      }
    }

    if (
      fileExtensions.length > 0 &&
      fileExtensions.every((x) => x != extension)
    ) {
      return new NotSupportedFileTypeResult();
    }

    return new SuccessResult();
  }
}
