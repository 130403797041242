
<ui-app-layout>
  <ui-side-menu>
    <section class="ui-menu-header">
      <div class="header-row">
        <ui-jct-logo
          *ngIf="!appLayout.isMobile"
          color="white"
          [includePadding]="false"
          [width]="47">
        </ui-jct-logo>

        <span i18n>מערכת הרישום</span>
      </div>

      <div class="header-row">
        <span i18n>המרכז האקדמי לב</span>
      </div>
    </section>

    <ui-menu-item route="/start">
      <ui-menu-content
        icon="edit"
        i18n>
        התחל בתהליך הרישום
      </ui-menu-content>
    </ui-menu-item>

    <ui-menu-item route="/reset-process">
      <ui-menu-content
        icon="lock_open"
        i18n>
        איפוס סיסמה שנשכחה
      </ui-menu-content>
    </ui-menu-item>

    <ui-menu-item route="/calculator">
      <ui-menu-content
        icon="computer"
        i18n>
        מחשבון ציון התאמה
      </ui-menu-content>
    </ui-menu-item>

    <ui-menu-item route="/contact">
      <ui-menu-content
        icon="phone"
        i18n>
        יצירת קשר
      </ui-menu-content>
    </ui-menu-item>

    <mat-divider *ngIf="appLayout.isXSmall && l10n.enabled"></mat-divider>

    <ui-menu-item
      *ngIf="appLayout.isXSmall && l10n.enabled"
      (action)="l10n.openLanguageSelector()">
      <ui-menu-content
        icon="language"
        i18n>
        שנוי שפת תצוגה
      </ui-menu-content>
    </ui-menu-item>
  </ui-side-menu>

  <section ui-toolbar-header class="ui-toolbar-header">
    <ui-jct-logo
      *ngIf="appLayout.isMobile"
      color="white"
      [includePadding]="false"
      [width]="47">
    </ui-jct-logo>

    <span i18n *ngIf="appLayout.isMobile">
      מערכת הרישום
    </span>

    <span *ngIf="!appLayout.isMobile">
      {{ pageTitle?.current }}
    </span>
  </section>

  <section ui-toolbar-actions class="ui-toolbar-actions">
    <language-menu></language-menu>
    <!-- <page-layout-selector></page-layout-selector> -->
  </section>
</ui-app-layout>
