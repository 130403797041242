
<term #tooltip i18n>
  {menuState, select,
    opened {סגירת תפריט}
    closed {פתיחת תפריט}}
</term>

<mat-toolbar>
  <mat-toolbar-row class="primary">
    <button mat-icon-button
      type="button"
      [attr.aria-label]="tooltip.text"
      (click)="menu.toggle()"
      [matTooltip]="tooltip.text">
      <mat-icon>menu</mat-icon>
    </button>

    <ng-content select="[ui-toolbar-header]"></ng-content>
    <ng-content select="[ui-toolbar-actions]"></ng-content>
  </mat-toolbar-row>

  <mat-toolbar-row class="secondary" *ngIf="appLayout.isMobile">
    {{ pageTitle.currentTitle | async}}
  </mat-toolbar-row>
</mat-toolbar>
