
import { ProvideValue, TestDescription, TestBuilders } from '@jct/core';
import { VoucherModel } from '../vouchers';
import { StudentDormitoryModel, DormitoryModel } from './dormitory.types';

export const ID_PROVIDER = TestBuilders.numberBetween(1, 99);
export const DORM_NAME_PROVIDER = TestBuilders.oneOf(['אמרצי', 'כנרת', 'רקפת', 'שושנה', 'בורוז']);
export const COMMENT_PROVIDER = TestBuilders.oneOf(['נא לשבץ ליד החלון', 'אפשר בבקשה מיץ קולה', '']);
export const VOUCHER_IDENTITY_PROVIDER = TestBuilders.identity({ onlyNumbers: true });
export const VOUCHER_PROVIDER = (paid: boolean) => () => (<VoucherModel>{
  voucherIdentity: VOUCHER_IDENTITY_PROVIDER(),
  amount: 1000,
  isPaid: paid,
  isReceiptAvailable: paid,
  components: [{
    amount: 1000,
    description: 'רישום למעונות',
  }],
  numPayments: 3,
});

@TestDescription('מודל הרשמה למעונות לפני חיוב')
export class FakeStudentDormitoryModelBeforePayment extends StudentDormitoryModel {
  @ProvideValue(ID_PROVIDER)
  zdormId: number;
  @ProvideValue(DORM_NAME_PROVIDER)
  dormName: string;
  @ProvideValue(COMMENT_PROVIDER)
  comment: string;
  @ProvideValue(VOUCHER_IDENTITY_PROVIDER)
  voucherIdentity: string;
  paidVoucher?: VoucherModel;
  @ProvideValue(VOUCHER_PROVIDER(false))
  voucherForPayment?: VoucherModel;
}

@TestDescription('מודל הרשמה למעונות אחרי חיוב')
export class FakeStudentDormitoryModelAfterPayment extends StudentDormitoryModel {
  @ProvideValue(ID_PROVIDER)
  dormId: number;
  @ProvideValue(DORM_NAME_PROVIDER)
  dormName: string;
  @ProvideValue(COMMENT_PROVIDER)
  comment: string;
  @ProvideValue(VOUCHER_IDENTITY_PROVIDER)
  voucherIdentity: string;
  @ProvideValue(VOUCHER_PROVIDER(true))
  paidVoucher?: VoucherModel;
  voucherForPayment?: VoucherModel;
}

@TestDescription('קבלת אפשרויות הרשמה למעונות')
export class FakeDormitoryModel extends DormitoryModel {
  @ProvideValue(ID_PROVIDER)
  id: number;
  @ProvideValue(DORM_NAME_PROVIDER)
  dormName: string;
}
