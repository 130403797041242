
interface String {
  trim(): string;
  trim(trimChar: string): string;
  trim(trimChars: string[] | null): string;

  trimEnd(): string;
  trimEnd(trimChar: string): string;
  trimEnd(trimChars: string[] | null): string;

  trimStart(): string;
  trimStart(trimChar: string): string;
  trimStart(trimChars: string[] | null): string;

  toLower(): string;
  toUpper(): string;
}

String.prototype.toLower = function() {
  let text = <string>this.toString();

  return text.toLowerCase();
}

String.prototype.toUpper = function() {
  let text = <string>this.toString();

  return text.toUpperCase();
}

String.prototype.trim = function(trimChar?: string | string[] | null): string {
  let text = <string>this.toString();

  if (trimChar === null) {
    return text;
  }

  if (typeof trimChar === 'undefined') {
    return text.replace(/(^\s*|\s*$)/g, '');
  }

  if (Array.isArray(trimChar)) {
    trimChar.forEach(x => {
      const pattern = new RegExp(`(^(\\${x})*|(\\${x})*$)`, 'g');
      text = text.replace(pattern, '');
    });
  }
  else {
    const pattern = new RegExp(`(^(\\${trimChar})*|(\\${trimChar})*$)`, 'g');
    text = text.replace(pattern, '');
  }

  return text;
}

String.prototype.trimEnd = function(trimChar?: string | string[] | null): string {
  let text = <string>this.toString();

  if (trimChar === null) {
    return text;
  }

  if (typeof trimChar === 'undefined') {
    return text.replace(/\s*$/g, '');
  }

  if (Array.isArray(trimChar)) {
    trimChar.forEach(x => {
      const pattern = new RegExp(`(\\${x})*$`, 'g');
      text = text.replace(pattern, '');
    });
  }
  else {
    const pattern = new RegExp(`(\\${trimChar})*$`, 'g');
    text = text.replace(pattern, '');
  }

  return text;
}

String.prototype.trimStart = function(trimChar?: string | string[] | null): string {
  let text = <string>this.toString();

  if (trimChar === null) {
    return text;
  }

  if (typeof trimChar === 'undefined') {
    return text.replace(/^\s*/g, '');
  }

  if (Array.isArray(trimChar)) {
    trimChar.forEach(x => {
      const pattern = new RegExp(`^(\\${x})*`, 'g');
      text = text.replace(pattern, '');
    });
  }
  else {
    const pattern = new RegExp(`^(\\${trimChar})*`, 'g');
    text = text.replace(pattern, '');
  }

  return text;
}
