<div container>
  <ui-error [state]="loadState"></ui-error>

  <form [formGroup]="form"
    novalidate
    autocomplete="off"
    *visible="loadState.isCompleted"
    (submit)="submit()">

    <mat-form-field>
      <mat-select required
        formControlName="academicStudyTypeId">
        <mat-option [value]="0" i18n>
          {gender, select,
            male {בחר}
            female {בחרי}
          }
          סוג מסלול לימודים
        </mat-option>

        <mat-option *ngFor="let type of academicStudyTypes" [value]="type.academicStudyTypeId">
          {{ type?.academicStudyTypeName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-select required
        formControlName="instituteId">
        <mat-option [value]="0" i18n>
          {gender, select,
            male {בחר}
            female {בחרי}
          }
          מכון
        </mat-option>

        <mat-option *ngFor="let institute of institutes"
          [value]="institute.instituteId">
          {{ institute?.instituteName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <article *visible="hasExplain">
      <h3 i18n>
        לידיעת {gender, select, male {הנרשמים} female {הנרשמות}}
      </h3>

      <p *visible="displayForMechina" i18n>
        <strong>ידוע לי כי לאחר שאתקבל למכינה לאקדמיה באחד מהמסלולים שיתאים לנתוניי אהיה תלמיד מכינה ואינני נחשב סטודנט לתואר מהמניין.</strong><br /><br />
        <strong>מכינת רענון</strong><br>
        מיועדת לזכאי בגרות העומדים בתנאי הקבלה לאקדמיה למעט מתמטיקה.
      </p>

      <p *visible="displayForMaleMechina" i18n>
        <strong>מכינת "חיילים שנת משימה"</strong><br>
        <sub>
        מיועדת לחיילים הנמצאים במסגרת שנת משימה בשירותם הצבאי.
       </sub>
      </p>

      <p *visible="displayForMaleMechina" i18n>
        <strong>מכינה ייעודית סמיסטריאלית להנדסה</strong><br>
        מיועדת למעונינים בלימודי הנדסה עם בגרות מלאה או שחסרות להם עד 5 יח"ל.
      </p>

      <!-- <p *visible="displayForFemaleFirstDegree" i18n>
        <strong>חוג סיעוד</strong><br>
        <b>מועמדת יקרה, עקב הביקוש הגבוה לחוג לסיעוד, הרישום הינו על בסיס מקום פנוי. ייתכן והרשמתך תעבור לשנת הלימודים הבאה.</b>
        <br>
        המרכז האקדמי לב שומר לעצמו את הזכות עקב אילוצים שונים, לשבץ את הנרשמות לכיתת סיעוד באחד מהקמפוסים השונים שבה מתקיימת התכנית.<br>
      </p> -->

      <p *visible="displayForMaharTalFirstDegree" i18n>
        <strong>מח"ר - טל תבונה</strong><br>
        פתיחת כיתה בחוג למחשבים ב"מח"ר- טל תבונה" מותנית במספר מינימאלי של תלמידות  בכיתה.
        במידה ולא תיפתח כיתה הלימודים יתקיימו בקמפוס טל.
        פתיחת כיתה בחוג להשלמה לתואר במדעי המחשב- מותנית במספר מינימאלי של בנות בכיתה.
      </p>

      <p *visible="displayForMaharTalAndLustigFirstDegree" i18n>
        <strong>השלמה להנדסאיות לתואר ראשון במדעי המחשב, קמפוס לוסטיג/מח"ר - טל תבונה</strong><br>
        פתיחת הכיתה מותנית במספר סטודנטיות מינימאלי.
      </p>

      <p *visible="displayForTalFirstDegree" i18n>
        <strong>תוכנית רוקחות</strong><br>
        התכנית לרוקחות הנה שיתוף פעולה של המרכז האקדמי לב  והאוניברסיטה העברית.<br>
        המרכז האקדמי לב איננו  הגוף האקדמי המעניק את התואר ברוקחות אלא האוניברסיטה העברית.<br>
        הקורסים המתקיימים במרכז האקדמי לב, נלמדים במשך שנתיים אקדמיות (ראשונות)  כצבירת קורסים,<br>
        ובסיומם יש להירשם ולהגיש מועמדות לאוניברסיטה העברית, למעבר מלא ללימודי המשך לתואר ברוקחות.<br>
        למרכז האקדמי לב אין כל אחריות והשפעה  על הקבלה לאוניברסיטה העברית ו/או על זמן הלימודים באוניברסיטה העברית, היקפם, תוכנם ואיפיונם  או על שלל הנהלים הנהוגים באוניברסיטה העברית.
      </p>

      <p *visible="displayForTalSecondDegree" i18n>
        <strong>תואר שני בסיעוד MSN, קמפוס טל</strong><br>
        פתיחת הכיתה וקיום הלימודים מותנים באישור סופי של המל"ג וכן במספר הנרשמות.<br><br>
        <strong>תואר שני בכריית מידע <strong dir="ltr"> M.Sc.</strong>, קמפוס טל</strong><br>
        פתיחת הכיתה וקיום הלימודים מותנים באישור סופי של המל"ג וכן במספר הנרשמות.<br><br>
        <strong>תואר שני במידענות טכנולוגית בבריאות <strong dir="ltr"> M.H.</strong>, קמפוס טל</strong><br>
        פתיחת הכיתה וקיום הלימודים מותנים באישור סופי של המל"ג וכן במספר הנרשמות.
      </p>

      <p *visible="displayForLustigCompletionYear" i18n>
        <strong>שנת השלמה בחשבונאות בקמפוס לוסטיג</strong><br>
        פתיחת וקיום הלימודים בשנת השלמה בחשבונאות בקמפוס לוסטיג מותנים במספר מינימאלי של סטודנטיות בכיתה.
      </p>

      <p *visible="displayForLustigFirstDegree" i18n>
        <strong>ביואינפורמטיקה בקמפוס לוסטיג</strong><br>
        פתיחת הלימודים בפועל  מותנית במספר סטודנטיות בכיתה.  כמו כן,  הלימודים בשנה ג' יתקיימו בקמפוס לוסטיג ברמת גן,  בתנאי שיהיו 25 סטודנטיות בפועל בכיתה. במידה והמספר יהיה נמוך מזה הסטודנטיות יעברו ללמוד בשנה ג' בקמפוס טל בירושלים.
      </p>

      <p *visible="displayForLevSecondDegree" i18n>
        <strong>תואר שני MSc קמפוס לב</strong><br>
        הענקת התואר מותנית באישור סופי של המל"ג.<br>
        פתיחת הכיתה וקיום הלימודים מותנים במספר מינימאלי של סטודנטים בכיתה.
      </p>
    </article>

    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        #dataTable
        [dataSource]="dataSource"
        formArrayName="studyTracks"
        [ui-column-width]="[220,250,140,160]">

        <ng-container matColumnDef="departmentId">
          <th mat-header-cell *matHeaderCellDef i18n>
            מחלקה
          </th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <mat-form-field>
              <mat-select required
                formControlName="departmentId">
                <mat-option [value]="0" i18n>
                  {gender, select, male {בחר} female {בחרי}}
                  מחלקה
                </mat-option>

                <mat-option *ngFor="let department of row?.value?.departments"
                  [value]="department.departmentId">
                  {{ department?.departmentName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="trackId">
          <th mat-header-cell *matHeaderCellDef i18n>
            חוג
          </th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <mat-form-field>
              <mat-select required
                formControlName="trackId">
                <mat-option [value]="0" i18n>
                  {gender, select, male {בחר} female {בחרי}}
                  חוג
                </mat-option>

                <mat-option
                  *ngFor="let track of row?.value?.tracks"
                  [value]="track.trackId">
                  {{ track?.trackName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="academicYear">
          <th mat-header-cell *matHeaderCellDef i18n>
            שנה אקדמית
          </th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <mat-form-field>
              <mat-select required
                formControlName="academicYear">
                <mat-option [value]="0" i18n>
                  {gender, select, male {בחר} female {בחרי}}
                  שנה אקדמית
                </mat-option>

                <mat-option
                  *ngFor="let academicYear of row?.value?.academicYears"
                  [value]="academicYear.academicYear"
                >
                  {{ academicYear?.academicYearTitle }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <button mat-icon-button
              i18n-matTooltip
              type="button"
              matTooltip="עדיפות נמוכה"
              (click)="moveDown(index)"
              [disabled]="!canMoveDown(index)">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

            <button mat-icon-button
              i18n-matTooltip
              type="button"
              matTooltip="עדיפות גבוהה"
              (click)="moveUp(index)"
              [disabled]="!canMoveUp(index)">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>

            <button mat-icon-button
              type="button"
              [matTooltip]="deleteTooltip.text"
              (click)="removeRow(index)"
              [disabled]="!canRemoveRow(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      </table>
    </div>

    <ui-alert type="info" *visible="hasDifferences">
      <h3 ui-header i18n>
        שינוי שנת רישום
      </h3>

      <p ui-body i18n>
        {gender, select,
          male {
            הנך הולך לשנות את שנת הלימודים,
            בעקבות זאת תדרש להצהיר על כל ההצהרות מחדש וכן להעלות מסמכים מחדש.}
          female {
            הנך הולכת לשנות את שנת הלימודים,
            בעקבות זאת תדרשי להצהיר על כל ההצהרות מחדש וכן להעלות מסמכים מחדש.
          }
        }
      </p>
    </ui-alert>

    <ui-alert type="warning" *visible="hasConflict">
      <h3 ui-header i18n>
        התנגשות בבחירת מסלול לימודים
      </h3>

      <p ui-body i18n>
        ניתן לבחור מסלולי לימודים רק משנה אחת בלבד.
      </p>
    </ui-alert>

    <ui-alert type="warning" *visible="hasInvalidTrack">
      <h3 ui-header i18n>
        לא ניתן לבחור מסלול לימודים זה
      </h3>

      <p ui-body i18n>
        המסלול הלימודים הנבחר לא קיים בשנה שבחרת
      </p>
    </ui-alert>

    <ui-alert type="warning" *visible="showError && isSubmitOnce">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngIf="errorList?.length > 0; else generalError">
          <ng-container *ngFor="let error of errorList">
            <li *ngIf="error == 'trackId-required'" i18n>
              חובה לבחור חוג
            </li>
            <li *ngIf="error == 'academicYear-required'" i18n>
              חובה לבחור שנה אקדמית
            </li>
          </ng-container>
        </ng-container>

        <ng-template #generalError>
          <li i18n>
            חובה לבחור לפחות מסלול לימודים אחד
          </li>
        </ng-template>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [state]="saveState"
      [disabled]="hasConflict || hasInvalidTrack"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term #deleteTooltip
  i18n>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>
