
import { Component, ElementRef, AfterContentInit, HostBinding, ChangeDetectorRef } from '@angular/core';
import { domAccessor } from '@jct/ui/lib/services';

@Component({
  selector: 'ui-snack-bar-action',
  template: '<ng-content></ng-content>',
  exportAs: 'uiSnackBarAction',
})
export class SnackBarActionComponent implements AfterContentInit {
  private _content = '';

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef)
  { }

  ngAfterContentInit() {
    setTimeout(() => {
      this._content = domAccessor(this.elementRef).text();
      this.cd.markForCheck();
    }, 10);
  }

  get content() {
    return this._content;
  }

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() {
    return true;
  }

  @HostBinding('attr.aria-hidden')
  get ariaHidden() {
    return true;
  }
}
