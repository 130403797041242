
<div container>

  <ui-error [state]="loadState"></ui-error>

  <form [formGroup]="form"
    *visible="loadState.isCompleted"
    autocomplete="off">
    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        formArrayName="diplomas"
        [ui-column-width]="[250,100,100,50]">

        <ng-container matColumnDef="courseName">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            שם קורס
          </th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <mat-form-field floatLabel="never" *ngIf="row.value">
              <input type="text" matInput
                autocomplete="off"
                [placeholder]="courseNamePlaceholder.text"
                formControlName="courseName">
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="credits">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            נקודות זכות
          </th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <mat-form-field floatLabel="never" *ngIf="row.value" >
              <input type="number" matInput
                i18n-placeholder
                placeholder="נקודות זכות"
                formControlName="credits"
                autocomplete="off">
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef
            i18n>
            סה"כ: {{ creditsCount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="grade">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            ציון
          </th>

          <td mat-cell *matCellDef="let row; let index=index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="number" matInput
                i18n-placeholder
                placeholder="ציון"
                formControlName="grade"
                autocomplete="off">
            </mat-form-field>
          </td>

          <td mat-footer-cell *matFooterCellDef
            i18n>
            ממוצע: {{ average | number:'1.0-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row ; let index = index" [formGroupName]="index">
            <button mat-icon-button
              type="button"
              [matTooltip]="deleteButtonTooltip.text"
              (click)="removeDiplomaCourse(index)"
              *visible="canRemoveDiplomaCourse(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>

          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </div>


    <ui-alert type="warning" *visible="showError && errorList.length > 0">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngFor='let error of errorList'>
          <li *ngIf="error == 'credits-required'" i18n>
            חובה להזין מספר נקודות זכות
          </li>
          <li *ngIf="error == 'credits-min'" i18n>
            מספר נקודות הזכות קטן מידי, נא הזן מספר גדול מ-0
          </li>
          <li *ngIf="error == 'credits-max'" i18n>
            מספר נקודות הזכות שהזנת גדול מדי
          </li>
          <li *ngIf="error == 'grade-required'" i18n>
            חובה להזין ציון
          </li>
          <li *ngIf="error == 'grade-min'" i18n>
            הציון שהזנת אינו תקין, נא הזן ציון גדול או 0
          </li>
          <li *ngIf="error == 'grade-max'" i18n>
            נא הזן ציון נמוך או שווה 100
          </li>
        </ng-container>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [state]="saveState"
      (progress)="saveAsync()"
      i18n>
        שמור והמשך
    </ui-progress-button>
  </form>
</div>

<term #courseNamePlaceholder
  i18n>
  {gender, select, male {הזן} female {הזיני}}
  שם קורס
</term>

<term #deleteButtonTooltip
  i18n>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>
