import {
  Input,
  Optional,
  Self,
  Injector,
  Component,
  ViewEncapsulation,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";

import { CommonService } from "@jct/api";
import { AutocompleteFieldComponent } from "@jct/ui";
import { HttpStatusResult } from "@jct/core";

@Component({
  selector: "street-field",
  templateUrl: "./street-field.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteFieldComponent,
    },
  ],
})
export class StreetFieldComponent extends AutocompleteFieldComponent {
  @Input()
  cityName: string;

  constructor(
    injector: Injector,
    @Optional() @Self() ngControl: NgControl,
    private commonService: CommonService
  ) {
    super(injector, ngControl);
  }

  async callApiAsync(query: string): Promise<string[]> {
    if (this.cityName && this.cityName.trim().length > 0) {
      let result = await this.commonService.searchStreetNameAsync(
        this.cityName,
        query
      );

      if (result instanceof HttpStatusResult) {
        return [];
      }

      return result;
    }

    return [];
  }
}
