import { RequestOptions } from '../api-client';
import { HttpResultPromise } from './http-status-result';

export enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Post = 'POST',
  Delete = 'DELETE',
  Head = 'HEAD',
  Patch = 'PATCH',
}

export enum ParameterPlacement {
  QueryString = 'queryString',
  Route = 'route',
  Body = 'body',
}

export enum DescriptorType {
  Action = 'action',
  Controller = 'controller',
  Route = 'route',
  Class = 'class',
  Field = 'field',
}

export interface RangeMetadata {
  min: number;
  max: number;
}

export enum FieldTypes {
  Integer = 'integer',
  String = 'string',
  Decimal = 'decimal',
  Boolean = 'boolean',
  DateTime = 'date-time',
  Date = 'date',
  Time = 'time',
  ArrayOfStrings = 'array-of-strings',
  ArrayOfIntegers = 'array-of-integers'
}

export type UrlBuilder = (...args: any[]) => string;
export type BodyBuilder = (...args: any[]) => any;
export type ActionMethodBuilder = (
  urlBuilder: UrlBuilder,
  options?: RequestOptions,
  bodyBuilder?: BodyBuilder) => (...args: any[]) => HttpResultPromise;
