import { Component, Input, ViewEncapsulation } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { AppLayoutService } from "@jct/ui";

export interface FormDebuggerSettings {
  displayModel?: boolean;
  displayState?: boolean;
}

@Component({
  selector: "form-debugger-toolbar",
  templateUrl: "./form-debugger-toolbar.html",
  styleUrls: ["./form-debugger-toolbar.scss"],
  exportAs: "formDebuggerToolbar",
  encapsulation: ViewEncapsulation.None,
})
export class FormDebuggerToolbar {
  constructor(public appLayout: AppLayoutService) {}

  @Input()
  form: UntypedFormGroup;

  storeSettings(settings: FormDebuggerSettings) {
    localStorage.setItem("formDebugger", JSON.stringify(settings));
  }

  getSettings(): FormDebuggerSettings {
    return <FormDebuggerSettings>JSON.parse(localStorage.getItem('formDebugger'));
  }

  set displayModel(value: boolean) {
    const settings = this.getSettings();

    this.storeSettings({
      ...settings,
      displayModel: value,
    });
  }
  get displayModel() {
    const settings = this.getSettings();

    return settings?.displayModel || false;
  }

  set displayState(value: boolean) {
    const settings = this.getSettings();

    this.storeSettings({
      ...settings,
      displayState: value,
    });
  }
  get displayState() {
    const settings = this.getSettings();

    return settings?.displayState || false;
  }

  get formValue() {
    return JSON.stringify(this.form.value, null, 2);
  }

  get formState() {
    const {
      dirty,
      disabled,
      enabled,
      controls,
      invalid,
      pending,
      parent,
      pristine,
      status,
      touched,
      untouched,
      updateOn,
      valid,
    } = this.form;

    const errors = {};

    for (let key in controls) {
      let control = controls[key];

      if (control.errors) {
        errors[key] = control.errors;
      }
    }

    const state = {
      dirty,
      disabled,
      enabled,
      errors,
      invalid,
      pending,
      parent,
      pristine,
      status,
      touched,
      untouched,
      updateOn,
      valid,
    };

    return JSON.stringify(state, null, 2);
  }
}
