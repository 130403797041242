
<ui-error [state]="loadState"></ui-error>

<section *visible="loadState.isCompleted">
  <dormitory-registration
    *visible="!hasDormitory"
    [dormitoryOptions]="dormitoryOptions"
    (registered)="reloadStudentDormitory($event)">
  </dormitory-registration>

  <student-dormitory
    *visible="hasDormitory"
    [studentDormitory]="studentDormitory">
  </student-dormitory>
</section>
