
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { IdentifyForm } from './+identify';
import { GeneratePage } from './+generate';
import { GetResetLinkPage } from './+get-reset-link';

export const RESET_MODULE_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'identify',
    pathMatch: 'full'
  },
  {
    path: 'identify',
    component: IdentifyForm,
  },
  {
    path: 'get-reset-link',
    component: GetResetLinkPage,
  },
  {
    path: 'generate',
    component: GeneratePage,
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  declarations: [
    IdentifyForm,
    GetResetLinkPage,
    GeneratePage,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
  ],
})
export class ResetProcessModule { }
