
import { TimeSpan } from '../system';
import { ListItem } from './types';

export function distinct(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export function distinctArray<T>(array: T[], idField: string, nameField: string, filterFn?: (model: T) => boolean) {
  return filterFn
    ? array.filter(filterFn)
      .map(x => x[idField])
      .filter(distinct)
      .map(x => <ListItem<number>>{
        value: x,
        text: array.find(y => y[idField] == x)[nameField]
      })
    : array.map(x => x[idField])
      .filter(distinct)
      .map(x => <ListItem<number>>{
        value: x,
        text: array.find(y => y[idField] == x)[nameField]
      });
}

export function fromPeriodString(period: string) {
  let timeSpan = 0;
  let parts = period.split(' ');

  if (parts.length > 0) {
    for (let part of parts) {
      if (part.endsWith('s')) {
        let seconds = part.substr(0, part.length - 1);
        let value = parseInt(seconds);

        if (!isNaN(value)) {
          timeSpan += value * 1000;
        }
      }
      else if (part.endsWith('m')) {
        let minutes = part.substr(0, part.length - 1);
        let value = parseInt(minutes);

        if (!isNaN(value)) {
          timeSpan += value * 1000 * 60;
        }
      }
      else if (part.endsWith('h')) {
        let hours = part.substr(0, part.length - 1);
        let value = parseInt(hours);

        if (!isNaN(value)) {
          timeSpan += value * 1000 * 60 * 60;
        }
      }
      else if (part.endsWith('d')) {
        let days = part.substr(0, part.length - 1);
        let value = parseInt(days);

        if (!isNaN(value)) {
          timeSpan += value * 1000 * 60 * 60 * 24;
        }
      }
    }
  }

  return timeSpan;
}

export const randomNumberGenerator = () => {
  return Math.random() * Date.now() * Math.pow(10, 8);
};

export function token(size: number) {
  let result = '';

  const length = parseInt('' + (size / 10));

  if (length > 0) {
    for (let index = 1; index <= length; index++) {
      result += randomNumberGenerator().toString(16).substr(0, 10);
    }
  }
  else {
    result = randomNumberGenerator().toString(16).substr(0, 10);
  }

  return result.toLowerCase().substr(0, size);
}

export function isValidIdNumber(idNumber: string) {
  if (idNumber.length > 9 ||
    idNumber.length < 5) {
    return false;
  }

  if (!/\d+/g.test(idNumber))	{
    return false;
  }

  idNumber = idNumber.padEnd(9, '0');

  let sum = 0;

  for (let index = 0; index < 9; index++) {
    let value = idNumber.charCodeAt(index) - 48;

    value *= (index % 2) + 1;

    if (value > 9)
    {
      value -= 9;
    }

    sum += value;
  }

  return sum % 10 == 0;
}

export function generateIdNumber() {
  let nearestId = Math.round(Math.random() * 899_999_999 + 100_000_000);
  const ends = nearestId + 99;

  while (!isValidIdNumber(''+nearestId)) {
    nearestId++;

    if (nearestId > ends) {
      break;
    }
  }

  return nearestId;
}

export function guid() {
  let result = token(32);

  return result.substr(0, 8) + '-' +
    result.substr(8, 4) + '-' +
    result.substr(12, 4) + '-' +
    result.substr(16, 4) + '-' +
    result.substr(20);
}

export function randomize(from: number, to?: number): number {
  if (typeof to === 'undefined') {
    return Math.round(Math.random() * from);
  }

  let delta = to - from;

  return Math.round(Math.random() * delta + from);
}

export interface TokenBuilderOptions {
  size?: number;
  onlyNumbers?: boolean;
  onlyLetters?: boolean;
}

export namespace TestBuilders {
  export const tokenized = (size: number) => () => token(size);

  export const dateBetween = (from: Date, to: Date) =>
    () => new Date(randomize(from.getTime(), to.getTime())).toJSON();

  export const timeBetween = (from: string, to: string) => () => {
    const _from = TimeSpan.parse(from);
    const _to = TimeSpan.parse(to);

    const _ticks = randomize(_from.ticks, _to.ticks);

    return TimeSpan.fromTicks(_ticks).toString('hh:mm:ss');
  };

  export const oneOf = <T = any>(value: T[]) =>
    () => value[randomize(0, value.length - 1)];

  export const manyOf = <T = any>(value: T[], count: number) =>
    () => {
      const arr = value.map(x => x);
      const result: T[] = [];

      for (let index = 0; index < count; index++) {
        const idx = randomize(0, arr.length - 1);
        result.push(arr[idx]);
        arr.splice(idx, 1);
      }

      return result;
    };

  export const phoneNumber = (prefixes: string[], length: number) =>
    () => {
      const from = Math.pow(10, length - 1);
      const to = Math.pow(10, length - 1) * 10 - 1;

      return prefixes[randomize(0, prefixes.length - 1)] + randomize(from, to);
    }

  export const numberBetween = (from: number, to: number) =>
    () => randomize(from, to);

  export const minutesFromNow = (minutes: number) =>
    () => new Date().addMinutes(minutes).toJSON();

  export const identity = (options: TokenBuilderOptions) =>
    () => {
      const actualSize = options.size || 10;
      let result = token(actualSize * 2);

      if (options.onlyLetters) {
        return result.replace(/\W*/ig, '').substring(0, actualSize);
      }

      if (options.onlyNumbers) {
        return result.replace(/\D*/g, '').substring(0, actualSize);
      }

      return result.substring(0, actualSize);
    };
}
