<div container *ngIf="paymentType">
  <meta-title i18n> התשלום נכשל </meta-title>

  <section *ngIf="paymentType === 'standing-order'">
    <h2 i18n>ההוראת קבע נכשלה</h2>
  </section>

  <section *ngIf="paymentType != 'standing-order'">
    <h2 i18n>התשלום נכשל</h2>
  </section>

  <p style="font-size: 1.2rem; line-height: 2.5rem">
    <span i18n>מזהה עסקה</span><br />

    <span>
      {{ transactionId }}
    </span>
  </p>

  <p style="font-size: 1.2rem; line-height: 2.5rem">
    <span i18n>קוד שגיאה {{ errorCode }} : {{ errorText }}.</span
    ><br />
  </p>

  <button mat-raised-button color="primary" (click)="goBack()" i18n>
    חזרה לעמוד הקודם
  </button>
</div>
