import { ProvideValue, TestBuilders, TestDescription } from '@jct/core';
import { Gender } from '@jct/localization';
import { AcademicStudyTypes } from '../study-track/study-track.types';
import { RegistrationProfile, RegistrationStages, RegistrationStates } from './registration.types';

const STUDENT_ID = TestBuilders.numberBetween(1000, 10000);
const ACADEMIC_YEAR = TestBuilders.numberBetween(5780, 5782);
const STUDENT_YEARLY_REGISTRATION_ID = TestBuilders.numberBetween(10000, 30000);
const ACADEMIC_STUDY_TYPE = TestBuilders.oneOf([
  AcademicStudyTypes.CompletionYear,
  AcademicStudyTypes.Diploma,
  AcademicStudyTypes.FirstDegree,
  AcademicStudyTypes.Mechina,
  AcademicStudyTypes.SecondDegree,
]);

@TestDescription('משתמש חדש')
export class NewUserRegistrationProfile extends RegistrationProfile {
  @ProvideValue(RegistrationStates.NewStudent)
  state?: RegistrationStates;
}

@TestDescription('משתמש קיים - גבר בשלב א')
export class MaleStudentRegistrationProfile extends RegistrationProfile {
  gender = Gender.Male;
  @ProvideValue(ACADEMIC_STUDY_TYPE)
  academicStudyType?: AcademicStudyTypes;
  @ProvideValue(RegistrationStages.פרטי_קרובים)
  currentStage?: RegistrationStages;
  @ProvideValue(RegistrationStates.NewStudent)
  state?: RegistrationStates;
}

@TestDescription('משתמשת קיימת - אישה בשלב א')
export class FemaleStudentRegistrationProfile extends RegistrationProfile {
  gender = Gender.Female;
  @ProvideValue(ACADEMIC_STUDY_TYPE)
  academicStudyType?: AcademicStudyTypes;
  @ProvideValue(RegistrationStages.פרטי_קרובים)
  currentStage?: RegistrationStages;
  @ProvideValue(RegistrationStates.NewStudent)
  state?: RegistrationStates;
}

@TestDescription('משתמש קיים - גבר בשלב ב')
export class MaleStudentStageBRegistrationProfile extends RegistrationProfile {
  gender = Gender.Male;
  @ProvideValue(ACADEMIC_STUDY_TYPE)
  academicStudyType?: AcademicStudyTypes;
  @ProvideValue(RegistrationStages.תשלום)
  currentStage?: RegistrationStages;
  @ProvideValue(RegistrationStates.NewStudent)
  state?: RegistrationStates;
  isAccepted = true;
}

@TestDescription('משתמשת קיימת - אישה בשלב ב')
export class FemaleStudentStageBRegistrationProfile extends RegistrationProfile {
  gender = Gender.Male;
  @ProvideValue(ACADEMIC_STUDY_TYPE)
  academicStudyType?: AcademicStudyTypes;
  @ProvideValue(RegistrationStages.תשלום)
  currentStage?: RegistrationStages;
  @ProvideValue(RegistrationStates.NewStudent)
  state?: RegistrationStates;
  isAccepted = true;
}
