
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from '@jct/api';
import { HttpStatusResult } from '@jct/core';

import { StateFactory } from '@jct/ui';

@Component({
  selector: 'confirm.page',
  templateUrl: './confirm.page.html',
})
export class ConfirmPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private router: Router,
    private route: ActivatedRoute,
    private accountService:AccountService)
    { }

    loadState = this.sf.create();
    ownerKeyNumber: number;
    emailAddress: string;
    showEmailAddress: Boolean = false;

  async ngOnInit() {
    await this.loadState.inProcess();

    this.route.queryParams.subscribe(async params => {
      this.ownerKeyNumber = params['key'];
      if (!params['state']) {
        this.loadState.quit();
        this.router.navigate(['/start/identify']);
      }
      else {
        const result = await this.accountService.getUserEmail(this.ownerKeyNumber);

        if (result instanceof HttpStatusResult) {
          this.loadState.completed();
          return;
        }
        else {
          this.emailAddress = result;
          this.showEmailAddress = true;
          this.loadState.completed(500);
        }
      }
    });
  }
}
