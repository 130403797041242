
import { Component } from '@angular/core';
import { PageLayout } from '@jct/localization';

@Component({
  selector: 'previous-education.page',
  templateUrl: './previous-education-page.component.html',
})
export class PreviousEducationPageComponent {
  constructor(public pageLayout: PageLayout) {}
}
