import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { TestCaseDescriptor } from '@jct/core';
import { PageLayout } from '@jct/localization';
import { SelectTestCasesDialogData } from '../common';

@Component({
  selector: 'select-test-cases-dialog',
  templateUrl: 'select-test-cases-dialog.html',
  styleUrls: ['./select-test-cases-dialog.scss'],
})
export class SelectTestCasesDialog {
  constructor(
    public pageLayout: PageLayout,
    public dialogRef: MatDialogRef<SelectTestCasesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SelectTestCasesDialogData)
  {
    this.descriptors = data.descriptors;
  }

  descriptors: TestCaseDescriptor[];

  hasType(descriptor: TestCaseDescriptor) {
    const name = descriptor.classType?.name;

    return name?.length > 0 && name.toLowerCase() !== 't';
  }

  select(descriptor: TestCaseDescriptor): void {
    this.dialogRef.close(descriptor);
  }
}
