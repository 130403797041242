
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { CacheInterceptorProvider, CacheService, ClearCachePage } from './cache';
import { Connectivity, ConnectivityInterceptorProvider } from './connectivity';
import { GuardDevTools } from './security';
import { Uploader } from './uploader';
import { HasErrorPipe, GetValuePipe, IsValuePipe, IsEmptyPipe, IsNotEmptyPipe, GetErrorPipe, AnyPipe, SelectPipe, FirstPipe, LastPipe, SearchFilterPipe, SafePipe } from './forms';
import { SINGLETON_SERVICE } from './common';
import { ApplicationProvider } from './application';
import { Authenticator, AuthenticatorInterceptorProvider } from './authenticator';
import { Profiler } from './profiler';
import { META_COMPONENTS, META_SERVICES } from './meta';
import { ApiClient, ApiClientImpl, HttpHandler, HttpHandlerImpl, StateInterceptorProvider, BasicHttpInterceptorProvider } from './api-client';
import { LoggerInterceptorProvider } from './logging';
import { ProgressIndicator, ProgressIndicatorInterceptorProvider } from './progress-indicator';
import { SemesterPipe } from './common/semester.pipe';

const EXPORTED_SERVICES = [
  ApplicationProvider,
  {
    provide: ApiClient,
    useClass: ApiClientImpl,
  },
  {
    provide: HttpHandler,
    useClass: HttpHandlerImpl,
  },
  ...META_SERVICES,
  {
    provide: SINGLETON_SERVICE,
    useClass: GuardDevTools,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: ProgressIndicator,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: CacheService,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: Connectivity,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: Authenticator,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: Profiler,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: Uploader,
    multi: false,
  },
  BasicHttpInterceptorProvider,
  StateInterceptorProvider,
  LoggerInterceptorProvider,
  ConnectivityInterceptorProvider,
  ProgressIndicatorInterceptorProvider,
  CacheInterceptorProvider,
  AuthenticatorInterceptorProvider,
];

export const EXPORTED_PIPES = [
  GetValuePipe,
  HasErrorPipe,
  SearchFilterPipe,
  IsValuePipe,
  IsEmptyPipe,
  IsNotEmptyPipe,
  GetErrorPipe,
  AnyPipe,
  SelectPipe,
  FirstPipe,
  LastPipe,
  SafePipe,
  SemesterPipe
];

export const EXPORTED_COMPONENTS = [
  ...META_COMPONENTS,
  ClearCachePage,
];

@NgModule({
  providers: [EXPORTED_SERVICES],
  declarations: [...EXPORTED_PIPES, ...EXPORTED_COMPONENTS],
  imports: [
    HttpClientModule,
  ],
  exports: [...EXPORTED_PIPES, ...EXPORTED_COMPONENTS],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
      return {
          ngModule: CoreModule,
          providers: [EXPORTED_SERVICES],
      };
  }
}
