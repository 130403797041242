
<mat-card [ngClass]="notificationClass">
  <mat-card-content>
    <mat-icon class="close-button" *ngIf="displayCloseButton" (click)="close()">close</mat-icon>

    <div class="notification-content">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>
