// Types

export enum AuthenticatorStates {
  ShouldVerify = "shouldVerify",
  ShouldRegister = "shouldRegister",
  ShouldConfirmByEmail = "shouldConfirmByEmail",
  ShouldConfigureTwoFactorAuthentication = "shouldConfigureTwoFactorAuthentication",
  ShouldValidateTwoFactorAuthentication = "shouldValidateTwoFactorAuthentication",
  ConfigureTwoFactorAuthentication = "configureTwoFactorAuthentication",
  LoggedIn = "loggedIn",
  LoggedOut = "loggedOut",
}

export enum Domains {
  ADMIN,
  ACAD,
}

export enum IdentifyMethod {
  IdNumber = "idNumber",
  Passport = "passport",
  Username = "username",
}

export class AuthenticatorState {
  state: AuthenticatorStates;
  token?: string;
  qrCode?: string;
  tokenLifeTime?: string;
  tokenExpiredOn?: string;
}

export class GeneratePasswordState {
  newGeneratedPassword: string;
  canLogin: boolean;
  tokenLifeTime?: string;
  tokenExpiredOn?: string;
}

// Models

export class ChangePasswordModel {
  userId: number;
  oldPassword: string;
  newPassword: string;
}

export class ConfigureTfaModel {
  token: string;
}

export class IdentifyModel {
  passwordGeneratorRelativePath: string;
  key: string;
  method: IdentifyMethod;
}

export class CreateAccountModel extends IdentifyModel {
  email: string;
}

export class GeneratePasswordModel {
  token: string;
}

export class LoginModel {
  username: string;
  password: string;
  domain?: Domains;
}

export class RegisterModel {
  passwordGeneratorRelativePath: string;
  email: string;
  token: string;
}

export class ResetPasswordModel extends IdentifyModel {}

export class ValidateTfaModel {
  token: string;
  code: string;
}

export class VerifyModel {
  password: string;
  token: string;
}
