
import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'hasError',
  pure: false
})
export class HasErrorPipe implements PipeTransform {
  transform(form: UntypedFormGroup, fieldName: string, errorType: string = 'required'): boolean {
    let field = form.get(fieldName);

    if (field && field.errors) {
      return field.hasError(errorType) && field.touched;
    }

    return false;
  }
}

@Pipe({
  name: 'any',
  pure: false
})
export class AnyPipe implements PipeTransform {
  transform(object: Object|any[]|null): boolean {
    if (!object) {
      return false;
    }

    if (Array.isArray(object)) {
      return object?.length > 0;
    }

    if (typeof object === 'object') {
      return Object.keys(object).length > 0
    }

    return false;
  }
}

@Pipe({
  name: 'select',
  pure: false
})
export class SelectPipe implements PipeTransform {
  transform(object: Object|null, propertyName: string): any {
    if (object && object[propertyName]) {
      return object[propertyName];
    }

    return null;
  }
}

@Pipe({
  name: 'first',
  pure: false
})
export class FirstPipe implements PipeTransform {
  transform(object: Object|any[]|null): any|null {
    if (!object) {
      return false;
    }

    if (Array.isArray(object) && object.length > 0) {
      return object[0];
    }

    if (typeof object === 'object') {
      const keys = Object.keys(object);

      if (keys.length > 0) {
        return keys[0];
      }
    }

    return null;
  }
}

@Pipe({
  name: 'last',
  pure: false
})
export class LastPipe implements PipeTransform {
  transform(object: Object|any[]|null): any|null {
    if (!object) {
      return false;
    }

    if (Array.isArray(object) && object.length > 0) {
      return object[object.length - 1];
    }

    if (typeof object === 'object') {
      const keys = Object.keys(object);

      if (keys.length > 0) {
        return keys[keys.length - 1];
      }
    }

    return null;
  }
}
