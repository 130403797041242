
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PUBLIC_ROUTES } from './public';

export const ADMIN_APP_ROUTES: Routes = [
  {
    path:'admin',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      ...PUBLIC_ROUTES,
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ADMIN_APP_ROUTES, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule]
})
export class AdminAppRoutingModule { }
