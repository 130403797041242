
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { StateFactory } from '@jct/ui';
import { Gender } from '@jct/localization';

import {
  StatementsService,
  ProfileService,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
  RegistrationUserProfile,
} from '@jct/api';
import { HttpStatusResult } from '@jct/core';

@Component({
  selector: 'criminal-record-tab.page',
  templateUrl: './criminal-record-tab.component.html',
})

export class CriminalRecordTabComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private statementsService: StatementsService,
    private registrationNavigator: RegistrationNavigator,
    private userProfile: RegistrationUserProfile,
    private profileService: ProfileService)
  { }

  @ViewChild('criminalform') criminalform: ElementRef;

  loadState = this.sf.create();
  saveState = this.sf.create();

  showError = false;

  form = this.fb.group({
    hasCriminalRecord: ['', Validators.required],
    criminalRecordDescription: [''],
    hasAgreed: ['', Validators.required],
    criminalRecordDocumentation: ['']
  });

  async ngOnInit() {
    if (!this.userProfile.enabledCriminalRecord) {
      await this.registrationNavigator.navigateToNextStage();
      return;
    }

    await this.loadState.inProcess();

    this.form.valueChanges.subscribe(values => {
      if (!values.hasCriminalRecord) {
        this.form.controls['criminalRecordDescription'].setErrors(null);
      }
    });

    let result = await this.statementsService.getStudentCriminalRecordAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      this.form.patchValue(result);
    }

    this.loadState.completed();
  }

  async submit() {
    if (!this.saveState.isInProcess) {
      await this.saveState.inProcess();

    this.form.markAllAsTouched();

      if (this.form.invalid) {
        this.showError = true;
        await this.saveState.completed();

        return;
      }

      this.form.controls['criminalRecordDocumentation'].patchValue(this.criminalform.nativeElement.textContent);

      let result = await this.statementsService.saveStudentCriminalRecordAsync(this.form.value);

      if (!result.succeeded) {
        this.saveState.failed(result);
        return;
      }

      this.registrationNavigator.setCurrentStage(RegistrationStages.הצהרת_העדר_רישום_פלילי);
      await this.saveState.completed();
      this.registrationNavigator.navigateToNextStage();
    }
  }

  get gender(): Gender {
    return this.userProfile.gender;
  }
}
