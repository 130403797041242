<div container>
  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <form
      [formGroup]="form"
      autocomplete="off"
      novalidate
      class="mb-10"
      #healthform
    >
      <mat-radio-group formControlName="hasHealthProblem" let>
        <mat-label i18n>
          האם הנך {gender, select, male {סובל} female {סובלת}} מבעיה רפואית או
          מגבלה כל שהיא?

          <strong
            [ngStyle]="{
              color:
                form.get('hasHealthProblem').invalid &&
                form.get('hasHealthProblem').touched
                  ? 'red'
                  : '#000'
            }"
          >
            (* חובה להצהיר)
          </strong>
        </mat-label>

        <mat-radio-button [value]="true" i18n>כן</mat-radio-button>
        <mat-radio-button [value]="false" i18n>לא</mat-radio-button>
      </mat-radio-group>

      <section *ngIf="form | get: 'hasHealthProblem'">
        <label for="healthProblemDescription">
          {{ detailLabel?.text }}
        </label>

        <mat-form-field appearance="fill">
          <textarea
            matInput
            autofocus
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="2"
            id="healthProblemDescription"
            formControlName="healthProblemDescription"
            placeholder="נא לפרט בקצרה"
            i18n-placeholder
            [required]="form.value.hasHealthProblem"
          ></textarea>
          <mat-error>חובה לפרט</mat-error>
        </mat-form-field>

        <mat-radio-group formControlName="problemRequiredSpecialConditions">
          <mat-label i18n>
            האם הבעיה / המגבלה דורשת תנאים מיוחדים במעונות או בלימודים אקדמיים?
          </mat-label>

          <mat-radio-button [value]="true" i18n>כן</mat-radio-button>
          <mat-radio-button [value]="false" i18n>לא</mat-radio-button>
        </mat-radio-group>

        <section *ngIf="form | get: 'problemRequiredSpecialConditions'">
          <label for="specialConditionsDescription">
            {{ detailLabel?.text }}
          </label>

          <mat-form-field appearance="fill">
            <textarea
              matInput
              autofocus
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="2"
              formControlName="specialConditionsDescription"
              id="specialConditionsDescription"
              i18n-placeholder
              placeholder="נא לפרט בקצרה"
              [required]="form | get: 'problemRequiredSpecialConditions'"
            ></textarea>
            <mat-error>חובה לפרט</mat-error>
          </mat-form-field>
        </section>
      </section>

      <mat-radio-group formControlName="needSpecialTestConditions">
        <mat-label i18n>
          האם {gender, select, male {אתה זקוק} female {את זקוקה}} למבחנים
          מותאמים מיוחדים (בגלל לקות למידה וכדו')?

          <strong
            [ngStyle]="{
              color:
                form.get('needSpecialTestConditions').invalid &&
                form.get('needSpecialTestConditions').touched
                  ? 'red'
                  : '#000'
            }"
          >
            (* חובה להצהיר)
          </strong>
        </mat-label>

        <mat-radio-button [value]="true" i18n>כן</mat-radio-button>
        <mat-radio-button [value]="false" i18n>לא</mat-radio-button>
      </mat-radio-group>

      <section *ngIf="form.value.needSpecialTestConditions">
        <label for="specialTestConditionsDescription">{{
          detailLabel?.text
        }}</label>

        <mat-form-field appearance="fill">
          <textarea
            matInput
            autofocus
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="2"
            formControlName="specialTestConditionsDescription"
            id="specialTestConditionsDescription"
            i18n-placeholder
            placeholder="נא לפרט בקצרה"
            [required]="form | get: 'needSpecialTestConditions'"
          >
          </textarea>
          <mat-error>חובה לפרט</mat-error>

          <mat-hint i18n>
            צרפו אבחון ממכון מוכר<br />
            בעמוד העלאת מסמכים - מסמכים נוספים
          </mat-hint>
        </mat-form-field>
      </section>

      <mat-radio-group formControlName="hadMentalTreatment">
        <mat-label i18n>
          האם עברת טיפול פסיכולוגי, או שהינך {gender, select, male {מטופל}
          female {מטופלת}} כיום ויש לכך השלכות ללימודיך בקמפוס?

          <strong
            [ngStyle]="{
              color:
                form.get('hadMentalTreatment').invalid &&
                form.get('hadMentalTreatment').touched
                  ? 'red'
                  : '#000'
            }"
          >
            (* חובה להצהיר)
          </strong>
        </mat-label>

        <mat-radio-button [value]="true" i18n>כן</mat-radio-button>
        <mat-radio-button [value]="false" i18n>לא</mat-radio-button>
      </mat-radio-group>

      <section *ngIf="form | get: 'hadMentalTreatment'">
        <label for="mentalTreatmentDescription">
          {{ detailLabel?.text }}
        </label>

        <mat-form-field appearance="fill">
          <textarea
            matInput
            autofocus
            cdkTextareaAutosize
            [cdkAutosizeMinRows]="2"
            formControlName="mentalTreatmentDescription"
            id="mentalTreatmentDescription"
            i18n-placeholder
            placeholder="נא לפרט בקצרה"
            [required]="form | get: 'hadMentalTreatment'"
          >
          </textarea>
          <mat-error>חובה לפרט</mat-error>

          <mat-hint i18n>
            צרפו חוות דעת הרופא המטפל, המתייחסת לאופי הלימודים בקמפוס בעמוד
            העלאת מסמכים - מסמכים נוספים
          </mat-hint>
        </mat-form-field>
      </section>

      <br />
      <strong i18n> לתשומת ליבך: </strong>

      <ul>
        <li i18n>
          עליך לצרף אישורים מתאימים להצהרותיך, אם מצב בריאותך לא תקין. בעמוד
          העלאת מסמכים - מסמכים נוספים
        </li>
        <li i18n>
          מידע רלוונטי יימסר למנהל המעונות, במידה {gender, select, male {ותבקש}
          female {ותבקשי}} לגור במעונות. העתק ממנו יישאר בתיק האישי שלך.
        </li>
        <li *ngIf="isTourist" i18n>
          {gender, select, male {תייר, צרף} female {תיירת, צרפי}} פוליסת ביטוח
          לשנה לפחות.
        </li>
      </ul>

      <h3 i18n>הצהרה</h3>
      <div>
        <p i18n>
          הנני {gender, select, male {מצהיר} female {מצהירה}} בזאת, כי כל הפרטים
          שמסרתי לעיל מצב בריאותי הם נכונים.
        </p>
        <p i18n *ngIf="nursingTrack">
          והריני {gender, select, male {מתחייב} female {מתחייבת}} לעדכן באופן
          מידי את מזכירת המחלקה או מזכירות סטודנטים (מזת"ל) על כל שינוי במצבי
          הבריאותי.
          <br />
          הנני {gender, select, male {מצהיר} female {מצהירה}} בזאת כי
          <u>
            אינני {gender, select, male {סובל} female {סובלת}} ולא סבלתי ממחלה
            מסכנת, לרבות ממחלת נפש העלולה לסכן את בריאות הציבור
          </u>
          שבטיפולי או העלולה לשלול ממני את היכולת לעסוק בסיעוד לחלוטין, זמנית או
          חלקית.
          <br />
        </p>

        <p i18n *ngIf="nursingTrack">
          ידוע לי כי במועד רישומי ללימודים במרכז האקדמי לב ע"ר, בהתאם לנהלי משרד
          הבריאות הקיימים תנאי לקבלה ללימודי סיעוד וביצוע התנסות קלינית בסיעוד הוא חיסון
          {gender, select, male {הסטודנט} female {הסטודנטית}}
          נגד נגיף הקורונה.
          <br />
          הריני לאשר כי אני {gender, select, male {מחוסן} female {מחוסנת}} כנגד
          נגיף הקורונה או {gender, select, male {מתחייב} female {מתחייבת}}
          להתחסן כנגד נגיף הקורונה עד למועד תחילת שנה"ל.
        </p>

        <p i18n>
          ידוע לי, כי אם יתגלה שהפרטים שמסרתי אינם נכונים או אינם מלאים, המרכז
          האקדמי לב יהא רשאי, לפי שיקול דעתו, לנקוט בכל צעד שיראה לו לרבות
          הרחקתי מהקמפוס. מכל מקום, המרכז האקדמי לב יהיה פטור מכל אחריות כלפי
          בשל בעיות בריאות למיניהן, בין כאלה הקיימות כיום, בין אלה שהיו בעבר,
          ובין כאלה שתהיינה, חלילה בעתיד.
        </p>
      </div>

      <mat-checkbox
        [checked]="form | get: 'hasAgreed'"
        formControlName="hasAgreed"
        required
        i18n
      >
        הנני {gender, select, male {מצהיר} female {מצהירה}}

        <strong
          [ngStyle]="{
            color:
              form.get('hasAgreed').invalid && form.get('hasAgreed').touched
                ? 'red'
                : '#000'
          }"
        >
          (* חובה להצהיר)
        </strong>
      </mat-checkbox>
    </form>

    <mat-divider class="mb-10"></mat-divider>

    <ui-alert type="warning" *visible="showError">
      <div ui-body i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>
    </ui-alert>

    <ui-progress-button
      color="primary"
      [state]="saveState"
      (progress)="submit()"
      i18n
    >
      {saveState.value, select, inProcess {שומר נתונים} other {שמור והמשך} }
    </ui-progress-button>
  </section>
</div>

<term #detailLabel i18n> {gender, select, male {פרט} female {פרטי}}: </term>

<term #confirmGenderText i18n>
  הנני {gender, select, male {מצהיר} female {מצהירה}}
</term>
