
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { L10nModule } from '@jct/localization';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { UploadPageComponent } from './upload-page.component';
import { AdditionalFilesTabComponent } from './+additional-files';
import { RegistrationFilesTabComponent } from './+registration-files';
import { UploadRoutingModule } from './upload-routing.module';

@NgModule({
  declarations: [
    UploadPageComponent,
    RegistrationFilesTabComponent,
    AdditionalFilesTabComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    UploadRoutingModule,
  ],
})
export class UploadModule { }
