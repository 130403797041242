import { Component, Input, ElementRef, AfterContentInit } from "@angular/core";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { domAccessor } from "@jct/ui/lib/services";

@Component({
  selector: "ui-phone-link",
  templateUrl: "./phone-link.component.html",
  styleUrls: ["./phone-link.component.scss"],
})
export class PhoneLinkComponent implements AfterContentInit {
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private sanitizer: DomSanitizer
  ) {}

  @Input()
  color: "primary" | "accent" | "warn" | "" = "";

  trustedUrl: SafeUrl;

  ngAfterContentInit() {
    let text = domAccessor(this.elementRef).query("mat-icon + span").text();

    this.trustedUrl = this.sanitizer.bypassSecurityTrustUrl(`tel:${text}`);
  }
}
