
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { TimeSpan } from '@jct/core/system';

import { HttpStatusResult, WITHOUT_EMIT_EVENT, Authenticator, TokenSettings } from '@jct/core';
import { AccountService, ValidateTfaModel, AuthenticatorStates, ProfileService } from '@jct/api';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'validate-tfa-page',
  templateUrl: './validate-tfa-page.html',
})
export class ValidateTfaPage implements OnInit {
  private _timer: any;
  private _redirect: string = null;

  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private authenticator: Authenticator,
    private profileService: ProfileService,
    private router: Router)
  { }

  loadState = this.sf.create();
  validateState = this.sf.create();

  form = this.fb.group({
    token: [''],
    code: [''],
  });

  async ngOnInit() {
    await this.loadState.inProcess();
    const state = this.authenticator.loadState();

    if (!state) {
      this.reset();
      return;
    }

    this._redirect =  state['redirect'] || null;

    if (!state['token']) {
      this.reset();
    }
    else {
      this.form.patchValue({
        token: state.token,
      });

      const tokenLifeTime = TimeSpan.parse(state.tokenLifeTime);

      this._timer = setTimeout(() => {
        this.reset();
      }, tokenLifeTime.totalSeconds * 1000);

      this.loadState.completed(10);
    }
  }

  ngOnDestroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  reset() {
    this.loadState.quit();
    this.authenticator.clearState();
    this.router.navigate(['/admin/login']);
  }

  async validate() {
    if (!this.validateState.isInProcess) {
      this.form.disable();
      this.validateState.inProcess();

      const model = this.form.value as ValidateTfaModel;
      const result = await this.accountService.validate(model);

      if (result instanceof HttpStatusResult) {
        this.form.enable(WITHOUT_EMIT_EVENT);
        this.validateState.failed(result);
        return;
      }

      if (result.state === AuthenticatorStates.LoggedIn) {
        this.authenticator.authenticate(<TokenSettings>result);
        this.validateState.completed();

        if (this._redirect && !this._redirect.startsWith('http')) {
          this.router.navigateByUrl(this._redirect);
        }
        else {
          this.router.navigateByUrl('/admin/dashboard');
        }
      }
    }
  }
}
