
<term #tooltip i18n>
  שינוי שפת תצוגה
</term>

<button mat-icon-button
  *ngIf="isEnabled"
  aria-haspopup="true"
  type="button"
  [matTooltip]="tooltip.text"
  [attr.aria-label]="tooltip.text"
  [matMenuTriggerFor]="languageMenu">
  <mat-icon>translate</mat-icon>
</button>

<mat-menu #languageMenu="matMenu">
  <button mat-menu-item *ngFor="let language of supportedLanguages"
    type="button"
    [attr.dir]="pageLayout.rtl ? 'rtl' : 'ltr'"
    [class.active]="language.code == currentLanguage.code"
    (click)="setLanguage(language)">
    <span>{{ language?.name }}</span>
  </button>
</mat-menu>
