export class ExamDetails {
  parentCourseNumber: number;
  actualCourseNumber: number;
  acdemicYearId: number;
  gradePartTypeId: number;
  gradePartId: number;
  testTimeTypeId: number;
}

export class StudentDetails {
  ownerKeyNumber: string;
  examDetails: ExamDetails;
}

export class ExamsProfile {
  userId: number;
}

export class StudentNotebookModel {
  studentDetails: StudentDetails;
  sourcePath: string;
  StudentTestNotebookId?: number;
  index: number;
}

export class WrongTomaxNotebook {
  path: string;
  file: any;
}

export class StudentGradePartModel {
  gradePartTypeId: number;
  gradePartTypeName: string;
  studentCourseGradePartId: number;
}

export class CourseDetailsWithGradePartsModel {
  actualCourseNumber: string;
  courseName: string;
  actualCourseId: number;
  gradeParts: StudentGradePartModel[];
}

export class ActualCourseGradePart {
  id: number;
  actualCourseId: number;
  gradePartTypeId: number;
  minGrade: number;
  weight: number;
  calcTypeId: number;
  hasMoadim: boolean;
  appealWaitForScan: boolean;
  testTimesTypeId: number;
}

export class CourseGradePartType {
  id: number;
  gradePartTypeName: string;
  gradePartTypeEnglishName?: string;
  gradePartTypeColor: number;
  calcTypeId: number;
  hasMoadim: boolean;
  appealWaitForScan: boolean;
  allowLateGradeProviding: boolean;
  isCombinedToTest: boolean;
  testTimesTypeId: number;
}

export class StudentTestNotebook {
  id: number;
  partGradeId: number;
  testTimeTypeId: number;
  path: string;
  createdOn: Date;
  fileSize: number;
  pagesCount?: number;
  notebookIndex: number;
  createdByUserId: number;
  computerizedQuestionnaireNumber?: string;
  computerizedQuestionnaireAnswers?: string;
  computerizedQuestionaire00Answers?: string;
}

export class StudentTestModel {
  id: number;
  actualCourseId: number;
  courseNumber: string;
  courseName: string;
  testTimeTypeName: string;
  testDate: Date;
}

export class RegistrationTestModel {
  ownerKeyNumber: number;
  actualCourseId: number;
  actualCourseTestId: number;
}

