
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { Gender } from '@jct/localization';
import { StateFactory } from '@jct/ui';
import { extractErrors, HttpStatusResult, WITHOUT_EMIT_EVENT } from '@jct/core';

import {
  ProfileService,
  StudentDiplomaCourse,
  DiplomaService,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
  RegistrationStates,
} from '@jct/api';

interface TableRow {
  courseName?: string;
  credits?: number;
  grade?: number;
}

@Component({
  selector: 'diploma-tab.page',
  templateUrl: './diploma-tab.component.html',
})
export class DiplomaTabComponent implements OnInit {
  @Output()
  done = new EventEmitter<any>();

  constructor(
    private diplomaService: DiplomaService,
    private profileService: ProfileService,
    private registrationNavigator: RegistrationNavigator,
    private sf: StateFactory,
    private fb: UntypedFormBuilder)
  { }

  private _table: TableRow[] = [];

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayedColumns: string[] = ['courseName', 'credits', 'grade', 'actions'];

  rows: UntypedFormArray = this.fb.array([]);
  form:UntypedFormGroup = this.fb.group({diplomas: this.rows});

  loadState = this.sf.create();
  saveState = this.sf.create();

  showError = false;

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.diplomaService.getStudentDiplomaCoursesAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      result.forEach(data => this.createRow(data));
    }

    this.dataSource.next(this.rows.controls);
    this.addEmptyRow();

    this.loadState.completed();
  }

  addEmptyRow() {
    if (!this.hasEmptyRow) {
      this.createRow();
      this.dataSource.next(this.rows.controls);
    }
  }

  private createRow(data?: TableRow) {

    const config = {
      'courseName': [ data ? data.courseName : null ],
      'credits': [{ value: data ? data.credits : null, disabled: !data?.courseName },[
        Validators.required, Validators.min(1),Validators.max(20)]],
      'grade': [{ value: data ? data.grade : null, disabled: !data?.courseName },[
        Validators.required, Validators.min(0),Validators.max(100)]]
    };

    const row = this.fb.group(config);

    row.valueChanges.subscribe((data: TableRow) => {
      if (data.courseName) {
        row.enable(WITHOUT_EMIT_EVENT);
        this.addEmptyRow();
      }
    });

    this.rows.push(row);
  }

  private get hasEmptyRow() {
    return this.rows.controls.findIndex(x => x.value.courseName == null) >= 0;
  }

  removeDiplomaCourse(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }

  get returnStudent() {
    return this.profileService.profile?.state == RegistrationStates.ReturnStudent;
  }

  canRemoveDiplomaCourse(row: UntypedFormGroup) {
    return row.value.courseName &&
    !this.returnStudent;
  }

  get gender(): Gender {
    return this.profileService.profile?.gender;
  }

  get creditsCount() {
    return this._table
      .filter(x => x.credits)
      .map(x => x.credits)
      .reduce((total, num) => total + num, 0);
  }

  get average() {
    return this.creditsCount > 0 ? this._table
      .filter(x => x.grade && x.credits)
      .map(x => x.grade * x.credits)
      .reduce((total, num) => total + num, 0) / this.creditsCount : 0;
  }

  get simpleAverage() {
    return this.creditsCount > 0 ? this._table
      .filter(x => x.grade)
      .map(x => x.grade)
      .reduce((total, num) => total + num, 0) / (this._table.length - 1) : 0;
  }

  get incompleted() {
    let incompleted = this.rows.controls
      .filter(x =>
        (x.value.courseName && (!x.value.credits ||
          !x.value.grade)));

    return incompleted.length > 0;
  }

  get canSubmit() {
    return !this.incompleted && !this.form.invalid;
  }

  get errorList() {
    return extractErrors(this.rows);
  }

  async saveAsync() {
    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();

    this.form.markAllAsTouched();

    if (!this.canSubmit) {
      this.showError = true;
      await this.saveState.completed();
      return;
    }

    const model = this.rows.controls
      .map(x => <StudentDiplomaCourse>x.value)
      .filter(x =>
        x.courseName &&
        x.credits &&
        x.grade)
      .map(x => ({ ...x, grade: Math.round(x.grade) }));

    const result = await this.diplomaService.saveStudentDiplomaCoursesAsync(model);

    if (!result.isSuccess) {
      this.saveState.failed(result);
      return;
    }

    this.profileService.update<RegistrationProfile>({
      currentStage: RegistrationStages.דיפלומה,
    });

    await this.saveState.completed();
    await this.registrationNavigator.navigateToNextStage();
  }
}
