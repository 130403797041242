
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  HttpPost,
  FromRoute,
  FromBody,
  HostEnvironment,
  Produce,
  resultOf,
  HttpStatusResult,
  SuccessTestCase,
  SuccessResult,
  FailedTestCase,
  SuccessTestCaseProvider,
  ForbiddenResult
} from '@jct/core';

import { CREDIT_GUARD_TEST_PAYMENT_PROVIDER } from '../payment';
import { VoucherModel } from './vouchers.types';
import { PAID_VOUCHERS_PROVIDER, VOUCHERS_FOR_PAYMENT_PROVIDER } from './vouchers.test';

@Injectable({ providedIn: 'root' })
@Controller('vouchers')
export class VouchersService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,)
  { }

  get baseUrl() {
    return `${this.environment.apiServerUrl}/vouchers`;
  }

  @HttpGet()
  @SuccessTestCaseProvider(VOUCHERS_FOR_PAYMENT_PROVIDER, 'קבלת שוברים לתשלום')
  @FailedTestCase(ForbiddenResult)
  @Produce(VoucherModel, true)
  getVouchersForPaymentAsync() {
    return resultOf<VoucherModel[]>();
  }

  @HttpGet('paid')
  @SuccessTestCaseProvider(PAID_VOUCHERS_PROVIDER, 'קבלת שוברים ששולמו')
  @FailedTestCase(ForbiddenResult)
  @Produce(VoucherModel, true)
  getPaidVouchersAsync() {
    return resultOf<VoucherModel[]>();
  }

  @HttpPost('{voucherIdentity}')
  @SuccessTestCase(CREDIT_GUARD_TEST_PAYMENT_PROVIDER(), 'קבלת קישור לתשלום ב-CreditGuard')
  @Produce('string')
  beginPaymentProcessViaCGAsync(
    @FromRoute('voucherIdentity') voucherIdentity: string,
    @FromBody('numPayments') numPayments: number)
  {
    return resultOf<string>();
  }

  @HttpPost('{transactionId}/success')
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  endPaymentAsync(
    @FromRoute('transactionId') transactionId: string,
    @FromBody('token') token: string)
  {
    return resultOf();
  }

  @HttpPost('{transactionId}/failed')
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  receiveFailedPaymentAsync(
    @FromRoute('transactionId') transactionId: string)
  {
    return resultOf();
  }

  @HttpPost('{transactionId}/cancel')
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  cancelPaymentAsync(
    @FromRoute('transactionId') transactionId: string)
  {
    return resultOf();
  }
}
