
<div container>
  <ui-error [state]="loadState" ></ui-error>

  <form
    [formGroup]="form"
    *visible="loadState.isCompleted"
    (submit)="saveAsync()"
    autocomplete="off"
  >
    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        formArrayName="highSchools"
        [ui-column-width]="[270,150,150,100,80,50]">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            שם התיכון
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <high-school-field
              [label]="highSchoolNameLabel.text"
              floatLabel="never"
              formControlName="name"
              appearance="legacy">
            </high-school-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            ארץ
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <country-field
              formControlName="country"
              [label]="countryLabel.text"
              floatLabel="never"
              appearance="legacy">
            </country-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            עיר
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <city-field
              formControlName="city"
              [label]="cityLabel.text"
              floatLabel="never"
              appearance="legacy">
            </city-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="start-year">
          <th mat-header-cell *matHeaderCellDef
            i18n>
            שנת התחלה
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input matInput type="number"
                formControlName="startYear"
                i18n-placeholder
                placeholder="שנת התחלה"
                (click)="addEmptyRow()"
                [max]="maxStartYear"
                required>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="end-year">
          <th mat-header-cell *matHeaderCellDef
          i18n>
            שנת סיום
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="number" matInput
                formControlName="endYear"
                i18n-placeholder
                [max]="maxEndYear"
                placeholder="שנת סיום">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <button mat-icon-button
              type="button"
              [matTooltip]="deleteButtonTooltip.text"
              (click)="removeHighSchool(index)"
              *visible="canRemoveHighSchool(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></tr>
      </table>
    </div>

    <ui-alert type="warning" *visible="showError && errorList.length > 0">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngFor="let error of errorList">
          <li *ngIf="error == 'startYear-required'" i18n>
           חובה להזין שנת התחלה
          </li>
          <li *ngIf="error == 'startYear-max'" i18n>
            שנת התחלה לא יכולה להיות גדולה משנה שעברה
          </li>
          <li *ngIf="error == 'startYear-pattern'" i18n>
            שנת התחלה לא בפורמט תקין
          </li>
          <li *ngIf="error == 'endYear-min'" i18n>
            שנת סיום לא יכולה להיות קטנה משנת התחלה
          </li>
          <li *ngIf="error == 'endYear-max'" i18n>
            שנת סיום לא יכולה להיות גדולה מהשנה הנוכחית
          </li>
        </ng-container>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [state]="saveState"
      i18n>
        {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term #deleteButtonTooltip
  i18n>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>

<term #highSchoolNameLabel
  i18n>
  {gender, select, male {הזן} female {הזיני}}
  שם תיכון
</term>

<term #countryLabel
  i18n>
  {gender, select, male {בחר} female {בחרי}}
  ארץ
</term>

<term #cityLabel
  i18n>
  {gender, select, male {בחר} female {בחרי}}
  עיר
</term>
