
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessTestCase,
  EntityNotExistsResult,
  SuccessResult,
  Cache,
  HttpStatusResult,
  InvalidModelResult,
  FailedTestCase
} from '@jct/core';

import { BagrutSubject, FAKE_BAGRUT_SUBJECTS } from '../../hatama';
import { STUDENT_BAGRUT_GRADES_PROVIDER } from './bagrut-grades.test';
import { StudentBagrutGrade } from './bagrut-grades.types';

@Injectable({ providedIn: 'root' })
@Controller('bagrut-grades')
export class BagrutGradesService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet('bagrut-subjects')
  @Cache('30d')
  @SuccessTestCase(FAKE_BAGRUT_SUBJECTS, '')
  @Produce(BagrutSubject, true)
  loadBagrutSubjectsAsync() {
    return resultOf<BagrutSubject[]>();
  }

  @UseState()
  @HttpGet()
  @SuccessTestCase(STUDENT_BAGRUT_GRADES_PROVIDER(), 'קבלת רשימת הבגרויות של הסטודנט')
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(StudentBagrutGrade, true)
  getStudentBagrutGradesAsync() {
    return resultOf<StudentBagrutGrade[]>();
  }

  @UseState()
  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentBagrutGradesAsync(
    @FromBody('studentBagrutGrades') bagrutGrades: StudentBagrutGrade[])
  {
    return resultOf();
  }
}
