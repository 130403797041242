import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { Application } from "@jct/core";

import {
  LOCALE_PROVIDER,
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  MULTIPLE_LOCALIZATION_FEATURE,
} from "./core";
import { Language } from "./language";

let firstTime = true;

@Injectable({
  providedIn: "root",
})
export class Localization {
  private _enabled = true;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private application: Application
  ) {
    let current = this.current;

    LOCALE_PROVIDER.useValue = current.code;

    if (firstTime) {
      firstTime = false;
      document.documentElement.setAttribute("dir", current.layout);
      document.documentElement.setAttribute("lang", current.code);
    }
  }

  get current() {
    let specify = SUPPORTED_LANGUAGES.find(
      (x) =>
        location.pathname.startsWith(`/${x.code}/`) ||
        location.pathname === `/${x.code}`
    );

    if (specify) {
      return specify;
    }

    return DEFAULT_LANGUAGE;
  }

  change(target: Language) {
    if (this._enabled) {
      let current = this.current;
      let urlPart = `/${current.code}/`;

      if (target.code != current.code) {
        let path = location.pathname;

        if (location.pathname.startsWith(urlPart)) {
          path = location.pathname.substring(urlPart.length);
        }

        // if (DEFAULT_LANGUAGE.code == target.code) {
        //   location.pathname = `${path}`;
        // } else {
        location.pathname = `/${target.code}/${path}`;
        // }
      }
    }
  }

  enable() {
    this._enabled = true;
  }

  get enabled() {
    return this.application.settings[MULTIPLE_LOCALIZATION_FEATURE] === true;
  }
}
