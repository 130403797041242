
<meta-title i18n>
  השרת שלנו אינו זמין
</meta-title>

<div container>
  <p i18n>
    המערכת לא מצליחה לתקשר עם השרת שלנו.
    <br>
    המערכת תחזור לתפקד ברגע שהשרת יהיה זמין.
  </p>

  <ui-progress-button i18n
    color="primary"
    type="button"
    [state]="connectState"
    (progress)="tryConnect()">
    {connectState.value, select,
      inProcess {מנסה להתחבר}
      other {נסה להתחבר שוב}}
  </ui-progress-button>
</div>
