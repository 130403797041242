
import { Injectable, Injector } from '@angular/core';
import { UserNotification } from '@jct/api';

import {
  Controller,
  resultOf,
  Produce,
  HttpStatusResult,
  FromBody,
  HttpPost,
  HttpGet,
  FromQueryString
} from '@jct/core';

import { UsersListModel } from '../common';

@Injectable({ providedIn: 'root' })
@Controller('password-manager')
export class PasswordManagerService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet('enforce')
  // @Produce(UserNotification, true)
  getEnforceList()
  {
    return resultOf<UserNotification[]>();
  }
}
