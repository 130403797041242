import { Injectable, Provider } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { CACHING_FEATURE, CacheService } from './cache.service';
import { ApiInterceptor, API_INTERCEPTORS } from '../api-client/types';
import { HttpContext } from "../api-client/http-context";
import { Connectivity } from '../connectivity';
import { Application } from '../application';

@Injectable()
export class CacheInterceptor implements ApiInterceptor {
  enabled = false;
  order = 3;

  constructor(
    private application: Application,
    private connectivity: Connectivity,
    private cache: CacheService)
  {
    this.enabled = !!this.application.settings[CACHING_FEATURE];
  }

  async begin(context: HttpContext) {
    if (!!context?.action?.cache) {
      const cachedItem = this.cache.restore(context.method, context.url);
      const produce = context.action.produce;

      if (cachedItem && produce &&
        this.connectivity.isConnected) {
        const result = produce.producer(cachedItem);

        context.cachedResponse(new HttpResponse({
          body: result,
          status: 200,
          url: context.url,
        }));
      }
    }
  }

  async end(context: HttpContext) {
    const body = context?.response?.body;

    if (body &&
      this.connectivity.isConnected &&
      !context.hasError)
    {
      this.cache.store(context.method, context.url, body);
    }
  }
}

export const CacheInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: CacheInterceptor,
  multi: true,
};
