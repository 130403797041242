
<div container>
  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate
    #criminalform
  >
    <strong i18n>נוהל הצהרת העדר רישום פלילי – מועמדים ללימודים בבתי ספר לסיעוד</strong>
    <br>
    <span i18n>
        מועמד ללימודי סיעוד וקורסים על בסיסיים בבתי ספר לסיעוד, שהכירה בהם האחות הראשית הארצית,
        או לחוג או למגמה אוניברסיטאית בסיעוד, יצהיר בעת ההרשמה בפני הנהלת ביה"ס, החוג או המגמה האוניברסיטאית, כדלקמן
    </span>:
    <br>
    <br>

    <strong i18n>לעניין הרישום הפלילי:</strong>
    <br>
    <span i18n>
      {gender, select, male {המועמד} female {המועמדת}}
        יצהיר כי ידוע לו
    </span>:
    <ul>
      <li i18n>
          שעפ"י תקנות בריאות העם (עוסקים בסיעוד בבתי החולים), התשמ"ט – 1988 , סעיף 5 (א): "זכאי להיות רשום בפנקס אזרח או תושב ישראל בן 18 שנים או יותר,
          שהוכיח להנחת דעתה של האחות הראשית כי הוא בעל הכשרה מקצועית כאמורה בתקנות אלה וכי יש לו ידיעה בסיסית, לפחות, בעברית
          וכי לא הורשע בעבירה שדינה מאסר שיש בה, לדעת האחות הראשית, כדי למנוע עיסוק בסיעוד."
      </li>
      <li i18n>
          שעפ"י תקנות בריאות העם (צוות סיעוד במרפאות), התשמ"א – 1981 , סעיף 11 " המנהל רשאי להורות שלא לרשום בפנקס אדם, אף אם נתמלאו התנאים המורים בתקנה 4,
          אם המבקש הורשע בעבירה שיש עמה קלון או שיש בה כדי להראות שהוא חסר האחריות הדרושה לעיסוק בסיעוד, וטרם חלפו עשר שנים אחרי שריצה את עונשו
          או אחרי שנסתיימה תקופת התנאי לפי סעיף 52 (ב) לחוק העונשין, התשל"ז- 1977 , הכל לפי העניין ולפי המאוחר יותר, ובלבד שנתן הזדמנות למבקש לטעון טענותיו".
      </li>
      <li i18n>
        כל הרשעה פלילית תיבדק גם לקראת הדיון ברישום
        {gender, select, male {המועמד} female {המועמדת}}
          בפנקס, או שדרוגו המקצועי. למועמד תינתן זכות שימוע לפני קבלת החלטה.
      </li>
    </ul>
    <br>

    <label i18n>האם הורשעת אי פעם במשפט פלילי בארץ או בחוץ לארץ ?
      <strong [ngStyle]="{'color': form.get('hasCriminalRecord').invalid && form.get('hasCriminalRecord').touched  ? 'red' : '#000'}">
        (* חובה להצהיר)
      </strong>
    </label>

    <mat-radio-group formControlName="hasCriminalRecord">
      <mat-radio-button [value]="true" i18n>כן</mat-radio-button>
      <mat-radio-button [value]="false" i18n>לא</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="form.value.hasCriminalRecord == true">
      <label i18n>פירוט העבירה, נסיבותיה, מועד ביצועה והעונש הנפסק</label>:
      <br>
      <mat-form-field appearance="fill">
        <textarea matInput
        formControlName="criminalRecordDescription"
        [required]="form | get:'hasCriminalRecord'"></textarea>
        <mat-error>חובה למלא</mat-error>
      </mat-form-field>
    </div>

    <strong i18n>הצהרה:</strong>
    <ul>
      <li i18n>
        {{ confirmGenderText?.text }}
        כי המידע שמסרתי נכון ומדויק ובמידה ויוכח אחרת, עשוי הדבר להוות עילה להפסקת לימודי, זאת לאחר שתינתן לי זכות לשימוע.
      </li>
      <li i18n>
        אני
        {gender, select, male {מוסר} female {מוסרת}}
         מידע זה מתוך ידיעה שישמר בסוד וכי הוא ישמש רק לעניין לימודי סיעוד ורישום בפנקס האחות או שדרוג מקצועי.
      </li>
      <li i18n>
        כמו כן
        {{ confirmGenderText?.text }}
         כי ידועים לי כל הפרטים הכתובים לעיל.
      </li>
    </ul>

    <mat-checkbox
    [checked]="form.value.hasAgreed"
    formControlName="hasAgreed"
    required>
     {{ confirmGenderText?.text }}
    <strong [ngStyle]="{'color': form.get('hasAgreed').invalid && form.get('hasAgreed').touched  ? 'red' : '#000'}">
      (* חובה להצהיר)
    </strong>
    </mat-checkbox>

    <br><br>


    <ui-alert type="warning" *visible="showError">
      <div ui-body i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>
    </ui-alert>

    <ui-progress-button
      color="primary"
      [state]="saveState"
      (progress)="submit()"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>

  </form>
</div>

<term #confirmGenderText i18n>
  הנני
  {gender, select, male {מצהיר} female {מצהירה}}
</term>
