import { SnackBarComponent } from './snack-bar.component';
import { SnackBarMessageComponent } from './snack-bar-message.component';
import { SnackBarActionComponent } from './snack-bar-action.component';

export * from './snack-bar-action.component';
export * from './snack-bar-message.component';
export * from './snack-bar.component';

export const SNACK_BAR_COMPONENTS = [
  SnackBarComponent,
  SnackBarMessageComponent,
  SnackBarActionComponent,
];
