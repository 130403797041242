
<nav mat-tab-nav-bar color="accent" updateLayout dir>
  <a mat-tab-link
    routerLink="/upload/registration-files"
    i18n>
    מסמכי רישום
  </a>

  <a mat-tab-link
    routerLink="/upload/additional-files"
    i18n>
    מסמכים נוספים
  </a>
</nav>

<section class="tab-place-holder">
  <router-outlet></router-outlet>
</section>
