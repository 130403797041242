
import { Dir } from '@angular/cdk/bidi';
import { Directive, OnInit, Optional } from '@angular/core';

import { PageLayout } from '../page-layout';

@Directive({
  selector: '[updateLayout][dir]',
})
export class UpdateLayout implements OnInit {
  constructor(
    private pageLayout: PageLayout,
    @Optional() private dir: Dir)
  { }

  async ngOnInit() {
    setTimeout(() => {
      if (this.dir) {
        this.dir.dir = this.pageLayout.layout;
      }
    });

    this.pageLayout.layoutChange.subscribe(layout => {
      setTimeout(() => {
        if (this.dir) {
          this.dir.dir = this.pageLayout.layout;
        }
      });
    });
  }
}
