
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { HttpStatusResult, Filtering, ValidationError, extractErrors, YEAR_PATTERN } from '@jct/core';
import { StateFactory } from '@jct/ui';

import {
  HighSchoolService,
  StudentHighSchool,
  ProfileService,
  RegistrationStates,
  RegistrationUserProfile,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
} from '@jct/api';
import { Gender } from '@jct/localization';

interface TableRow {
  name?: string;
  country?: string;
  city?: string;
  startYear?: number;
  endYear?: number;
}

const DisableEmitEvent = { emitEvent: false };

@Component({
  selector: 'high-school-tab.page',
  templateUrl: './high-school-tab.component.html',
})
export class HighSchoolTabComponent implements OnInit {
  @Output()
  done = new EventEmitter<any>();

  constructor(
    private highSchoolService: HighSchoolService,
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private registrationNavigator: RegistrationNavigator,
    private profileService: ProfileService)
  { }

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns: string[] = ['name', 'country', 'city', 'start-year', 'end-year', 'actions'];

  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup = this.fb.group({ 'highSchools': this.rows });

  loadState = this.sf.create();
  saveState = this.sf.create();
  showError = false;

  filteredHighSchools: Observable<string[]>;

  get gender(): Gender {
    return this.profileService.profile?.gender;
  }

  async valueChanged(value: string) {
    this.filteredHighSchools = of(await this.filterInput(value));
  }

  async getDataAsync(filtering: Filtering): Promise<string[]> {
    let result = await this.highSchoolService.searchHighSchoolsAsync(filtering);

    if (result instanceof HttpStatusResult) {
      return [];
    }

    return result;
  }

  async filterInput(value: string): Promise<string[]> {
    if (!value) {
      return await this.getDataAsync(new Filtering(100, ''));
    }

    return await this.getDataAsync(new Filtering(100, value));
  }

  maxStartYear = new Date().addYears(-1).getFullYear();
  maxEndYear = new Date().getFullYear();

  get errorList() {
    return extractErrors(this.rows);
  }

  private _setEndYearValidators(row: UntypedFormGroup, startYearValue: number|null) {
    const endYearValidators: ValidatorFn[] = [];

    endYearValidators.push(Validators.max(this.maxEndYear));
    if (startYearValue !== null) {
      endYearValidators.push(Validators.min(startYearValue));
    }

    row.get('endYear').setValidators(endYearValidators);
  }

  private createRow(data?: TableRow) {
    const startYearValue = data ? Math.min(data.startYear, this.maxStartYear) : null;

    const config = {
      'name': [ data ? data.name : null ],
      'city': [{ value: data ? data.city : null, disabled: !data }],
      'country': [{ value: data ? data.country : null, disabled: !data }],
      'startYear': [{ value: startYearValue, disabled: !data }, [
        Validators.required, Validators.pattern(YEAR_PATTERN), Validators.max(this.maxStartYear)]],
      'endYear': [{ value: data ? data.endYear : null, disabled: !data }, [
        Validators.min((data?.startYear || 1900)), Validators.max(this.maxEndYear)]],
    };

    const row = this.fb.group(config);

    this._setEndYearValidators(row, startYearValue);

    row.get('startYear').valueChanges.subscribe(value => {
      this._setEndYearValidators(row, value);
    });

    row.controls['name'].valueChanges.subscribe(async name => {
      this.filteredHighSchools = of(await this.filterInput(name));
    });

    row.valueChanges.subscribe((data: TableRow) => {
      if (data.name) {
        row.enable(DisableEmitEvent);
        this.addEmptyRow();
      }
    });

    this.rows.push(row);
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    const result = await this.highSchoolService.getStudentHighSchoolsAsync();
    // let data: StudentHighSchool[];

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      result.forEach(data => this.createRow(data));
    }

    this.dataSource.next(this.rows.controls);
    this.addEmptyRow();

    this.loadState.completed();
  }

  addEmptyRow() {
    if (!this.hasEmptyRow) {
      this.createRow();
      this.dataSource.next(this.rows.controls);
    }
  }

  private get hasEmptyRow() {
    return this.rows.controls.findIndex(x => x.value.name == null) >= 0;
  }

  removeHighSchool(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }

  get returnStudent() {
    return this.profileService.profile?.state == RegistrationStates.ReturnStudent;
  }

  canRemoveHighSchool(row: UntypedFormGroup) {
    return row.value.name &&
    !this.returnStudent;
  }

  get incompleted() {
    let incompleted = this.rows.controls
      .filter(x =>
        (!x.value.name && x.value.startYear) ||
        (x.value.name && !x.value.startYear));

    return incompleted.length > 0;
  }

  get canSubmit() {
    return !this.incompleted && !this.form.invalid;
  }

  async saveAsync() {
    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();

    this.form.markAllAsTouched();

    if (!this.canSubmit) {
      this.showError = true;
      await this.saveState.completed();
      return;
    }

    let studentHighSchools = this.form.value.highSchools
      .filter(x => x.name && x.startYear);

    let model = studentHighSchools as StudentHighSchool[];
    let result = await this.highSchoolService.saveStudentHighSchoolsAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    this.profileService.update<RegistrationProfile>({
      currentStage: RegistrationStages.תיכונים,
    });

    await this.saveState.completed();
    this.registrationNavigator.navigateToNextStage();
  }
}
