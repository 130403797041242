
<h1 mat-dialog-title i18n>
  רישום לשנה הבאה
</h1>

<section mat-dialog-content>
  <p i18n>
    נרשמת ללימודים בשנת {{ academicYear }}. <br />
  </p>
  <p i18n>
    {gender, select,
      male {האם אתה בטוח שברצונך להירשם מחדש לשנה הבאה?}
      female {האם את בטוחה שברצונך להירשם מחדש לשנה הבאה?}
    }
  </p>
</section>

<section mat-dialog-actions>
  <button mat-raised-button
    color="accent"
    type="button"
    (click)="back()">
    <strong i18n>חזור</strong>
  </button>&nbsp;&nbsp;

  <button mat-raised-button
    color="primary"
    type="button"
    (click)="registerNextYear()">
    <strong i18n>רישום לשנה הבאה</strong>
  </button>

  <ui-error [state]="registerState"></ui-error>
</section>
