
<div container>
  <meta-title i18n>
    הגדרת אימות דו שלבי
  </meta-title>

  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <form
      [formGroup]="form"
      novalidate
      autocomplete="off"
      (submit)="configure()"
    >
      <p i18n>
        אנא סרוק את QR קוד הבא למכשירך:
      </p>

      <img [src]="qrCodeData" style="margin: auto; width: 200px; padding: 0 0 1rem; display: block;">

      <mat-form-field appearance="fill">
        <mat-label i18n>
          קוד אימות
        </mat-label>

        <input matInput
          type="text"
          required
          formControlName="code"
          [autofocus]="loadState"
          maxlength="10">

        <mat-error i18n>
          אנא הזן קוד אימות תקין
        </mat-error>
      </mat-form-field>

      <ui-progress-button
        color="primary"
        [disabled]="form.invalid"
        [state]="configureState"
        i18n>
        {configureState.value, select,
          inProcess {מאמת קוד}
          other {אימות קוד}}
      </ui-progress-button>&nbsp;

      <button mat-raised-button
        type="button"
        color="pirmary"
        (click)="reset()"
        i18n>
        ביטול
      </button>

      <ui-alert type="warning" *visible="configureState.error?.isInvalidModel || configureState.error?.isVerificationFailed">
        <div ui-body i18n>
          קוד האימות אינו נכון
        </div>
      </ui-alert>

      <ui-error [state]="configureState"
        [ignoreList]="[400,401]">
      </ui-error>
    </form>
  </section>
</div>
