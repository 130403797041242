
import { ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

import { HttpStatusResult, ProgressIndicator } from '@jct/core';

export type ActionStates = 'init' | 'inProcess' | 'failed' | 'completed' | 'quit';
type ChangeDetector = () => void;

export class ActionState {
  constructor(
    private changeDetector: ChangeDetector,
    private progressIndicator: ProgressIndicator)
  {
    this._state = 'init';
  }

  private _detector = new BehaviorSubject<ActionStates>('init');
  private _state: ActionStates;
  private _errorResult: HttpStatusResult|null = null;

  get detector() {
    return this._detector;
  }

  get value() {
    return this._state;
  }

  get error(): HttpStatusResult|null {
    return this._errorResult;
  }

  set(state: ActionStates) {
    this._state = state;
    this.changeDetector();
    this._detector.next(this._state);
  }

  is(states: ActionStates | ActionStates[]) {
    if (typeof states === 'string') {
      return this._state === states;
    }

    return states.includes(this._state);
  }

  failed(errorResult?: HttpStatusResult) {
    this._errorResult = errorResult;
    this.set('failed');
    this.progressIndicator.done();
  }

  init() {
    this.clearError();
    this.set('init');
  }

  clearError() {
    this._errorResult = null;
  }

  async inProcess(millisecondsToWait?: number) {
    this.set('inProcess');
    this.progressIndicator.inProgress();

    if (millisecondsToWait && millisecondsToWait > 0) {
      await timer(millisecondsToWait).toPromise();
    }
  }

  async completed(millisecondsToWait: number = 10) {
    this.clearError();

    if (millisecondsToWait && millisecondsToWait > 0) {
      await timer(millisecondsToWait).toPromise();
    }

    this.set('completed');
    this.progressIndicator.done();
  }

  async quit(millisecondsToWait?: number) {
    this.clearError();

    if (millisecondsToWait && millisecondsToWait > 0) {
      await timer(millisecondsToWait).toPromise();
    }

    this.set('quit');
    this.progressIndicator.done();
  }

  toString() {
    return this._state;
  }

  get isInit() {
      return this.is('init');
  }

  get isInProcess() {
    return this.is('inProcess');
  }

  get isFailed() {
    return this.is('failed');
  }

  get isCompleted() {
    return this.is('completed');
  }

  get isQuit() {
    return this.is('quit');
  }
}
