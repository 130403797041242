import { Injectable, ApplicationRef } from "@angular/core";

import { ActionState } from "../common/action-state";
import { ProgressIndicator } from "@jct/core";

@Injectable({
  providedIn: "root",
})
export class StateFactory {
  constructor(
    private appRef: ApplicationRef,
    private progressIndicator: ProgressIndicator
  ) {}

  create() {
    return new ActionState(
      () => this.appRef.components[0].changeDetectorRef.detectChanges(),
      this.progressIndicator
    );
  }
}
