
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ProfileService, RegistrationProfile, RegistrationUserProfile, StudyTrack, StudyTrackService } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { StateFactory } from '../../services';


@Component({
  templateUrl: 'new-registration-dialog.component.html',
})
export class NewRegistrationDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {mainStudentStudyTrack: StudyTrack},
    private sf: StateFactory,
    private router: Router,
    private studyTrackService: StudyTrackService,
    private profileService: ProfileService,
    private userProfile: RegistrationUserProfile,
    private dialogRef: MatDialogRef<NewRegistrationDialogComponent>)
  { }

  registerState = this.sf.create();

  get gender() {
    return this.userProfile.gender;
  }

  back() {
    this.dialogRef.close();
  }

  get academicYear() {
    return this.data.mainStudentStudyTrack.academicYearTitle;
  }

  async registerNextYear() {
    if (!this.registerState.isInProcess) {
      await this.registerState.inProcess();

      const result = await this.studyTrackService.startOverAsync();

      if (result instanceof HttpStatusResult) {
        this.registerState.failed(result);
        return;
      }

      this.profileService.update<RegistrationProfile>({
        ...result,
        minAcademicYear: this.data.mainStudentStudyTrack.academicYear,
      });

      await this.router.navigate(['/fill-in/study-track']);
      await this.registerState.completed();

      this.dialogRef.close();
    }
  }
}
