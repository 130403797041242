
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
  PreviousDegreeService,
  ProfileService,
  StudentPreviousDegree,
  TypesListItem,
  RegistrationStates,
  RegistrationUserProfile,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
} from '@jct/api';

import { StateFactory } from '@jct/ui';
import { extractErrors, HttpStatusResult } from '@jct/core';

interface TableRow {
  degreeTypeId?: number;
  degreeTrackId?: number;
  average?: number;
}

const DisableEmitEvent = { emitEvent: false };

@Component({
  selector: 'previous-degrees-tab.page',
  templateUrl: './previous-degrees-tab.component.html',
})
export class PreviousDegreesTabComponent implements OnInit {
  @Output()
  done = new EventEmitter<any>();

  constructor(
    private profile: RegistrationUserProfile,
    private previousDegreeService: PreviousDegreeService,
    private profileService: ProfileService,
    private registrationNavigator: RegistrationNavigator,
    private sf: StateFactory,
    private fb: UntypedFormBuilder)
  { }

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns: string[] = ['degree-type', 'degree-track-name', 'average', 'actions'];

  loadState = this.sf.create();
  saveState = this.sf.create();

  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup = this.fb.group({ 'degrees': this.rows });

  degreeTypes: TypesListItem[];
  degreeTracks: TypesListItem[];

  showError = false;

  get gender() {
    return this.profile?.gender;
  }

  async loadDegreeTypesAsync(): Promise<TypesListItem[]> {
    let result = await this.previousDegreeService.loadDegreeTypesAsync();

    if (result instanceof HttpStatusResult) {
      return [];
    }

    this.degreeTypes = result;

    return result;
  }

  async loadDegreeTracksAsync(): Promise<TypesListItem[]> {
    let result = await this.previousDegreeService.loadDegreeTracksAsync();

    if (result instanceof HttpStatusResult) {
      return [];
    }

    this.degreeTracks = result;

    return result;
  }

  private createRow(data?: TableRow) {
    const config = {
      'degreeTypeId': [ data ? data.degreeTypeId : 0 ],
      'degreeTrackId': [{ value: data ? data.degreeTrackId : 0, disabled: !data }, [Validators.required, Validators.min(1)]],
      'average': [{ value: data ? data.average : null, disabled: !data }, [Validators.required, Validators.min(0), Validators.max(100)]],
    };

    const row = this.fb.group(config);

    this.rows.push(row);

    row.valueChanges.subscribe((data: TableRow) => {
      if (data.degreeTypeId > 0) {
        row.enable(DisableEmitEvent);
        this.addEmptyRow();
      }
    });
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.previousDegreeService.getStudentPreviousDegreesAsync();
    let isEmpty = false;

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
      else {
        isEmpty = true;
      }
    }

    if (!isEmpty) {
      let data = <StudentPreviousDegree[]>result;

      data.forEach(data => this.createRow(data));
    }

    this.dataSource.next(this.rows.controls);
    this.addEmptyRow();

    await this.loadDegreeTypesAsync();
    await this.loadDegreeTracksAsync();
    this.loadState.completed();
  }

  addEmptyRow() {
    if (!this.hasEmptyRow) {
      this.createRow();
      this.dataSource.next(this.rows.controls);
    }
  }

  private get hasEmptyRow() {
    return this.rows.controls.findIndex(x => x.value.degreeTypeId == 0) >= 0;
  }

  removeDegree(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataSource.next(this.rows.controls);
    }
  }

  get returnStudent() {
    return this.profileService.profile?.state == RegistrationStates.ReturnStudent;
  }

  canRemoveDegree(row: UntypedFormGroup) {
    return row.value.degreeTypeId > 0 ;
    // && !this.returnStudent;
  }

  get incompleted() {
    let incompleted = this.rows.controls
    .filter(x =>
      x.value.degreeTypeId > 0 &&
      (!x.value.degreeTrackId ||
      !x.value.average));

    return incompleted.length > 0;
  }

  get submitDisabled() {
    return this.incompleted || this.form.invalid;
  }

  get errorList() {
    return extractErrors(this.rows);
  }

  async saveAsync() {
    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();
    this.form.markAllAsTouched();

    if (this.submitDisabled) {
      this.showError = true;
      await this.saveState.completed();
      return;
    }

    let studentDegrees = this.form.value.degrees
      .filter((x: TableRow) =>
        x.degreeTypeId &&
        x.degreeTrackId &&
        x.average > 0);

    let model = studentDegrees as StudentPreviousDegree[];

    let result = await this.previousDegreeService.saveStudentPreviousDegreesAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    this.profileService.update<RegistrationProfile>({
      currentStage: RegistrationStages.תארים_אקדמים,
    });

    await this.saveState.completed();
    this.registrationNavigator.navigateToNextStage();
  }
}
