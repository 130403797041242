
import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';

import { Connectivity } from '@jct/core';
import { StateFactory } from '@jct/ui/lib/services';

@Component({
  selector: 'server-unavailable.page',
  templateUrl: './server-unavailable.page.html',
})
export class ServerUnavailablePage implements OnInit, OnDestroy {
  private _subscription: Subscription;

  constructor(
    private connectivity: Connectivity,
    private sf: StateFactory)
  { }

  connectState = this.sf.create();

  async ngOnInit() {
    if (this.connectivity.isServerAvailable) {
      this.connectivity.goBack();
      return;
    }

    this._subscription = interval(1000 * 60).subscribe(() => this.tryConnect());
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  async tryConnect() {
    this.connectState.inProcess();
    await this.connectivity.checkConnectivityAsync();
    this.connectState.completed();
  }
}
