import { ApplicationRef, Component, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { filter, map } from "rxjs/operators";

import { ApiDescriptionProvider } from "../api";

@Component({
  selector: "clear-cache.page",
  templateUrl: "./clear-cache.html",
})
export class ClearCachePage {
  constructor(
    @Optional() updates: SwUpdate,
    appRef: ApplicationRef,
    router: Router
  ) {
    ApiDescriptionProvider.clearAllStates();
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = "";

    if (updates && updates.isEnabled) {
      appRef.isStable.subscribe(() => {
        updates.versionUpdates
          .pipe(
            filter(
              (evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"
            ),
            map((evt) => ({
              type: "UPDATE_AVAILABLE",
              current: evt.currentVersion,
              available: evt.latestVersion,
            }))
          )
          .subscribe(() => {
            updates.activateUpdate().then(() => document.location.reload());
          });

        updates.checkForUpdate();

        setTimeout(() => {
          router.navigateByUrl("/");
        }, 5000);
      });
    } else {
      router.navigateByUrl("/");
    }
  }
}
