
interface Date {
  addMilliseconds(value: number): Date;
  addSeconds(value: number): Date;
  addMinutes(value: number): Date;
  addHours(value: number): Date;
  addDays(value: number): Date;
  addMonths(value: number): Date;
  addYears(value: number): Date;
}

Date.prototype.addMilliseconds = function(value: number) {
  this.setMilliseconds(this.getMilliseconds() + value);
  return this;
}

Date.prototype.addSeconds = function(value: number) {
  this.setSeconds(this.getSeconds() + value);
  return this;
}

Date.prototype.addMinutes = function(value: number) {
  this.setMinutes(this.getMinutes() + value);
  return this;
}

Date.prototype.addHours = function(value: number) {
  this.setHours(this.getHours() + value);
  return this;
}

Date.prototype.addDays = function(value: number) {
  this.setDate(this.getDate() + value);
  return this;
}

Date.prototype.addMonths = function(value: number) {
  this.setMonth(this.getMonth() + value);
  return this;
}

Date.prototype.addYears = function(value: number) {
  this.setFullYear(this.getFullYear() + value);
  return this;
}
