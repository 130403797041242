
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  Produce,
  SuccessTestCaseProvider,
  FailedTestCase,
  ForbiddenResult
} from '@jct/core';

import { RegistrationStatusModel } from './registration-status.types';
import { REGISTRATION_STATUS_PROVIDER } from './registration-status.test';

@Injectable({ providedIn: 'root' })
@Controller('status')
export class RegistrationStatusService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet()
  @SuccessTestCaseProvider(REGISTRATION_STATUS_PROVIDER, 'הצלחה')
  @FailedTestCase(ForbiddenResult)
  @Produce(RegistrationStatusModel)
  getRegistrationStatusAsync() {
    return resultOf<RegistrationStatusModel>();
  }
}
