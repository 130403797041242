import { Injectable, Provider } from '@angular/core';

import { HostEnvironmentOptions } from './host-environment-options';

@Injectable({ providedIn: 'root' })
export class HostEnvironment {
  private _options: HostEnvironmentOptions;

  config(options?: HostEnvironmentOptions) {
    this._options = options;
  }

  static create(options: HostEnvironmentOptions) {
    const app = new HostEnvironment();
    app.config(options);
    return app;
  }

  get environmentName() { return this._options.environmentName; }
  get apiServerUrl() { return this._options.apiServerUrl; }
  get applicationUrl() { return this._options.applicationUrl; }

  isDevelopment() {
    return this.isEnvironment('development');
  }

  isProduction() {
    return this.isEnvironment('production');
  }

  isStaging() {
    return this.isEnvironment('staging');
  }

  isTest() {
    return this.isEnvironment('test');
  }

  isEnvironment(environmentName: string) {
    return this.environmentName.toLowerCase() === environmentName.toLowerCase();
  }
}

export const environmentProvider = (hostEnvironment: HostEnvironment): Provider => ({
  provide: HostEnvironment,
  useValue: hostEnvironment,
  multi: false,
});
