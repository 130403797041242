import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { ProgressIndicator } from '@jct/core';
import { ProfileService } from './profile.service';
import { RegistrationProfile, ALL_REGISTRATION_STAGES, RegistrationStages } from './registration.types';

@Injectable({
  providedIn: 'root'
})
export class RegistrationNavigator {
  constructor(
    protected injector: Injector,
    private profileService: ProfileService,
    private progressIndicator: ProgressIndicator,
    private router: Router)
  { }

  private get _profile(): RegistrationProfile {
    return this.profileService.profile;
  }

  private _resolveStageIndex(stage: RegistrationStages) {
    return ALL_REGISTRATION_STAGES.findIndex(x => x.stage === stage);
  }

  private _checkStageAvailability(stage: RegistrationStages, logErrors: boolean = false) {
    if (!this._profile.currentStage) {
      return false;
    }

    const currentStageIndex = this._resolveStageIndex(this._profile.currentStage);

    switch (stage) {
      case RegistrationStages.פרטי_התקשרות:
        const personalStageIndex = this._resolveStageIndex(RegistrationStages.פרטים_אישיים);
        const result = currentStageIndex >= personalStageIndex;

        if (!result && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return result;

      case RegistrationStages.בחירת_מסלול_לימודים:
        const lastPersonalStageIndex = this._resolveStageIndex(RegistrationStages.פרטי_התקשרות);
        const personalResult = currentStageIndex >= lastPersonalStageIndex;

        if (!personalResult && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return personalResult;

      case RegistrationStages.פרטי_קרובים:
        const studyTracksStageIndex = this._resolveStageIndex(RegistrationStages.בחירת_מסלול_לימודים);
        const studyTracksResult = currentStageIndex >= studyTracksStageIndex;

        if (!studyTracksResult && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return studyTracksResult;

      case RegistrationStages.פרטי_התקשרות_הורים:
        const relStageIndex = this._resolveStageIndex(RegistrationStages.פרטי_קרובים);
        const relResult = currentStageIndex >= relStageIndex;

        if (!relResult && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return relResult;

      case RegistrationStages.תיכונים:
        const relativesResult = currentStageIndex >= this._resolveStageIndex(RegistrationStages.פרטי_התקשרות_הורים);

        if (!relativesResult && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return relativesResult;

      case RegistrationStages.הצהרת_בריאות:
        const lastRelativesStageIndex = this._resolveStageIndex(RegistrationStages.פרטי_התקשרות_הורים);
        const relativeResult = currentStageIndex >= lastRelativesStageIndex;

        if (!relativeResult && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return relativeResult;

      case RegistrationStages.תשלום:
        const lastDeclarationsStageIndex = this._resolveStageIndex(
          this._profile?.enabledCriminalRecord ?
          RegistrationStages.הצהרת_העדר_רישום_פלילי :
          RegistrationStages.הצהרות_ואישורים);

        const declarationsResult = currentStageIndex >= lastDeclarationsStageIndex;

        if (!declarationsResult && logErrors) {
          console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
        }

        return declarationsResult;
    }

    return true;
  }

  resolveNextStage(stage: RegistrationStages): RegistrationStages {
    let index = -1;

    ALL_REGISTRATION_STAGES.forEach((item, idx) => {
      if (item.stage === stage) {
        index = idx + 1;
      }
    });

    return index >= 0 ? ALL_REGISTRATION_STAGES[index]?.stage || null : null;
  }

  resolveUrl(url: string): RegistrationStages {
    let segments = url.trim('/').split('/');

    if (segments.length < 2 || segments[0] !== 'fill-in') {
      return null;
    }

    const page = segments[1];
    const tab = segments.length > 2 ? segments[2] : null;

    const info = ALL_REGISTRATION_STAGES.find(
      val => val.page === page && (val.tab ? val.tab === tab || !tab : true));

    return info?.stage || null;
  }

  resolveStage(stage: RegistrationStages): string {
    const info = ALL_REGISTRATION_STAGES.find(x => x.stage === stage) || null;

    if (info === null) {
      return '';
    }

    return `/fill-in/${info.page}${info.tab ? ('/' + info.tab) : ''}`;
  }

  canNavigateToUrl(url: string, logErrors: boolean = false): boolean {
    if (!this.profileService.hasProfile) {
      if (logErrors) {
        console.warn(`cannot navigate to url: "${url}" - no profile loaded`);
      }
      return false;
    }

    if (url.trim('/') === 'registration-status') {
      const result = this._profile.isAccepted === true;

      if (!result && logErrors) {
        console.warn(`cannot navigate to url: "${url}" - not permitted`);
      }

      return result;
    }

    const desireStage = this.resolveUrl(url);
    const desireStageIndex = this._resolveStageIndex(desireStage);

    if (desireStageIndex < 0) {
      if (logErrors) {
        console.warn(`cannot navigate to url: "${url}" - url is not recognized`);
      }
      return false;
    }

    if (!this._profile.currentStage) {
      const result = url.trim('/') === this.resolveStage(RegistrationStages.פרטים_אישיים).trim('/');

      if (!result && logErrors) {
        console.warn(`cannot navigate to url: "${url}" - not permitted`);
      }

      return result;
    }

    const currentStageIndex = this._resolveStageIndex(this._profile.currentStage);

    if (currentStageIndex < 0) {
      if (logErrors) {
        console.warn(`cannot navigate to url: "${url}" - current stage not defined`);
      }
      return false;
    }

    return this._checkStageAvailability(desireStage, logErrors);
  }

  canNavigateToStage(stage: RegistrationStages, logErrors: boolean = false): boolean {
    if (!this.profileService.hasProfile) {
      if (logErrors) {
        console.warn(`cannot navigate to stage: "${stage}" - no profile loaded`);
      }
      return false;
    }

    const desireStageIndex = ALL_REGISTRATION_STAGES.findIndex(x => x.stage === stage);

    if (desireStageIndex < 0) {
      if (logErrors) {
        console.warn(`cannot navigate to stage: "${stage}" - stage is not defined`);
      }
      return false;
    }

    if (!this._profile.currentStage) {
      const result = stage === RegistrationStages.פרטים_אישיים;

      if (!result && logErrors) {
        console.warn(`cannot navigate to stage: "${stage}" - not permitted`);
      }

      return result;
    }

    const currentStageIndex = ALL_REGISTRATION_STAGES.findIndex(x => x.stage === this._profile.currentStage);

    if (currentStageIndex < 0) {
      if (logErrors) {
        console.warn(`cannot navigate to stage: "${stage}" - current stage not defined`);
      }
      return false;
    }

    return this._checkStageAvailability(stage, logErrors);
  }

  async navigateToCurrentStage() {
    if (this._profile?.currentStage === RegistrationStages.תשלום) {
      this.progressIndicator.inProgress();
      await this.router.navigateByUrl('/registration-status');
      this.progressIndicator.done();
      return;
    }

    const currentStage = this._profile?.currentStage;
    const nextStage = this.resolveNextStage(currentStage) || RegistrationStages.פרטים_אישיים;

    if (!this.canNavigateToStage(nextStage, true)) {
      return;
    }

    const url = this.resolveStage(nextStage);

    this.progressIndicator.inProgress();
    await this.router.navigateByUrl(url);
    this.progressIndicator.done();
  }

  async navigateToNextStage() {
    const currentStage = this.resolveUrl(this.router.url);

    if (currentStage === RegistrationStages.תשלום) {
      if (this._profile.currentStage === RegistrationStages.תשלום) {
        this.progressIndicator.inProgress();
        await this.router.navigateByUrl('/registration-status');
        this.progressIndicator.done();
      }
      else {
        console.warn(`cannot navigate to url: "/registration-status" - not permitted`);
      }

      return;
    }

    const nextStage = this.resolveNextStage(currentStage) || RegistrationStages.פרטים_אישיים;

    if (!this.canNavigateToStage(nextStage, true)) {
      return;
    }

    const url = this.resolveStage(nextStage);

    this.progressIndicator.inProgress();
    await this.router.navigateByUrl(url);
    this.progressIndicator.done();
  }

  setCurrentStage(stage: RegistrationStages) {
    const profile = this.profileService.profile as RegistrationProfile;

    if (this._resolveStageIndex(profile.currentStage) < this._resolveStageIndex(stage)) {
      this.profileService.update<RegistrationProfile>({
        currentStage: stage
      });
    }
  }
}
