
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PAGES_COMPONENTS_ROUTES } from '@jct/ui';

import { AuthGuard, PayedRegistrationFeeGuard } from './guards';
import { PrivateAppComponent } from './private-app.component';
import { RegistrationStatusPage } from './+registration-status';

export const PRIVATE_APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'registration-status',
    pathMatch: 'full'
  },
  {
    path: '',
    component: PrivateAppComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'student',
        canActivate: [AuthGuard, PayedRegistrationFeeGuard],
        canActivateChild: [AuthGuard, PayedRegistrationFeeGuard],
        loadChildren: () => import('./+student/student.module').then(mod => mod.StudentModule),
      },
      {
        path: 'fill-in',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./+fill-in/fill-in.module').then(mod => mod.FillInModule),
      },
      {
        path: 'registration-status',
        canActivate: [AuthGuard, PayedRegistrationFeeGuard],
        canActivateChild: [AuthGuard, PayedRegistrationFeeGuard],
        component: RegistrationStatusPage,
      },
      {
        path: 'payments',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./+payments/payments.module').then(mod => mod.PaymentsModule),
      },
      {
        path: 'upload',
        loadChildren: () => import('./+upload/upload.module').then(mod => mod.UploadModule),
      },
      ...PAGES_COMPONENTS_ROUTES,
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(PRIVATE_APP_ROUTES)
  ],
  exports: [RouterModule]
})
export class PrivateAppRoutingModule { }
