
import { FileRequest, StudentFile } from './files.types';

export const FAKE_STUDENT_FILES: StudentFile[] = [
  {
    "id": 1,
    "fileType": 1,
    "description": "id"
  },
  {
    "id": 2,
    "fileType": 2,
    "description": "passport"
  }
];

export const FAKE_FILE_REQUESTS: FileRequest[] = [
  {
    "fileType": 1,
    "typeName": "צילום תעודת זהות/דרכון",
    "englishTypeName": "Copy of Israeli ID or Passport",
    "frenchTypeName": "Copie de carte d'identité/Passeport",
    "description": null,
    "isMandatory": true,
    "studentFile": {
      "id": 1,
      "fileType": 1,
      "description": "id"
    }
  },
  {
    "fileType": 2,
    "typeName": "תמונת פספורט",
    "englishTypeName": "Passport Photo",
    "frenchTypeName": "Photo d'identité",
    "description": "",
    "isMandatory": true,
    "studentFile": {
      "id": 2,
      "fileType": 2,
      "description": "passport"
    }
  },
  {
    "fileType": 3,
    "typeName": "תעודת בגרות/מכינה",
    "englishTypeName": "Highschool Diploma/ Matriculation Exams/ Pre-Academic Preparation",
    "frenchTypeName": "Diplôme du Bac",
    "description": "יש לצרף תעודת בגרות או גיליון ציונים בו מצוין באופן מפורש \"זכאי לבגרות\". לתלמיד/ת מכינה קד\"א במרכז האקדמי לב מוגשים ציוני המכינה במרוכז ע\"י הנהלת המכינה במהלך חודש תמוז וע\"כ אין צורך להעלות מסמך של הציונים.  במידה ואינך זכאי/ת עדיין לתעודת בגרות או מכינה קד\"א, עליך לצרף: תעודות כיתה יא' ומחצית א של יב', אישור ממזכירות ביה\"ס על מס' יח\"ל בגרות, וציוני בגרות שהתקבלו עד כה. מועמדת מסמינר בי\"ע תצרף ציוני חוצים ותעודות י\"א וי\"ב.",
    "isMandatory": true,
    "studentFile": {
      "id": 3,
      "fileType": 3,
      "description": ""
    }
  },
  {
    "fileType": 4,
    "typeName": "אישור ממזכירות ביה\"ס על מס' יח\"ל בכל המקצועות",
    "englishTypeName": "Authorized certificate from school on learning units in each subject",
    "frenchTypeName": "Relevés de note du Bac",
    "description": "<a href='http://www.jct.ac.il/sites/default/files/rishum/ishur%20bagrut.docx'>להורדת המסמך למילוי לחץ כאן</a>",
    "isMandatory": null,
    "studentFile": {
      "id": 4,
      "fileType": 4,
      "description": ""
    }
  },
  {
    "fileType": 5,
    "typeName": "ציוני בגרות שהתקבלו עד כה",
    "englishTypeName": "Matriculation grades received up to now",
    "frenchTypeName": "Relevés de note du Bac",
    "description": null,
    "isMandatory": null,
    "studentFile": {
      "id": 5,
      "fileType": 5,
      "description": ""
    }
  },
  {
    "fileType": 6,
    "typeName": "צילום תעודות י\"א ו-י\"ב",
    "englishTypeName": "Copy of 11th and 12th grade report cards",
    "frenchTypeName": "Bulletins scolaires classe de 1ere et terminale",
    "description": null,
    "isMandatory": null,
    "studentFile": {
      "id": 6,
      "fileType": 6,
      "description": ""
    }
  },
  {
    "fileType": 7,
    "typeName": "תעודת י\"ב - למועמד/ת שטרם סיים/ה לימודי תיכון/סמינר",
    "englishTypeName": "12th grade report card for candidate who has not completed high school yet",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null,
    "studentFile": {
      "id": 7,
      "fileType": 7,
      "description": ""
    }
  },/*
  {
    "fileType": 8,
    "typeName": "אישור/הצהרה פיקוח אחר חרדי ",
    "englishTypeName": "Certificate/ statement of institution uder other supervision (Chareidi)",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 9,
    "typeName": "ציון פסיכומטרי כלשהו",
    "englishTypeName": "Psycometric Exam Grade",
    "frenchTypeName": "Note examen psychométrie/til",
    "description": "או תאריך בו  ניגשת או שהינך מתכוון לגשת לבחינה.  במידה וניגשת למבחן תיל הזן תאריך.",
    "isMandatory": false
  },
  {
    "fileType": 10,
    "typeName": "דפלומה או גיליון ציונים אקדמי (לפי הצורך)",
    "englishTypeName": "Diploma or Academic Grades (if necessary)",
    "frenchTypeName": "Bulletins universitaires si besoin",
    "description": " אחות מוסמכת תצרף- תעודה מתאימה",
    "isMandatory": null
  },
  {
    "fileType": 13,
    "typeName": "תעודת לידה של המועמד",
    "englishTypeName": "Candidate Birth Certificate",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 14,
    "typeName": "תעודת לידה של האם",
    "englishTypeName": "Mothers Birth Certificate",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 15,
    "typeName": "תעודת נישואין/גירושין של האם",
    "englishTypeName": "Mothers Marriage/ Divorce Certificate",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 16,
    "typeName": "תעודת נישואין של המועמד",
    "englishTypeName": "Candidate Marriage Certificate",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 17,
    "typeName": "נמצא בהליך גיור",
    "englishTypeName": "In the process of converting",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 18,
    "typeName": "תעודת שחרור/פטור/אישור  על   שירות לאומי /אזרחי / צבאי",
    "englishTypeName": "Service/Release/ Exempt Certificate from Army/ Civil/ National Services",
    "frenchTypeName": "Dispense millitaire ou fin de service",
    "description": "נתן להעלות תעודת חוגר או אישור על שרות בפועל",
    "isMandatory": true
  },
  {
    "fileType": 19,
    "typeName": "ציון מבחן GMAT",
    "englishTypeName": "GMAT Grade",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 20,
    "typeName": "קורות חיים",
    "englishTypeName": "CV",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": true
  },
  {
    "fileType": 21,
    "typeName": "תעודת תואר ראשון",
    "englishTypeName": "Bachelors Degree Certificate",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": true
  },
  {
    "fileType": 22,
    "typeName": "אישור זכאות לתואר ראשון",
    "englishTypeName": "Bachelors Degree Completion Approval",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": false
  },
  {
    "fileType": 29,
    "typeName": "הוכחת יהדות",
    "englishTypeName": "Proof of Judaism",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 30,
    "typeName": "תעודת אחות מוסמכת",
    "englishTypeName": "Certified Nurse Certificate",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 31,
    "typeName": "אישור הורים",
    "englishTypeName": "Parent's Consent",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 35,
    "typeName": "אחר",
    "englishTypeName": "Other",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 37,
    "typeName": "תעודת זהות של הורה כולל ספח",
    "englishTypeName": "Copy of Parent's ID including addendum",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": true
  },
  {
    "fileType": 38,
    "typeName": "אישור על נכות/מחלה/גירושין וכד' של התלמיד/בןמשפחה",
    "englishTypeName": "Certificate of Handicap/Illness/Divorce of the student or his relative",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 39,
    "typeName": "הכנסת אב",
    "englishTypeName": "Father's Income",
    "frenchTypeName": null,
    "description": "טופס 106/אישור שומה/אישור מעמד לא עובד מביטוח לאומי/אישור מלגה מכולל",
    "isMandatory": null
  },
  {
    "fileType": 40,
    "typeName": "הכנסת אם",
    "englishTypeName": "Mother's Income",
    "frenchTypeName": null,
    "description": "טופס 106/אישור שומה/אישור מעמד לא עובד מביטוח לאומי",
    "isMandatory": null
  },
  {
    "fileType": 41,
    "typeName": "הכנסת בת זוג",
    "englishTypeName": "Spouse's Income",
    "frenchTypeName": null,
    "description": "טופס 106/אישור שומה/אישור מעמד לא עובד מביטוח לאומי (סעיף זה מיועד לנרשמים נשואים עם ילד ומעלה)",
    "isMandatory": null
  },
  {
    "fileType": 42,
    "typeName": "רישיון רכב תלמיד",
    "englishTypeName": "Student's Car License Number",
    "frenchTypeName": null,
    "description": null,
    "isMandatory": null
  },
  {
    "fileType": 43,
    "typeName": "אישור לימודים מישיבות",
    "englishTypeName": "Yeshiva Learning Certificate",
    "frenchTypeName": null,
    "description": "מיועד לנרשמים שלמדו בישיבה קטנה /גדולה",
    "isMandatory": null
  },
  {
    "fileType": 47,
    "typeName": "הצהרת מועמד לתואר שני בהנדסת תקשרת מחשבים ובכריית מידע",
    "englishTypeName": "Candidate for Master's in Computer Communication Engineering and Information Mining, Statement.\r\n\r\n",
    "frenchTypeName": null,
    "description": "<a href='http://rishum.jct.ac.il/content/documents/Candidate_for_Masters_in_Computer_Communication_Engineering_and_Information_Mining_Statement.docx'>להורדת המסמך למילוי לחץ כאן</a>\r\n",
    "isMandatory": null
  }*/
];
