import { HostBinding, AfterContentInit, ElementRef, Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'meta-description',
  template: '<ng-content></ng-content>',
})
export class MetaDescription implements AfterContentInit {
  private _description = '';

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() { return true; }

  @HostBinding('attr.aria-hidden')
  get ariaHidden() { return true; }

  constructor(
    private elementRef: ElementRef<Element>,
    private meta: Meta)
  { }

  ngAfterContentInit() {
    this._description = this.elementRef.nativeElement.textContent.trim();

    this.meta.updateTag({
      content: this._description,
    }, 'name="description"');
  }

  get description() {
    return this._description;
  }
}
