import { NgModule, ModuleWithProviders } from "@angular/core";

import { CoreModule } from "@jct/core";

import { AntiforgeryService } from "./anti-forgery";

import { DashboardService, UserManagerService } from "./idm";
import { LecturersService, SettingsService, UsersService } from "./lers";

import {
  AmiramService,
  BagrutGradesService,
  DiplomaService,
  EntranceExamsService,
  HatamaService,
  HighSchoolService,
  CommonService,
  ProfileService,
  RegistrationUserProfile,
  RegistrationFeeService,
  PersonService,
  PreviousDegreeService,
  StatementsService,
  StandingOrderService,
  StudyTrackService,
  FilesService,
  AccountService,
  RegistrationNavigator,
} from "./registration";
import { ExportCSVService } from "./shared/export-csv.service";

const EXPORTED_SERVICES = [
  DashboardService,
  UserManagerService,
  LecturersService,
  SettingsService,
  UsersService,
  AccountService,
  AntiforgeryService,
  ProfileService,
  RegistrationUserProfile,
  RegistrationNavigator,

  AmiramService,
  BagrutGradesService,
  DiplomaService,
  EntranceExamsService,
  HatamaService,
  HighSchoolService,
  CommonService,
  StandingOrderService,
  PersonService,
  PreviousDegreeService,
  StatementsService,
  StudyTrackService,
  FilesService,
  RegistrationFeeService,

  ExportCSVService,
];

@NgModule({
  providers: EXPORTED_SERVICES,
  imports: [CoreModule.forRoot()],
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: EXPORTED_SERVICES,
    };
  }
}

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}
