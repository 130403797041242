
export enum DefaultBagrutSubjects {
  אזרחות = 34,
  אנגלית = 3,
  הבעה_עברית = 11,
  הסטוריה = 303,
  מחשבת_ישראל_דתי = 38,
  מתמטיקה = 914,
  ספרות = 8,
  תנך = 2
}

export class StudentBagrutGrade {
  bagrutSubjectId: number;
  units: number;
  grade?: number;
  bonus?: number;
  finalGrade?: number;
}
