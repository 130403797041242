
export class StudentStatement {
  id: number;
  description?: string;
  hasAgreed?: boolean;
  required?: boolean;
}

export class StudentHealthStatement {
  id: number;
  hasHealthProblem: boolean;
  healthProblemDescription?: string;
  problemRequiredSpecialConditions?: boolean;
  specialConditionsDescription?: string;
  needSpecialTestConditions: boolean;
  specialTestConditionsDescription?: string;
  hadMentalTreatment: boolean;
  mentalTreatmentDescription?: string;
  hasAgreed?: boolean;
  healthStatementDocumentation: string;
}

export class StudentCriminalRecord {
  hasCriminalRecord: string;
  criminalRecordDescription?: string;
  hasAgreed: boolean;
  criminalRecordDocumentation: string;
}
