<mat-card inner-container class="mb-10">
  <mat-card-header>
    <mat-card-title>
      <h4 i18n>
        שובר מספר {{ voucherDetails?.voucherIdentity }} על סך {{
        voucherDetails?.amount }} &#8362;
      </h4>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <p *ngFor="let component of voucherDetails?.components">
      {{ component?.description }}
      <span *ngIf="voucherDetails?.components.length > 1"
        >({{ component?.amount }} &#8362;)</span
      >
    </p>

    <section *ngIf="voucherDetails.isPaid">
      <a
        *ngIf="voucherDetails.isReceiptAvailable"
        [attr.href]="downloadLink"
        target="_blank"
        mat-raised-button
        color="accent"
      >
        <mat-icon>cloud_download</mat-icon>
        &nbsp;
        <span i18n>קבלה</span>
      </a>

      <strong *ngIf="!voucherDetails.isReceiptAvailable" i18n>
        השובר שולם אך טרם ניתן להנפיק קבלה
      </strong>
    </section>

    <section *visible="!voucherDetails.isPaid">
      <!-- TODO: add form group -->
      <mat-form-field appearance="fill" class="no-hint">
        <mat-label i18n>מספר תשלומים</mat-label>

        <mat-select [(value)]="selectedNumPayments" required>
          <mat-option [value]="null" [disabled]="true" i18n>בחר...</mat-option>
          <mat-option *ngFor="let option of paymentOptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ui-progress-button
        color="primary"
        [state]="beginPaymentState"
        [disabled]="!selectedNumPayments"
        (progress)="payVoucher()">
        <mat-icon>payment</mat-icon>
        <span i18n>לביצוע התשלום</span>
      </ui-progress-button>
      <!-- <div style="color: red">
        <span style="text-decoration: underline">האתר עובר כרגע שדרוג</span>
        , על כן זמנית לא ניתן לבצע תשלום דרך האתר.
        <br />
        יש לפנות במייל למדור חשבונות סטודנטים
        <span style="text-decoration: underline">heshbonot@jct.ac.il</span>
        <br />
        ונחזור אליכם למימוש הקבלה בהקדם.
      </div> -->
    </section>
  </mat-card-content>
</mat-card>
