import { TestBuilders } from '@jct/core';

export const FAKE_COUNTRIES = [
  'ישראל',
  'ארצות הברית',
  'קנדה',
  'צרפת',
  'גרמניה',
  'רוסיה',
  'אוסטרליה',
  'בלגיה',
  'אטליה',
  'אתיופיה',
].sort();

export const FAKE_CITIES = [
  'ירושלים',
  'תל אביב',
  'רמת גן',
  'ראשון לציון',
  'נהריה',
  'רחובות',
  'נס ציונה',
  'בית שמש',
  'בני ברק',
  'אשדוד',
  'חיפה',
].sort();

export const FAKE_STREETS = [
  'עזה',
  'רוטשילד',
  'חזון איש',
  'בוליביה',
  'חפץ חיים',
  'פינסקר',
  'ארלוזרוב',
  'אלנבי',
  'יפו',
  'דרך ארץ',
  'ויקטור',
  'זכריה',
  'שאול המלך',
  'טבריה',
  'כסליו',
  'עזרה',
  'נחמיה',
  'יהודה הלוי',
  'הרב קוק',
  'אהרון הכהן',
  'הועד הלאומי',
].sort();

export const FAKE_ZIP_CODE = TestBuilders.identity({
  onlyNumbers: true,
  size: 6
})();
