import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";

import { Authenticator, HttpStatusResult, WITHOUT_EMIT_EVENT } from "@jct/core";
import {
  AntiforgeryService,
  AccountService,
  LoginModel,
  AuthenticatorStates,
} from "@jct/api";
import { AppLayoutService, StateFactory } from "@jct/ui";

@Component({
  selector: "login-page",
  templateUrl: "./login-page.html",
})
export class LoginPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private af: AntiforgeryService,
    private accountService: AccountService,
    public appLayout: AppLayoutService,
    private router: Router,
    private authenticator: Authenticator,
    private activatedRoute: ActivatedRoute
  ) {}

  loadState = this.sf.create();
  loginState = this.sf.create();

  form = this.fb.group({
    username: [""],
    password: [""],
  });

  params: Params;

  async ngOnInit() {
    await this.loadState.inProcess();

    const result = await this.af.generateAsync();

    if (!result.succeeded) {
      this.loadState.failed(result);
      return;
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
    });

    await this.loadState.completed();
  }

  async login() {
    if (!this.loginState.isInProcess) {
      this.form.disable(WITHOUT_EMIT_EVENT);
      await this.loginState.inProcess(10);

      const model = this.form.value as LoginModel;
      const result = await this.accountService.login(model);

      console.log(result);

      if (result instanceof HttpStatusResult) {
        this.form.enable(WITHOUT_EMIT_EVENT);
        this.loginState.failed(result);
        return;
      }

      this.authenticator.storeState({ ...this.params, ...result });

      console.log(result);

      switch (result.state) {
        case AuthenticatorStates.ShouldConfigureTwoFactorAuthentication:
          await this.router.navigate(["/admin/configure-tfa"]);
          await this.loginState.completed();
          break;

        case AuthenticatorStates.ShouldValidateTwoFactorAuthentication:
          await this.router.navigate(["/admin/validate-tfa"]);
          await this.loginState.completed();
          break;
      }
    }
  }
}
