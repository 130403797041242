
<section [attr.dir]="pageLayout.rtl ? 'rtl' : 'ltr'">
  <h3 mat-dialog-title>
    <strong>
      בחרו באחת מאפשרויות הבדיקה עבור הבקשה
    </strong>

    <div class="request">
      {{ data.route.httpMethod }}
      /{{ data.route.templatePath }}
    </div>
  </h3>

  <div mat-dialog-content>
    <mat-selection-list #descriptorsList
      [multiple]="false">
      <mat-list-option *ngFor="let descriptor of descriptors; let index = index"
        [value]="descriptor"
        disableRipple="true"
        [selected]="index == 0">
        <mat-icon matListIcon *ngIf="descriptor.success">done_outline</mat-icon>
        <mat-icon matListIcon *ngIf="!descriptor.success">block</mat-icon>
        <div mat-line>{{ descriptor.description }}</div>
        <div *ngIf="hasType(descriptor)" mat-line>({{ descriptor.classType?.name }})</div>
      </mat-list-option>
    </mat-selection-list>
  </div>

  <div mat-dialog-actions align="start">
    <button mat-button (click)="select(descriptorsList.selectedOptions.selected[0]?.value)" cdkFocusInitial>בחר</button>
  </div>
</section>
