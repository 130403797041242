
import { Injectable, Injector } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';

import { HttpGet, Produce, Controller, resultOf, HttpStatusResult, SuccessTestCase, SuccessResult } from '../api';

@Injectable({ providedIn: 'root' })
@Controller('communication')
export class Connectivity {
  private _online = true;
  private _serverAvailable = true;
  private _urlBeforeServerAvailable = '';
  private _urlBeforeOffline = '';

  constructor(
    private router: Router,
    protected injector: Injector,
    private location: Location)
  {
    this._online = navigator.onLine;
    this._serverAvailable = true;

    window.addEventListener('offline', () => this.raiseOfflineEvent());
    window.addEventListener('online', () => this.raiseOnlineEvent());

    router.events.subscribe(x => {
      if (x instanceof NavigationStart) {
        if (!this._online && x.url != '/offline') {
          this.router.navigateByUrl('/offline');
        }
      }
    })
  }

  online = new Subject<boolean>();
  serverAvailable = new Subject<boolean>();

  get isOffline() {
    return !this._online;
  }

  get isOnline() {
    return this._online;
  }

  get isServerUnavailable() {
    return !this._serverAvailable;
  }

  get isServerAvailable() {
    return this._serverAvailable;
  }

  get isConnected() {
    return this._online && this._serverAvailable;
  }

  get hasError() {
    return !this._online || !this._serverAvailable;
  }

  isSameUrl(url: string) {
    return this.router.url == url;
  }

  reportServerUnavailable() {
    if (this._serverAvailable) {
      this._serverAvailable = false;
      this.serverAvailable.next(false);
    }

    if (!this.isSameUrl('/server-unavailable')) {
      this._urlBeforeServerAvailable = this.router.url;
      this.router.navigateByUrl('/server-unavailable');
    }
  }

  goBack() {
    if (document.referrer && document.referrer.startsWith(location.origin)) {
      this.location.back();
    }
    else {
      this.router.navigateByUrl('/');
    }
  }

  @HttpGet()
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  communicate() {
    return resultOf();
  }

  async checkConnectivityAsync() {
    if (this._serverAvailable) {
      return;
    }

    let result = await this.communicate();

    if (result?.succeeded) {
      this._serverAvailable = true;
      this.serverAvailable.next(true);
      await this.router.navigateByUrl(this._urlBeforeServerAvailable);
      this._urlBeforeServerAvailable = '';
    }
  }

  private raiseOnlineEvent() {
    if (!this._online) {
      this._online = true;
      this.online.next(true);

      this.router.navigateByUrl(this._urlBeforeOffline);
      this._urlBeforeOffline = '';
    }
  }

  private raiseOfflineEvent() {
    if (this._online) {
      this._online = false;
      this.online.next(false);

      if (!this.isSameUrl('/offline')) {
        this._urlBeforeOffline = this.router.url;
        this.router.navigateByUrl('/offline');
      }
    }
  }
}
