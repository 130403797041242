import { HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';

import { TestProvider, TestBuilders } from '@jct/core';
import { RegistrationStatusModel } from './registration-status.types';

export const REGISTRATION_STATUS_PROVIDER: TestProvider = (injector: Injector, request: HttpRequest<any>) => {
  const oneOf = TestBuilders.oneOf(FAKE_REGISTRATION_STATUSES);

  return oneOf();
};

export const FAKE_REGISTRATION_STATUSES: RegistrationStatusModel[] = [
  {
    "isAcceptanceStatus": true,
    "webMessage": "התקבלת ללימודים לשנת הלימודים הקרובה. מכתב הקבלה בצרוף שוברי המקדמה והודעה על תחילת הלימודים יישלחו לביתך בימים הקרובים.",
    "statusName": "התקבל תקין"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": "התקבלת \"על תנאי \" לשנת הלימודים הקרובה. עליך יהיה לעמוד בתנאי שנקבע ושיצויין במכתב הקבלה שיישלח לביתך בימים הקרובים.לידיעתך, סטאטוס זה מעכב את קבלת המלגות הפנימיות של בית הספר הגבוה לטכנולוגיה.",
    "statusName": "התקבל על תנאי"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "בתהליך"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": "נתוניך הועברו לדיון בועדת חריגים. לאחר קבלת ההחלטה הודעה תימסר ע\"י מדור מידע ורישום.",
    "statusName": "לועדת קבלה"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": "אינך עומד בדרישות הקבלה ללימודים האקדמיים לשנת הלימודים הקרובה. <br />אנו ממליצים לך לשפר את נתוניך. <br />מכתב רשמי יישלח לביתך בימים הקרובים.\r\n",
    "statusName": "נדחה"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": "להשלמת תהליך הרישום והקבלה, עליך להשלים בדחיפות נתונים חסרים, לדוגמה: <br />\r\nנרשם לתואר ראשון- ציון מבחן פסיכומטרי,ו/או אישור מספר יחידות לימוד בגרות ו/או ציוני בגרות חלקיים ו/או תעודות כיתה יא ויב וכו.\r\n<br />נרשם לתואר שני- ציון מבחן GMAT ו/או אישור זכאות לתואר ראשון וכו. <br /><span style=\"text-decoration:underline;\">\r\nבבקשה צור קשר בדחיפות עם מדור מידע ורישום תלמידים במכון אליו נרשמת. </span>\r\n",
    "statusName": "ברשימת המתנה"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "ביטול הרשמה"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": null,
    "statusName": "התקבל - שומע חופשי"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": "המסמכים ששלחת התקבלו, עליך ליצור קשר עם משרד מידע ורישום לצורך תיאום ראיון אישי. מכתב התשובה יישלח רק לאחר קיום הראיון האישי.",
    "statusName": "מועמד"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": null,
    "statusName": "התקבל-תכנית חלקית"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "פונה"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "נצח יהודה דחוי"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "נדחה - לא אושר ע\"י צה\"ל"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": "עליך לשלוח בהקדם את כל האישורים והמסמכים. רק לאחר קבלת <span style=\"text-decoration:underline;\">כל</span> המסמכים, אנו נטפל ברישומך.",
    "statusName": "נרשם מהאינטרנט"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": null,
    "statusName": "לנסיון"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": null,
    "statusName": "התקבל ללימודי השלמה"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "ביטול הרשמה - לאחר קבלה"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "בתהליך הרשמה באינטרנט"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "הפסיק רישום- לא מעונין"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": null,
    "statusName": "קורסי צבירה"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": "התקבלת ללימודים לשנת הלימודים הקרובה. מכתב הקבלה בצרוף שוברי המקדמה והודעה על תחילת הלימודים יישלחו לביתך בימים הקרובים.",
    "statusName": "התקבל תקין *"
  },
  {
    "isAcceptanceStatus": false,
    "webMessage": null,
    "statusName": "עוזב אלול"
  },
  {
    "isAcceptanceStatus": true,
    "webMessage": null,
    "statusName": "תכנית מוגבלת"
  }
];
