
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Application } from '../application';
import { FakeTokenSettings } from './authenticator.test';
import { TokenSettings } from './authenticator.types';
import { Controller, HttpGet, SuccessTestCase, Produce, HttpStatusResult, resultOf, ApiDescriptionProvider } from '../api';
import { TimeSpan } from '../system';
import { Profiler } from '../profiler';

const NOTIFICATION_TIME_BEFORE_TIMEOUT = 3 * 60;
const TOKEN_SETTINGS_STORAGE_KEY = 'tokenSettings';
const NOTIFY_BEFORE_TIMEOUT = new EventEmitter();
let hasNotified = false;

@Injectable({ providedIn: 'root' })
@Controller()
export class Authenticator {
  constructor(
    protected injector: Injector,
    private application: Application,
    private profiler: Profiler,
    private router: Router)
  {
    setInterval(() => this._timerHandler(), 1000);
  }

  @Output()
  get notifyBeforeTimeout() { return NOTIFY_BEFORE_TIMEOUT; }

  get hasToken(): boolean {
    return !!localStorage.getItem(TOKEN_SETTINGS_STORAGE_KEY);
  }

  get tokenSettings(): TokenSettings {
    return <TokenSettings>JSON.parse(localStorage.getItem(TOKEN_SETTINGS_STORAGE_KEY));
  }

  get expired() {
    return this.hasToken ? new Date(this.tokenSettings?.tokenExpiredOn) : null;
  }

  get lifeTime() {
    return this.hasToken ? TimeSpan.parse(this.tokenSettings?.tokenLifeTime) : null;
  }

  get remainderTokenLifetime() {
    if (!this.hasToken) {
      return 0;
    }

    return Math.round((this.expired.getTime() - Date.now()) / 1000);
  }

  get durationBeforeNotify() {
    if (this.remainderTokenLifetime <= 0) {
      return 0;
    }

    return this.remainderTokenLifetime - NOTIFICATION_TIME_BEFORE_TIMEOUT;
  }

  get isAuthenticated() {
    return this.remainderTokenLifetime > 0;
  }

  storeState(state: any) {
    const hash = btoa(JSON.stringify(state));

    sessionStorage.setItem('authenticatorState', hash);
  }

  loadState() {
    const hash = sessionStorage.getItem('authenticatorState');

    if (hash) {
      return JSON.parse(atob(hash));
    }

    return null;
  }

  clearState() {
    sessionStorage.removeItem('authenticatorState');
  }

  authenticate(tokenState: TokenSettings) {
    this.clearState();
    localStorage.setItem(TOKEN_SETTINGS_STORAGE_KEY, JSON.stringify(tokenState));
    hasNotified = false;
  }

  async refreshToken() {
    const result = await this._extendLifeTime();

    if (!(result instanceof HttpStatusResult)) {
      this.authenticate(result);
    }
  }

  logout(navigateToLoginRoute: boolean = true, redirect?: string) {
    localStorage.removeItem(TOKEN_SETTINGS_STORAGE_KEY);
    ApiDescriptionProvider.clearAllStates();
    this.profiler.clear();
    hasNotified = false;

    if (navigateToLoginRoute && !this._isPublicUrl) {
      this.router.navigate([this.application.loginPath], {
        queryParams: {
          redirect
        }
      });
    }
  }

  timeout() {
    localStorage.removeItem(TOKEN_SETTINGS_STORAGE_KEY);
    ApiDescriptionProvider.clearAllStates();
    this.profiler.clear();

    if (!this._isPublicUrl) {
      this.router.navigate(['/timeout']);
    }
  }

  private get _isPublicUrl() {
    const url = this.router.url;
    const publicRoutes = this.application.settings['publicRoutes'] as string[];

    return publicRoutes.some(route =>
      route.endsWith('*') && url.startsWith(route.trimEnd('*').trimEnd('/')) || url === route);
  }

  private _timerHandler() {
    if (!this.hasToken) {
      return;
    }

    if (!hasNotified && this.durationBeforeNotify <= 0) {
      hasNotified = true;

      if (!this._isPublicUrl) {
        this.notifyBeforeTimeout.next();
      }
    }

    if (this.remainderTokenLifetime <= 0) {
      this.timeout();
    }
  }

  @HttpGet('account/extend')
  @SuccessTestCase(FakeTokenSettings)
  @Produce(TokenSettings)
  private _extendLifeTime() {
    return resultOf<TokenSettings>();
  }
}
