import { Injectable, Provider } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { ApiDescriptionProvider } from '../api/api-description-provider';
import { HttpMethod } from '../api/types';
import { ApiInterceptor, API_INTERCEPTORS } from './types';
import { HttpContext } from './http-context';
import { Connectivity } from '../connectivity';
import { HttpStatusResult } from '../api/http-status-result';

@Injectable()
export class StateInterceptor implements ApiInterceptor {
  enabled = true;
  order = 3;

  constructor(
    private connectivity: Connectivity)
  { }

  async begin(context: HttpContext) {
    if (context.action?.useState) {
      const body = context.request?.body;
      const action = context.action;
      const templatePath = ApiDescriptionProvider.combineTemplatePath(
        action.controller.templatePath,
        action.templatePath);
      const route = ApiDescriptionProvider.getRouteDescriptor(
        HttpMethod.Get,
        templatePath);
      const produce = route.action?.produce;
      const state = ApiDescriptionProvider.getState(context.url);

      switch (context.method) {
        case HttpMethod.Get:
          if (state && this.connectivity.isConnected) {
            if (produce) {
              const result = produce.producer(state);

              context.cachedResponse(new HttpResponse({
                body: result,
                status: 200,
                url: context.request.url,
              }));
            }
            else {
              context.cachedResponse(new HttpResponse({
                body: state,
                status: 200,
                url: context.request.url,
              }));
            }
          }
          break;

          case HttpMethod.Post:
          case HttpMethod.Put:
            const newState = produce.producer(body);
            ApiDescriptionProvider.setState(context.url, newState);
            break;

          case HttpMethod.Patch:
            Object.assign(state, body);
            ApiDescriptionProvider.setState(context.url, state);
            break;
      }
    }
  }

  async end(context: HttpContext) {
    const body = context.response?.body;
    const produce = context.action.produce;

    if (body &&
      context.action?.useState &&
      context.method === HttpMethod.Get &&
      context.response.status === 200)
    {
      if (produce) {
        if (produce?.classType === HttpStatusResult) {
          const state = <HttpStatusResult>produce.producer({});
          state.status = 200;
          state.statusText = body;
          ApiDescriptionProvider.setState(context.url, state);
        }
        else {
          const state = produce.producer(body);
          ApiDescriptionProvider.setState(context.url, state);
        }
      }
      else {
        ApiDescriptionProvider.setState(context.url, body);
      }
    }
  }
}

export const StateInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: StateInterceptor,
  multi: true,
};
