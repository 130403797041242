
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
  pure: false
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    return !value;
  }
}
