import { NgModule, ModuleWithProviders, Provider } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
  MarkdownModule,
  MarkdownModuleConfig,
  MarkedOptions,
} from "ngx-markdown";

import { MaterialModule } from "@jct/material";
import { CoreModule, SINGLETON_SERVICE } from "@jct/core";
import { ApiModule } from "@jct/api";
import { L10nModule } from "@jct/localization";

import { AppLayoutService, MenuService, StateFactory } from "./services";

import {
  UiAlert,
  UiFooter,
  AppLayoutComponent,
  UiError,
  LoadingProgressComponent,
  MailLinkComponent,
  LogoutDialog,
  NewRegistrationDialogComponent,
  UpdateDormRequestsDialogComponent,
  MatFormGroup,
  MenuContentComponent,
  MenuItemComponent,
  NotificationComponent,
  PhoneLinkComponent,
  ProgressButtonComponent,
  SideMenuComponent,
  SNACK_BAR_COMPONENTS,
  SubmenuComponent,
  TimeoutDialogComponent,
  JctLogoComponent,
  SideMenuHeaderComponent,
  UiToolbar,
  UiDisabled,
  UiCard,
  AutofocusDirective,
  ColumnWidth,
  MatTabLinkExtension,
  Visible,
  RouterView,
  UiLink,
} from "./components";

import { PAGES_COMPONENTS } from "./pages";
import { UploadFilesComponent } from "./components/upload-files/upload-files.component";

const COMPONENTS = [
  RouterView,
  Visible,
  LogoutDialog,
  NewRegistrationDialogComponent,
  UpdateDormRequestsDialogComponent,
  UiAlert,
  UiFooter,
  AppLayoutComponent,
  UiError,
  MatFormGroup,
  JctLogoComponent,
  LoadingProgressComponent,
  MailLinkComponent,
  MenuContentComponent,
  MenuItemComponent,
  NotificationComponent,
  PhoneLinkComponent,
  ProgressButtonComponent,
  SideMenuComponent,
  SideMenuHeaderComponent,
  ...SNACK_BAR_COMPONENTS,
  SubmenuComponent,
  TimeoutDialogComponent,
  UiToolbar,
  UiCard,
  UiDisabled,
  AutofocusDirective,
  ColumnWidth,
  MatTabLinkExtension,
  UiLink,
  UploadFilesComponent,
  ...PAGES_COMPONENTS,
];

const EXPORTED_SERVICES: Provider[] = [
  {
    provide: SINGLETON_SERVICE,
    useClass: AppLayoutService,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: MenuService,
    multi: false,
  },
  {
    provide: SINGLETON_SERVICE,
    useClass: StateFactory,
    multi: false,
  },
];

const MARKDOWN_MODULE_CONFIGURATION: MarkdownModuleConfig = {
  markedOptions: {
    provide: MarkedOptions,
    useValue: {
      gfm: true,
      tables: true,
      breaks: true,
      pedantic: true,
      sanitize: false,
      smartLists: true,
      smartypants: true,
    },
  },
};

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    MaterialModule.forRoot(),
    MarkdownModule.forRoot(MARKDOWN_MODULE_CONFIGURATION),
  ],
  exports: [MaterialModule, MarkdownModule, ...COMPONENTS],
  providers: EXPORTED_SERVICES,
})
export class UiModule {
  static forRoot(): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: EXPORTED_SERVICES,
    };
  }
}
