import { Injectable, ApplicationRef, EventEmitter } from "@angular/core";
import { MatDrawerToggleResult, MatSidenav } from "@angular/material/sidenav";
import { Router, NavigationEnd } from "@angular/router";

import { Connectivity, Authenticator, ProgressIndicator } from "@jct/core";
import { MenuItem } from "../common";
import { AppLayoutService } from "./app-layout.service";
import { PageTitleService } from "@jct/core/lib/meta/page-title.service";

interface MatchResult {
  match: MenuItem;
  exact: boolean;
}

interface MatchesResult {
  matches: MatchResult[];
}

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private _sidenav: MatSidenav;
  private _items: MenuItem[] = [];
  private _current?: MenuItem;
  private _opened = false;

  constructor(
    private appLayout: AppLayoutService,
    private connectivity: Connectivity,
    private authenticator: Authenticator,
    private pageTitle: PageTitleService,
    private appRef: ApplicationRef,
    private progressIndicator: ProgressIndicator,
    private router: Router
  ) {
    this.appLayout.layoutChanges.subscribe((layout) => {
      if (!this._sidenav) {
        return;
      }

      if (layout === "xsmall" || layout === "small") {
        this._sidenav.mode = "over";
      } else {
        this._sidenav.opened = true;
        this._sidenav.mode = "side";
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const item = this.matchMenuItem(event.urlAfterRedirects);

        setTimeout(() => {
          if (item) {
            this.openItem(item);
          }
        }, 10);
      }
    });
  }

  get current() {
    return this._current;
  }

  get items() {
    return this._items;
  }

  get isOpen() {
    return (
      this._sidenav && this._sidenav.opened && this._sidenav.mode != "side"
    );
  }

  get hasSidebar() {
    return this._opened;
  }

  stateChange = new EventEmitter<MatDrawerToggleResult>();

  setSidenav(sidenav: MatSidenav) {
    this._sidenav = sidenav;

    this._sidenav.openedChange.subscribe((opened: boolean) => {
      if (opened) {
        this.stateChange.emit("open");
      } else {
        this.stateChange.emit("close");
      }
    });

    this._sidenav.closedStart.subscribe(() => {
      setTimeout(() => {
        this._opened = false;
        this.appRef.components[0].changeDetectorRef.markForCheck();
      });
    });

    this._sidenav.openedStart.subscribe(() => {
      setTimeout(() => {
        this._opened = true;
        this.appRef.components[0].changeDetectorRef.markForCheck();
      });
    });
  }

  open() {
    if (!this._sidenav) {
      return null;
    }

    return this._sidenav.open();
  }

  close() {
    if (!this._sidenav) {
      return null;
    }

    return this._sidenav.close();
  }

  toggle() {
    if (!this._sidenav) {
      return;
    }

    this._sidenav.toggle();
  }

  registerMenu(items: MenuItem[]) {
    this._items.splice(0, this._items.length);
    this._items.push(...items);

    const item = this.matchMenuItem(this.router.url);

    setTimeout(() => {
      if (item) {
        this.openItem(item);
      }
    }, 10);
  }

  matchMenuItem(url: string) {
    const match = (items: MenuItem[]): MatchesResult => {
      const matches: MatchResult[] = [];

      for (let item of items) {
        if (url === item.route) {
          matches.push({
            match: item,
            exact: true,
          });
        }

        if (url.startsWith(item.route)) {
          matches.push({
            match: item,
            exact: false,
          });
        }

        if (item.children) {
          const result = match(item.children);

          matches.push(...result.matches);
        }
      }

      return { matches };
    };

    const result = match(this._items);

    return (
      result.matches.sort((a, b) => {
        if (a.exact && !b.exact) {
          return 1;
        }

        if (!a.exact && b.exact) {
          return -1;
        }

        return 0;
      })[0]?.match || null
    );
  }

  openItem(item: MenuItem) {
    if (!item.display) {
      return;
    }

    this.pageTitle.setTitle(item.title);
    this._current = item;
    this.unselectedAll(this._items);
    this.selectItem(item);
  }

  closeItem(item: MenuItem) {
    if (!item.display) {
      return;
    }

    if (item.children) {
      if (item.opened) {
        item.opened = false;
        item.state = "closing";
        setTimeout(() => {
          item.state = "close";
        }, 300);
      }
    }
  }

  focusNext(item: MenuItem, inside: boolean = true) {
    let items = this._items;
    let next: MenuItem;

    if (inside && item.children && item.opened) {
      next = item.children[0];
    } else {
      if (item.parent) {
        items = item.parent.children;
      }

      let index = items.findIndex((x) => x == item);

      if (index + 1 < items.length) {
        next = items[index + 1];
      } else {
        if (items[0].parent) {
          this.focusNext(item.parent, false);
          return;
        }
      }
    }

    if (next) {
      next.element
        .querySelector<HTMLAnchorElement>("> ui-menu-content > a")
        .focus();
    }
  }

  focusPrev(item: MenuItem) {
    let items = this._items;
    let prev: MenuItem;

    if (item.parent) {
      items = item.parent.children;
    }

    let index = items.findIndex((x) => x == item);

    if (index > 0) {
      prev = items[index - 1];

      if (prev.children && prev.opened) {
        prev = prev.children[prev.children.length - 1];
      }
    } else if (index == 0) {
      if (item.parent) {
        item.parent.element
          .querySelector<HTMLAnchorElement>("> ui-menu-content > a")
          .focus();
        return;
      }
    }

    if (prev) {
      prev.element
        .querySelector<HTMLAnchorElement>("> ui-menu-content > a")
        .focus();
    }
  }

  async navigateTo(item: MenuItem) {
    if (item.children) {
      if (item.opened) {
        item.opened = false;
        item.state = "closing";
        setTimeout(() => {
          item.state = "close";
        }, 300);
      } else {
        item.opened = true;
        item.state = "opening";
        setTimeout(() => {
          item.state = "open";
        }, 300);
      }
      return;
    }

    if (this.appLayout.isMobile) {
      this.close();
    }

    let allowedNavigation = !!item.route || item.action.observers.length > 0;

    if (allowedNavigation && this.connectivity.isConnected) {
      if (typeof item.canNavigate === "boolean") {
        allowedNavigation = item.canNavigate;
      } else {
        allowedNavigation =
          item.canNavigate &&
          item.canNavigate(this.authenticator.tokenSettings);
      }
    }

    if (allowedNavigation) {
      if (this._current == item) {
        return;
      }
      this._current = item;

      if (item.action.observers.length > 0) {
        item.action.next();
        return;
      }

      this.pageTitle.setTitle(item.title);

      this.progressIndicator.inProgress();
      await this.router.navigate([item.route]);
      this.progressIndicator.done();
    }

    this.openItem(item);
  }

  private selectItem(item: MenuItem) {
    if (item.parent) {
      this.selectItem(item.parent);
    }

    if (item.children) {
      item.opened = true;
      item.state = "open";
    } else {
      item.selected = true;
    }
  }

  private unselectedAll(items: MenuItem[]) {
    for (let item of items) {
      if (item.children) {
        this.unselectedAll(item.children);
      } else {
        item.selected = false;
      }
    }
  }
}
