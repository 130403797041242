
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes } from '@angular/router';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { CalculatorPage } from './calculator-page';
import { ExplanationPart } from './explanation-part';
import { BagrutTable } from './bagrut-table';

export const CALCULATOR_MODULE_ROUTES: Routes = [
  {
    path: '',
    component: CalculatorPage,
  },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  declarations: [
    CalculatorPage,
    ExplanationPart,
    BagrutTable,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
  ],
})
export class CalculatorModule { }
