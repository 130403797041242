
<ui-app-layout #app>
  <ui-side-menu>
    <section class="ui-menu-header">
      <div class="header-row">
        <ui-jct-logo
          *ngIf="!appLayout.isMobile"
          color="white"
          [includePadding]="false"
          [width]="47">
        </ui-jct-logo>

        <span i18n>מערכת הרישום</span>
      </div>

      <div class="header-row">
        <span i18n>המרכז האקדמי לב</span>
      </div>
    </section>

    <ui-menu-item *ngIf="!isStudent()">
      <ui-menu-content i18n>
        שלב א' - מילוי פרטים
      </ui-menu-content>

      <ui-submenu>
        <ui-menu-item
          route="/fill-in/personal"
          [canNavigate]="isUrlEnabled('/fill-in/personal/details')">
          <ui-menu-content
            i18n
            icon="person">
            פרטים אישיים
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item
          route="/fill-in/study-track"
          [canNavigate]="isUrlEnabled('/fill-in/study-track')">
          <ui-menu-content
            i18n
            icon="domain">
            מסלול לימודים
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item
          route="/fill-in/relatives"
          [canNavigate]="isUrlEnabled('/fill-in/relatives/details')">
          <ui-menu-content
            i18n
            icon="people">
            קרובי משפחה
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item
          route="/fill-in/previous-education"
          [canNavigate]="isUrlEnabled('/fill-in/previous-education/high-school')">
          <ui-menu-content
            i18n
            icon="school">
            לימודים קודמים
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item
          route="/fill-in/statements"
          [canNavigate]="isUrlEnabled('/fill-in/statements/health')">
          <ui-menu-content
            i18n
            icon="verified_user">
            הצהרות ואישורים
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item
          route="/fill-in/registration-fee"
          [canNavigate]="isUrlEnabled('/fill-in/registration-fee')">
          <ui-menu-content
            i18n
            icon="payment">
            דמי רישום
          </ui-menu-content>
        </ui-menu-item>
      </ui-submenu>
    </ui-menu-item>

    <ui-menu-item
      *ngIf="isStudent()"
      route="/registration-status"
      [canNavigate]="isStudent()">
      <ui-menu-content
        i18n
        icon="how_to_reg">
        מצב הרשמה
      </ui-menu-content>
    </ui-menu-item>

    <ui-menu-item
      *ngIf="isUploadEnabled()"
      route="/upload"
      [canNavigate]="isUploadEnabled()">
      <ui-menu-content
        i18n
        icon="cloud_upload">
        העלאת מסמכים
      </ui-menu-content>
    </ui-menu-item>

    <ui-menu-item *ngIf="isStudent()">
      <ui-menu-content
        i18n
        [isOpen]="true"
        icon="account_box">
        שלב ב' - בקשות ותהליכים
      </ui-menu-content>

      <ui-submenu>
        <ui-menu-item [link]="downloadRegistrationConfirmationPath">
          <ui-menu-content
            icon="cloud_download"
            i18n>
            הורדת אישור נרשם
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item route="/student/vouchers"
          [canNavigate]="isAccepted()"
          matTooltip="לא זמין לסטודנט שטרם התקבל"
          i18n-matTooltip
          [matTooltipDisabled]="isAccepted()"
          [matTooltipPosition]="'left'">
          <ui-menu-content
            i18n
            icon="payment">
            מקדמות ושוברים
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item route="/student/standing-order"
          [canNavigate]="isAccepted()"
          matTooltip="לא זמין לסטודנט שטרם התקבל"
          i18n-matTooltip
          [matTooltipDisabled]="isAccepted()"
          [matTooltipPosition]="'left'">
          <ui-menu-content
            icon="payment"
            i18n>
            הוראת קבע
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item route="/student/interview">
          <ui-menu-content
            icon="date_range"
            i18n>
            זימון ראיון
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item route="/student/amiram-test">
          <ui-menu-content
            i18n
            icon="menu_book">
            מבחן אמירם
          </ui-menu-content>
        </ui-menu-item>

        <ui-menu-item route="/student/dormitory"
          [canNavigate]="isAccepted()"
          matTooltip="לא זמין לסטודנט שטרם התקבל"
          i18n-matTooltip
          [matTooltipDisabled]="isAccepted()"
          [matTooltipPosition]="'left'">
          <ui-menu-content
            i18n
            icon="home">
            מעונות
          </ui-menu-content>
        </ui-menu-item>
      </ui-submenu>
    </ui-menu-item>

    <ui-menu-item
      *ngIf="appLayout.isMobile && l10n.enabled"
      (action)="l10n.openLanguageSelector()">
      <ui-menu-content
        icon="language"
        i18n>
        שנוי שפת תצוגה
      </ui-menu-content>
    </ui-menu-item>

    <ui-menu-item
      *ngIf="appLayout.isMobile"
      (action)="app.logout()">
      <ui-menu-content
        icon="power_settings_new"
        i18n>
        התנתק מהמערכת
      </ui-menu-content>
    </ui-menu-item>
  </ui-side-menu>

  <section ui-toolbar-header class="ui-toolbar-header">
    <ui-jct-logo
      *ngIf="appLayout.isMobile"
      color="white"
      [includePadding]="false"
      [width]="47">
    </ui-jct-logo>

    <span i18n *ngIf="appLayout.isMobile">
      מערכת הרישום
    </span>

    <span *ngIf="!appLayout.isMobile">
      {{ pageTitle.currentTitle | async}}
    </span>
  </section>

  <section ui-toolbar-actions class="ui-toolbar-actions">
    <language-menu></language-menu>
    <!-- <page-layout-selector></page-layout-selector> -->

    <button mat-icon-button
      *ngIf="!appLayout.isMobile"
      type="button"
      (click)="app.logout()"
      i18n-matTooltip
      matTooltip="התנתק מהמערכת">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </section>
</ui-app-layout>
