
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { L10nModule } from '@jct/localization';
import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { FillInRoutingModule } from './fill-in-routing.module';
import { PersonalPageComponent, PersonalDetailsTabComponent, PersonalContactTabComponent } from './+personal';
import { PreviousEducationComponents } from './+previous-education';
import { StudyTrackPage } from './+study-track';
import { RegistrationFeePageComponent } from './+registration-fee';
import { StatementsPageComponent, GeneralStatementsTabComponent, HealthStatementTabComponent, CriminalRecordTabComponent } from './+statements';
import { RelativesPageComponent, RelativesDetailsTabComponent, RelativesContactTabComponent } from './+relatives';
import { FillInComponent } from './fill-in.component';
import { SharedComponentsModule } from './shared-components';

@NgModule({
  declarations: [
    FillInComponent,
    StudyTrackPage,
    PersonalPageComponent,
    PersonalDetailsTabComponent,
    PersonalContactTabComponent,
    ...PreviousEducationComponents,
    HealthStatementTabComponent,
    CriminalRecordTabComponent,
    StatementsPageComponent,
    GeneralStatementsTabComponent,
    RelativesPageComponent,
    RelativesDetailsTabComponent,
    RelativesContactTabComponent,
    RegistrationFeePageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    SharedComponentsModule,
    FillInRoutingModule,
  ],
})
export class FillInModule { }
