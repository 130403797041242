
import { Component, OnInit } from '@angular/core';

import { DormitoryModel, DormitoryService, StudentDormitoryModel } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { StateFactory } from '@jct/ui';

@Component({
  selector: 'dormitory.page',
  templateUrl: './dormitory-page.html',
})
export class DormitoryPageComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private dormitoryService: DormitoryService)
  { }

  loadState = this.sf.create();
  hasDormitory = false;
  studentDormitory: StudentDormitoryModel;
  dormitoryOptions: DormitoryModel[];

  async ngOnInit() {
    await this.loadState.inProcess();

    const studentDormitoryResult = await this.dormitoryService.getStudentDormitoryAsync();

    if (studentDormitoryResult instanceof HttpStatusResult) {
      if (!studentDormitoryResult.isEntityNotExists) {
        this.loadState.failed(studentDormitoryResult);
        return;
      }

      const dormitoryOptionsResult = await this.dormitoryService.getStudentDormitoryOptionsAsync();

      if (dormitoryOptionsResult instanceof HttpStatusResult) {
        this.loadState.failed(dormitoryOptionsResult);
        return;
      }

      this.dormitoryOptions = dormitoryOptionsResult;
    }
    else {
      this.studentDormitory = studentDormitoryResult;
      this.hasDormitory = true;
    }

    await this.loadState.completed();
  }

  reloadStudentDormitory(studentDormitory: StudentDormitoryModel) {
    this.studentDormitory = studentDormitory;
    this.hasDormitory = true;
  }
}
