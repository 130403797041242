
import { Component } from '@angular/core';

import { AppLayoutService } from '@jct/ui/lib/services';

@Component({
  selector: 'not-found.page',
  templateUrl: './not-found.page.html',
})
export class NotFoundPage {
  constructor(
    public appLayout: AppLayoutService)
  { }
}
