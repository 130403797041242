
<div container *visible="loadState.isCompleted">
  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate
    (submit)="verify()">
    <p i18n>אנא הזן את הסיסמה שלך:</p>

    <mat-form-field appearance="fill">
      <mat-label i18n>הסיסמה שלך</mat-label>

      <input matInput
        [autofocus]="loadState"
        type="password"
        formControlName="password"
        autocomplete="off"
        required>

      <mat-hint i18n>אנא הזן את הסיסמה שלך (שנשלחה אליך במייל שנרשמת)</mat-hint>

      <mat-error i18n>סיסמה היא הכרחית</mat-error>
    </mat-form-field>

    <ui-progress-button
      color="accent"
      [disabled]="this.form.invalid"
      [state]="verifyState"
      i18n>
      כניסה
    </ui-progress-button>&nbsp;

    <button mat-raised-button
      type="button"
      color="pirmary"
      (click)="reset()"
      i18n>
      ביטול
    </button>

    <ui-alert type="warning" *visible="verifyState.error?.isVerificationFailed">
      <div ui-body i18n>
        הסיסמה שהזנת לא נכונה
      </div>
    </ui-alert>

    <ui-error
      [state]="verifyState"
      [ignoreList]="[401]">
    </ui-error>
  </form>

  <mat-divider></mat-divider>

  <ui-link to="/reset-process" i18n>
    שכחת את הסיסמה שלך?
  </ui-link>
</div>
