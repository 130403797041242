
import { ElementRef, Component, HostBinding, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  selector: 'term',
  template: '<ng-content></ng-content>',
  exportAs: 'term'
})
export class TermComponent implements AfterContentInit {
  private _text = '';

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    private contentObserver: ContentObserver)
  { }

  get textContent() {
    return this.elementRef.nativeElement.textContent.trim();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this._text = this.textContent;
      this.cd.markForCheck();
    });

    this.contentObserver.observe(this.elementRef)
      .subscribe(x => {
        this._text = this.textContent;
      });
  }

  get text() {
    return this._text;
  }

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() { return true; }

  @HostBinding('attr.aria-hidden')
  get hidden() { return true; }
}
