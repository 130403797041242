
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { L10nModule } from '@jct/localization';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { PublicAppRoutingModule } from './public-app-routing.module';
import { PublicAppComponent } from './public-app.component';
import { ContactPage } from './+contact';
import { StartModule } from './+start';
import { ResetProcessModule } from './+reset-process';
import { CalculatorModule } from './+calculator';

@NgModule({
  declarations: [
    PublicAppComponent,
    ContactPage,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    StartModule,
    ResetProcessModule,
    CalculatorModule,
    PublicAppRoutingModule,
  ],
})
export class PublicAppModule { }
