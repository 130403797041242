
<div container>
  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <ui-alert type="info" *visible="hasStandingOrder">
      <section ui-body i18n>
        לתשומת ליבך, קיים כבר הסדר הוראת קבע עבורך למרכז האקדמי לב.
      </section>
    </ui-alert>

    <section *visible="!hasStandingOrder">
      <!-- <ui-alert type="info">
        <section ui-body i18n>
          זמנית לא ניתן ליצור הסדר הוראת קבע עקב שדרוג. אנא נסו מאוחר יותר.
        </section>
      </ui-alert> -->


      <strong i18n>
    תנאי הסדרת הוראת קבע באשראי למרכז האקדמי לב
  </strong>:

  <p i18n>
    הריני מצהיר/ה בזאת כי ידוע לי
  </p>

  <ol>
    <li i18n>
      שבעצם מילוי פרטי האשראי הנני נותן/ת לכם בזה הוראה לחייב את חשבוני באמצעות כרטיס האשראי
      לפי הפרטים הנ"ל בגין תשלום החיובים למרכז האקדמי לב בסכומים ובמועדים שיקבעו על ידכם.
    </li>

    <li i18n>
      המרכז האקדמי לב ( להלן " המוטב" ) יפעל בהתאם להוראה זו כל עוד לא תהיה מניעה חוקית או אחרת לביצוע,
      כל עוד לא התקבלה אצל המוטב הוראת ביטול בכתב על ידי או כל עוד לא החליטה חברת האשראי או המוטב להוציאני מההסדר.
    </li>

    <li i18n>
      הוראה זו ניתנת לביטול על ידי הודעה ממני בכתב.
    </li>

    <li i18n>
      חלה עלי האחריות להודיע למוטב על כל שינוי בפרטי כרטיס האשראי כגון : תוקף הכרטיס ומספרו.
    </li>

    <li i18n>
      המוטב רשאי להוציאני מההסדר המפורט בכתב הרשאה זה אם תהיה לו סיבה סבירה לכך ובלבד שיודיע לי על כך עם קבלת החלטתו.
    </li>

    <li i18n>
      אישור על התשלום יופיע באתר "לב-נט".
    </li>

    <li i18n>
      לכל חיוב תתווסף עמלה בסך חצי אחוז מגובה החיוב.
    </li>

    <li i18n>
      באחריותי לוודא שיש במסגרת האשראי יתרה מספקת לחיוב התשלומים החודשיים למוסד.
    </li>

    <li i18n>
      כרטיס שלא יכובד על ידי חברת האשראי – תבוטל הוראת הקבע שלו מהמערכת. בגין כך יחוייב התלמיד ב25 ₪ עמלה,
      והוא יחסם באופן אוטומטי באתר הלב-נט עד להסדרת התשלום והקמת הוראת קבע חדשה.
    </li>
  </ol>

  <mat-checkbox [checked]="false"
                [(ngModel)]="hasAgreed"
                required
                i18n>
    {gender, select,
    male {הנני מצהיר}
    female {הנני מצהירה}
    }
  </mat-checkbox>

  <br><br>

  <ui-progress-button color="primary"
                      [disabled]="!hasAgreed"
                      [state]="loadState"
                      (progress)="payAsync()"
                      i18n>
    להכנסת פרטי אשראי
  </ui-progress-button>
    </section>
  </section>
</div>
