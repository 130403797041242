import { Injectable, Provider } from "@angular/core";

import { HostEnvironment } from "../environment";
import { Localization } from "@jct/localization/lib/localization.service";
import { ApiInterceptor, API_INTERCEPTORS } from "./types";
import { HttpContext } from "./http-context";
import { HttpStatusResult } from "../api/http-status-result";

export const ACCESS_CONTROL_ORIGIN_HEADER_NAME = "Access-Control-Allow-Origin";
export const APPLICATION_URL_HEADER_NAME = "X-Application-URL";
export const ACCESS_CONTROL_CREDENTIAL_HEADER_NAME =
  "Access-Control-Allow-Credentials";

@Injectable()
export class BasicHttpInterceptor implements ApiInterceptor {
  enabled = true;
  order = 2;

  constructor(
    private environment: HostEnvironment,
    private localization: Localization
  ) {}

  config(context: HttpContext) {
    let language = "";

    if (this.localization.enabled && this.environment.isProduction()) {
      language = `/${this.localization.current.code}`;
    }
    const headers = context.request.headers
      .set(ACCESS_CONTROL_ORIGIN_HEADER_NAME, this.environment.applicationUrl)
      .set(
        APPLICATION_URL_HEADER_NAME,
        `${this.environment.applicationUrl}${language}`
      );

    let url = context.request.url;

    url += (url.includes("?") ? "&" : "?") + "disable_cache=" + Math.random();

    context.request = context.request.clone({
      headers,
      url,
    });
  }

  async end(context: HttpContext) {
    const body = context?.response?.body;
    const status = context?.response?.status;
    const produce = context?.action?.produce;

    if (context.fromServer && body && produce) {
      let result: any;

      if (produce.classType === HttpStatusResult) {
        result = <HttpStatusResult>produce.producer({});
        result.status = status;
        result.statusText = body;
      } else {
        result = produce.producer(body);
      }

      context.setResponseBody(result);
    }
  }
}

export const BasicHttpInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: BasicHttpInterceptor,
  multi: true,
};
