<div container *visible="loadState.isCompleted">
  <form [formGroup]="form" autocomplete="off" novalidate (submit)="register()">
    <p i18n>אנא הזן את כתובת הדוא"ל שלך:</p>

    <mat-form-field appearance="fill">
      <mat-label i18n>כתובת הדוא"ל שלך</mat-label>

      <input
        matInput
        [autofocus]="loadState"
        type="email"
        formControlName="email"
        required
      />

      <mat-hint i18n> אנא הזן את כתובת הדוא"ל שלך </mat-hint>

      <mat-error i18n> אנחנו צריכים כתובת דוא"ל תקינה שלך </mat-error>
    </mat-form-field>

    <ui-progress-button
      color="accent"
      [disabled]="form.invalid"
      [state]="registerState"
      i18n
    >
      התחל בהרשמה </ui-progress-button
    >&nbsp;

    <button
      mat-raised-button
      type="button"
      color="pirmary"
      (click)="reset()"
      i18n
    >
      ביטול
    </button>

    <ui-alert type="warning" *visible="registerState.error?.isInvalidModel">
      <div ui-body i18n>כתובת הדוא"ל שהזנת אינה תקינה</div>
    </ui-alert>

    <ui-error [state]="registerState" [ignoreList]="[400]"> </ui-error>

  </form>
</div>
