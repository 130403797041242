
<mat-form-field

  [appearance]="appearance"
  [floatLabel]="floatLabel"
>
  <mat-label>{{ label }}</mat-label>

  <input matInput
    [matAutocomplete]="autocomplete"
    type="text"
    [formControl]="inputControl"
    [placeholder]="placeholder"
    i18n-placeholder>
</mat-form-field>

<mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete">
  <mat-option *ngIf="isLoading" style="padding: 16px;">
    <mat-spinner diameter="20" color="accent"></mat-spinner>
  </mat-option>

  <ng-container *ngIf="!isLoading">
    <mat-option *ngFor="let result of filteredResults" [value]="result">
      {{ result }}
    </mat-option>
  </ng-container>
</mat-autocomplete>
