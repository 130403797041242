
import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { DormitoryService, RegistrationUserProfile, StudentDormitoryModel } from '@jct/api';
import { PageLayout } from '@jct/localization';

import { UpdateDormRequestsDialogComponent, StateFactory } from '@jct/ui';

@Component({
  selector: 'student-dormitory',
  templateUrl: './student-dormitory.html',
})
export class StudentDormitory {
  constructor(
    private sf: StateFactory,
    private dormitoryService: DormitoryService,
    private userProfile: RegistrationUserProfile,
    public pageLayout: PageLayout,
    private dialog: MatDialog)
  { }

  @Input()
  studentDormitory: StudentDormitoryModel;

  showDormType:boolean;

  updateState = this.sf.create();

  private _updateDormRequestsDialogOpened = false;

  get gender() { return this.userProfile.gender; }
  get thisComponent() { return this; }

  ngOnInit() {
    this.showDormType = this.studentDormitory.dormTypeName != null;
  }

  unsafeDownloadReceiptLink(dormId: number) {
    return this.dormitoryService.unsafeDownloadReceiptLink(dormId);
  }

  async update() {
    if (!this.updateState.isInProcess) {
     await this.updateState.inProcess();

      if (this._updateDormRequestsDialogOpened === false) {
        this._updateDormRequestsDialogOpened = true;

        const dialogRef = this.dialog.open(UpdateDormRequestsDialogComponent, {
          data: {
            dormId: this.studentDormitory.dormId,
            comment: this.studentDormitory.comment
          },
          disableClose: true,
          hasBackdrop: true,
          direction: this.pageLayout.layout,
        });

        dialogRef.beforeClosed().subscribe(() => {
          this._updateDormRequestsDialogOpened = false;
        });

        dialogRef.afterClosed().subscribe((comment) => {
          this.studentDormitory.comment=comment;
        });
      }
      await this.updateState.completed();
    }
  }
}
