
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuService, AppLayoutService } from '@jct/ui/lib/services';
import { PageTitleService } from '@jct/core/lib/meta/page-title.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[ui-toolbar]',
  templateUrl: './toolbar.html',
  styleUrls: ['./toolbar.scss'],
  host: {'class': 'ui-toolbar'},
  exportAs: 'uiToolbar',
  encapsulation: ViewEncapsulation.None,
})
export class UiToolbar implements OnDestroy, OnInit {
  private sub: Subscription;

  constructor(
    public menu: MenuService,
    public appLayout: AppLayoutService,
    public pageTitle: PageTitleService)
  { }

  ngOnInit() {
    this.currentTitle = this.pageTitle.current;
    this.sub = this.pageTitle.currentTitle.subscribe(x => {
      console.log('changed');
      this.currentTitle = x;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  get menuState() {
    return this.menu.hasSidebar ? 'opened' : 'closed';
  }

  currentTitle = '';
}
