
<div container>
  <p i18n>ליצירת קשר או לקבלת עזרה טכנית במערכת ניתן לפנות למשרד מידע ורישום</p>

  <section>
    <h5 i18n>קמפוס לב</h5>

    <p>
      <ui-phone-link color="primary">*3239</ui-phone-link>
      <ui-mail-link color="primary">harshama@jct.ac.il</ui-mail-link>
    </p>
  </section>

  <section>
    <h5 i18n>קמפוס טל</h5>

    <p>
      <ui-phone-link color="primary">*3239</ui-phone-link>
      <ui-mail-link color="primary">harshama-tal@jct.ac.il</ui-mail-link>
    </p>
  </section>

  <section>
    <h5 i18n>קמפוס לוסטיג</h5>

    <p>
      <ui-phone-link color="primary">*3118</ui-phone-link>
      <ui-mail-link color="primary">harshama-lustig@jct.ac.il</ui-mail-link>
    </p>
  </section>

  <section>
    <h5 i18n>מח"ר תבונה</h5>

    <p>
      <ui-phone-link color="primary">*3463</ui-phone-link>
      <ui-mail-link color="primary">tvuna@jct.ac.il</ui-mail-link>
    </p>
  </section>

  <mat-divider></mat-divider>

  <p i18n>ליצירת קשר בעניין תשלומי מקדמות ושוברים</p>

  <section>
    <h5 i18n>קמפוס לב</h5>

    <p>
      <ui-phone-link color="primary">02-6751255</ui-phone-link>
      <ui-mail-link color="primary">heshbonot@jct.ac.il</ui-mail-link>
    </p>
  </section>

  <section>
    <h5 i18n>טל לב</h5>

    <p>
      <ui-phone-link color="primary">02-6547207</ui-phone-link>
      <ui-mail-link color="primary">heshbonot@jct.ac.il</ui-mail-link>
    </p>
  </section>

  <mat-divider></mat-divider>

  <p i18n>ליצירת קשר בעניין שם משתמש וסיסמה</p>

  <section>
    <h5 i18n>תמיכה</h5>

    <p>
      <ui-phone-link color="primary">02-6751152</ui-phone-link>
      <ui-mail-link color="primary">support@jct.ac.il</ui-mail-link>
    </p>
  </section>
</div>
