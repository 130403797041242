
<div container *visible="loadState.isCompleted">
  <ui-alert type="info">
    <div ui-body i18n>
      לתשומת לבך! הטיפול במסמכים שצרפת יתחיל רק לאחר תשלום דמי רישום
      (לא כולל נרשמי תורה ומדע / קרייית הרצוג / טליה).
    </div>
  </ui-alert>

  <mat-card *ngFor="let file of fileRequests" class="mb-10">
    <h4 ui-header>
      {{ file?.typeName }}

      <sub *ngIf="file.isMandatory && !file.studentFile">
        <strong i18n>
          * מסמך חובה
        </strong>
      </sub>
    </h4>

    <div ui-body>
      <markdown>{{ file?.description }}</markdown>

      <a [href]="downloadPath(file)"
        target="_blank"
        mat-raised-button
        color="accent"
        *ngIf="file.studentFile">
        <mat-icon>cloud_download</mat-icon>&nbsp;&nbsp;
        <span i18n>הורדת המסמך</span>
      </a>

      <input type="file" #fileInput
        (change)="upload(file, fileInput)"
        style="visibility: hidden; width: 0; height: 0; position: absolute;" />

      <mat-progress-bar mode="determinate"
        *ngIf="showProgressBar(file)"
        [value]="progress[file.fileType].percents"></mat-progress-bar>

      <button mat-raised-button
        color="primary"
        type="button"
        (click)="fileInput.click()"
        *ngIf="showUploadButton(file)"
        [disabled]="isUploadButtonsDisabled()">
        <mat-icon>attach_file</mat-icon>&nbsp;
        <span i18n>העלאת מסמך</span>
      </button>
    </div>
  </mat-card>
</div>

<ui-snack-bar [duration]="2000" #fileUploadedMessage>
  <ui-snack-bar-message i18n>
    המסמך נשמר בהצלחה
  </ui-snack-bar-message>

  <ui-snack-bar-action  i18n>
    אישור
  </ui-snack-bar-action>
</ui-snack-bar>

<ui-snack-bar [duration]="10000" #errorFileUploadedMessage>
  <ui-snack-bar-message i18n>
    <ui-alert>
      המסמך שהועלה אינו תקין
    </ui-alert>
  </ui-snack-bar-message>

  <ui-snack-bar-action  i18n>
    אישור
  </ui-snack-bar-action>
</ui-snack-bar>


