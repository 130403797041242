
<div container>
  <ui-error [state]="loadState">
  </ui-error>

  <section *visible="loadState.isCompleted">
    <form
      [formGroup]="form"
      novalidate
      autocomplete="off"
      (submit)="identify()"
    >
      <mat-radio-group formControlName="method">
        <mat-label i18n>
          אנא הכניסו את מספר תעודת הזהות או הדרכון שלכם שעימו נרשמתם במערכת
        </mat-label>

        <mat-radio-button value="idNumber" i18n>
          תעודת זהות
        </mat-radio-button>

        <mat-radio-button value="passport" i18n>
          מספר דרכון
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="fill">
        <mat-label i18n>
          {method, select,
            idNumber {תעודת הזהות}
            passport {מספר הדרכון}
          }
          שלך
        </mat-label>

        <input matInput
          [type]="form.value.method == 'idNumber' ? 'number' : 'text'"
          required
          [autofocus]="loadState"
          formControlName="key"
          maxlength="10">

        <mat-hint i18n>
          עליך לוודא
          ש{method, select,
            idNumber {תעודת הזהות}
            passport {מספר הדרכון}
          }
          שלך ולא של משהו אחר
        </mat-hint>

        <mat-error i18n>
          אנא הזן מספר
          {method, select,
            idNumber {תעודת זהות}
            passport {דרכון}
          }
          תקין
        </mat-error>
      </mat-form-field>

      <ui-progress-button
        color="primary"
        [disabled]="form.invalid"
        [state]="identifyState"
        i18n>
        {identifyState.value, select,
          inProcess {מאפס את הסיסמה שלך}
          other {אפס את הסיסמה שלי}
        }
      </ui-progress-button>

      <ui-alert type="warning" *visible="identifyState.error?.isInvalidModel">
        <div ui-body i18n>
          {method, select,
            idNumber {תעודת הזהות שהזנת אינה תקינה}
            passport {מספר הדרכון שהזנת אינו תקין}
          }
        </div>
      </ui-alert>

      <ui-alert type="info" *visible="identifyState.error?.isEntityNotExists">
        <div ui-body i18n>
          מצטערים, אבל איננו מזהים אותך במערכת שלנו
          <br>
          בחר בתפריט
          <strong>התחל בתהליך הרישום</strong>
          לצורך זיהוי מחדש
        </div>
      </ui-alert>

      <ui-error [state]="identifyState"
        [ignoreList]="[400, 404]">
      </ui-error>
    </form>

    <mat-divider></mat-divider>

    <section>
      <p>
        <ui-link to="/start/identify" i18n>
          נזכרת בסיסמה שלך?
        </ui-link>
      </p>
    </section>
  </section>
</div>
