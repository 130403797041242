import { Injectable, Provider } from '@angular/core';

import { ApiInterceptor, API_INTERCEPTORS, HttpContext } from '../api-client';
import { TimeSpan } from '../system';
import { Authenticator } from './authenticator';

@Injectable()
export class AuthenticatorInterceptor implements ApiInterceptor {
  enabled = true;
  order = 5;

  constructor(
    private authenticator: Authenticator)
  { }

  async end(context: HttpContext) {
    if (context.fromServer &&
      this.authenticator.remainderTokenLifetime > 0 &&
      !context.hasError)
    {
      const tokenSettings = { ...this.authenticator.tokenSettings };

      const tokenExpiredOn = new Date();
      const tokenLifeTime = TimeSpan.parse(tokenSettings.tokenLifeTime);

      tokenSettings.tokenExpiredOn = tokenExpiredOn.addMinutes(
        tokenLifeTime.totalMinutes).toJSON();

      this.authenticator.authenticate(tokenSettings);
    }
  }
}

export const AuthenticatorInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: AuthenticatorInterceptor,
  multi: true,
};
