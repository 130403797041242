import { Injectable, Provider } from "@angular/core";

import { ApiInterceptor, API_INTERCEPTORS, HttpContext } from "@jct/core";
import { Localization } from "../localization.service";

export const CULTURE_HEADER_NAME = "X-Culture";

@Injectable()
export class LocalizationInterceptor implements ApiInterceptor {
  enabled = true;
  order = 2;

  constructor(private localization: Localization) {}

  config(context: HttpContext) {
    const headers = context.request.headers
      .set(CULTURE_HEADER_NAME, this.localization.current.code);

    context.request = context.request.clone({
      headers,
    });
  }
}

export const LocalizationInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: LocalizationInterceptor,
  multi: true,
};
