
<meta-title i18n>
הודעה נשלחה אליך במייל
</meta-title>

<div container *visible="loadState.isCompleted">
  <h2 i18n>
    שלחנו לך בדוא"ל קישור לאיפוס הסיסמה שלך
  </h2>

  <p i18n>
    זיהינו אותך בהצלחה,
    <br>
    שלחנו לך בדוא"ל שאיתו נרשמת קישור לאיפוס סיסמה.
    <br>
    <span [hidden]="!showEmailAddress">
    כתובת הדוא"ל שלך המעודכנת במערכת היא:
    <span dir="ltr">{{emailAddress}}</span>
    <br>
    </span>
    <br>
    לאחר הכניסה לקישור תקבל סיסמה חדשה שאיתה תוכל להכנס למערכת.
    <br>
    <br>
    בברכה,
    <br>
    מערכות מידע
  </p>
</div>
