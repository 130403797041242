import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { timer } from "rxjs";

import {
  PaymentTypes,
  AmiramService,
  StandingOrderService,
  VouchersService,
  RegistrationFeeService,
  DonationService,
} from "@jct/api";

@Component({
  selector: "cancel.page",
  templateUrl: "./cancel-page.component.html",
})
export class CancelPageComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private amiramService: AmiramService,
    private standingOrderService: StandingOrderService,
    private vouchersService: VouchersService,
    private registrationFeeService: RegistrationFeeService,
    private donationService: DonationService
  ) {}

  paymentType: PaymentTypes;

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      let queryString = this.router.parseUrl(this.router.url).queryParamMap;

      const paymentType = <PaymentTypes>queryString.get("paymentType");
      const transactionId = queryString.get("txId");

      this.paymentType = paymentType;

      this.cancel(paymentType, transactionId);
    });
  }

  async cancel(paymentType: string, transactionId: string) {
    switch (paymentType) {
      case PaymentTypes.Amiram:
        await this.amiramService.cancelPaymentAsync(transactionId);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/amiram-test");
        break;

      case PaymentTypes.RegistrationFee:
        await this.registrationFeeService.cancelPaymentAsync(transactionId);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/fill-in/registration-fee");
        break;

      case PaymentTypes.Voucher:
        await this.vouchersService.cancelPaymentAsync(transactionId);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/vouchers");
        break;

      case PaymentTypes.StandingOrder:
        await this.standingOrderService.cancelPaymentAsync(transactionId);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/student/standing-order");
        break;

      case PaymentTypes.Donation:
        await this.donationService.cancelPaymentAsync(transactionId);
        await timer(4000).toPromise();
        await this.router.navigateByUrl("/donation-details");
        break;
    }
  }
}
