

export class LdapStatisticsModel {
  name: string;
  lastSync: string;
  entriesCount: number;
  usersCount: number;
  enableUsersCount: number;
  disableUsersCount: number;
  groupsCount: number;
  organizationUnitsCount: number;
  computersCount: number;
}

export enum OwnerKeyTypes {
    IdNumber = 'idNumber',
    Passport = 'passport',
    EmployeeNumber = 'employeeNumber',
  }
  
  export enum UserNotificationStates {
    Idle = 'idle',
    Active = 'active',
    Ended = 'ended',
    Blocked = 'blocked',
    Cancelled = 'cancelled',
  }
  
  export class UserNotification {
    id: number;
    userId: number;
    type: string;
    state: UserNotificationStates;
    counter: number;
    beginAt: Date;
    lastNotification?: Date;
    takeActionByDate?: string;
    takeActionByCounter?: number;
    username?: string;
    userFullname: string;
    email:string;
  }
  
  export class UserPermission {
    id: number;
    userId: number;
    permissionId: number;
    permissionName?: string;
    username?: string;
  }
  
  export class UserRole {
    id: number;
    userId: number;
    roleId: number;
    roleName?: string;
    username?: string;
    expiredOn?: string;
  }
  
  export class UserEmailAddress {
    id: number;
    userId: number;
    email: string;
    emailConfirmed: boolean;
    isPrimary: boolean;
    isPrivate: boolean;
  }
  
  export class UserPhoneNumber {
    id: number;
    userId: number;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    isPrimary: boolean;
  }
  
  export class User {
    id: number;
    ownerKeyNumber?: number;
    ownerKeyType?: OwnerKeyTypes;
    idNumber?: string;
    passport?: string;
    employeeNumber?: number;
    primaryDomain?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    userFullName?: string;
    title?: string;
    description?: string;
    campus?: string;
    department?: string;
    twoFactorEnabled: boolean;
    email?: string;
    phoneNumber?: string;
  
    userNotifications?: UserNotification[];
    userPermissions?: UserPermission[];
    userRoles?: UserRole[];
    emailAddresses?: UserEmailAddress[];
    phoneNumbers?: UserPhoneNumber[];
  }
  

// ------------message module-------------
  export class mailMessage{
    id: number;  
		fromAddress:string;
		fromDisplayName:string;  
		fromUserId:number;  
    subject:string;
		body:string;
		createdOn:Date;
    messageTypeId: number;  
		// mailMessageProcess: mailMessageProcess;  
		// attachments: mailMessageAttachment[];  
		// addresses:mailMessageAddress[];  
  }

  export class messageFilter{
    sentTo: string;
    messageType: number;
    fromDate: Date;
		untillDate: Date;
  }
// ----------end message module-------------
