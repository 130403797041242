
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@jct/core';
import { ApiModule } from '@jct/api';
import { UiModule } from '@jct/ui';
import { L10nModule } from '@jct/localization';
import { TestModule } from '@jct/test';

import { hostEnvironment } from 'projects/registration-app/environments/environment';
import { StudentRoutingModule } from './student-routing.module';
import { AmiramTestPage, AvailableAmiramTests, StudentAmiramTests} from './+amiram-test';
import { ConfirmationPageComponent } from './+confirmation';
import { DormitoryPageComponent, StudentDormitory, DormitoryRegistrationComponent } from './+dormitory';
import { InterviewPageComponent } from './+interview';
import { StandingOrderPage } from './+standing-order';
import { VouchersPage } from './+vouchers';
import { Voucher } from './shared-components';
import { StudentComponent } from './student.component';

@NgModule({
  declarations: [
    StudentComponent,
    AmiramTestPage,
    AvailableAmiramTests,
    StudentAmiramTests,
    ConfirmationPageComponent,
    DormitoryPageComponent,
    StudentDormitory,
    DormitoryRegistrationComponent,
    InterviewPageComponent,
    StandingOrderPage,
    VouchersPage,
    Voucher,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    StudentRoutingModule,
  ],
})
export class StudentModule { }
