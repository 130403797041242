
import { Component, OnInit, Input } from '@angular/core';

import { VouchersService, VoucherModel } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { StateFactory } from '@jct/ui';

import { VoucherParent } from './voucher-parent';

@Component({
  selector: 'voucher',
  templateUrl: './voucher.html',
})
export class Voucher implements OnInit{
  constructor(
    private vouchersService: VouchersService,
    private sf: StateFactory)
  { }

  @Input()
  voucherDetails: VoucherModel;

  @Input()
  parent: VoucherParent|null = null;

  beginPaymentState = this.sf.create();

  _selectedNumPayments: number;
  paymentOptions: number[];

  async ngOnInit() {
    this._selectedNumPayments = this.voucherDetails.numPayments == 1 ? 1 : null;
    const options = [];

    for (let index = 0; index < this.voucherDetails.numPayments; index++) {
      options.push(index+1);
    }

    this.paymentOptions = options;
  }

  get selectedNumPayments() {
    return this._selectedNumPayments;
  }

  set selectedNumPayments(value){
    this._selectedNumPayments = value;
  }

  get downloadLink() {
    return `${this.vouchersService.baseUrl}/${this.voucherDetails.voucherIdentity}`;
  }

  async payVoucher() {
    if (this.beginPaymentState.isInProcess) {
      return;
    }

    this.beginPaymentState.inProcess();

    const result = await this.vouchersService.beginPaymentProcessViaCGAsync(
      this.voucherDetails.voucherIdentity,
      this.selectedNumPayments);

    if (result instanceof HttpStatusResult) {
      this.beginPaymentState.failed(result);
      return;
    }

    location.href = result;
  }
}
