
import { Component, OnInit } from '@angular/core';

import { NewRegistrationDialogComponent, StateFactory } from '@jct/ui';
import { RegistrationStatusService, RegistrationStatusModel, ProfileService, StudyTrackService, StudyTrack, RegistrationUserProfile, RegistrationNavigator, RegistrationProfile } from '@jct/api';
import { HttpStatusResult } from '@jct/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PageLayout } from '@jct/localization';

@Component({
  selector: 'registration-status.page',
  templateUrl: './registration-status-page.html',
})
export class RegistrationStatusPage implements OnInit {
  constructor(
    private sf: StateFactory,
    private registrationStatusService: RegistrationStatusService,
    private studyTrackService: StudyTrackService,
    private profileService: ProfileService,
    private userProfile: RegistrationUserProfile,
    public pageLayout: PageLayout,
    private dialog: MatDialog)
  { }

  loadState = this.sf.create();
  registerState = this.sf.create();

  registrationStatus: RegistrationStatusModel;
  mainStudentStudyTrack: StudyTrack;

  get gender() {
    return this.userProfile.gender;
  }

  private _newRegistrationDialogOpened = false;

  async ngOnInit() {
    await this.loadState.inProcess();

    const loadRegistrationResult = await this.profileService.loadRegistrationProfile();

    if (loadRegistrationResult instanceof HttpStatusResult) {
      this.loadState.failed(loadRegistrationResult);
      return;
    }

    const mainStudentStudyTrack = await this.studyTrackService.getMainStudentStudyTrackAsync();

    if (mainStudentStudyTrack instanceof HttpStatusResult) {
      this.loadState.failed(mainStudentStudyTrack);
      return;
    }

    this.mainStudentStudyTrack = mainStudentStudyTrack;

    const result = await this.registrationStatusService.getRegistrationStatusAsync();

    if (result instanceof HttpStatusResult) {
      this.loadState.failed(result);
      return;
    }

    this.registrationStatus = result;
    await this.loadState.completed();
  }

  async register() {
    if (!this.registerState.isInProcess) {
     await this.registerState.inProcess();

      if (this._newRegistrationDialogOpened === false) {
        this._newRegistrationDialogOpened = true;

        const dialogRef = this.dialog.open(NewRegistrationDialogComponent, {
          data: { mainStudentStudyTrack: this.mainStudentStudyTrack },
          disableClose: true,
          hasBackdrop: true,
          direction: this.pageLayout.layout,
        });

        dialogRef.beforeClosed().subscribe(() => {
          this._newRegistrationDialogOpened = false;
        });
      }
      await this.registerState.completed();
    }
  }
}
