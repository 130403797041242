
export interface LetterInfo {
  letter: string;
  locale: string;
  isVowels?: boolean;
  suffix?: string;
  incidence?: number;
}

export const AllLettersInfo: LetterInfo[] = [
  // hebrew letters
  {
    letter: 'א',
    locale: 'he',
    isVowels: true,
    incidence: 27059 / 304805,
  },
  {
    letter: 'ב',
    locale: 'he',
    isVowels: false,
    incidence: 16345 / 304805,
  },
  {
    letter: 'ג',
    locale: 'he',
    isVowels: false,
    incidence: 2109 / 304805,
  },
  {
    letter: 'ד',
    locale: 'he',
    isVowels: false,
    incidence: 7032 / 304805,
  },
  {
    letter: 'ה',
    locale: 'he',
    isVowels: true,
    incidence: 28056 / 304805,
  },
  {
    letter: 'ו',
    locale: 'he',
    isVowels: true,
    incidence: 30513 / 304805,
  },
  {
    letter: 'ז',
    locale: 'he',
    isVowels: false,
    incidence: 2198 / 304805,
  },
  {
    letter: 'ח',
    locale: 'he',
    isVowels: false,
    incidence: 7189 / 304805,
  },
  {
    letter: 'ט',
    locale: 'he',
    isVowels: false,
    incidence: 1804 / 304805,
  },
  {
    letter: 'י',
    locale: 'he',
    isVowels: true,
    incidence: 31531 / 304805,
  },
  {
    letter: 'כ',
    locale: 'he',
    isVowels: false,
    suffix: 'ך',
    incidence: (8610 + 3358) / 304805,
  },
  {
    letter: 'ל',
    locale: 'he',
    isVowels: false,
    incidence: 21570 / 304805,
  },
  {
    letter: 'מ',
    locale: 'he',
    isVowels: false,
    suffix: 'ם',
    incidence: (14466 + 10624) / 304805,
  },
  {
    letter: 'נ',
    locale: 'he',
    isVowels: false,
    suffix: 'ן',
    incidence: (9867 + 4259) / 304805,
  },
  {
    letter: 'ס',
    locale: 'he',
    isVowels: false,
    incidence: 1833 / 304805,
  },
  {
    letter: 'ע',
    locale: 'he',
    isVowels: false,
    incidence: 11250 / 304805,
  },
  {
    letter: 'פ',
    locale: 'he',
    isVowels: false,
    suffix: 'ף',
    incidence: (3975 + 830) / 304805,
  },
  {
    letter: 'צ',
    locale: 'he',
    isVowels: false,
    suffix: 'ץ',
    incidence: (2927 + 1035) / 304805,
  },
  {
    letter: 'ק',
    locale: 'he',
    isVowels: false,
    incidence: 4695 / 304805,
  },
  {
    letter: 'ר',
    locale: 'he',
    isVowels: false,
    incidence: 18125 / 304805,
  },
  {
    letter: 'ש',
    locale: 'he',
    isVowels: false,
    incidence: 15595 / 304805,
  },
  {
    letter: 'ת',
    locale: 'he',
    isVowels: false,
    incidence: 17950 / 304805,
  },
  // english letters
  {
    letter: 'a',
    locale: 'en',
    isVowels: true,
    incidence: 0.0804,
  },
  {
    letter: 'b',
    locale: 'en',
    isVowels: false,
    incidence: 0.0154,
  },
  {
    letter: 'c',
    locale: 'en',
    isVowels: false,
    incidence: 0.0306,
  },
  {
    letter: 'd',
    locale: 'en',
    isVowels: false,
    incidence: 0.0399,
  },
  {
    letter: 'e',
    locale: 'en',
    isVowels: true,
    incidence: 0.1251,
  },
  {
    letter: 'f',
    locale: 'en',
    isVowels: false,
    incidence: 0.0230,
  },
  {
    letter: 'g',
    locale: 'en',
    isVowels: false,
    incidence: 0.0196,
  },
  {
    letter: 'h',
    locale: 'en',
    isVowels: false,
    incidence: 0.0549,
  },
  {
    letter: 'i',
    locale: 'en',
    isVowels: true,
    incidence: 0.0726,
  },
  {
    letter: 'j',
    locale: 'en',
    isVowels: false,
    incidence: 0.0016,
  },
  {
    letter: 'k',
    locale: 'en',
    isVowels: false,
    incidence: 0.0067,
  },
  {
    letter: 'l',
    locale: 'en',
    isVowels: false,
    incidence: 0.0414,
  },
  {
    letter: 'm',
    locale: 'en',
    isVowels: false,
    incidence: 0.0253,
  },
  {
    letter: 'n',
    locale: 'en',
    isVowels: false,
    incidence: 0.0709,
  },
  {
    letter: 'o',
    locale: 'en',
    isVowels: true,
    incidence: 0.0760,
  },
  {
    letter: 'p',
    locale: 'en',
    isVowels: false,
    incidence: 0.0200,
  },
  {
    letter: 'q',
    locale: 'en',
    isVowels: false,
    incidence: 0.0011,
  },
  {
    letter: 'r',
    locale: 'en',
    isVowels: false,
    incidence: 0.0612,
  },
  {
    letter: 's',
    locale: 'en',
    isVowels: false,
    incidence: 0.0654,
  },
  {
    letter: 't',
    locale: 'en',
    isVowels: false,
    incidence: 0.0925,
  },
  {
    letter: 'u',
    locale: 'en',
    isVowels: true,
    incidence: 0.0271,
  },
  {
    letter: 'v',
    locale: 'en',
    isVowels: false,
    incidence: 0.0099,
  },
  {
    letter: 'w',
    locale: 'en',
    isVowels: true,
    incidence: 0.0192,
  },
  {
    letter: 'x',
    locale: 'en',
    isVowels: false,
    incidence: 0.0019,
  },
  {
    letter: 'y',
    locale: 'en',
    isVowels: true,
    incidence: 0.0173,
  },
  {
    letter: 'z',
    locale: 'en',
    isVowels: false,
    incidence: 0.0009,
  },
];

export const HebrewLettersInfo = AllLettersInfo.filter(x => x.locale == 'he');
export const HebrewVowelsLettersInfo = HebrewLettersInfo.filter(x => x.isVowels);
export const HebrewNonVowelsLettersInfo = HebrewLettersInfo.filter(x => !x.isVowels);
export const EnglishLettersInfo = AllLettersInfo.filter(x => x.locale == 'en');
export const EnglishVowelsLettersInfo = EnglishLettersInfo.filter(x => x.isVowels);
export const EnglishNonVowelsLettersInfo = EnglishLettersInfo.filter(x => !x.isVowels);
