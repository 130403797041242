
<h1 mat-dialog-title class="mb-5">
  <strong i18n>התנתקות מהמערכת</strong>
</h1>

<section mat-dialog-content>
  <p i18n>
    האם הנך רוצה להתנתק מהמערכת?
  </p>
</section>

<section mat-dialog-actions>
  <button mat-raised-button
    color="primary"
    type="button"
    (click)="cancel()">
    <strong i18n>ביטול</strong>
  </button>&nbsp;&nbsp;

  <button mat-raised-button
    color="accent"
    type="button"
    (click)="logMeOut()">
    <strong i18n>תנתקו אותי</strong>
  </button>
</section>
