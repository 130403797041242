
import { Component } from '@angular/core';

import { RegistrationUserProfile } from '@jct/api';

@Component({
  selector: 'upload.page',
  templateUrl: './upload-page.component.html',
})
export class UploadPageComponent {
  constructor(
    public profile: RegistrationUserProfile)
  { }
}
