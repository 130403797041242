import { HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';

import { TestBuilders } from '@jct/core';
import { Gender } from '@jct/localization';
import { RegistrationUserProfile } from '../profile/registration-user-profile';
import { StudentYearlyStudyTracks, StudyTrack } from './study-track.types';

export const STUDENT_YEARLY_STUDY_TRACKS_PROVIDER = (injector: Injector, request: HttpRequest<any>) => {
  const profile = injector.get(RegistrationUserProfile);

  let length = TestBuilders.numberBetween(1, 3)();

  let studyTypes = FAKE_STUDY_TRACKS.map(x => x.academicStudyTypeId);
  studyTypes = studyTypes.filter((x, index) => studyTypes.indexOf(x) === index);

  const academicStudyTypeId = profile?.academicStudyType || TestBuilders.oneOf(studyTypes)();
  const gender = profile?.gender || TestBuilders.oneOf([Gender.Male, Gender.Female])();

  let allTracks = FAKE_STUDY_TRACKS.filter(x =>
    x.academicStudyTypeId === academicStudyTypeId &&
    x.gender === gender);

  let allAcademicYears = allTracks.map(x => x.academicYear);
  allAcademicYears = allAcademicYears.filter((x, index) => allAcademicYears.indexOf(x) === index);

  let institutes = allTracks.map(x => x.instituteId);
  institutes = institutes.filter((x, index) => institutes.indexOf(x) === index);

  const academicYear = TestBuilders.oneOf(allAcademicYears)();
  const institute = TestBuilders.oneOf(institutes)();

  allTracks = allTracks.filter(x =>
    x.academicYear === academicYear &&
    x.instituteId === institute);

  const tracks: StudyTrack[] = [];
  const indexes = allTracks.map((x, idx) => idx);

  length = Math.min(length, allTracks.length);

  for (let index = 0; index < length; index++) {
    const next = TestBuilders.numberBetween(0, indexes.length - 1);
    const idx = next();

    tracks.push(allTracks[idx]);
    indexes.splice(idx, 1);
  }

  let result = new StudentYearlyStudyTracks();

  Object.assign(result, {
    tracks: tracks.map(x => x.trackId).distinct(),
    academicYear,
    academicStudyTypeId
  });

  return result;
};


export const FAKE_STUDY_TRACKS: StudyTrack[] = [
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 6,
    "departmentName": "חשבונאות ומערכות מידע",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 30,
    "trackName": "חשבונאות ומערכות מידע"
  },
  {
    "academicStudyTypeId": 5,
    "academicStudyTypeName": "שנת השלמה בחשבונאות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 15,
    "departmentName": "שנת השלמה",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 32,
    "trackName": "שנת השלמה חשבונאות"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 10,
    "departmentName": "הנדסת תעשיה וניהול",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 45,
    "trackName": "הנדסת תעשיה וניהול"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 12,
    "departmentName": "פיסיקה יישומית / הנדסת אלקטרו-אופטיקה",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 51,
    "trackName": "הנדסת אלקטרו-אופטיקה"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 13,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 58,
    "trackName": "מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 23,
    "departmentName": "חשבונאות ומערכות מידע",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 86,
    "trackName": "חשבונאות ומערכות מידע"
  },
  {
    "academicStudyTypeId": 5,
    "academicStudyTypeName": "שנת השלמה בחשבונאות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 30,
    "departmentName": "שנת השלמה",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 88,
    "trackName": "שנת השלמה חשבונאות"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 26,
    "departmentName": "הנדסת תעשיה וניהול",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 93,
    "trackName": "הנדסת תעשיה וניהול"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 29,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 103,
    "trackName": "מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 38,
    "departmentName": "חשבונאות ומערכות מידע",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 137,
    "trackName": "חשבונאות ומערכות מידע"
  },
  {
    "academicStudyTypeId": 5,
    "academicStudyTypeName": "שנת השלמה בחשבונאות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 46,
    "departmentName": "שנת השלמה",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 139,
    "trackName": "שנת השלמה חשבונאות"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 42,
    "departmentName": "הנדסת תעשיה וניהול",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 152,
    "trackName": "הנדסת תעשיה וניהול"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 44,
    "departmentName": "פיסיקה יישומית / הנדסת אלקטרו-אופטיקה",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 157,
    "trackName": "הנדסת אלקטרו-אופטיקה"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 45,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 164,
    "trackName": "מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 52,
    "departmentName": "חשבונאות ומערכות מידע",
    "gender": Gender.Female,
    "instituteId": 5,
    "instituteName": "מכון לוסטיג",
    "trackId": 177,
    "trackName": "חשבונאות ומערכות מידע"
  },
  {
    "academicStudyTypeId": 5,
    "academicStudyTypeName": "שנת השלמה בחשבונאות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 57,
    "departmentName": "שנת השלמה",
    "gender": Gender.Female,
    "instituteId": 5,
    "instituteName": "מכון לוסטיג",
    "trackId": 179,
    "trackName": "שנת השלמה חשבונאות"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 55,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 5,
    "instituteName": "מכון לוסטיג",
    "trackId": 188,
    "trackName": "מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 13,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 208,
    "trackName": "ישיבה תיכונית"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 79,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 9,
    "instituteName": "מכינות - טל",
    "trackId": 228,
    "trackName": "ישראלים"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 29,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 236,
    "trackName": "טליה"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 102,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 14,
    "instituteName": "מבח\"ר בנים",
    "trackId": 239,
    "trackName": "מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 13,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 280,
    "trackName": "קרית הרצוג"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 13,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 287,
    "trackName": "דוברי אנגלית"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 45,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 288,
    "trackName": "דוברי אנגלית"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 122,
    "departmentName": "ביואינפורמטיקה",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 294,
    "trackName": "ביואינפורמטיקה"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 127,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 28,
    "instituteName": "מכינת תבונה",
    "trackId": 342,
    "trackName": "מכינה קדם אקדמית"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 55,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 5,
    "instituteName": "מכון לוסטיג",
    "trackId": 347,
    "trackName": "מדעי המחשב-שילוב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 139,
    "departmentName": "סיעוד",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 393,
    "trackName": "סיעוד"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 140,
    "departmentName": "סיעוד",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 394,
    "trackName": "סיעוד"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 60,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Male,
    "instituteId": 6,
    "instituteName": "מכינות - לב",
    "trackId": 400,
    "trackName": "מכינה תלמידי חו\"ל"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 79,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 9,
    "instituteName": "מכינות - טל",
    "trackId": 411,
    "trackName": "מכינה תלמידי חו\"ל"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 12,
    "departmentName": "פיסיקה יישומית / הנדסת אלקטרו-אופטיקה",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 418,
    "trackName": "ישיבה תיכונית"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 166,
    "departmentName": "סיעוד",
    "gender": Gender.Female,
    "instituteId": 32,
    "instituteName": "מכינת אופק",
    "trackId": 433,
    "trackName": "אופק-סיעוד"
  },
  {
    "academicStudyTypeId": 6,
    "academicStudyTypeName": "לימודי תעודה",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 163,
    "departmentName": "אלקטרוניקה הנדסת הספק",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 434,
    "trackName": "השלמה להנדסת חשמל/מערכות הספק"
  },
  {
    "academicStudyTypeId": 6,
    "academicStudyTypeName": "לימודי תעודה",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 164,
    "departmentName": "אלקטרוניקה הנדסת הספק",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 435,
    "trackName": "השלמה להנדסת חשמל/מערכות הספק"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 29,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 473,
    "trackName": "דוברי אנגלית"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 174,
    "departmentName": "חשבונאות ומערכות מידע",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 483,
    "trackName": "חשבונאות ומערכות מידע"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 173,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 485,
    "trackName": "מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 173,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 486,
    "trackName": "השלמה לתואר במדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 173,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 489,
    "trackName": "אופק-מדעי המחשב"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 173,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 493,
    "trackName": "מדעי המחשב-שילוב"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 60,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Male,
    "instituteId": 6,
    "instituteName": "מכינות - לב",
    "trackId": 500,
    "trackName": "מכינה ייעודית להנדסה ומחשבים"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 60,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Male,
    "instituteId": 6,
    "instituteName": "מכינות - לב",
    "trackId": 501,
    "trackName": "מכינה ייעודית לחשבונאות ומנהל עסקים"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 13,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 505,
    "trackName": "הנדסת תוכנה (מדע הנתונים)"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 29,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 509,
    "trackName": "הנדסת תוכנה (מדע הנתונים)"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 173,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 513,
    "trackName": "הנדסת תוכנה (מדע הנתונים)"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 55,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 5,
    "instituteName": "מכון לוסטיג",
    "trackId": 516,
    "trackName": "הנדסת תוכנה (מדע הנתונים)"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 45,
    "departmentName": "מחשבים",
    "gender": Gender.Male,
    "instituteId": 3,
    "instituteName": "מכון נוה",
    "trackId": 520,
    "trackName": "הנדסת תוכנה (מדע הנתונים)"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 60,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Male,
    "instituteId": 6,
    "instituteName": "מכינות - לב",
    "trackId": 522,
    "trackName": "מכינה לסיעוד"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 159,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 32,
    "instituteName": "מכינת אופק",
    "trackId": 524,
    "trackName": "מכינת אופק למחשבים חצי שנתי"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 159,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 32,
    "instituteName": "מכינת אופק",
    "trackId": 525,
    "trackName": "מכינת אופק לסיעוד חצי שנתי"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 159,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 32,
    "instituteName": "מכינת אופק",
    "trackId": 526,
    "trackName": "מכינת אופק לסיעוד שנתי"
  },
  {
    "academicStudyTypeId": 4,
    "academicStudyTypeName": "תואר שני",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 182,
    "departmentName": "כריית מידע M.Sc.",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 527,
    "trackName": "כריית מידע"
  },
  {
    "academicStudyTypeId": 4,
    "academicStudyTypeName": "תואר שני",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 182,
    "departmentName": "כריית מידע M.Sc.",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 528,
    "trackName": "כריית מידע עם תזה"
  },
  {
    "academicStudyTypeId": 4,
    "academicStudyTypeName": "תואר שני",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 183,
    "departmentName": "כריית מידע M.Sc.",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 529,
    "trackName": "כריית מידע"
  },
  {
    "academicStudyTypeId": 4,
    "academicStudyTypeName": "תואר שני",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 183,
    "departmentName": "כריית מידע M.Sc.",
    "gender": Gender.Female,
    "instituteId": 2,
    "instituteName": "מכון טל",
    "trackId": 530,
    "trackName": "כריית מידע עם תזה"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 173,
    "departmentName": "מחשבים",
    "gender": Gender.Female,
    "instituteId": 34,
    "instituteName": "מח\"ר-טל תבונה",
    "trackId": 533,
    "trackName": "דרכי שרה"
  },
  {
    "academicStudyTypeId": 4,
    "academicStudyTypeName": "תואר שני",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 185,
    "departmentName": "הנדסת תקשורת מחשבים",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 541,
    "trackName": "הנדסת תקשורת מחשבים MSc"
  },
  {
    "academicStudyTypeId": 2,
    "academicStudyTypeName": "מכינות",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 81,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Female,
    "instituteId": 12,
    "instituteName": "מכינת מח\"ר טל",
    "trackId": 549,
    "trackName": "מכינת תבונה-מחשבים"
  },
  {
    "academicStudyTypeId": 3,
    "academicStudyTypeName": "תואר ראשון",
    "academicYear": 5781,
    "academicYearTitle": "תשפ\"א",
    "departmentId": 189,
    "departmentName": "מכינה קדם אקדמית",
    "gender": Gender.Male,
    "instituteId": 1,
    "instituteName": "מכון לב",
    "trackId": 552,
    "trackName": "מכינה משולבת לתלמידי חו\"ל"
  }
];
