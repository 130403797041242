
<button mat-raised-button
  [color]="color"
  [type]="type"
  [disabled]="state.isInProcess || disabled || form?.invalid"
  [class.in-process]="state.isInProcess"
  (click)="onClick()"
>
  <span *visible="state.isInProcess; transition 'curtain'"
    class="spinner"
  >
    <mat-progress-spinner
      mode="indeterminate"
      strokeWidth="1.5"
      diameter="19">
    </mat-progress-spinner>
  </span>

  <span class="body">
    <ng-content></ng-content>
  </span>
</button>
