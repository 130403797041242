
import { Injectable, Injector } from '@angular/core';

import { resultOf, Controller, HttpGet, Produce, Profiler, SuccessTestCase } from '@jct/core';
import { RegistrationProfile } from './registration.types';
import { IdmProfile } from './idm.types';

import {
  NewUserRegistrationProfile,
  MaleStudentRegistrationProfile,
  FemaleStudentRegistrationProfile,
  MaleStudentStageBRegistrationProfile,
  FemaleStudentStageBRegistrationProfile
} from './registration.test';
import { ExamsProfile } from '../../exams';
import { LersProfile } from '../../lers';

@Injectable({ providedIn: "root" })
@Controller()
export class ProfileService {
  constructor(protected injector: Injector, private profiler: Profiler) {}

  get profile() {
    return this.profiler.profile;
  }

  get hasProfile() {
    return this.profiler.hasProfile;
  }

  update<T = any>(profile: T) {
    const updatedProfile = { ...this.profiler.profile, ...profile };
    this.profiler.store(updatedProfile);
  }

  @HttpGet("account/registration/profile")
  @SuccessTestCase(NewUserRegistrationProfile)
  @SuccessTestCase(MaleStudentRegistrationProfile)
  @SuccessTestCase(FemaleStudentRegistrationProfile)
  @SuccessTestCase(MaleStudentStageBRegistrationProfile)
  @SuccessTestCase(FemaleStudentStageBRegistrationProfile)
  @Produce(RegistrationProfile)
  private _loadRegistrationProfile() {
    return resultOf<RegistrationProfile>();
  }

  @HttpGet("account/profile")
  @SuccessTestCase(NewUserRegistrationProfile)
  @SuccessTestCase(MaleStudentRegistrationProfile)
  @SuccessTestCase(FemaleStudentRegistrationProfile)
  @SuccessTestCase(MaleStudentStageBRegistrationProfile)
  @SuccessTestCase(FemaleStudentStageBRegistrationProfile)
  @Produce(LersProfile)
  private _loadLersProfile() {
    return resultOf<LersProfile>();
  }

  @HttpGet("account/idm/profile")
  @Produce(IdmProfile)
  private _loadIdmProfile() {
    return resultOf<IdmProfile>();
  }
  @HttpGet("account/exams/profile")
  @Produce(ExamsProfile)
  private _loadExamsProfile() {
    return resultOf<ExamsProfile>();
  }

  async loadRegistrationProfile() {
    return await this.profiler.load(() => this._loadRegistrationProfile());
  }

  async loadIdmProfile() {
    return await this.profiler.load(() => this._loadIdmProfile());
  }

  async loadLersProfile() {
    return await this.profiler.load(() => this._loadLersProfile());
  }

  async loadExamsProfile() {
    return await this.profiler.load(() => this._loadExamsProfile());
  }
}
