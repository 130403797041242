
<nav mat-tab-nav-bar color="accent" updateLayout dir>
  <a mat-tab-link
    routerLink="/fill-in/statements/health"
    i18n>
    הצהרת בריאות
  </a>

  <a mat-tab-link
    routerLink="/fill-in/statements/general"
    i18n>
    הצהרות כלליות
  </a>

  <a *ngIf="!hideCriminalRecordTab"
    mat-tab-link
    routerLink="/fill-in/statements/criminal-record"
    i18n>
    הצהרת העדר רישום פלילי
  </a>
</nav>

<section class="tab-place-holder">
  <router-outlet></router-outlet>
</section>
