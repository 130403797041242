import { Injectable, Provider } from '@angular/core';

import { HttpMethod } from '../api';
import { Application } from '../application';
import { HostEnvironment } from '../environment';
import { Map } from '../common';
import { ApiInterceptor, API_INTERCEPTORS, HttpContext } from '../api-client';

interface Performance {
  method: HttpMethod;
  url: string;
  average: number;
  requests: number[];
}

export const LOGGING_FEATURE = 'logging';

@Injectable()
export class LoggerInterceptor implements ApiInterceptor {
  enabled = true;
  order = 1;

  constructor(
    private environment: HostEnvironment,
    private application: Application)
  {
    this.enabled = !!this.application.settings[LOGGING_FEATURE];
  }

  private _performanceMap: Map<Performance> = {};

  async begin(context: HttpContext) {
    context.items['timeTaken'] = Date.now();
  }

  async end(context: HttpContext) {
    const key = `${context.method}_${context.url}`;
    const duration = Date.now() - context.items['timeTaken'];

    this._performanceMap[key] = this._performanceMap[key] || {
      method: context.method,
      url: context.url,
      requests: [],
      average: 0,
    };

    const route = this._performanceMap[key];

    route.requests.push(duration);
    route.average = route.requests.reduce((acc, cur) => acc + cur) / route.requests.length;

    if (!this.environment.isProduction() && context.fromServer && console.table) {
      console.table({
        [`${context.method} /${context.url}`]: !!context.error ? `FAILED (${context.response.status})` : `SUCCESS (${context.response.status})`,
        'response time': `${duration}ms`,
        'requests count': route.requests.length,
        'average response time': `${Math.round(route.average)}ms`,
      });
    }
  }
}

export const LoggerInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: LoggerInterceptor,
  multi: true,
};
