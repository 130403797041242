
import { Component, OnInit } from '@angular/core';

import { Connectivity } from '@jct/core';

@Component({
  selector: 'offline.page',
  templateUrl: './offline.page.html',
})
export class OfflinePage implements OnInit {
  constructor(
    private connectivity: Connectivity)
  { }

  async ngOnInit() {
    if (this.connectivity.isOnline) {
      this.connectivity.goBack();
    }
  }
}
