import { ProvideValue, TestBuilders, TestDescription } from '@jct/core';
import { Gender } from '@jct/localization';
import { CitizenshipTypes, ContactTypes, FamilyStates, OccupationTypes, PersonContact, PersonDetails, PersonRelative, RelationTypes, VoluntaryServiceTypes } from './person.types';

import { FAKE_CITIES, FAKE_COUNTRIES, FAKE_STREETS } from '../information/information.test';

const COUNTRY_PROVIDER = TestBuilders.oneOf(FAKE_COUNTRIES);
const CITY_PROVIDER = TestBuilders.oneOf(FAKE_CITIES);
const STREET_PROVIDER = TestBuilders.oneOf(FAKE_STREETS);
const CELLULAR_PHONE_PROVIDER = TestBuilders.phoneNumber(['050', '051', '052', '053', '054', '055', '058'], 7);
const WORK_PHONE_PROVIDER = TestBuilders.phoneNumber(['02', '03', '04', '08', '09'], 7);

const RELATION_TYPE_PROVIDER = TestBuilders.oneOf([
  RelationTypes.Boyfriend,
  RelationTypes.Brother,
  RelationTypes.Daughter,
  RelationTypes.Father,
  RelationTypes.Girlfriend,
  RelationTypes.Mother,
  RelationTypes.Sister,
  RelationTypes.Son,
  RelationTypes.Spouse,
]);

const OCCUPATION_TYPE_PROVIDER = TestBuilders.oneOf([
  OccupationTypes.Employee,
  OccupationTypes.PartiallyEmployed,
  OccupationTypes.Passed,
  OccupationTypes.Pension,
  OccupationTypes.SelfEmployed,
  OccupationTypes.Studying,
  OccupationTypes.Unemployed,
]);

@TestDescription('פרטים אישיים - גבר')
export class FakeMalePersonDetails extends PersonDetails {
  gender = Gender.Male;
  firstName = 'ישראל';
  lastName = 'ישראלי';
  englishFirstName = 'Israel';
  englishLastName = 'Israeli';
  birthDate = '1990-01-01T00:00:00.000Z';
  familyState = FamilyStates.Single;
  birthCountry = 'ישראל';
  citizenship = CitizenshipTypes.Citizen;
  voluntaryServiceType = VoluntaryServiceTypes.ArmyCompulsoryService;
}

@TestDescription('פרטים אישיים - אישה')
export class FakeFemalePersonDetails extends PersonDetails {
  gender = Gender.Female;
  firstName = 'ישראלית';
  lastName = 'ישראלי';
  englishFirstName = 'Israelit';
  englishLastName = 'Israeli';
  birthDate = '1990-06-01T00:00:00.000Z';
  familyState = FamilyStates.Married;
  marriageDate = '2018-02-11T00:00:00.000Z';
  birthCountry = 'ישראל';
  citizenship = CitizenshipTypes.Citizen;
  voluntaryServiceType = VoluntaryServiceTypes.NationalService;
}

@TestDescription('פרטי התקשרות')
export class FakePersonContact extends PersonContact {
  id = 1;
  personId = 1;
  contactType = ContactTypes.Self;
  @ProvideValue(COUNTRY_PROVIDER)
  zcountry?: string;
  state?: string;
  @ProvideValue(CITY_PROVIDER)
  city?: string;
  @ProvideValue(STREET_PROVIDER)
  street?: string;
  @ProvideValue('fake-email@organization.com')
  email?: string;
  @ProvideValue(CELLULAR_PHONE_PROVIDER)
  cellularPhone?: string;
}

@TestDescription('קבלת פרטי קרובים')
export class FakePersonRelative extends PersonRelative {
  @ProvideValue(RELATION_TYPE_PROVIDER)
  relationType: RelationTypes;
  @ProvideValue('ישראל')
  firstName: string;
  @ProvideValue('ישראלי')
  lastName: string;
  @ProvideValue(COUNTRY_PROVIDER)
  birthCountry?: string;
  @ProvideValue(TestBuilders.numberBetween(1, 12))
  educationYears?: number;
  @ProvideValue(OCCUPATION_TYPE_PROVIDER)
  occupationType?: OccupationTypes;
  @ProvideValue(WORK_PHONE_PROVIDER)
  workPhone?: string;
}
