
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromRoute,
  FromBody,
  HttpStatusResult,
  Produce,
  SuccessTestCase,
  FailedTestCase,
  VerificationFailedResult,
  SuccessResult,
  UnauthorizedResult,
} from '@jct/core';
import { CREDIT_GUARD_TEST_PAYMENT_PROVIDER } from './payment.test';

@Injectable({ providedIn: 'root' })
@Controller('standing-order')
export class StandingOrderService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet()
  @SuccessTestCase(true, 'קיימת הוראת קבע במערכת')
  @SuccessTestCase(false, 'לא קיימת הוראת קבע במערכת')
  getPaymentDetailsAsync() {
    return resultOf<boolean>();
  }

  @HttpPost()
  @SuccessTestCase(CREDIT_GUARD_TEST_PAYMENT_PROVIDER(true), 'קבלת קישור להסדר הוראת קבע ב-CreditGuard')
  @FailedTestCase(UnauthorizedResult)
  @Produce('string')
  beginPaymentProcessViaCGAsync() {
    return resultOf<string>();
  }

  @HttpPost('{transactionId}/success')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(VerificationFailedResult)
  @Produce(HttpStatusResult)
  endPaymentAsync(
    @FromRoute('transactionId') transactionId: string,
    @FromBody('token') token: string) {
    return resultOf();
  }

  @HttpPost('{transactionId}/failed')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(VerificationFailedResult)
  @Produce(HttpStatusResult)
  receiveFailedPaymentAsync(
    @FromRoute('transactionId') transactionId: string) {
    return resultOf();
  }

  @HttpPost('{transactionId}/cancel')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(VerificationFailedResult)
  @Produce(HttpStatusResult)
  cancelPaymentAsync(
    @FromRoute('transactionId') transactionId: string) {
    return resultOf();
  }
}
