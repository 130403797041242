
<h1 mat-dialog-title i18n>
  עדכון הבקשות שלי
</h1>

<section mat-dialog-content>
  <mat-form-field appearance="fill">
    <textarea
      matInput
      [(ngModel)]="data.comment"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="3"
    ></textarea>
  </mat-form-field>
</section>

<section mat-dialog-actions>
  <button mat-raised-button
    color="accent"
    type="button"
    (click)="back()">
    <strong i18n>ביטול</strong>
  </button>&nbsp;&nbsp;

  <button mat-raised-button
    color="primary"
    type="button"
    (click)="update()">
    <strong i18n>עדכון</strong>
  </button>

  <ui-error [state]="updateState"></ui-error>
</section>
