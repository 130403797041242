import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";

import {
  Produce,
  Application,
  HttpPost,
  FromBody,
  Controller,
  resultOf,
  HttpGet,
  FromRoute,
  TokenSettings,
} from "@jct/core";

import { LoginModel } from "../../registration";
import { AuthenticatorState } from "../../registration";

const TOKEN_SETTINGS_STORAGE_KEY = "tokenSettings";

@Injectable({ providedIn: "root" })
@Controller("account")
export class ExamsAccountService {
  constructor(
    protected injector: Injector,
  ) {}

  @HttpPost("exam-login")
  @Produce(AuthenticatorState)
  login(@FromBody() model: LoginModel) {
    return resultOf<AuthenticatorState>();
  }

}
