
<meta-title i18n>
איפוס ועדכון המערכת
</meta-title>

<div container>
  <h2 i18n>
    מחיקת זכרון מטמון
  </h2>

  <p i18n>
    הזכרון נמחק בהצלחה, והמערכת עודכנה לגרסה האחרונה.
  </p>
</div>
