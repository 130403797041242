
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Application, Authenticator, HttpStatusResult, TokenSettings } from '@jct/core';
import { AccountService, AuthenticatorStates, ProfileService, RegisterModel, RegistrationNavigator } from '@jct/api';
import { StateFactory } from '@jct/ui';
import { TimeSpan } from '@jct/core/system';

@Component({
  selector: 'register-form.page',
  templateUrl: './register-form.html',
})
export class RegisterForm implements OnInit, OnDestroy {
  private _timer: any;
  private _redirect: string = null;

  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private authenticator: Authenticator,
    private profileService: ProfileService,
    private registrationNavigator: RegistrationNavigator,
    private route: ActivatedRoute,
    private application: Application,
    private router: Router)
  { }

  loadState = this.sf.create();
  registerState = this.sf.create();

  form = this.fb.group({
    token: [''],
    email: [null, [Validators.required, Validators.email]],
    passwordGeneratorRelativePath: [this.application.passwordGeneratorPath],
  });

  async ngOnInit() {
    await this.loadState.inProcess();

    this.route.queryParams.subscribe(async params => {
      this._redirect =  params['redirect'] || null;

      if (!params['token']) {
        this.reset();
      }
      else {
        this.form.patchValue({
          token: params.token,
        });

        const tokenLifeTime = TimeSpan.parse(params.tokenLifeTime);

        this._timer = setTimeout(() => {
          this.reset();
        }, tokenLifeTime.totalSeconds * 1000);

        this.loadState.completed(10);
      }
    });
  }

  ngOnDestroy() {
    if (this._timer) {
      clearTimeout(this._timer);
      this._timer = null;
    }
  }

  async reset() {
    this.loadState.quit();
    this.router.navigate(['/start/identify']);
  }

  async register() {
    if (!this.registerState.isInProcess) {
      this.form.disable();
      this.registerState.inProcess();

      const model = this.form.value as RegisterModel;
      const result = await this.accountService.register(model);

      if (result instanceof HttpStatusResult) {
        this.form.enable();
        this.registerState.failed(result);
        return;
      }

      if (result.state === AuthenticatorStates.LoggedIn) {
        this.authenticator.authenticate(<TokenSettings>result);
        const loadProfileResult = await this.profileService.loadRegistrationProfile();

        if (loadProfileResult instanceof HttpStatusResult) {
          this.form.enable();
          this.registerState.failed(loadProfileResult);
          return;
        }

        this.registerState.completed();

        if (this._redirect && this.registrationNavigator.canNavigateToUrl(this._redirect)) {
          this.router.navigateByUrl(this._redirect);
        }
        else {
          await this.registrationNavigator.navigateToCurrentStage();
        }
      }
    }
  }
}
