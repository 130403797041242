
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CancelPageComponent, FailedPageComponent, SuccessPageComponent } from './+redirect';

export const PAYMENTS_ROUTES: Routes = [
  {
    path: 'redirect/success',
    component: SuccessPageComponent,
  },
  {
    path: 'redirect/failed',
    component: FailedPageComponent,
  },
  {
    path: 'redirect/cancel',
    component: CancelPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(PAYMENTS_ROUTES)
  ],
  exports: [RouterModule]
})
export class PaymentsRoutingModule { }
