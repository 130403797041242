import { Component, Input, HostBinding } from "@angular/core";

const BODY_PADDING_RATIO = 3 / 4;
const DRAWER_WIDTH_BODY_RATIO = 1 / 20;

export interface Point {
  x: number;
  y: number;
}
/**
      i18n-aria-label
      aria-label="לוגו"
*/
@Component({
  selector: "ui-jct-logo",
  template: `
    <svg
      [attr.width]="hostWidth"
      [attr.height]="hostHeight"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        [attr.points]="B + ' ' + E + ' ' + F"
        fill="none"
        [attr.stroke]="color"
        [attr.stroke-width]="lineWidth"
        stroke-linecap="round"
      />

      <polyline
        [attr.points]="F + ' ' + A + ' ' + B"
        fill="none"
        [attr.stroke]="color"
        [attr.stroke-width]="lineWidth"
        stroke-linecap="round"
      />

      <polyline
        [attr.points]="B + ' ' + D + ' ' + F"
        fill="none"
        [attr.stroke]="color"
        [attr.stroke-width]="lineWidth"
        stroke-linecap="butt"
      />

      <polyline
        [attr.points]="D + ' ' + C"
        fill="none"
        [attr.stroke]="color"
        [attr.stroke-width]="lineWidth"
        stroke-linecap="butt"
      />
    </svg>
  `,
  host: {
    "style.display": "block",
    class: "logo",
  },
  styleUrls: ["./jct-logo.component.scss"],
})
export class JctLogoComponent {
  @Input()
  width: number = 72;

  @Input()
  color: string = "white";

  @Input()
  includePadding: boolean = true;

  get height() {
    return this.width;
  }

  get padding() {
    return this.includePadding
      ? (this.width - this.bodyWidth) / 2
      : this.lineWidth / 2;
  }

  get length() {
    return (
      Math.sqrt((4 / 3) * Math.pow(this.bodyWidth, 2)) / 2 -
      (this.includePadding ? 0 : this.lineWidth / 2)
    );
  }

  get bodyWidth() {
    let width = BODY_PADDING_RATIO * this.width;
    let lineWidth = DRAWER_WIDTH_BODY_RATIO * width;

    return this.includePadding ? width - lineWidth : width + lineWidth / 2;
  }

  get lineWidth() {
    return Math.round(DRAWER_WIDTH_BODY_RATIO * this.bodyWidth);
  }

  get paddingTopBottom() {
    return (
      (Math.sqrt(Math.pow(this.length, 2) - Math.pow(this.length / 2, 2)) * 2 -
        this.length * 1.5) /
        2 +
      this.padding
    );
  }

  @HostBinding("style.height")
  get hostHeight() {
    return Math.round(this.includePadding ? this.width : this.bodyWidth) + "px";
  }

  @HostBinding("style.width")
  get hostWidth() {
    return Math.round(this.includePadding ? this.width : this.bodyWidth) + "px";
  }

  parse(point: Point) {
    return `${Math.round(point.x)},${Math.round(point.y)}`;
  }

  get A() {
    return this.parse(this.pointA);
  }

  get pointA() {
    return <Point>{
      x: this.padding,
      y: this.paddingTopBottom,
    };
  }

  get B() {
    return this.parse(this.pointB);
  }

  get pointB() {
    return <Point>{
      x: this.padding,
      y: this.paddingTopBottom + this.length,
    };
  }

  get C() {
    return this.parse(this.pointC);
  }

  get pointC() {
    return <Point>{
      x:
        this.padding +
        Math.sqrt(Math.pow(this.length, 2) - Math.pow(this.length / 2, 2)),
      y: this.paddingTopBottom + this.length / 2,
    };
  }

  get D() {
    return this.parse(this.pointD);
  }

  get pointD() {
    return <Point>{
      x:
        this.padding +
        Math.sqrt(Math.pow(this.length, 2) - Math.pow(this.length / 2, 2)),
      y: this.paddingTopBottom + this.length * 1.5,
    };
  }

  get E() {
    return this.parse(this.pointE);
  }

  get pointE() {
    return <Point>{
      x:
        this.padding +
        Math.sqrt(Math.pow(this.length, 2) - Math.pow(this.length / 2, 2)) * 2,
      y: this.paddingTopBottom,
    };
  }

  get F() {
    return this.parse(this.pointF);
  }

  get pointF() {
    return <Point>{
      x:
        this.padding +
        Math.sqrt(Math.pow(this.length, 2) - Math.pow(this.length / 2, 2)) * 2,
      y: this.paddingTopBottom + this.length,
    };
  }
}
