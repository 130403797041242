import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { HostEnvironment } from '../environment';

export const GUARD_DEV_TOOLS_FEATURE = 'guard-dev-tools';

@Injectable({
  providedIn: 'root'
})
export class GuardDevTools {
  constructor(
    environment: HostEnvironment,
    @Inject(DOCUMENT) private document: Document)
  {
    if (environment.isProduction()) {
      this.apply();
    }
  }

  apply() {
    this.document.addEventListener(
      'contextmenu',
      (event: Event) => event.preventDefault(),
      false);

    this.document.addEventListener(
      'keydown',
      (event: KeyboardEvent) => (
        event.key == 'F12' ||
        event.key == 'I' && event.ctrlKey && event.shiftKey) && event.preventDefault(),
      false);
  }
}
