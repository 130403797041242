
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ui-link',
  template: `
    <term #tooltip i18n>
    {linkType, select, local {פתח קישור} external {פתח קישור בעמוד חדש}}
    </term>

    <a [href]="link"
      [routerLink]="[isLocal && !disable ? to : null]"
      *ngIf="!button"
      [matTooltip]="tooltip.text"
      [disabled]="disable"
      [target]="target"
      [attr.rel]="isLocal ? null : 'noreferrer'">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      <mat-icon *ngIf="!isLocal" inline="true">open_in_new</mat-icon>
    </a>

    <a [href]="link"
      [routerLink]="[isLocal && !disable ? to : null]"
      *ngIf="button"
      [attr.rel]="isLocal ? null : 'noreferrer'"
      mat-raised-button
      [matTooltip]="tooltip.text"
      [disabled]="disable"
      [target]="target">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      <mat-icon *ngIf="!isLocal" inline="true">open_in_new</mat-icon>
    </a>

    <ng-template #contentTemplate>
      <span>
        <ng-content></ng-content>
      </span>
    </ng-template>
  `,
  styleUrls: ['./link.scss'],
  exportAs: 'uiLink',
  encapsulation: ViewEncapsulation.None,
})
export class UiLink {
  private _buttonRole = false;

  constructor(
    private sanitizer: DomSanitizer)
  { }

  get linkType() {
    return this.isLocal ? 'local' : 'external';
  }

  @Input()
  to: string;

  @HostBinding('style.pointer-events')
  get stopEvent() {
    return this.disable ? 'none' : 'auto';
  }

  @Input()
  disable = false;

  @Input()
  get button() { return this._buttonRole; }
  set button(value: boolean) { this._buttonRole = value !== false; }

  get link() {
    return this.sanitizer.bypassSecurityTrustUrl(this.to);
  }

  get isLocal() {
    return !this.to.startsWith('http:') &&
      !this.to.startsWith('mailto:') &&
      !this.to.startsWith('tel:') &&
      !this.to.startsWith('https:') &&
      !this.to.startsWith('ftp:');
  }

  get target() {
    return this.isLocal ? '_self' : '_blank';
  }
}
