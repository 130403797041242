import { Injectable, ApplicationRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs'
;
@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  current = '';

  constructor(
    private title: Title,
    private meta: Meta,
    private applicationRef: ApplicationRef)
  { }

  get applicationName() {
    return this.meta.getTag('name="application-name"').content;
  }

  setTitle(title: string) {
    this.title.setTitle(`${this.applicationName} - ${title}`);

    setTimeout(() => {
      this.current = title;
      this.currentTitle.next(title);
      this.applicationRef.components.forEach(x => x.changeDetectorRef.detectChanges());
    });
  }

  currentTitle = new Subject<string>();
}
