
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RegistrationNavigator, RegistrationUserProfile } from '@jct/api';

@Injectable({
  providedIn: 'root',
})
export class AcceptedStateGuard  {
  constructor(
    private navigator: RegistrationNavigator,
    private profile: RegistrationUserProfile)
  { }

  private guard(url: string) {
    if (!this.profile.isAccepted) {
      this.navigator.navigateToCurrentStage();
      return false;
    }

    return true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.guard(state.url);
  }
}
