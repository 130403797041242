
import { Component, Input } from '@angular/core';

export type NotificationTypes = 'error' | 'warning' | 'info' | 'success' | 'normal';

@Component({
  selector: 'ui-notification',
  templateUrl: './notification.component.html',
  exportAs: 'uiNotification',
})
export class NotificationComponent {
  private _show = false;

  @Input()
  notificationType: NotificationTypes = 'normal';

  @Input()
  displayCloseButton: boolean = false;

  @Input()
  set show(value: boolean) {
    this._show = value;
  }
  get show() {
    return this._show;
  }

  get notificationClass() {
    return `ui-notification ${this.notificationType}`;
  }

  async close() {
    this.show = false;
  }

  async open() {
    this.show = true;
  }
}
