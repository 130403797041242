

<meta-title i18n>
  הזמן עבר לחוסר פעילות במערכת
</meta-title>

<div container>
  <h2 i18n>
    נותקת מהמערכת בשל חוסר פעילות
  </h2>

  <p i18n>
    שמנו לב שלא בצעת שום פעולה במשך הרבה זמן,
    <br>
    בשל שיקולי אבטחה ושמירה על הפרטיות שלך
    המערכת ניתקה אותך אוטומטית
  </p>
</div>
