
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Gender, PageLayout } from '@jct/localization';
import { AppLayoutService, StateFactory } from '@jct/ui';
import { ENGLISH_NAME_PATTERN, HEBREW_NAME_PATTERN, HttpStatusResult, SHORT_ENGLISH_NAME_PATTERN, SHORT_HEBREW_NAME_PATTERN } from '@jct/core';

import {
  PersonService,
  ProfileService,
  RegistrationStates,
  CitizenshipTypes,
  FamilyStates,
  ServiceExemptionReasons,
  VoluntaryServiceTypes,
  PersonDetails,
  RegistrationNavigator,
  RegistrationStages,
  RegistrationProfile
} from '@jct/api';

@Component({
  selector: 'personal-details-tab.page',
  templateUrl: './personal-details-tab.component.html',
})
export class PersonalDetailsTabComponent implements OnInit {
  constructor(
    private sf: StateFactory,
    private fb: UntypedFormBuilder,
    private personService: PersonService,
    private registrationNavigator: RegistrationNavigator,
    private profileService: ProfileService,
    public appLayout: AppLayoutService)
  { }

  @Output()
  saved = new EventEmitter();

  loadState = this.sf.create();
  saveState = this.sf.create();

  CitizenshipTypes = CitizenshipTypes;
  FamilyStates = FamilyStates;
  VoluntaryServiceTypes = VoluntaryServiceTypes;
  ServiceExemptionReasons = ServiceExemptionReasons;

  get MALE() { return Gender.Male; }
  get FEMALE() { return Gender.Female; }

  get isMale() { return this.gender == Gender.Male; }
  get isFemale() { return this.gender == Gender.Female; }

  get returnStudent() { return this.profileService.profile?.state == RegistrationStates.ReturnStudent; }

  maxBirthDate = new Date().addYears(-13);
  maxAliyaDate = new Date().addDays(-1);

  form = this.fb.group({
    firstName: [null,[Validators.pattern(SHORT_HEBREW_NAME_PATTERN),Validators.minLength(2)]],
    lastName: [null, [Validators.pattern(SHORT_HEBREW_NAME_PATTERN),Validators.minLength(2)]],
    englishFirstName: [null, [Validators.pattern(SHORT_ENGLISH_NAME_PATTERN),Validators.minLength(3)]],
    englishLastName: [null, [Validators.pattern(SHORT_ENGLISH_NAME_PATTERN),Validators.minLength(3)]],
    gender: [{ value:null, disabled: this.returnStudent }, Validators.required],
    birthDate: [null, [Validators.required]],
    birthCountry: [null],
    familyState: [null, [Validators.required]],
    citizenship: [null, [Validators.required]],
    aliyaCountry: [null],
    aliyaDate: [null],
    voluntaryServiceType: ['', [Validators.required]],
    serviceExemptionReason: [null],
    workPlace: [null],
    role: [null],
  });

  get gender(): Gender {
    return this.form.get('gender').value;
  }

  controlValue(name: string) {
    return this.form.get(name).value;
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    this.form.get('voluntaryServiceType').valueChanges
      .subscribe(voluntaryServiceType => {
        if (voluntaryServiceType == 1) {
          this.form.get('serviceExemptionReason').setValidators(Validators.required);
        }
        else {
          this.form.get('serviceExemptionReason').clearValidators();
          this.form.patchValue({ serviceExemptionReason: '' });
        }
      });

    this.form.get('familyState').disable();
    this.form.get('citizenship').disable();
    this.form.get('voluntaryServiceType').disable();
    this.form.get('serviceExemptionReason').disable();

    this.form.get('gender').valueChanges
      .subscribe(gender => {
        if (!gender) {
          this.form.get('familyState').disable();
          this.form.get('citizenship').disable();
          this.form.get('voluntaryServiceType').disable();
          this.form.get('serviceExemptionReason').disable();
        }
        else {
          this.form.get('familyState').enable();
          this.form.get('citizenship').enable();
          this.form.get('voluntaryServiceType').enable();
          this.form.get('serviceExemptionReason').enable();
        }
      });

    const result = await this.personService.getDetailsAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      this.form.patchValue({
        ...result,
        serviceExemptionReason: result.serviceExemptionReason || '',
      });
      this.form.markAllAsTouched();
    }

    await this.loadState.completed();
  }

  async submit() {
    if (!this.saveState.isInProcess) {
      await this.saveState.inProcess();

      let model = <PersonDetails>this.form.value;

      model = {
        ...model,
        serviceExemptionReason: model.serviceExemptionReason || null
      };

      const result = await this.personService.saveDetailsAsync(model);

      if (!result.succeeded) {
        this.saveState.failed(result);
        return;
      }

      this.profileService.update<RegistrationProfile>({
        gender: <Gender>model.gender,
      });

      this.registrationNavigator.setCurrentStage(RegistrationStages.פרטים_אישיים);
      await this.saveState.completed();
      this.saved.emit();
      await this.registrationNavigator.navigateToNextStage();
    }
  }
}
