import { Component } from '@angular/core';

import { Localization } from '../localization.service';
import { PageLayout } from './page-layout';

@Component({
  selector: 'page-layout-selector',
  templateUrl: './page-layout-selector.html',
})
export class PageLayoutSelector {
  constructor(
    private pageLayout: PageLayout,
    private localization: Localization)
  { }

  get isRtl() { return this.pageLayout.rtl; }

  get layout() {
    return this.pageLayout.rtl ? 'rtl' : 'ltr';
  }

  toggleRtl() {
    this.pageLayout.rtl = !this.pageLayout.rtl;
  }

  get isEnabled() {
    return this.localization.enabled;
  }
}
