
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
  HostEnvironment,
  Controller,
  HttpGet,
  Produce,
  FromRoute,
  HttpPost,
  FromBody,
  resultOf,
  SuccessTestCase,
  HttpStatusResult,
  SuccessResult
} from '@jct/core';

import { AmiramTestModel, StudentAmiramTestModel } from './amiram.types';
import { FakeAmiramTestModel, FakeStudentAmiramTestModel } from './amiram.test';
import { CREDIT_GUARD_TEST_PAYMENT_PROVIDER } from '../payment';

@Injectable({ providedIn: 'root' })
@Controller('amiram')
export class AmiramService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private sanitizer: DomSanitizer)
  { }

  certificateLink(studentTest: StudentAmiramTestModel) {
    return this.sanitizer.bypassSecurityTrustUrl(
      `${this.environment.apiServerUrl}/amiram/${studentTest.amiramTestId}`);
  }

  @HttpGet('available')
  @SuccessTestCase(FakeAmiramTestModel)
  @Produce(AmiramTestModel, true)
  getAvailableAmiramTestsAsync() {
    return resultOf<AmiramTestModel[]>();
  }

  @HttpGet()
  @SuccessTestCase(FakeStudentAmiramTestModel)
  @Produce(StudentAmiramTestModel, true)
  getStudentCurrentAmiramTestsAsync() {
    return resultOf<StudentAmiramTestModel[]>();
  }

  @HttpPost('{amiramTestId}')
  @SuccessTestCase(CREDIT_GUARD_TEST_PAYMENT_PROVIDER(), 'קבלת קישור לתשלום ב-CreditGuard')
  @Produce('string')
  beginPaymentProcessViaCGAsync(
    @FromRoute('amiramTestId') amiramTestId: number) {
    return resultOf<string>();
  }

  @HttpPost('{transactionId}/success')
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  endPaymentAsync(
    @FromRoute('transactionId') transactionId: string,
    @FromBody('token') token: string) {
    return resultOf();
  }

  @HttpPost('{transactionId}/failed')
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  receiveFailedPaymentAsync(
    @FromRoute('transactionId') transactionId: string) {
    return resultOf();
  }

  @HttpPost('{transactionId}/cancel')
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  cancelPaymentAsync(
    @FromRoute('transactionId') transactionId: string) {
    return resultOf();
  }
}
