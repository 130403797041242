
import { Input, Injector, Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

import { of } from 'rxjs';
import { debounceTime, tap, switchMap } from 'rxjs/operators';

import { BaseFieldComponent } from '../base-field';

@Directive({})
export class AutocompleteFieldComponent extends BaseFieldComponent<string> {
  static Template = `
  <mat-form-field
    [appearance]="appearance"
    [floatLabel]="floatLabel"
  >
    <mat-label>{{ label }}</mat-label>

    <input matInput
      [matAutocomplete]="autocomplete"
      type="text"
      [formControl]="inputControl"
      [placeholder]="placeholder">
  </mat-form-field>

  <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete">
    <mat-option *ngIf="isLoading" style="padding: 16px;">
      <mat-spinner diameter="20" color="accent"></mat-spinner>
    </mat-option>

    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let result of filteredResults" [value]="result">
        {{ result }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  `;

  isLoading = false;
  filteredResults: string[];

  get empty() {
    const { value } = this.inputControl;
    return !value || value.trim().length === 0;
  }

//#region value
  @Input()
  get value(): string | null {
    const { value } = this.inputControl;
    if (value && value.trim().length > 0) {
      return value.trim();
    }
    return null;
  }
  set value(value: string | null) {
    this.inputControl.setValue(value || '');
    this.callApiAsync(value || '')
      .then(values => this.filteredResults = values);
  }
//#endregion

  constructor(
    injector: Injector,
    ngControl: NgControl)
  {
    super(injector, ngControl);
  }

  async callApiAsync(query: string): Promise<string[]> {
    return of([]).toPromise();
  }

  async ngOnInit() {
    super.ngOnInit();

    this.inputControl.valueChanges
      .pipe(
        tap(() => this.isLoading = true),
        debounceTime(500),
        switchMap(async value => {
          let result = await this.callApiAsync(value);
          this.isLoading = false;
          return result;
        })
      ).subscribe(values => this.filteredResults = values);
  }
}
