
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, StageGuard, StudyTrackGuard } from '../guards';
import { FillInComponent } from './fill-in.component';
import { ContactDetailsTabComponent } from './shared-components';
import { PersonalPageComponent, PersonalDetailsTabComponent, PersonalContactTabComponent } from './+personal';
import {
  PreviousEducationPageComponent,
  EntranceExamsTabComponent,
  HighSchoolTabComponent,
  BagrutTabComponent,
  DiplomaTabComponent,
  InstitutionsTabComponent,
  PreviousDegreesTabComponent,
} from './+previous-education';
import { RelativesPageComponent, RelativesDetailsTabComponent, RelativesContactTabComponent } from './+relatives';
import {
  StatementsPageComponent,
  GeneralStatementsTabComponent,
  HealthStatementTabComponent,
  CriminalRecordTabComponent,
} from './+statements';
import { StudyTrackPage } from './+study-track';
import { RegistrationFeePageComponent } from './+registration-fee';

export const FILL_IN_ROUTES: Routes = [
  {
    path: 'fill-in',
    redirectTo: '/fill-in/personal/details',
    pathMatch: 'full'
  },
  {
    path: '',
    component: FillInComponent,
    canActivate: [AuthGuard, StageGuard],
    canActivateChild: [AuthGuard, StageGuard],
    children: [
      {
        path: '',
        redirectTo: 'personal',
        pathMatch: 'full'
      },
      {
        path: 'personal',
        component: PersonalPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'details',
            pathMatch: 'full'
          },
          {
            path: 'details',
            component: PersonalDetailsTabComponent,
          },
          {
            path: 'contact',
            component: PersonalContactTabComponent,
          },
        ],
      },
      {
        path: 'study-track',
        component: StudyTrackPage,
      },
      {
        path: 'relatives',
        component: RelativesPageComponent,
        canActivate: [StudyTrackGuard],
        canActivateChild: [StudyTrackGuard],
        children: [
          {
            path: '',
            redirectTo: 'details',
            pathMatch: 'full'
          },
          {
            path: 'details',
            component: RelativesDetailsTabComponent,
          },
          {
            path: 'contact',
            component: RelativesContactTabComponent,
          },
        ],
      },
      {
        path: 'previous-education',
        component: PreviousEducationPageComponent,
        canActivate: [StudyTrackGuard],
        canActivateChild: [StudyTrackGuard],
        children: [
          {
            path: '',
            redirectTo: 'high-school',
            pathMatch: 'full'
          },
          {
            path: 'high-school',
            component: HighSchoolTabComponent,
          },
          {
            path: 'bagrut',
            component: BagrutTabComponent,
          },
          {
            path: 'entrance-exams',
            component: EntranceExamsTabComponent,
          },
          {
            path: 'institutions',
            component: InstitutionsTabComponent,
          },
          {
            path: 'diploma',
            component: DiplomaTabComponent,
          },
          {
            path: 'previous-degrees',
            component: PreviousDegreesTabComponent,
          },
        ]
      },
      {
        path: 'statements',
        component: StatementsPageComponent,
        canActivate: [StudyTrackGuard],
        canActivateChild: [StudyTrackGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'health',
          },
          {
            path: 'health',
            component: HealthStatementTabComponent,
          },
          {
            path: 'general',
            component: GeneralStatementsTabComponent,
          },
          {
            path: 'criminal-record',
            component: CriminalRecordTabComponent,
          },
        ],
      },
      {
        path: 'registration-fee',
        component: RegistrationFeePageComponent,
        canActivate: [StudyTrackGuard],
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(FILL_IN_ROUTES)
  ],
  exports: [RouterModule]
})
export class FillInRoutingModule { }
