import { Injectable, Injector } from "@angular/core";

import {
  HttpGet,
  Controller,
  UseState,
  resultOf,
  Produce,
  SuccessResult,
  SuccessTestCase,
  HttpStatusResult,
} from "@jct/core";

@Injectable({ providedIn: "root" })
@Controller("antiforgery")
export class AntiforgeryService {
  constructor(protected injector: Injector) {}

  //@HttpGet("Generate")
  @HttpGet()
  @UseState()
  @SuccessTestCase(SuccessResult)
  @Produce(HttpStatusResult)
  generateAsync() {
    return resultOf();
  }
}
