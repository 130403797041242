
<meta-title i18n>
  לא קיים חיבור לאינטרנט
</meta-title>

<div container>
  <h2 i18n>
    שמנו לב שהתנתקת מאינטרנט
  </h2>

  <p i18n>
    המערכת לא יכולה לעבוד ללא חיבור קבוע לאינטרנט.
  </p>
</div>
