import { HttpHeaders } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  Produce,
  HttpPost,
  HttpGet,
  FromBody,
  Controller,
  resultOf,
  FromRoute,
  HttpStatusResult,
  HostEnvironment,
} from "@jct/core";
import { LersUser } from "..";
import { ProfileService } from "../../registration";
import { Role } from "../account";
import { ActionDefination, ActionService } from "../actions";

import {
  Lecturer,
  Lecturers,
  BasicUserDetails,
  LecturerWithAllStatuses,
  LecturersStstus,
  DepartmentHeadScore,
  RoleInArticle,
} from "./lecturers.types";

@Injectable({ providedIn: "root" })
@Controller("Lecturers")
export class LecturersService {
  constructor(
    protected injector: Injector,
    private environment: HostEnvironment,
    private sanitizer: DomSanitizer,
    private profileService: ProfileService,
    private actionService: ActionService
  ) {}

  lecturers: any;
  lecturerId: number;
  lecturerData: Lecturer;
  actionDefination: Map<string, ActionDefination>;

  @HttpGet("GetAllLecturers")
  // @SuccessTestCase(ShouldVerifyState)
  // @SuccessTestCase(ShouldRegisterState)
  // @SuccessTestCase(ShouldConfirmByEmailState)
  // @FailedTestCase(InvalidModelResult, 'תעודת הזהות או הדרכון אינם תקינים')
  @Produce(Lecturers, true)
  getAllLecturers() {
    return resultOf<Lecturers[]>();
  }

  @HttpGet("CalculateScoreToAllLecturer")
  // @SuccessTestCase(ShouldVerifyState)
  // @SuccessTestCase(ShouldRegisterState)
  // @SuccessTestCase(ShouldConfirmByEmailState)
  // @FailedTestCase(InvalidModelResult, 'תעודת הזהות או הדרכון אינם תקינים')
  @Produce(Lecturer, true)
  calculateScoreToAllLecturer() {
    return resultOf<Lecturer[]>();
  }

  @HttpGet("SynchronizeAll")
 // @Produce(Lecturer[], true)
  synchronizeAll() {
    return resultOf<any>();
  }

  @HttpGet("GetLecturer/{id}")
  //@Produce(Lecturer)
  getLecturer(@FromRoute("id") id: number) {
    return resultOf<Lecturer>();
  }

  @HttpGet("getRoleInArticle")
  @Produce(RoleInArticle, true)
  getRoleInArticle() {
    return resultOf<RoleInArticle[]>();
  }
  
  @HttpGet("getLecturersByDepartmentHeadId/{departmentHeadId}")
  @Produce(Lecturer, true)
  getLecturersByDepartmentHeadId(
    @FromRoute("departmentHeadId") departmentHeadId: number
  ) {
    return resultOf<Lecturer[]>();
  }

  @HttpGet("GetLecturerWithAllStatuses/{id}")
  @Produce(LecturerWithAllStatuses)
  getLecturerWithAllStatuses(@FromRoute("id") id: number) {
    return resultOf<LecturerWithAllStatuses>();
  }

  @HttpGet("GetAbsentSenateMembers")
  @Produce(BasicUserDetails, true)
  getAbsentSenateMembers() {
    return resultOf<BasicUserDetails[]>();
  }

  @HttpGet("SaveSenateMemberAbsent/{id}")
  @Produce(BasicUserDetails)
  saveSenateMemberAbsent(@FromRoute("id") senateNenberId: number) {
    return resultOf<BasicUserDetails>();
  }

  @HttpGet("DeleteSenateMemberAbsent/{id}")
  @Produce("boolean")
  deleteSenateMemberAbsent(@FromRoute("id") senateNenberId: number) {
    return resultOf<boolean>();
  }

  @HttpGet("DeleteLecturer/{id}")
  @Produce("number")
  deleteLecturer(@FromRoute("id") lecturerId: number) {
    return resultOf<number>();
  }

  @HttpPost("UpdateLecturer")
  @Produce(Lecturer)
  updateLecturer(@FromBody() lecturer: Lecturer) {
    return resultOf<Lecturer>();
  }
  @HttpPost("UpdateStatus")
  updateStatus(@FromBody() lecturerStatus: LecturersStstus) {
    return resultOf<Lecturer>();
  }

  @HttpPost("GetLecturerCategoriesScores")
  @Produce(Lecturer)
  getLecturerCategoriesScores(@FromBody() lecturer: Lecturer) {
    return resultOf<Lecturer>();
  }

  @HttpPost("SaveDepartmentHeadScores")
  saveDepartmentHeadScores(@FromBody() departmentHeadScores :DepartmentHeadScore[]){
    return resultOf<any>();
  }

  @HttpPost("SendScoreSummaryReportToLecturer")
  sendScoreSummaryReportToLecturer(@FromBody() userDetails : LersUser){
    return resultOf<any>();
  }

  @HttpGet("GetTwoHebrewConsecutiveYears/{currentYearId}") 
 getTwoHebrewConsecutiveYears(@FromRoute("currentYearId") yearId:number)
  {
    return resultOf<any>();
  }


  reportLink(lecturerId: number) {
    return this.sanitizer.bypassSecurityTrustUrl(
      `${this.environment.apiServerUrl}/lecturers/${lecturerId}`
    );
  }



  async getLecturerData() {
    if (!this.lecturerData) {

      if (this.profileService.profile.role == Role.Admin) {
        this.lecturerId = this.profileService.profile.loginAs;
      } else {
        this.lecturerId = this.profileService.profile.userId;
      }
      const result = await this.getLecturer(this.lecturerId);
      if (result instanceof HttpStatusResult) {
        return result;
      }
      this.lecturerData = result;
      if (this.actionDefination == undefined || this.actionDefination) {
        const resultAction = await this.actionService.getActionDefination();
        if (resultAction instanceof HttpStatusResult) {
          return result;
        }
        this.actionDefination = new Map<string, ActionDefination>();
        resultAction.forEach((a) => {
          this.actionDefination.set(a.actionKey, a);
        });
      }
      return this.lecturerData;
    }
    return this.lecturerData;
  }

  async updateLecturerData() {
    if (this.profileService.profile.role == Role.Admin) {
      this.lecturerId = this.profileService.profile.loginAs;
    } else {
      this.lecturerId = this.profileService.profile.userId;
    }
    const result = await this.getLecturer(this.lecturerId);
    if (result instanceof HttpStatusResult) {
      return result;
    }
    this.lecturerData = result;
    return this.lecturerData;
  }

  setLecturerData(lecturerData: Lecturer) {
    this.lecturerData = lecturerData;
  }


}
