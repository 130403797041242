
export class FileRequest {
  fileType: number;
  typeName: string;
  englishTypeName?: string;
  frenchTypeName?: string;
  description?: string;
  isMandatory?: boolean;

  // linked field
  studentFile?: StudentFile;
}

export interface StudentFileUpload {
  file: File;
  fileType: number;
  description?: string;
}

export class StudentFile {
  id: number;
  fileType: number;
  description?: string;
}
