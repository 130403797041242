import { Injectable, Provider } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import {
  Application,
  ApiInterceptor,
  HttpContext,
  API_INTERCEPTORS,
  TestCaseDescriptor,
  RouteDescriptor,
  Connectivity,
  ServerUnavailableResult,
  InvalidClientResult,
  ProtectorServiceErrorResult,
  DbInternalErrorResult,
  HttpStatusResult,
  InternalServerErrorResult,
  InternalErrorResult,
  OperationFailedResult,
  GatewayTimeoutResult,
  InvalidTokenResult,
  InvalidJsonFormatResult,
  ApiDescriptionProvider,
  ServiceUnavailableResult,
  NotImplementedResult,
  TooManyRequestsResult
} from '@jct/core';

import { TestCasesProvider } from './test-cases.provider';
import { SelectTestCasesDialog } from '../select-test-cases-dialog';
import { PageLayout } from '@jct/localization';

export const TEST_CASES_PROVIDER_FEATURE = 'test-cases-provider';

const GENERAL_FAILED_CASES = [
  InternalServerErrorResult,
  DbInternalErrorResult,
  InternalErrorResult,
  OperationFailedResult,
  ServerUnavailableResult,
  InvalidClientResult,
  InvalidTokenResult,
  InvalidJsonFormatResult,
  ProtectorServiceErrorResult,
  GatewayTimeoutResult,
  ServiceUnavailableResult,
  NotImplementedResult,
  TooManyRequestsResult,
];

@Injectable()
export class TestInterceptor implements ApiInterceptor {
  enabled = false;
  order = 4;

  constructor(
    application: Application,
    private pageLayout: PageLayout,
    private testCasesProvider: TestCasesProvider,
    private connectivity: Connectivity,
    private dialog: MatDialog)
  {
    this.enabled = !!application.settings[TEST_CASES_PROVIDER_FEATURE];
  }

  async begin(context: HttpContext) {
    if (context.hasResponse) {
      return;
    }

    const route = context.route;
    const action = context.action;
    const descriptors = <TestCaseDescriptor[]>action.descriptors
      .filter(x => x instanceof TestCaseDescriptor)
      .reverse();

    if (descriptors.length === 0) {
      return;
    }

    GENERAL_FAILED_CASES
      .forEach(failedCase => {
        const classDescriptor = ApiDescriptionProvider.getClassDescriptor(failedCase);
        const testCaseDescriptor = new TestCaseDescriptor(failedCase, classDescriptor.description, false);

        testCaseDescriptor.classDescriptor = classDescriptor;
        descriptors.push(testCaseDescriptor);
      });

    const descriptor = await this._openSelectTestCasesDialog(route, descriptors);
    const result = this.testCasesProvider.supplyTestCase(descriptor, context.request);

    if (result instanceof HttpStatusResult) {
      if (result.succeeded) {
        context.generateResponse(new HttpResponse({
          body: result,
          status: result.status,
          url: context.request.url,
          statusText: result.statusText,
        }));
      }
      else {
        context.generateResponse(new HttpResponse({
          body: result,
          status: result.status,
          url: context.request.url,
          statusText: result.statusText,
        }));

        context.generateError(new HttpErrorResponse({
          error: result,
          status: result.status,
          url: context.request.url,
          statusText: result.statusText,
        }));

        if (result.isServerUnavailable ||
          result.isGatewayTimeout) {
          this.connectivity.reportServerUnavailable();
        }
      }
    }
    else {
      context.generateResponse(new HttpResponse({
        body: result,
        status: 200,
        url: context.request.url,
        statusText: 'ok',
      }));
    }
  }

  private _openSelectTestCasesDialog(route: RouteDescriptor, descriptors: TestCaseDescriptor[]) {
    return new Promise<TestCaseDescriptor>((resolve, reject) => {
      const dialogRef = this.dialog.open(SelectTestCasesDialog, {
        hasBackdrop: true,
        disableClose: true,
        autoFocus: true,
        direction: this.pageLayout.layout,
        data: {
          descriptors,
          route
        }
      });

      dialogRef.afterClosed()
        .subscribe((descriptor: TestCaseDescriptor) => {
          resolve(descriptor);
        });
    });
  }
}

export const TestInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: TestInterceptor,
  multi: true,
};
