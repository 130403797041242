
export class TimeSpan {
  static readonly TicksPerDay = 864000000000;
  static readonly TicksPerHour = 36000000000;
  static readonly TicksPerMillisecond = 10000;
  static readonly TicksPerMinute = 600000000;
  static readonly TicksPerSecond = 10000000;

  static readonly MaxValue = new TimeSpan(
    10675199 * TimeSpan.TicksPerDay +
    2 * TimeSpan.TicksPerHour +
    48 * TimeSpan.TicksPerMinute +
    5 * TimeSpan.TicksPerSecond +
    4775807 * TimeSpan.TicksPerMillisecond);

  static readonly MinValue = new TimeSpan(
    -10675199 * TimeSpan.TicksPerDay +
    -2 * TimeSpan.TicksPerHour +
    -48 * TimeSpan.TicksPerMinute +
    -5 * TimeSpan.TicksPerSecond +
    -4775808 * TimeSpan.TicksPerMillisecond);

  static readonly Zero = new TimeSpan(0);

  private _ticks: number;

  constructor(ticks: number);
  constructor(hours: number, minutes: number, seconds: number);
  constructor(days: number, hours: number, minutes: number, seconds: number);
  constructor(days: number, hours: number, minutes: number, seconds: number, milliseconds: number);
  constructor(days: number, hours?: number, minutes?: number, seconds?: number, milliseconds?: number) {
    if (typeof minutes === 'undefined') {
      this._ticks = days || 0;
      return;
    }

    if (typeof seconds === 'undefined') {
      let _hours = days;
      let _minutes = hours;
      let _seconds = minutes;

      this._ticks = _hours * TimeSpan.TicksPerHour +
        _minutes * TimeSpan.TicksPerMinute +
        _seconds * TimeSpan.TicksPerSecond;
      return;
    }

    if (typeof milliseconds === 'undefined') {
      this._ticks = days * TimeSpan.TicksPerDay +
        hours * TimeSpan.TicksPerHour +
        minutes * TimeSpan.TicksPerMinute +
        seconds * TimeSpan.TicksPerSecond;
      return;
    }

    this._ticks = days * TimeSpan.TicksPerDay +
      hours * TimeSpan.TicksPerHour +
      minutes * TimeSpan.TicksPerMinute +
      seconds * TimeSpan.TicksPerSecond +
      milliseconds * TimeSpan.TicksPerMillisecond;
  }

  get totalMilliseconds() { return this._ticks / TimeSpan.TicksPerMillisecond; }
  get totalHours() { return this._ticks / TimeSpan.TicksPerHour; }
  get totalDays() { return this._ticks / TimeSpan.TicksPerDay; }
  get totalMinutes() { return this._ticks / TimeSpan.TicksPerMinute; }
  get totalSeconds() { return this._ticks / TimeSpan.TicksPerSecond; }

  get ticks() { return this._ticks; }
  get seconds() { return parseInt('' + this._ticks / TimeSpan.TicksPerSecond % 60); }
  get minutes() { return parseInt('' + this._ticks / TimeSpan.TicksPerMinute % 60); }
  get milliseconds() { return parseInt('' + this._ticks / TimeSpan.TicksPerMillisecond % 1000); }
  get hours() { return parseInt('' + this._ticks / TimeSpan.TicksPerHour % 24); }
  get days() { return parseInt('' + this._ticks / TimeSpan.TicksPerDay); }

  static fromDays(value: number) { return new TimeSpan(value * TimeSpan.TicksPerDay); }
  static fromHours(value: number) { return new TimeSpan(value * TimeSpan.TicksPerHour); }
  static fromMilliseconds(value: number) { return new TimeSpan(value * TimeSpan.TicksPerMillisecond); }
  static fromMinutes(value: number) { return new TimeSpan(value * TimeSpan.TicksPerMinute); }
  static fromSeconds(value: number) { return new TimeSpan(value * TimeSpan.TicksPerSecond); }
  static fromTicks(value: number) { return new TimeSpan(value); }
  static parse(s?: string) {
    s = s || '';
    let ticks = 0;
    let parts = s.trim().split('.');
    let time = '';

    if (parts[0].includes(':')) {
      time = parts[0];

      if (parts.length > 1) {
        ticks += parseInt(parts[1]) * TimeSpan.TicksPerMillisecond;
      }
    }
    else {
      if (parts.length > 1) {
        ticks += parseInt(parts[0]) * TimeSpan.TicksPerDay;
        time = parts[1];

        if (parts.length > 2) {
          ticks += parseInt(parts[2]) * TimeSpan.TicksPerMillisecond;
        }
      }
    }

    let timeParts = time.split(':');

    if (timeParts.length > 1) {
      ticks += parseInt(timeParts[0]) * TimeSpan.TicksPerHour;
      ticks += parseInt(timeParts[1]) * TimeSpan.TicksPerMinute;
    }

    if (timeParts.length > 2) {
      ticks += parseInt(timeParts[2]) * TimeSpan.TicksPerSecond;
    }

    return new TimeSpan(ticks);
  }

  add(ts: TimeSpan) {
    let ticks = this._ticks + ts._ticks;
    return new TimeSpan(ticks);
  }

  negate() {
    let ticks = this._ticks * -1;
    return new TimeSpan(ticks);
  }

  subtract(ts: TimeSpan) {
    let ticks = this._ticks - ts._ticks;
    return new TimeSpan(ticks);
  }

  toString(format: string = 'd.hh:mm:ss.fff') {
    if (!format) {
      return (this._ticks < 0 ? '-' : '') +
        (this.days > 0 ? this.days + '.' : '') +
        ('' + this.hours || '0').padStart(2,'0') + ':' +
        ('' + this.minutes || '0').padStart(2,'0') + ':' +
        ('' + this.seconds || '0').padStart(2,'0') + '.' +
        ('' + this.milliseconds || '0').padStart(6,'0');
    }

    let result = '';

    format.split(/[ \:\,\.\-]/g)
      .filter(x => x && x.trim().length > 0)
      .forEach(part => {
        if (part.startsWith('d')) {
          result += ('' + this.days).padStart(part.length, '0');
        }
        else if (part.startsWith('m')) {
          result += ('' + this.minutes).padStart(part.length, '0');
        }
        else if (part.startsWith('h')) {
          result += ('' + this.hours).padStart(part.length, '0');
        }
        else if (part.startsWith('s')) {
          result += ('' + this.seconds).padStart(part.length, '0');
        }
        else if (part.startsWith('f')) {
          result += ('' + this.milliseconds).padStart(part.length, '0');
        }
      });

    return result;
  }

  valueOf() {
    return this._ticks;
  }
}
