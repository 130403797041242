
import { Injectable, Injector } from '@angular/core';

import {
  Produce,
  HttpPost,
  HttpGet,
  FromBody,
  Controller,
  resultOf,
  SuccessTestCase,
  FailedTestCase,
  InvalidModelResult,
  SuccessResult,
  VerificationFailedResult,
  HttpStatusResult,
  FromRoute
} from '@jct/core';

import {
  CanLoginPasswordState,
  CanNotLoginPasswordState,
  ConfigureTwoFactorAuthenticationState,
  LoggedInState,
  LoggedOutState,
  ShouldConfigureTwoFactorAuthenticationState,
  ShouldConfirmByEmailState,
  ShouldRegisterState,
  ShouldValidateTwoFactorAuthenticationState,
  ShouldVerifyState
} from './account.test';

import {
  AuthenticatorState,
  IdentifyModel,
  VerifyModel,
  RegisterModel,
  CreateAccountModel,
  LoginModel,
  ConfigureTfaModel,
  ValidateTfaModel,
  ResetPasswordModel,
  ChangePasswordModel,
  GeneratePasswordState,
  GeneratePasswordModel
} from './account.types';

@Injectable({ providedIn: 'root' })
@Controller('account')
export class AccountService {
  constructor(
    protected injector: Injector)
  { }

  @HttpPost('identify')
  @SuccessTestCase(ShouldVerifyState)
  @SuccessTestCase(ShouldRegisterState)
  @SuccessTestCase(ShouldConfirmByEmailState)
  @FailedTestCase(InvalidModelResult, 'תעודת הזהות או הדרכון אינם תקינים')
  @Produce(AuthenticatorState)
  identify(@FromBody() model: IdentifyModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('verify')
  @SuccessTestCase(LoggedInState)
  @FailedTestCase(VerificationFailedResult)
  @Produce(AuthenticatorState)
  verify(@FromBody() model: VerifyModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('register')
  @SuccessTestCase(LoggedInState)
  @FailedTestCase(InvalidModelResult, 'כתובת המייל לא תקינה')
  @Produce(AuthenticatorState)
  register(@FromBody() model: RegisterModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('create')
  @SuccessTestCase(ShouldConfirmByEmailState)
  @Produce(AuthenticatorState)
  create(@FromBody() model: CreateAccountModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('login')
  @SuccessTestCase(LoggedInState)
  @SuccessTestCase(ShouldConfigureTwoFactorAuthenticationState)
  @SuccessTestCase(ShouldValidateTwoFactorAuthenticationState)
  @Produce(AuthenticatorState)
  login(@FromBody() model: LoginModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('tfa/configure')
  @SuccessTestCase(ConfigureTwoFactorAuthenticationState)
  @Produce(AuthenticatorState)
  configure(@FromBody() model: ConfigureTfaModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('tfa/validate')
  @SuccessTestCase(LoggedInState)
  @Produce(AuthenticatorState)
  validate(@FromBody() model: ValidateTfaModel) {
    return resultOf<AuthenticatorState>();
  }

  @HttpGet('logout')
  @SuccessTestCase(LoggedOutState)
  @Produce(AuthenticatorState)
  logout() {
    return resultOf<AuthenticatorState>();
  }

  @HttpPost('password/reset-external')
  @SuccessTestCase(SuccessResult, 'מייל נשלח למשתמש עם קישור לאיפוס הסיסמה')
  @FailedTestCase(InvalidModelResult, 'תעודת הזהות או הדרכון אינם תקינים')
  @Produce('string')
  resetPassword(@FromBody() model: ResetPasswordModel) {
    return resultOf<string>();
  }

  @HttpPost('password/change-external')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  changePassword(@FromBody() model: ChangePasswordModel) {
    return resultOf();
  }

  @HttpPost('password/generate')
  @SuccessTestCase(CanLoginPasswordState)
  @SuccessTestCase(CanNotLoginPasswordState)
  @Produce(GeneratePasswordState)
  generatePassword(@FromBody() model: GeneratePasswordModel) {
    return resultOf<GeneratePasswordState>();
  }

  @HttpGet('password/get-email/{ownerKeyNumber}')
  @Produce('string')
  getUserEmail(@FromRoute('ownerKeyNumber') ownerKeyNumber: number) {
    return resultOf<string>();
  }
}
