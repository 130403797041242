
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessTestCase,
  EntityNotExistsResult,
  SuccessResult,
  InvalidModelResult,
  FailedTestCase,
  HttpStatusResult
} from '@jct/core';
import { FAKE_DIPLOMA_COURSE } from './diploma.test';
import { StudentDiplomaCourse } from './diploma.types';


@Injectable({providedIn: 'root'})
@Controller('diploma')
export class DiplomaService {
  constructor(
    protected injector: Injector)
  { }

  @UseState()
  @HttpGet()
  @SuccessTestCase(FAKE_DIPLOMA_COURSE, "קבלת נתוני דיפלומה עבור הסטודנט")
  @SuccessTestCase(EntityNotExistsResult, 'לא קיימים נתוני דיפלומה עבור הסטודנט')
  @Produce(StudentDiplomaCourse, true)
  getStudentDiplomaCoursesAsync()
  {
    return resultOf<StudentDiplomaCourse[]>();
  }

  @UseState()
  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentDiplomaCoursesAsync(
    @FromBody('studentDiplomaCourses') courses: StudentDiplomaCourse[])
  {
    return resultOf();
  }
}
