import { Injectable, Injector } from "@angular/core";
import { Controller, FromBody, FromQueryString, FromRoute, HttpGet, HttpPost, Produce, resultOf } from "@jct/core";
import { mailMessage, messageFilter } from "../shared";

@Injectable({ providedIn: 'root' })
@Controller('message')
export class MessageService {
    constructor(
        protected injector: Injector) { }

    @HttpPost('mail-messages')
    getMailMesseges(
        @FromBody() filter: messageFilter) {
        return resultOf<mailMessage[]>();
    }
}
