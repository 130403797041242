import { Component } from '@angular/core';

import { RegistrationNavigator } from '@jct/api';

@Component({
  selector: 'relatives.page',
  templateUrl: './relatives-page.component.html',
})
export class RelativesPageComponent {
  constructor(public navigator: RegistrationNavigator) {}
}
