
<div container>
  <ui-error [state]="loadState"></ui-error>

  <form
    [formGroup]="form"
    *visible="loadState.isCompleted"
    (submit)="submit()"
  >
    <div container>
      <ui-alert type="info">
        <div ui-body i18n>
          מספיק למלא פרטי קרוב אחד
        </div>
      </ui-alert>
    </div>

    <div class="table-responsive mat-elevation-z2">
      <table mat-table
        [dataSource]="dataSource"
        formArrayName="relatives"
        [ui-column-width]="[140,120,120,120,100,120,170,120,120,50]"
      >
        <ng-container matColumnDef="relation-type">
          <th mat-header-cell *matHeaderCellDef i18n>
            קרבה משפחתית
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field *ngIf="row.value">
              <mat-select formControlName="relationType">
                <mat-option value i18n>
                  {gender, select,
                    male {בחר}
                    female {בחרי}
                  }
                </mat-option>
                <mat-option [value]="RelationTypes.Spouse">
                  {gender, select,
                    male {בת זוג}
                    female {בן זוג}
                  }
                </mat-option>
                <mat-option [value]="RelationTypes.Father" i18n>אב</mat-option>
                <mat-option [value]="RelationTypes.Mother" i18n>אם</mat-option>
                <mat-option [value]="RelationTypes.Brother" i18n>אח</mat-option>
                <mat-option [value]="RelationTypes.Sister" i18n>אחות</mat-option>
                <mat-option [value]="RelationTypes.Son" i18n>ילד</mat-option>
                <mat-option [value]="RelationTypes.Daughter" i18n>ילדה</mat-option>
                <mat-option [value]="RelationTypes.Boyfriend" i18n>חבר</mat-option>
                <mat-option [value]="RelationTypes.Girlfriend" i18n>חברה</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="first-name">
          <th mat-header-cell *matHeaderCellDef i18n>
            שם פרטי
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="text" matInput
                formControlName="firstName"
                autocomplete="off"
                i18n-placeholder
                placeholder="שם פרטי"
                pattern="^([a-zA-Zא-ת ]*)$">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="last-name">
          <th mat-header-cell *matHeaderCellDef i18n>
            שם משפחה
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="text" matInput
                formControlName="lastName"
                autocomplete="off"
                i18n-placeholder
                placeholder="שם משפחה"
                pattern="^([a-zA-Zא-ת ]*)$">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="birth-country">
          <th mat-header-cell *matHeaderCellDef i18n>
            ארץ לידה
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <country-field
              formControlName="birthCountry"
              [label]="birthCountryPlaceholder.text"
              floatLabel="never"
              appearance="legacy">
            </country-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="education-years">
          <th mat-header-cell *matHeaderCellDef i18n>
            שנות לימוד
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input matInput type="number"
                formControlName="educationYears"
                min="0"
                max="20"
                pattern="^([0-9]{1}|1[0-9]|20)$"
                autocomplete="off"
                i18n-placeholder
                placeholder="שנות לימוד">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="occupation-type">
          <th mat-header-cell *matHeaderCellDef i18n>
            תעסוקה
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">

              <mat-select formControlName="occupationType">
                <mat-option value i18n>{gender, select, male {בחר} female {בחרי}}</mat-option>
                <mat-option [value]="OccupationTypes.Employee" i18n>{relativeGender(row), select, male {עובד} female {עובדת}}</mat-option>
                <mat-option [value]="OccupationTypes.SelfEmployed" i18n>{relativeGender(row), select, male {עצמאי} female {עצמאית}}</mat-option>
                <mat-option [value]="OccupationTypes.Studying" i18n>{relativeGender(row), select, male {לומד} female {לומדת}}</mat-option>
                <mat-option [value]="OccupationTypes.Passed" i18n>{relativeGender(row), select, male {נפטר} female {נפטרה}}</mat-option>
                <mat-option [value]="OccupationTypes.Pension" i18n>{relativeGender(row), select, male {פנסיונר} female {פנסיונרית}}</mat-option>
                <mat-option [value]="OccupationTypes.PartiallyEmployed" i18n>{relativeGender(row), select, male {עובד חלקית} female {עובדת חלקית}}</mat-option>
                <mat-option [value]="OccupationTypes.Unemployed" i18n>{relativeGender(row), select, male {לא עובד} female {לא עובדת}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="work-place">
          <th mat-header-cell *matHeaderCellDef i18n>
            מקום לימודים / עבודה
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input matInput type="text"
                formControlName="workPlace"
                autocomplete="off"
                i18n-placeholder
                placeholder="מקום לימודים/ עבודה">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="job-description">
          <th mat-header-cell *matHeaderCellDef i18n>
            תפקיד
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="text" matInput
                formControlName="jobDescription"
                autocomplete="off"
                i18n-placeholder
                placeholder="תפקיד">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="work-phone">
          <th mat-header-cell *matHeaderCellDef i18n>
            טלפון
          </th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field floatLabel="never">
              <input type="text" matInput type="tel"
                formControlName="workPhone"
                autocomplete="off"
                i18n-placeholder
                placeholder="טלפון"
                pattern="^([\d\-\+\s\(\)]{7,})$">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <button mat-icon-button
              type="button"
              [matTooltip]="deleteTooltip.text"
              (click)="removeRow(index)"
              *visible="canRemoveRow(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayColumns;"></tr>
      </table>
    </div>

    <ui-alert type="warning" *visible="showError && (errorList?.length > 0 || _completedRows.length <= 0)">
      <div ui-header i18n>
        לא ניתן לשמור מכיון שאחד או יותר מהפרטים שגויים או חסרים
      </div>

      <ul ui-body>
        <ng-container *ngIf="errorList?.length > 0; else generalError">
          <ng-container *ngFor="let error of errorList">
            <li *ngIf="error == 'firstName-required'" i18n>
              שם פרטי חובה
            </li>
            <li *ngIf="error == 'firstName-pattern'" i18n>
              שם פרטי לא תקין
            </li>
            <li *ngIf="error == 'lastName-required'" i18n>
              שם משפחה חובה
            </li>
            <li *ngIf="error == 'lastName-pattern'" i18n>
              שם משפחה לא תקין
            </li>
            <li *ngIf="error == 'workPhone-required'" i18n>
              טלפון חובה
            </li>
            <li *ngIf="error == 'workPhone-pattern'" i18n>
              מספר הטלפון אינו תקין
            </li>
          </ng-container>
        </ng-container>

        <ng-template #generalError>
          <li i18n>
            חובה להזין לפחות קרוב משפחה אחד
          </li>
        </ng-template>
      </ul>
    </ui-alert>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [state]="saveState"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>

<term #birthCountryPlaceholder
  i18n>
  {gender, select, male {בחר} female {בחרי}}
  ארץ לידה
</term>

<term #deleteTooltip
  i18n>
  {gender, select, male {מחק} female {מחקי}}
  שורה
</term>
