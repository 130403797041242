import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostBinding,
  ElementRef,
} from "@angular/core";

import { TokenSettings, Authenticator } from "@jct/core";

import { MenuService } from "@jct/ui/lib/services";
import { MenuItem, MenuItemStates } from "@jct/ui/lib/common";

@Component({
  selector: "ui-menu-item",
  templateUrl: "./menu-item.component.html",
})
export class MenuItemComponent implements MenuItem, OnInit {
  constructor(
    private menuService: MenuService,
    private profileService: Authenticator,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  @Input()
  route: string;

  title: string = "";

  get element() {
    return this.elementRef.nativeElement;
  }

  state?: MenuItemStates = "close";

  @HostBinding("class.open")
  get isOpen() {
    return this.state == "open";
  }

  @HostBinding("class.opening")
  get isOpening() {
    return this.state == "opening";
  }

  @HostBinding("class.close")
  get isClose() {
    return this.state == "close";
  }

  @HostBinding("class.closing")
  get isClosing() {
    return this.state == "closing";
  }

  @Input()
  link?: string;

  @Output()
  action = new EventEmitter();

  @Input()
  canNavigate?: boolean | ((profile: TokenSettings) => boolean) | null;

  @Input()
  display: boolean = true;

  children?: MenuItem[];

  @HostBinding("class.opened")
  opened: boolean = false;

  @HostBinding("class.list-item-active")
  selected: boolean = false;

  parent?: MenuItem;

  @HostBinding("class.disabled")
  get disabled() {
    if (typeof this.canNavigate === "boolean") {
      return !this.canNavigate;
    }

    if (this.canNavigate) {
      return !this.canNavigate(this.profileService.tokenSettings);
    }

    if (this.action.observers.length > 0) {
      return false;
    }

    return false;
  }

  async ngOnInit() {
    if (this.canNavigate == null || this.canNavigate == undefined) {
      this.canNavigate = true;
    }
  }

  async navigateTo() {
    if (this.disabled) {
      return;
    }

    await this.menuService.navigateTo(this);
  }
}
