
interface Array<T = any> {
  distinct(filter?: (value: T) => boolean): Array<T>;
  distinctBy(key: keyof T): Array<T>;
  orderBy(key: keyof T): Array<T>;
}

Array.prototype.distinct = function(filter?: (value: any) => boolean) {
  let arr = (<any[]>this);

  if (filter) {
    arr = arr.filter((item: any) => filter(item));
  }

  return arr.filter((item: any, index: number, array: any[]) => array.indexOf(item) === index);
}

Array.prototype.distinctBy = function(key: any) {
  let arr = (<any[]>this);
  const exists = [];

  return arr.filter((item: any) => {
    const value = item[key];

    if (!exists.includes(value)) {
      exists.push(item[key]);
      return true;
    }

    return false;
  });
}

Array.prototype.orderBy = function(key: any) {
  let arr = (<any[]>this);

  if (arr[0] && typeof arr[0][key] === 'number') {
    return arr.sort((a: any, b: any) => {
      const va = a[key];
      const vb = b[key];

      if (typeof va === 'number') {
        return va - vb;
      }

      return 0;
    });
  }

  return arr.sort();
}
