
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  HttpGet,
  resultOf,
  HttpPost,
  FromBody,
  UseState,
  Produce,
  SuccessTestCase,
  SuccessResult,
  EntityNotExistsResult,
  FailedTestCase,
  InvalidModelResult,
  HttpStatusResult
} from '@jct/core';

import { FakeFemalePersonDetails, FakeMalePersonDetails, FakePersonContact, FakePersonRelative } from './person.test';
import { PersonContact, PersonRelative, PersonDetails } from './person.types';

@Injectable({ providedIn: 'root' })
@Controller('person')
export class PersonService {
  constructor(
    protected injector: Injector)
  { }

  @UseState()
  @HttpGet('details')
  @SuccessTestCase(FakeMalePersonDetails)
  @SuccessTestCase(FakeFemalePersonDetails)
  @Produce(PersonDetails)
  getDetailsAsync() {
    return resultOf<PersonDetails>();
  }

  @UseState()
  @HttpPost('details')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveDetailsAsync(
    @FromBody('details') details: PersonDetails) {
    return resultOf();
  }

  @UseState()
  @HttpGet('contact')
  @SuccessTestCase(FakePersonContact)
  @Produce(PersonContact)
  getContactAsync() {
    return resultOf<PersonContact>();
  }

  @UseState()
  @HttpPost('contact')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveContactAsync(
    @FromBody('contact') contact: PersonContact) {
    return resultOf();
  }

  @UseState()
  @HttpGet('relative/contact')
  @SuccessTestCase(FakePersonContact)
  @Produce(PersonContact)
  getRelativeContactAsync() {
    return resultOf<PersonContact>();
  }

  @UseState()
  @HttpPost('relative/contact')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveRelativeContactAsync(
    @FromBody('contact') contact: PersonContact) {
    return resultOf();
  }

  @UseState()
  @HttpGet('relatives')
  @SuccessTestCase(FakePersonRelative)
  @SuccessTestCase(EntityNotExistsResult)
  @Produce(PersonRelative, true)
  getRelativesAsync() {
    return resultOf<PersonRelative[]>();
  }

  @UseState()
  @HttpPost('relatives')
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveRelativesAsync(
    @FromBody('relatives') relatives: PersonRelative[]) {
    return resultOf();
  }
}
