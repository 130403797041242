
export class StudentAmiramTestModel {
  amiramTestId: number;
  instituteName: string;
  testStartDate: string;
}

export class AmiramTestModel {
  id: number;
  instituteName: string;
  testStartDate: string;
}
