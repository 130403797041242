import { Category } from "../actions";
import { LersUser } from "../users/users.types";

export class Lecturers {
  lecturerId: number;
  ownerKeyNumber: number;
  idnumber: number;
  statusName: string;
  userTypeName: string;
  firstName: string;
  lastName: string;
  title: string;
  academicRankName: string;
  appointmentTypeName: string;
  email: string;
  isEntitledByComputeString: string;
  academicDepartment: string;
}

export class LecturerWithAllStatuses {
  lecturer: Lecturer;
  ranks: KeyValue[];
  userTypes: KeyValue[];
  lecturerTypes: KeyValue[];
  appointmentTypes: KeyValue[];
  lecturerStatuses: KeyValue[];
  stages: KeyValue[];
  excellenceLevels: KeyValue[];
}

export class Lecturer {
  lecturerId: number;
  ownerKeyNumber: number;
  idnumber: number;
  passportNumber: string;
  academicDepartment: string;
  currentAcademicDepartment: string;
  doneFillAllDetailsOn: Date;
  confirmedOn: Date;
  reason: string;
  hasMoreJobs: boolean;
  statusName: string;
  statusId: number;
  typeName: string;
  typeId: number;
  isEntitled: boolean;
  isEntitledByComputeString: string;
  excellenceLevelName: string;
  excellenceLevelId: number;
  lastStageName: string;
  lastStageId: string;
  notEligibleComment: string;
  userDetails: LersUser;
  percentEntitled: number;
  reasonForLeavingInitialGroup: string;
  finalScore: number;
  academicAppointmentsInSpecialPrograms: AcademicAppointmentsInSpecialPrograms;
  lecturersGeneralData: LecturersGeneralData;
  otherAppointmentsOutsideJct: OtherAppointmentsOutsideJct;
  seniorAcademicAppointments: SeniorAcademicAppointments;
  lecturersActions: LecturersActions[];
  lecturersAppointments: LecturersAppointments[];
  lecturersArticles: LecturersArticles[];
  lecturersContracts: LecturersContracts[];
  lecturersCourses: LecturersCourses[];
  lecturersGrants: LecturersGrants[];
  lecturersReports: LecturersReports[];
  lecturerScoreData: LecturerScoreData;
}

export class AcademicAppointmentsInSpecialPrograms {
  lecturerId: number;
  isOtherAppointment: boolean;
  specialAdditionalAppointment: string;
  specialAdditionalJobPercentages: number;
  specialAdditionalTeachingWh: number;
}

export class LecturersGeneralData {
  lecturerId: number;
  isImportantJobOwner: boolean;
  teachingPp: number;
  isJctwasOnlyJob: boolean;
  isPpover100: boolean;
  isPpover100Confirmed: boolean;
  isPpover150: boolean;
  isPpover150Confirmed: boolean;
  additionalPp: number;
}

export class OtherAppointmentsOutsideJct {
  lecturerId: number;
  isOtherAppointment: boolean;
  isPartnerOutsideJct: boolean;
  companyName: string;
  positionPercentages: number;
  positionHours: number;
}
export class SeniorAcademicAppointments {
  lecturerId: number;
  appointmentTypeId: number;
  academicRankId: number;
  teachingPp: number; //double
  teachingWh: number; //double
  isFirstSemesterInSabbatical: boolean;
  isSecondSemesterInSabbatical: boolean;
  wasInMaternityLeave: boolean;
  fullTimeWh: number; //double
  finalSabbaticalWh: number; //double
  finalMaternityLeaveWh: number; //double
  academicRankName: string;
  appointmentTypeName: string;
}
export class LecturersActions {
  actions: Actions;
  lecturerId: number;
  lecturerActionId: number;
  actionId: number;
  score: number; //double
  describe: string;
  updatedOn: Date;
  answer: number;
  multiplicityFactor: number;
  paperFactorId: number;
  hasAnswer: boolean;
  lecturersForms: LecturersForms[];
  paperFactor: PaperFactories;
}

export class LecturersAppointments {
  lecturerId: number;
  appointmentId: number;
  organizationName: string;
  teachingPp: number;
  teachingWh: number;
  nonTeachingPp: number;
  nonTeachingWh: number;
  nonRewardedTeachingPp: number;
  nonRewardedTeachingWh: number;
}
export class LecturersArticles {
  lecturerId: number;
  articleId: number;
  articleName: string;
  articleType: number;
  partners: number;
  publisherName: string;
  publishDate: string;
  confirmFileId: number;
  confirmFile2Id: number;
  confirmFile: Files;
  lecturerActionId: number;
  roleInArticleId?:number;
  confirmFile2?: Files;
}
export class LecturersContracts {
  lecturerId: number;
  contractId: number;
  contractDescription: string;
  contractAmount: number;
  partners: number;
  confirmFileId: number;
  lecturerActionId: number;
}
export class LecturersCourses {
  lecturerId: number;
  courseId: number;
  courseNumber: string;
  courseName: string;
  semesterId: number;
  courseType: number;
  courseRegistrations: number;
  isSelected: boolean;
  selectedBySystem: boolean;

  semester: Semester;
}

export class LecturersForms {
  formId: number;
  lecturerActionId: number;
  fileId: number;
  formType: number;
  formName: string;
  file: Files;
}

export class Files {
  fileId: number;
  fileName: string;
  fileSize: number;
  fileTypeId: number;
  createdOn: Date;
}

export class LecturersGrants {
  lecturerId: number;
  grantId: number;
  grantDescription: string;
  grantAmount: number;
  partners: number;
  confirmFileId: number;
  confirmFileId2: number;
  lecturerActionId: number;
}
export class LecturersReports {
  lecturerId: number;
  reportId: number;
  score: number; //double
  confirmedOn: Date;
}

export class Actions {
  actionId: number;
  categoryId: number;
  actionKey: string;
  maxScore: number; //double
  score: number; //double
  containMf: boolean;
  groupId: number;
  isMulti: boolean;
  containPf: boolean;
  category: Category;
  group: ActionsDefinitionsGroups;
}

export class ActionsDefinitionsGroups {
  groupId: number;
  groupName: string;
  groupMaxScore: number; //double
}

export class PaperFactories {
  id: number;
  type: number;
  above: number;
  until: number;
  value: number;
}

export class LecturerScoreData {
  totalA: number;
  totalB: number;
  totalC: number;
  totalD: number;
  totalE: number;
  totalF: number;

  firstGroup: number;
  finalGroup: number;
  finalScore: number;
  positionInScore: boolean;
  entitled3Years: boolean;
  reasonForLeavingInitialGroup: string;
  sekerScorePositionInThreeQuarters: boolean; //5.1
  isPublishedArticle: boolean; //5.2
  isSenatAttendance: boolean; //5.3
  isScoreInFUnder4AndEntitled3Years: boolean; //5.4
}

export class BasicUserDetails {
  id: number;
  ownerKeyNumber: number;
  fullName: string;
}

export class KeyValue {
  id: number;
  name: string;
}

export class LecturersStstus extends KeyValue {}


export class actionFile {
  action: LecturersActions;
  fileId: number;
}

export class DepartmentHeadScore {
  lecturerId: number;
  conversationExist: boolean;
  depatmentHeadScore: number;
  surveyAnswerAverage: number;
  surveyAnswerAverageScore: number;
}

export class Semester{
  id: number;
  semesterName: string;
  semesterShortName: string;
  englishName: string;
}

export class LersProfile{ 
  userId:number;
  role:string;
}

export class RoleInArticle{
  id:number;
  name:string;
}