
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { ProgressIndicator } from '@jct/core';

@Component({
  selector: 'ui-loading-progress',
  templateUrl: './loading-progress.component.html',
  exportAs: 'uiLoadingProgress',
  host: {
    'attr.style.position': 'fixed'
  }
})
export class LoadingProgressComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private _loading: boolean;

  constructor(
    private progressIndicator: ProgressIndicator)
  { }

  async ngOnInit() {
    this._loading = true;
    this._subscription = this.progressIndicator.observable.subscribe(x => {
      //this._loading = x;
    });
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  get isLoading() {
    return this._loading;
  }
}
