import { Injectable, Provider } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { Connectivity } from './connectivity.service';
import { ApiInterceptor, API_INTERCEPTORS } from '../api-client/types';
import { HttpContext } from "../api-client/http-context";
import { HttpStatusResult, OfflineResult, ServerUnavailableResult, GatewayTimeoutResult } from '../api';

@Injectable()
export class ConnectivityInterceptor implements ApiInterceptor {
  enabled = true;
  order = 2;

  constructor(
    private connectivity: Connectivity)
  { }

  async begin(context: HttpContext) {
    if (this.connectivity.isOffline) {
      const result = new OfflineResult();

      context.generateResponse(new HttpResponse({
        body: result,
        status: result.status,
        url: context.request.url,
      }));
    }
  }

  async error(context: HttpContext) {
    const errorResponse = context.error;
    const statusCode = errorResponse.status;

    let result: HttpStatusResult;

    switch (statusCode) {
      case 0:
        this.connectivity.reportServerUnavailable();
        result = new ServerUnavailableResult();
        break;

      case 504:
        this.connectivity.reportServerUnavailable();
        result = new GatewayTimeoutResult();
        break;

      default:
        result = new HttpStatusResult();
        result.statusText = errorResponse.error;
        result.status = errorResponse.status;
        break;
    }

    context.generateResponse(new HttpResponse({
      body: result,
      status: statusCode,
      url: context.error.url,
    }));
  }
}

export const ConnectivityInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: ConnectivityInterceptor,
  multi: true,
};
