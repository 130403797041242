import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { BehaviorSubject } from 'rxjs';

import { AcademicStudyTypes, Institutes, ProfileService, RegistrationNavigator, RegistrationProfile, RegistrationStages, RegistrationUserProfile, StudentYearlyStudyTracks, StudyTrack, StudyTrackService } from '@jct/api';
import { extractErrors, HttpStatusResult } from '@jct/core';
import { Gender } from '@jct/localization';
import { getCompletedRows, hasNewRow, StateFactory, TableRow, validateField, waitForDataTable } from '@jct/ui';

interface AcademicStudyType {
  academicStudyTypeId: number;
  academicStudyTypeName: string;
}

interface Institute {
  instituteId: number;
  instituteName: string;
}

interface Department {
  departmentId: number;
  departmentName: string;
}

interface Track {
  trackId: number;
  trackName: string;
}

interface AcademicYear {
  academicYear: number;
  academicYearTitle: string;
}

interface Row extends TableRow {
  departmentId?: number;
  trackId?: number;
  academicYear?: number;

  departments?: Department[];
  tracks?: Track[];
  academicYears?: AcademicYear[];
}

@Component({
  selector: 'study-track.page',
  templateUrl: './study-track-page.html',
})
export class StudyTrackPage implements OnInit {
  constructor(
    private studyTrackService: StudyTrackService,
    private profileService: ProfileService,
    private userProfile: RegistrationUserProfile,
    private registrationNavigator: RegistrationNavigator,
    private fb :UntypedFormBuilder,
    private sf: StateFactory)
  { }

  @ViewChild('dataTable')
  dataTable: MatTable<any>;

  displayColumns: string[] = ['departmentId', 'trackId', 'academicYear', 'actions'];

  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup = this.fb.group({
    academicStudyTypeId: [0, [Validators.required, Validators.min(1)]],
    instituteId: [0, [Validators.required, Validators.min(1)]],
    studyTracks: this.rows
  });
  dataSource = new BehaviorSubject<AbstractControl[]>(this.rows.controls);

  loadState = this.sf.create();
  saveState = this.sf.create();
  isSubmitOnce = false;

  private _studyTracks: StudyTrack[] = [];
  private _academicYearFromServer: number = 0;

  get gender() {
    return this.userProfile.gender;
  }

  private get isFemale() {
    return this.gender == Gender.Female;
  }

  get errorList() {
    return extractErrors(this.rows);
  }

  get displayForMechina() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.Mechina;
  }

  get displayForMaleMechina() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.Mechina && !this.isFemale;
  }

  get displayForFemaleFirstDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.FirstDegree && this.isFemale;
  }

  get displayForMaharTalFirstDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.FirstDegree && this.form.value.instituteId == Institutes.Mahar_Tal;
  }

  get displayForMaharTalAndLustigFirstDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.FirstDegree &&
    (this.form.value.instituteId == Institutes.Mahar_Tal || this.form.value.instituteId == Institutes.Lustig);
  }

  get displayForTalFirstDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.FirstDegree && this.form.value.instituteId == Institutes.Tal;
  }

  get displayForTalSecondDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.SecondDegree && this.form.value.instituteId == Institutes.Tal;
  }

  get displayForLevSecondDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.SecondDegree && this.form.value.instituteId == Institutes.Lev;
  }

  get displayForLustigFirstDegree() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.FirstDegree && this.form.value.instituteId == Institutes.Lustig;
  }

  get displayForLustigCompletionYear() {
    return this.form.value.academicStudyTypeId == AcademicStudyTypes.CompletionYear && this.form.value.instituteId == Institutes.Lustig;
  }

  get hasExplain() {
    return this.displayForMechina ||
      this.displayForMaleMechina ||
      this.displayForFemaleFirstDegree ||
      this.displayForMaharTalFirstDegree ||
      this.displayForMaharTalAndLustigFirstDegree ||
      this.displayForTalSecondDegree ||
      this.displayForTalFirstDegree ||
      this.displayForLevSecondDegree ||
      this.displayForLustigFirstDegree ||
      this.displayForLustigCompletionYear;
  }

  academicStudyTypes: AcademicStudyType[] = [];
  private _updateAcademicStudyTypes() {
    this.academicStudyTypes = this._studyTracks
      .map(({academicStudyTypeId, academicStudyTypeName}) => ({ academicStudyTypeId, academicStudyTypeName }))
      .distinctBy('academicStudyTypeId')
      .orderBy('academicStudyTypeId');
  }

  institutes: Institute[] = [];
  private _updateInstitutes() {
    const academicStudyTypeId = this.form.get('academicStudyTypeId').value as number;

    this.institutes = this._studyTracks
      .filter(x => x.academicStudyTypeId === academicStudyTypeId)
      .map(({ instituteId, instituteName }) => ({ instituteId, instituteName }))
      .distinctBy('instituteId')
      .orderBy('instituteName');
  }

  private _getDistinctDepartments() {
    const academicStudyTypeId = this.form.get('academicStudyTypeId').value as number;
    const instituteId = this.form.get('instituteId').value as number;

    return this._studyTracks
      .filter(x =>
        !this._takenTracks.some(t => t === x.trackId) &&
        x.academicStudyTypeId === academicStudyTypeId &&
        x.instituteId === instituteId)
      .map(({ departmentId, departmentName }) => ({ departmentId, departmentName }))
      .distinctBy('departmentId')
      .orderBy('departmentName');
  }

  private _getDistinctTracks(departmentId: number) {
    const academicStudyTypeId = this.form.get('academicStudyTypeId').value as number;
    const instituteId = this.form.get('instituteId').value as number;

    return this._studyTracks
      .filter(x =>
          !this._takenTracks.some(t => t === x.trackId) &&
          x.academicStudyTypeId === academicStudyTypeId &&
          x.instituteId === instituteId &&
          x.departmentId === departmentId)
      .map(({ trackId, trackName }) => ({ trackId, trackName }))
      .distinctBy('trackId')
      .orderBy('trackName');
  }

  private _getDistinctYears(departmentId: number, trackId: number) {
    const academicStudyTypeId = this.form.get('academicStudyTypeId').value as number;
    const instituteId = this.form.get('instituteId').value as number;

    return this._studyTracks
      .filter(x =>
          x.academicStudyTypeId === academicStudyTypeId &&
          x.instituteId === instituteId &&
          x.departmentId === departmentId &&
          x.trackId === trackId)
      .map(({ academicYear, academicYearTitle }) => ({ academicYear, academicYearTitle }))
      .distinctBy('academicYear')
      .orderBy('academicYear');
  }

  private _addEmptyRow() {
    if (!hasNewRow(this.rows)) {
      this._createRow();
    }
  }

  private get _takenTracks() {
    return this.rows.controls
      .map(x => (<Row>x.value).trackId)
      .filter(x => x > 0);
  }

  private get _completedRows() {
    return getCompletedRows<Row>(this.rows);
  }

  get hasConflict() {
    return this._completedRows.map(x => x.academicYear).distinct().length > 1;
  }

  get hasDifferences() {
    if (this._academicYearFromServer === 0) {
      return false
    }

    return this._completedRows.some(x => x.academicYear !== this._academicYearFromServer);
  }

  get hasInvalidTrack() {
    const takenTracks = this._studyTracks.filter(x => this._takenTracks.includes(x.trackId));

    return this._completedRows.some(x => !takenTracks.some(y => y.academicYear === x.academicYear && y.trackId === x.trackId));
  }

  canMoveDown(index: number) {
    return index < this.rows.length - 2;
  }

  canMoveUp(index: number) {
    return index > 0 && index < this.rows.length - 1;
  }

  canRemoveRow(row: UntypedFormGroup) {
    return !row.value.newRow;
  }

  removeRow(index: number) {
    if (index >= 0) {
      this.rows.removeAt(index);
      this.dataTable.renderRows();

      const departments = this._getDistinctDepartments();

      this.rows.controls.forEach(row => {
        row.patchValue({
          departments,
        });
      });
    }
  }

  moveUp(index: number) {
    if (index > 0) {
      const row = this.rows.at(index);

      this.rows.removeAt(index);
      this.rows.insert(index - 1, row);

      this.dataTable.renderRows();
    }
  }

  moveDown(index: number) {
    if (index >= 0) {
      const row = this.rows.at(index);

      this.rows.removeAt(index);
      this.rows.insert(index + 1, row);

      this.dataTable.renderRows();
    }
  }

  private _createRow(trackId: number = 0) {
    const studyTrack = this._studyTracks.find(x =>
      x.trackId === trackId &&
      (this._academicYearFromServer === null || x.academicYear === this._academicYearFromServer)) || null;

    const initValue = <Row>{
      newRow: studyTrack === null,
      departmentId: studyTrack?.departmentId || 0,
      trackId: studyTrack?.trackId || 0,
      academicYear: studyTrack?.academicYear || 0,
      complete: !!studyTrack?.academicYear,
    };

    if (trackId > 0 && initValue.newRow) {
      return;
    }

    const row = this.fb.group({
      'newRow': [initValue.newRow],
      'complete': [initValue.complete],
      'departmentId': [initValue.departmentId, []],
      'trackId': [initValue.trackId, [validateField('trackId')]],
      'academicYear': [initValue.academicYear, [validateField('academicYear')]],
      'departments': [[]],
      'tracks': [[]],
      'academicYears': [[]],
    });

    const departmentIdField = row.get('departmentId');
    const trackIdField = row.get('trackId');
    const academicYearField = row.get('academicYear');
    const departments = this._getDistinctDepartments();

    if (!initValue.newRow) {
      const tracks = this._getDistinctTracks(initValue.departmentId);
      const academicYears = this._getDistinctYears(initValue.departmentId, initValue.trackId);

      row.patchValue({
        departments,
        tracks,
        academicYears,
      });

      trackIdField.enable();
      academicYearField.enable();
    }
    else {
      row.patchValue({
        departments,
      });

      trackIdField.disable();
      academicYearField.disable();
    }

    departmentIdField.valueChanges.subscribe((departmentId: number) => {
      if (departmentId > 0) {
        row.patchValue({ newRow: false });

        setTimeout(() => {
          this._addEmptyRow();
        });

        const tracks = this._getDistinctTracks(departmentId);

        row.patchValue({ tracks });

        if (tracks.length === 1) {
          trackIdField.patchValue(tracks[0].trackId);
        }
        else {
          trackIdField.patchValue(0);
        }

        trackIdField.enable();
        trackIdField.markAllAsTouched();
      }
      else {
        trackIdField.disable();
        trackIdField.patchValue(0);

        row.patchValue({
          tracks: []
        });
      }
    });

    trackIdField.valueChanges.subscribe((trackId: number) => {
      academicYearField.patchValue(0);

      if (trackId > 0) {
        const departmentId = departmentIdField.value as number;
        const academicYears = this._getDistinctYears(departmentId, trackId);

        row.patchValue({ academicYears });

        if (academicYears.length === 1) {
          academicYearField.patchValue(academicYears[0].academicYear);
        }
        else {
          academicYearField.patchValue(0);
        }

        academicYearField.enable();
        academicYearField.markAllAsTouched();
      }
      else {
        academicYearField.disable();
        academicYearField.patchValue(0);

        row.patchValue({
          academicYears: []
        });
      }
    });

    academicYearField.valueChanges.subscribe((academicYear: number) => {
      if (academicYear > 0) {
        row.patchValue({ complete: true });
      }
      else {
        academicYearField.markAllAsTouched();
        row.patchValue({ complete: false });
      }
    });

    this.rows.push(row);

    if (this.dataTable) {
      this.dataTable.renderRows();
    }
  }

  async ngOnInit() {
    await this.loadState.inProcess();

    const studyTracks = await this.studyTrackService.getStudyTracksAsync();

    if (studyTracks instanceof HttpStatusResult) {
      this.loadState.failed(studyTracks);
      return;
    }

    if (this.userProfile.minAcademicYear > 0) {
      this._studyTracks = studyTracks.filter(x => x.academicYear > this.userProfile.minAcademicYear);
    }
    else {
      this._studyTracks = studyTracks;
    }

    const studentStudyTracks = await this.studyTrackService.getStudentYearlyStudyTracksAsync();

    if (studentStudyTracks instanceof HttpStatusResult) {
      if (!studentStudyTracks.isEntityNotExists) {
        this.loadState.failed(studentStudyTracks);
        return;
      }
    }

    let initiated = false;
    this._updateAcademicStudyTypes();

    const academicStudyTypeIdField = this.form.get('academicStudyTypeId');
    const instituteIdField = this.form.get('instituteId');

    academicStudyTypeIdField.valueChanges
      .subscribe((academicStudyTypeId: number) => {
        this._updateInstitutes();
        instituteIdField.patchValue(0);

        if (academicStudyTypeId > 0) {
          instituteIdField.enable();
        }
        else {
          instituteIdField.disable();
        }

        if (initiated) {
          this.rows.clear();
          this._addEmptyRow();

          setTimeout(() => {
            this.rows.disable();
          });
        }
      });

    instituteIdField.valueChanges
      .subscribe((instituteId: number) => {
        if (initiated) {
          this.rows.clear();
          this._addEmptyRow();

          if (instituteId === 0) {
            setTimeout(() => {
              this.rows.disable();
            });
          }
        }
      });

    if ('tracks' in studentStudyTracks) {
      const { academicStudyTypeId, academicYear, tracks } = studentStudyTracks;

      this._academicYearFromServer = academicYear;

      const firstStudyTrack = this._studyTracks.filter(x => tracks.includes(x.trackId))[0];

      this.form.patchValue({
        academicStudyTypeId,
        instituteId: firstStudyTrack?.instituteId || 0,
      });

      tracks.forEach(trackId => this._createRow(trackId));
    }

    this._addEmptyRow();
    initiated = true;

    await waitForDataTable(this.sf, () => this.dataTable);
    await this.loadState.completed();
  }

  get showError(){
    return (this._takenTracks.length === 0 ||
    this._completedRows.length === 0 ||
    this.form.invalid);
  }

  async submit() {
    if (this._takenTracks.length === 0 ||
      this._completedRows.length === 0 ||
      this.form.invalid) {
      this.isSubmitOnce = true;
      return;
    }

    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();

    const tracks = getCompletedRows<Row>(this.rows);
    const academicStudyTypeId = this.form.get('academicStudyTypeId').value as number;

    const model: StudentYearlyStudyTracks = {
      academicStudyTypeId,
      academicYear: tracks[0].academicYear,
      tracks: tracks.map(x => x.trackId),
    };

    const result = await this.studyTrackService.saveStudentYearlyStudyTracksAsync(model);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    const loadResult = await this.profileService.loadRegistrationProfile();

    if (loadResult instanceof HttpStatusResult) {
      this.saveState.failed(loadResult);
      return;
    }

    this.registrationNavigator.setCurrentStage(RegistrationStages.בחירת_מסלול_לימודים);
    this.profileService.update<RegistrationProfile>({
      academicStudyType: academicStudyTypeId,
    });

    await this.saveState.completed();
    await this.registrationNavigator.navigateToNextStage();
  }
}
