import { HttpRequest, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TestBuilders } from '@jct/core';
import { FAKE_CITIES, FAKE_COUNTRIES } from '../../information/information.test';

import {StudentHighSchool} from './high-school.types';

export const HIGH_SCHOOLS_PROVIDER = (injector: Injector, request: HttpRequest<any>) => {
  const params = new HttpParams({
    fromString: request.urlWithParams.split('?')[1]
  });

  const query = params.get('query') || '';
  const resultSize = parseInt(params.get('resultSize')) || 10;

  return FAKE_HIGH_SCHOOLS
    .filter(x => x.includes(query))
    .filter((x, index) => index < resultSize);
};

export const STUDENT_HIGH_SCHOOLS_PROVIDER = () => {
  const startYearProvider = TestBuilders.numberBetween(1990, 2010);
  const endYearProvider = TestBuilders.numberBetween(2010, 2015);
  const cityProvider = TestBuilders.oneOf(FAKE_CITIES);
  const countryProvider = TestBuilders.oneOf(FAKE_COUNTRIES);
  const nameProvider = TestBuilders.oneOf(FAKE_HIGH_SCHOOLS);

  const count = TestBuilders.numberBetween(1, 2)();
  const result: StudentHighSchool[] = [];

  for (let index = 0; index < count; index++) {
    const item = new StudentHighSchool();

    item.startYear = <number>startYearProvider();
    item.endYear = <number>endYearProvider();
    item.city = <string>cityProvider();
    item.country = <string>countryProvider();
    item.name = <string>nameProvider();

    result.push(item);
  }

  return result;
};

export const FAKE_HIGH_SCHOOLS = [
  '(דקל (תוחלת - לוד',
  'אביעזרי פעמי רחל - בני ברק',
  'אבן האזל אלעד - אלעד',
  'אבן חן(מוסד בני ציון - ראש העין',
  'אבן ישראל - ירושלים',
  'אגם - רעננה',
  'אדם חברה וטבע - כרמיאל',
  'אדרת אליהו - ירושלים',
  'אהבה - כפר ביאליק',
  'אהבה ושלום - בני ברק',
  'אהבת ישראל - בית שמש',
  'אהבת משה - ביתר עילית',
  'אהבת תורה - ביתר עילית',
  'אהבת תורה - ירושלים',
  'אהל אברהם - בני ברק',
  'אהל חנה - קרית אונו',
  'אהל רחל - בני ברק',
  'אהל שרה בית חנוך בנו - בני ברק',
  'אהל שרה מרכז שלמה - בני ברק',
  'אהל תורה - ירושלים',
  'אהלי חיים - ירושלים',
  'אהלי שמואל (יקירי רכסים) - רכסים',
  'אהלים של תורה - בית שמש',
  'אוהל אשר - אלעד',
  'אוהל אשר - בני ברק',
  'אוהל ברכה - ירושלים',
  'אוהל יהושע - חיפה',
  'אוהלי יעקב - ירושלים',
  'אוהלי מנחם - בית שמש',
  'אוזר ישראל - ירושלים',
  'אולפ\' בנ"ע מ. ברוך - חדרה',
  'אולפנא אמי"ת - צפת',
  'אולפנא בהר"ן - גדרה',
  'אולפנא בני עקיבא - ערד',
  'אולפנא בני עקיבא - תל אביב - יפו',
  'אולפנא הר חברון - סנסנה',
  'אולפנא השומרון - אלקנה',
  'אולפנא וישיבת צעירים - דימונה',
  'אולפנא ירושלים - ירושלים',
  'אולפנא לבנות - אשדוד',
  'אולפנא לבנות - קרית ארבע',
  'אולפנא לבנות יבנה - יבנה',
  'אולפנא לבנות צביה - הרצליה',
  'אולפנא לבנות צפירה - צפריה',
  'אולפנא לבנות שילה - שילה',
  'אולפנא נוה רוחמה - ירושלים',
  'אולפנא נריה - טלמון',
  'אולפנא סגולה - קרית מוצקין',
  'אולפנא עופרה',
  'אולפנא עירונית אמונה - בני ברק',
  'אולפנא צביה - עפולה',
  'אולפנא צביה - רחובות',
  'אולפנא צביה הר ברכה - ברכה',
  'אולפנא צביה לבנות - ירושלים',
  'אולפנא ראשון לציון',
  'אולפנא ת. לאומנויות - ירושלים',
  'אולפנא תורנית חורב - ירושלים',
  'אולפנא תורנית צביה - חיפה',
  'אולפנאת זבולון - ראש העין',
  'אולפנה גלעד - חמדיה',
  'אולפנה יחודית לבנות - נוב',
  'אולפנה לבנות - אלעד',
  'אולפנה לבנות - גבעת שמואל',
  'אולפנה לבנות - קרית גת',
  'אולפנה לבנות אמנה - כפר סבא',
  'אולפנה מדעים ואמנויות - אשדוד',
  'אולפנית דרכא - קרית שמונה',
  'אולפנית הדר גנים - פתח תקוה',
  'אולפנית ישורון - פתח תקווה',
  'אולפנית לבנות - טבריה',
  'אולפנית שחם - קרית אתא',
  'אולפנת אבן שמואל',
  'אולפנת אהבת ישראל - בית שמש',
  'אולפנת אור עקיבא - אור עקיבא',
  'אולפנת אורות עציון - ראש צורים',
  'אולפנת אוריה - אלון שבות',
  'אולפנת אליעד - לוד',
  'אולפנת אמי"ת - באר שבע',
  'אולפנת בית שאן - בית שאן',
  'אולפנת בנ"ע אהל שלמה - באר שבע',
  'אולפנת בנ"ע כפר פינס',
  'אולפנת בנ"ע פסגת זאב - ירושלים',
  'אולפנת בני עקיבא לכי - בני דקלים',
  'אולפנת בני עקיבא תאיר - בית אל',
  'אולפנת בקעת הירדן - חמרה',
  'אולפנת גילה בקצרין - קצרין',
  'אולפנת דולב',
  'אולפנת דרכי נעם - פתח תקווה',
  'אולפנת הללי לאמנות - בית רבן',
  'אולפנת הראל - נהריה',
  'אולפנת השומרון - אלקנה',
  'אולפנת חפץ חיים',
  'אולפנת טהר - יד בנימין',
  'אולפנת לבונה - מעלה לבונה',
  'אולפנת להבה - קדומים',
  'אולפנת לכתך אחרי - יתד',
  'אולפנת למרחב - פתח תקווה',
  'אולפנת נוגה - בית שמש',
  'אולפנת נוה דקלים',
  'אולפנת עטרת רחל - ירושלים',
];
