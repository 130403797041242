
// Types

//import * as internal from "stream";




export class Action {
  stage: string;
  data:{}
}

export class LecturerAction {
  lecturerId: number;
  // נתונים מהמזתל
  notEligibleComment : string;
  // העסקות במרכז
  isOtherAppointmentIn:boolean;
  appointment:string;
  appointmentList:string;
  appointmentText:string;
  jobPercentages:number;
  teachingWeeklyHours:number
  // העסקות מחוץ למרכז
  isOtherAppointmentOut:boolean
  isPartnerOutsideJCT:boolean;
  companyName:string;
  positionPercentages:number;
  positionHours:number;
  // נתונים כללים
  isPositionPercentagesOver100:boolean;
  isPositionPercentagesOver100Confirmed:boolean;
  isPositionPercentagesOver150:boolean;
  isPositionPercentagesOver150Confirmed:boolean;

  ownerKeyNumber: number;
  idnumber: number;
	passportNumber : string;
  academicDepartment: string;
	currentAcademicDepartment : string;
	doneFillAllDetailsOn :Date;
	confirmedOn :Date;
	reason : string;
	hasMoreJobs :boolean;
  status:number;
	type : number;
	isEntitled:boolean;
	excellenceLevel : number;
	lastStageName : string;

	

}

export class ActionDefination {
  actionId:number;
  CcategoryId:number;
  actionKey:string;
  maxScore:number;//double
  score:number;//double
  containMf:boolean;
  groupId:number;
  isMulti:boolean;
  containPf:boolean;
  category:Category;
}

export class Category {
  categoryId:number;
  categoryKey:string;
  categoryName:string;
  maxScore:number;//double
  multiplier:number;//double
  groupId:number;
  group:CategoriesGroups;
}

export class CategoriesGroups {
  groupId:number;
  groupName:string;
  groupMaxScore:number;//double
  groupMultiplier:number;//double
}

export enum ArticleType {
  Unknown,
  C1,
  C2a,
  C2b,
  C3a,
  C3b,
  C3c,
  C3d,
  C4,
  C5,
}

export interface ArticleAction {
  actionKey: string;
  description: string;
  numValue: number;
}
