import {
  Optional,
  Self,
  Injector,
  Component,
  ViewEncapsulation,
} from "@angular/core";
import { NgControl } from "@angular/forms";

import { CommonService } from "@jct/api";
import { AutocompleteFieldComponent } from "@jct/ui";
import { MatFormFieldControl } from "@angular/material/form-field";
import { HttpStatusResult } from "@jct/core";

@Component({
  selector: "city-field",
  templateUrl: "./city-field.component.html",
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteFieldComponent,
    },
  ],
})
export class CityFieldComponent extends AutocompleteFieldComponent {
  constructor(
    injector: Injector,
    @Optional() @Self() ngControl: NgControl,
    private commonService: CommonService
  ) {
    super(injector, ngControl);
  }

  async callApiAsync(query: string): Promise<string[]> {
    let result = await this.commonService.searchCityNameAsync(query);

    if (result instanceof HttpStatusResult) {
      return [];
    }

    return result;
  }
}
