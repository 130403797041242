import { Injectable, ApplicationRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';

export type Direction = 'ltr' | 'rtl';

@Injectable({
  providedIn: 'root',
})
export class PageLayout {
  constructor(
    private appRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document)
  { }

  layoutChange = new Subject<Direction>();

  private get _rootElement() {
    return this.document.documentElement;
  }

  get layout() {
    return this._rootElement.getAttribute('dir') == 'rtl' ? 'rtl' : 'ltr';
  }

  get rtl() {
    return this._rootElement.getAttribute('dir') == 'rtl';
  }
  set rtl(value: boolean) {
    if (this.rtl == value) {
      return;
    }

    if (value) {
      setTimeout(() => {
        this._rootElement.setAttribute('dir', 'rtl');
        this.layoutChange.next('rtl');
        this._updateView();
      });
    }
    else {
      setTimeout(() => {
        this._rootElement.setAttribute('dir', 'ltr');
        this.layoutChange.next('ltr');
        this._updateView();
      });
    }
  }

  private _updateView() {
    this.appRef.components[0].changeDetectorRef.markForCheck();
  }
}
