
<div container>
  <ui-error [state]="loadState"></ui-error>

  <section *visible="loadState.isCompleted">
    <form
      [formGroup]="form"
      novalidate
      autocomplete="off"
      (submit)="login()"
    >
      <mat-form-field appearance="fill">
        <mat-label i18n>
          שם משתמש
        </mat-label>

        <input matInput
          type="text"
          required
          formControlName="username"
          [autofocus]="loadState"
          maxlength="32">

        <mat-error i18n>
          אנא הזן שם משתמש תקין
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>
          סיסמה
        </mat-label>

        <input matInput
          type="password"
          required
          formControlName="password">

        <mat-error i18n>
          אנא הזן את הסיסמה שלך
        </mat-error>
      </mat-form-field>

      <ui-progress-button
        color="primary"
        [disabled]="form.invalid"
        [state]="loginState"
        i18n>
        {loginState.value, select,
          inProcess {מתחבר}
          other {התחבר}}
      </ui-progress-button>

      <ui-alert type="warning" *visible="loginState.error?.isInvalidModel || loginState.error?.isEntityNotExists || loginState.error?.isVerificationFailed">
        <div ui-body i18n>
          שם המשתמש או הסיסמה אינם נכונים
        </div>
      </ui-alert>

      <ui-error [state]="loginState"
        [ignoreList]="[400,404,401]">
      </ui-error>
    </form>

    <mat-divider></mat-divider>

    <section>
      <p class="mt-5">
        לאחר 5 נסיונות התחברות כושלים,<br/>
        המערכת תחסום אותך אוטומטית ל-20 דקות.
      </p>
    </section>
  </section>
</div>
