
<ui-app-layout>
  <ui-side-menu>
    <section class="ui-menu-header">
      <div class="header-row">
        <ui-jct-logo
          *ngIf="!appLayout.isMobile"
          color="white"
          [includePadding]="false"
          [width]="47">
        </ui-jct-logo>

        <span i18n>
          מערכת הרישום
        </span>
      </div>

      <div class="header-row">
        <span i18n>המרכז האקדמי לב</span>
      </div>
    </section>

    <ui-menu-item route="/admin/login">
      <ui-menu-content
        icon="lock_open"
        i18n>
        התחברות למערכת
      </ui-menu-content>
    </ui-menu-item>
  </ui-side-menu>

  <section ui-toolbar-header class="ui-toolbar-header">
    <ui-jct-logo
      *ngIf="appLayout.isMobile"
      color="white"
      [includePadding]="false"
      [width]="47">
    </ui-jct-logo>

    <span i18n *ngIf="appLayout.isMobile">
      ניהול מערכת הרישום
    </span>

    <span *ngIf="!appLayout.isMobile">
      {{ pageTitle?.current }}
    </span>
  </section>

  <section ui-toolbar-actions class="ui-toolbar-actions">
  </section>
</ui-app-layout>
