
<meta-title i18n>
  שגיאה כללית במערכת
</meta-title>

<div container>
  <h2 i18n>
    שגיאה כללית במערכת
  </h2>

  <p>
    <span i18n>
      מספר מזהה
    </span>:
    [support-id]
  </p>

  <button type="button"
    mat-raised-button
    color="primary"
    (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
    <span i18n>חזור חזרה</span>
  </button>
</div>
