
<div container>
  <ui-error [state]="loadState">
  </ui-error>

  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate
    (submit)="submit()"
    *visible="loadState.isCompleted"
  >
    <mat-form-group>
      <mat-form-field appearance="fill">
        <mat-label i18n>שם פרטי בעברית</mat-label>

        <input matInput
          formControlName="firstName"
          [readonly]="returnStudent"
          required>

        <mat-error *ngIf="form | hasError:'firstName'" i18n>
          שדה חובה
        </mat-error>

        <mat-error>
          <div *ngIf="form | hasError:'firstName':'pattern'; else firstNameError2" i18n>
            השדה צריך להכיל רק אותיות בעברית
          </div>
          <ng-template #firstNameError2 >
            <div *ngIf="form | hasError:'firstName':'minlength'" i18n>
              השדה צריך להכיל לפחות 2 תווים
            </div>
          </ng-template>
        </mat-error>

      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>שם משפחה בעברית</mat-label>

        <input matInput
          formControlName="lastName"
          required>

        <mat-error *ngIf="form | hasError:'lastName'" i18n>
          שדה חובה
        </mat-error>

        <mat-error>
          <div *ngIf="form | hasError:'lastName':'pattern'; else lastNameError2" i18n>
            השדה צריך להכיל רק אותיות בעברית
          </div>
          <ng-template #lastNameError2 >
            <div *ngIf="form | hasError:'lastName':'minlength'" i18n>
              השדה צריך להכיל לפחות 2 תווים
            </div>
          </ng-template>
        </mat-error>

      </mat-form-field>
    </mat-form-group>

    <mat-form-group>
      <mat-form-field appearance="fill">
        <mat-label i18n>שם פרטי באנגלית</mat-label>

        <input matInput
          formControlName="englishFirstName"
          required>

        <mat-error *ngIf="form | hasError:'englishFirstName'" i18n>
          שדה חובה
        </mat-error>

        <mat-error>
          <div *ngIf="form | hasError:'englishFirstName':'pattern' else englishFirstNameError2" i18n>
            השדה צריך להכיל רק אותיות באנגלית
          </div>
          <ng-template #englishFirstNameError2 >
            <div *ngIf="form | hasError:'englishFirstName':'minlength'" i18n>
              השדה צריך להכיל לפחות 3 תווים
            </div>
          </ng-template>
        </mat-error>

      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>שם משפחה באנגלית</mat-label>

        <input matInput
          formControlName="englishLastName"
          required>

        <mat-error *ngIf="form | hasError:'englishLastName'" i18n>
          שדה חובה
        </mat-error>


        <mat-error>
          <div *ngIf="form | hasError:'englishLastName':'pattern'; else englishLastNameError2" i18n>
            השדה צריך להכיל רק אותיות באנגלית
          </div>
          <ng-template #englishLastNameError2 >
            <div *ngIf="form | hasError:'englishLastName':'minlength'" i18n>
              השדה צריך להכיל לפחות 3 תווים
            </div>
          </ng-template>
        </mat-error>

      </mat-form-field>
    </mat-form-group>

    <mat-radio-group formControlName="gender">
      <mat-label i18n>
        בחרו את המין שלכם:
      </mat-label>

      <mat-radio-button value="male" i18n>
        זכר
      </mat-radio-button>

      <mat-radio-button value="female" i18n>
        נקבה
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-group>
      <mat-form-field appearance="fill">
        <mat-label i18n>
          תאריך לידה
        </mat-label>

        <input matInput
          formControlName="birthDate"
          [matDatepicker]="birthDatePicker"
          [max]="maxBirthDate"
          required>

        <term #birthDateTooltip
          i18n>
          {gender, select, male {בחר} female {בחרי} other {בחרו}}
          תאריך לידה
        </term>

        <mat-datepicker-toggle matSuffix
          [matTooltip]="birthDateTooltip.text"
          [for]="birthDatePicker">
        </mat-datepicker-toggle>

        <mat-datepicker #birthDatePicker
          [touchUi]="appLayout.isMobile"
          color="primary">
        </mat-datepicker>

        <mat-hint i18n>
          תאריך בפורמט DD/MM/YYYY
        </mat-hint>

        <mat-error *ngIf="form.get('birthDate').errors | any">
          <span [ngSwitch]="form.get('birthDate').errors | last">
            <ng-container *ngSwitchCase="'matDatepickerParse'" i18n>
              תאריך לא תקין
            </ng-container>
            <ng-container *ngSwitchCase="'matDatepickerMax'" i18n>
              תאריך לא תקין
            </ng-container>
            <ng-container *ngSwitchCase="'required'" i18n>
              שדה חובה
            </ng-container>
          </span>
        </mat-error>
      </mat-form-field>

      <term i18n
        #birthCountryPlaceHolder>
        {gender, select, male {בחר} female {בחרי}}
        ארץ לידה
      </term>

      <country-field
        formControlName="birthCountry"
        i18n-label
        label="ארץ לידה"
        [placeholder]="birthCountryPlaceHolder.text"
        [required]="true"
        appearance="fill">
      </country-field>
    </mat-form-group>

    <mat-form-field appearance="fill">
      <mat-label i18n>מצב משפחתי</mat-label>

      <mat-select formControlName="familyState" required>
        <mat-option value i18n>{gender, select, male {בחר} female {בחרי}}</mat-option>
        <mat-option [value]="FamilyStates.Single" i18n>{gender, select, male {רווק} female {רווקה}}</mat-option>
        <mat-option [value]="FamilyStates.Married" i18n>{gender, select, male {נשוי} female {נשואה}}</mat-option>
        <mat-option [value]="FamilyStates.Divorced" i18n>{gender, select, male {גרוש} female {גרושה}}</mat-option>
        <mat-option [value]="FamilyStates.Widow" i18n>{gender, select, male {אלמן} female {אלמנה}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label i18n>אזרחות</mat-label>

      <mat-select formControlName="citizenship" required>
        <mat-option value i18n>{gender, select, male {בחר} female {בחרי}}</mat-option>
        <mat-option [value]="CitizenshipTypes.Citizen" i18n>{gender, select, male {אזרח} female {אזרחית}}</mat-option>
        <mat-option [value]="CitizenshipTypes.Tourist" i18n>{gender, select, male {תייר} female {תיירת}}</mat-option>
        <mat-option [value]="CitizenshipTypes.TemporaryResident" i18n>{gender, select, male {תושב ארעי} female {תושבת ארעית}}</mat-option>
        <mat-option [value]="CitizenshipTypes.NewImmigrant" i18n>{gender, select, male {עולה חדש} female {עולה חדשה}}</mat-option>
        <mat-option [value]="CitizenshipTypes.ReturnedResident" i18n>{gender, select, male {תושב חוזר} female {תושבת חוזרת}}</mat-option>
      </mat-select>
    </mat-form-field>

    <term i18n
      #aliyaCountryPlaceHolder>
      {gender, select, male {בחר} female {בחרי}}
      ארץ עליה
    </term>

    <mat-form-group>
      <country-field
        formControlName="aliyaCountry"
        i18n-label
        label="ארץ עליה"
        [placeholder]="aliyaCountryPlaceHolder.text"
        appearance="fill">
      </country-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>
          תאריך עליה
        </mat-label>

        <input matInput
          formControlName="aliyaDate"
          [matDatepicker]="aliyaDatePicker"
          [max]="maxAliyaDate">

        <mat-hint i18n>
          תאריך בפורמט DD/MM/YYYY
        </mat-hint>

        <mat-datepicker-toggle matSuffix
          [matTooltip]="aliyaDateTooltip.text"
          [for]="aliyaDatePicker">
        </mat-datepicker-toggle>

        <mat-datepicker #aliyaDatePicker
          [touchUi]="appLayout.isMobile">
        </mat-datepicker>

        <term i18n
          #aliyaDateTooltip>
          {gender, select,
            male {בחר}
            female {בחרי}}
          תאריך עליה
        </term>

        <mat-error i18n>תאריך לא תקין</mat-error>
      </mat-form-field>
    </mat-form-group>

    <mat-form-group>
      <mat-form-field appearance="fill">
        <mat-label i18n>סוג השירות</mat-label>

        <mat-select formControlName="voluntaryServiceType" required>
          <mat-option value i18n>{gender, select, male {בחר} female {בחרי}}</mat-option>
          <mat-option [value]="VoluntaryServiceTypes.DidNotServe" i18n>לא שרתתי</mat-option>
          <mat-option [value]="VoluntaryServiceTypes.ArmyCompulsoryService" i18n>חובה רגיל</mat-option>
          <mat-option [value]="VoluntaryServiceTypes.ArmyPermanentService" i18n>שירות קבע</mat-option>
          <mat-option *ngIf="isMale" [value]="VoluntaryServiceTypes.YeshivatHesder" i18n>ישיבת הסדר</mat-option>
          <mat-option [value]="VoluntaryServiceTypes.AbroadVoluntary" i18n>מח"ל</mat-option>
          <mat-option [value]="VoluntaryServiceTypes.Nahal" i18n>נח"ל</mat-option>
          <mat-option [value]="VoluntaryServiceTypes.NationalService" i18n>שירות לאומי</mat-option>
          <mat-option *ngIf="isMale" [value]="VoluntaryServiceTypes.StageB" i18n>שלב ב</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="controlValue('voluntaryServiceType') == VoluntaryServiceTypes.DidNotServe" appearance="fill">
        <mat-label i18n>סיבת פטור משירות</mat-label>

        <mat-select formControlName="serviceExemptionReason" required>
          <mat-option value i18n>{gender, select, male {בחר} female {בחרי}}</mat-option>
          <mat-option [value]="ServiceExemptionReasons['עתודה_אקדמית']" i18n>עתודה אקדמית</mat-option>
          <mat-option *ngIf="isMale" [value]="ServiceExemptionReasons['עתודה_ב']" i18n>עתודה ב</mat-option>
          <mat-option [value]="ServiceExemptionReasons['רפואית']" i18n>רפואית</mat-option>
          <mat-option [value]="ServiceExemptionReasons['מתחת_לגיל_גיוס']" i18n>מתחת לגיל גיוס</mat-option>
          <mat-option [value]="ServiceExemptionReasons['אזרח_חוץ']" i18n>אזרח חוץ</mat-option>
          <mat-option *ngIf="isMale" [value]="ServiceExemptionReasons['עתודה_חרדית']" i18n>עתודה חרדית</mat-option>
          <mat-option *ngIf="isFemale" [value]="ServiceExemptionReasons['מטעמי_דת']" i18n>מטעמי דת</mat-option>
          <mat-option *ngIf="isMale" [value]="ServiceExemptionReasons['אקדמיזציה_חרדית']" i18n>אקדמיזציה חרדית</mat-option>
          <mat-option *ngIf="isMale" [value]="ServiceExemptionReasons['פטור']" i18n>פטור</mat-option>
          <mat-option *ngIf="isMale" [value]="ServiceExemptionReasons['חוסר_התאמה']" i18n>חוסר התאמה</mat-option>
          <mat-option [value]="ServiceExemptionReasons['הנדסאי_קריית_נוער']" i18n>הנדסאי קריית נוער</mat-option>
          <mat-option *ngIf="isMale" [value]="ServiceExemptionReasons['תורתו_אומנותו']" i18n>תורתו אומנותו</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-form-group>

    <mat-form-group>
      <mat-form-field appearance="fill">
        <mat-label i18n>מקום עבודה</mat-label>

        <input matInput
          formControlName="workPlace">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label i18n>תפקיד</mat-label>

        <input matInput
          formControlName="role">
      </mat-form-field>
    </mat-form-group>

    <mat-divider></mat-divider>

    <ui-error [state]="saveState"></ui-error>

    <ui-progress-button
      color="primary"
      [form]="form"
      [state]="saveState"
      i18n>
      {saveState.value, select,
        inProcess {שומר נתונים}
        other {שמור והמשך}
      }
    </ui-progress-button>
  </form>
</div>
