
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Authenticator } from '@jct/core';

@Component({
  templateUrl: 'logout-dialog.html',
})
export class LogoutDialog {
  constructor(
    private authenticator: Authenticator,
    private dialogRef: MatDialogRef<LogoutDialog>)
  { }

  seconds: number;

  cancel() {
    this.dialogRef.close();

    setTimeout(async () => {
      await this.authenticator.refreshToken();
    }, 0);
  }

  logMeOut() {
    this.dialogRef.close();

    setTimeout(async () => {
      this.authenticator.logout(true);
    }, 0);
  }
}
