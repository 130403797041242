
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import {
  EntranceExamsService,
  GeneralExamTypes,
  EnglishExamTypes,
  ProfileService,
  RegistrationNavigator,
  RegistrationProfile,
  RegistrationStages,
} from '@jct/api';

import { HttpStatusResult } from '@jct/core';
import { AppLayoutService, StateFactory } from '@jct/ui';

@Component({
  selector: 'entrance-exams-tab.page',
  templateUrl: './entrance-exams-tab.component.html'
})
export class EntranceExamsTabComponent implements OnInit {
  @Output()
  done = new EventEmitter<any>();

  constructor(
    private fb: UntypedFormBuilder,
    private educationService: EntranceExamsService,
    private registrationNavigator: RegistrationNavigator,
    private profileService: ProfileService,
    private sf: StateFactory,
    public appLayout: AppLayoutService)
  { }

  @Output()
  saved = new EventEmitter();

  loadState = this.sf.create();
  saveState = this.sf.create();

  GeneralExamTypes = GeneralExamTypes;
  EnglishExamTypes = EnglishExamTypes;

  form = this.fb.group({
    generalExamType: [null],
    generalExamDate: [null],
    englishExamType: [null],
    englishExamDate: [null]
  });

  async ngOnInit() {
    await this.loadState.inProcess();

    let result = await this.educationService.getEntranceExamsAsync();

    if (result instanceof HttpStatusResult) {
      if (!result.isEntityNotExists) {
        this.loadState.failed(result);
        return;
      }
    }
    else {
      this.form.patchValue(result);
    }

    this.loadState.completed();
  }

  async submit() {
    if (this.saveState.isInProcess) {
      return;
    }

    await this.saveState.inProcess();

    let result = await this.educationService.saveEntranceExamsAsync(this.form.value);

    if (!result.succeeded) {
      this.saveState.failed(result);
      return;
    }

    this.profileService.update<RegistrationProfile>({
      currentStage: RegistrationStages.פסיכומטרי_וסיווג_רמה_באנגלית,
    });

    await this.saveState.completed();
    this.saved.emit();

    this.registrationNavigator.navigateToNextStage();
  }
}
