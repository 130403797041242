import { HostEnvironment, Map, CACHING_FEATURE } from "../../@jct/core";

import { MULTIPLE_LOCALIZATION_FEATURE } from "../../@jct/localization";

//TEST
// export const hostEnvironment = HostEnvironment.create({
//   environmentName: "Production",
//   apiServerUrl: "https://api.jct.ac.il",
//   applicationUrl: "https://registration-test.jct.ac.il",
// });

export const hostEnvironment = HostEnvironment.create({
  environmentName: "Production",
  apiServerUrl: "https://api.jct.ac.il",
  applicationUrl: "https://registration.jct.ac.il",
});

export const appSettings: Map<any> = {
  [CACHING_FEATURE]: true,
  [MULTIPLE_LOCALIZATION_FEATURE]:true
};

