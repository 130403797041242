
import { Injectable, Injector } from '@angular/core';

import {
  Controller,
  resultOf,
  Produce,
  HttpStatusResult,
  FromBody,
  HttpPost,
  HttpGet,
  FromQueryString
} from '@jct/core';

import { UsersListModel } from '../common';
import { User } from '../shared/shared.types';

@Injectable({ providedIn: 'root' })
@Controller('user-manager')
export class UserManagerService {
  constructor(
    protected injector: Injector)
  { }

  @HttpGet('search')
  @Produce(User, true)
  searchAsync(
    @FromQueryString('query') query: string)
  {
    return resultOf<User[]>();
  }

  @HttpPost('enable')
  @Produce(HttpStatusResult)
  enableAsync(
    @FromBody() model: UsersListModel) {
    return resultOf();
  }

  @HttpPost('disable')
  @Produce(HttpStatusResult)
  disableAsync(
    @FromBody() model: UsersListModel) {
    return resultOf();
  }
}
