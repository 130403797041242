import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CoreModule } from "@jct/core";
import { ApiModule } from "@jct/api";
import { UiModule } from "@jct/ui";
import { L10nModule } from "@jct/localization";
import { TestModule } from "@jct/test";

import { PaymentsRoutingModule } from "./payments-routing.module";
import { PaymentsComponent } from "./payments.component";
import {
  CancelPageComponent,
  FailedPageComponent,
  SuccessPageComponent,
} from "./+redirect";

import { hostEnvironment } from "projects/registration-app/environments/environment";

@NgModule({
  declarations: [
    PaymentsComponent,
    CancelPageComponent,
    FailedPageComponent,
    SuccessPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ReactiveFormsModule,
    L10nModule.forRoot(),
    CoreModule.forRoot(),
    ApiModule.forRoot(),
    UiModule.forRoot(),
    TestModule.forRoot(!hostEnvironment.isProduction()),
    PaymentsRoutingModule,
  ],
})
export class PaymentsModule {}
