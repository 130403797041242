import { HostBinding, AfterContentInit, ElementRef, Component } from '@angular/core';
import { PageTitleService } from './page-title.service';

@Component({
  selector: 'meta-title',
  template: '<ng-content></ng-content>',
})
export class MetaTitle implements AfterContentInit {
  private _title = '';

  @HostBinding('class.cdk-visually-hidden')
  get visuallyHidden() { return true; }

  @HostBinding('attr.aria-hidden')
  get ariaHidden() { return true; }

  constructor(
    private elementRef: ElementRef<Element>,
    private pageTitle: PageTitleService)
  { }

  ngAfterContentInit() {
    this._title = this.elementRef.nativeElement.textContent.trim();
    this.pageTitle.setTitle(this._title);
    console.log('ContentInit', this._title);
  }

  get title() {
    return this._title;
  }
}
