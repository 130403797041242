import { Injectable, Provider } from '@angular/core';

import { ProgressIndicator } from './progress-indicator.service';
import { ApiInterceptor, API_INTERCEPTORS } from '../api-client';

@Injectable()
export class ProgressIndicatorInterceptor implements ApiInterceptor {
  enabled = true;
  order = 0;

  constructor(
    private progressIndicator: ProgressIndicator)
  { }

  async begin() {
    this.progressIndicator.inProgress();
  }

  async end() {
    this.progressIndicator.done();
  }
}

export const ProgressIndicatorInterceptorProvider: Provider = {
  provide: API_INTERCEPTORS,
  useClass: ProgressIndicatorInterceptor,
  multi: true,
};
