import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  EmbeddedViewRef
} from '@angular/core';

//import { DomApi } from '@jct/ui/lib/services';
import { AnimationsMap, AnimationType } from './animations';

@Directive({
  selector: '[visible]',
})
export class Visible {
  private _embeddedView: EmbeddedViewRef<any>;
  //private _element: DomApi.Warper;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>)
  {
    this.viewContainerRef.clear();
  }

  attachView() {
    this._embeddedView = this.viewContainerRef.createEmbeddedView(this.templateRef);
    //this._element = DomApi.warp(this._embeddedView.rootNodes[0]);
  }

  detachView() {
    this.viewContainerRef.clear();
    this._embeddedView = null;
    //this._element = null;
  }

  //animateEnter() {
  //  if (!this._element) {
  //    this.attachView();
  //  }
//
  //  this._element.animate({
  //    duration: this.duration,
  //    delay: this.delay,
  //    story: AnimationsMap[this.transition].enter,
  //  });
  //}

  //animateLeave() {
  //  if (this._element) {
  //    this._element.animate({
  //      story: AnimationsMap[this.transition].leave,
  //      duration: this.duration,
  //      delay: this.delay,
  //      disappear: true,
  //      end: () => {
  //        this.detachView();
  //      },
  //    });
  //  }
  //}

  @Input()
  set visible(value: any) {
    if (!!value) {
      //this.animateEnter();

      //if (!this._element) {
      this.attachView();
      //}
    }
    else {
      //this.animateLeave();

      this.detachView();
    }
  }

  @Input('visibleTransition')
  transition: AnimationType = 'default';

  @Input('visibleDelay')
  delay: number = 0;

  @Input('visibleDuration')
  duration: number = 400;
}
