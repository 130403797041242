
import { Injectable, Injector } from '@angular/core';

import {
  Filtering,
  Controller,
  resultOf,
  HttpGet,
  HttpPost,
  FromBody,
  FromQueryString,
  UseState,
  Produce,
  SuccessResult,
  SuccessTestCase,
  EntityNotExistsResult,
  Cache,
  SuccessTestCaseProvider,
  FailedTestCase,
  InvalidModelResult,
  HttpStatusResult
} from '@jct/core';

import { StudentHighSchool } from './high-school.types';
import { STUDENT_HIGH_SCHOOLS_PROVIDER, HIGH_SCHOOLS_PROVIDER } from './high-school.test';

@Injectable({providedIn: 'root'})
@Controller('high-school')
export class HighSchoolService {
  constructor(
    protected injector: Injector)
  { }

  @Cache('30d')
  @HttpGet('search')
  @SuccessTestCaseProvider(HIGH_SCHOOLS_PROVIDER)
  @Produce('string', true)
  searchHighSchoolsAsync(
    @FromQueryString('filtering') filter: Filtering)
  {
    return resultOf<string[]>();
  }

  @UseState()
  @HttpGet()
  @SuccessTestCase(STUDENT_HIGH_SCHOOLS_PROVIDER(), 'קבלת תיכונים של הסטודנט')
  @SuccessTestCase(EntityNotExistsResult, 'לא קיימים תיכונים עבור הסטודנט')
  @Produce(StudentHighSchool, true)
  getStudentHighSchoolsAsync()
  {
    return resultOf<StudentHighSchool[]>();
  }

  @UseState()
  @HttpPost()
  @SuccessTestCase(SuccessResult)
  @FailedTestCase(InvalidModelResult)
  @Produce(HttpStatusResult)
  saveStudentHighSchoolsAsync(
    @FromBody('studentHighSchool') highSchools: StudentHighSchool[])
  {
    return resultOf();
  }
}
