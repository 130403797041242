

import { Component } from '@angular/core';

import { RegistrationUserProfile } from '@jct/api';

@Component({
  selector: 'statements.page',
  templateUrl: './statements-page.component.html',
})
export class StatementsPageComponent {
  constructor(
    private profile: RegistrationUserProfile)
  { }

  get hideCriminalRecordTab() {
    return !this.profile?.enabledCriminalRecord;
  }
}
