import { Gender } from '@jct/localization';

export enum RegistrationStages {
  פרטים_אישיים = 'פרטים_אישיים',
  פרטי_התקשרות = 'פרטי_התקשרות',
  בחירת_מסלול_לימודים = 'בחירת_מסלול_לימודים',
  פרטי_קרובים = 'פרטי_קרובים',
  פרטי_התקשרות_הורים = 'פרטי_התקשרות_הורים',
  תיכונים = 'תיכונים',
  ציוני_בגרות = 'ציוני_בגרות',
  פסיכומטרי_וסיווג_רמה_באנגלית = 'פסיכומטרי_וסיווג_רמה_באנגלית',
  מוסדות_על_תיכוניים = 'מוסדות_על_תיכוניים',
  דיפלומה = 'דיפלומה',
  תארים_אקדמים = 'תארים_אקדמים',
  הצהרת_בריאות = 'הצהרת_בריאות',
  הצהרות_ואישורים = 'הצהרות_ואישורים',
  הצהרת_העדר_רישום_פלילי = 'הצהרת_העדר_רישום_פלילי',
  תשלום = 'תשלום',
}

export interface RegistrationStageDefinition {
  stage: RegistrationStages;
  page: string;
  tab?: string;
}

export const ALL_REGISTRATION_STAGES: RegistrationStageDefinition[] = [
  {
    stage: RegistrationStages.פרטים_אישיים,
    page: 'personal',
    tab: 'details',
  },
  {
    stage: RegistrationStages.פרטי_התקשרות,
    page: 'personal',
    tab: 'contact',
  },
  {
    stage: RegistrationStages.בחירת_מסלול_לימודים,
    page: 'study-track',
  },
  {
    stage: RegistrationStages.פרטי_קרובים,
    page: 'relatives',
    tab: 'details',
  },
  {
    stage: RegistrationStages.פרטי_התקשרות_הורים,
    page: 'relatives',
    tab: 'contact',
  },
  {
    stage: RegistrationStages.תיכונים,
    page: 'previous-education',
    tab: 'high-school',
  },
  {
    stage: RegistrationStages.ציוני_בגרות,
    page: 'previous-education',
    tab: 'bagrut',
  },
  {
    stage: RegistrationStages.פסיכומטרי_וסיווג_רמה_באנגלית,
    page: 'previous-education',
    tab: 'entrance-exams',
  },
  {
    stage: RegistrationStages.מוסדות_על_תיכוניים,
    page: 'previous-education',
    tab: 'institutions',
  },
  {
    stage: RegistrationStages.דיפלומה,
    page: 'previous-education',
    tab: 'diploma',
  },
  {
    stage: RegistrationStages.תארים_אקדמים,
    page: 'previous-education',
    tab: 'previous-degrees',
  },
  {
    stage: RegistrationStages.הצהרת_בריאות,
    page: 'statements',
    tab: 'health',
  },
  {
    stage: RegistrationStages.הצהרות_ואישורים,
    page: 'statements',
    tab: 'general',
  },
  {
    stage: RegistrationStages.הצהרת_העדר_רישום_פלילי,
    page: 'statements',
    tab: 'criminal-record',
  },
  {
    stage: RegistrationStages.תשלום,
    page: 'registration-fee',
  },
];

export enum RegistrationStates {
  NewStudent = 'newStudent',
  ReturnStudent = 'returnStudent',
  PaidOnThisYear = 'paidOnThisYear',
}

export class RegistrationProfile {
  gender?: Gender;
  academicStudyType?: number;
  currentStage?: RegistrationStages;
  enabledCriminalRecord?: boolean;
  state?: RegistrationStates;
  isAccepted?: boolean;
  minAcademicYear?: number;
}
