
export class TomaxBarcodesModel{
    examid:string;
    barcode:string;
    examDate:Date
}

export class ExamSyncDatesWithTomax {
    id: number;
    year: string;
    semesterId: number;
    startSyncMoedAdate: Date;
    startSyncMoedBdate: Date;
  }
  
  export class TomaxSyncSpecialDates {
    id: number;
    fromDate: Date;
    untilDate: Date;
    apppliedFrom: Date;
    appliedUntil: Date;
  }