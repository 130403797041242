import { Component, Input, ViewEncapsulation } from "@angular/core";

import { ActionState } from "@jct/ui/lib/common";
import { CardTypes } from "../card";

@Component({
  selector: "alert, ui-alert",
  templateUrl: "./alert.html",
  styleUrls: ["./alert.scss"],
  exportAs: "uiAlert",
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "ui-alert",
  },
})
export class UiAlert {
  private _state: ActionState;

  @Input("type")
  alertType: CardTypes;

  get error() {
    return this._state.error;
  }
}
