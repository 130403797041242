
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { DormitoryRegistrationModel, DormitoryService } from '@jct/api';
import { StateFactory } from '../../services';


@Component({
  templateUrl: 'update-dorm-requests-dialog.component.html',
})
export class UpdateDormRequestsDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {dormId:number, comment: string},
    private sf: StateFactory,
    private router: Router,
    private dormitoryService: DormitoryService,
    private dialogRef: MatDialogRef<UpdateDormRequestsDialogComponent>)
  { }

  updateState = this.sf.create();

  back() {
    this.dialogRef.close();
  }

  async update() {
    if (this.updateState.isInProcess) {
      return;
    }
    await this.updateState.inProcess();

    const model: DormitoryRegistrationModel = {
      dormId: this.data.dormId,
      comment: this.data.comment
    };

    const updateResult = await this.dormitoryService.registerAsync(model);

    if (!updateResult.succeeded) {
      this.updateState.failed(updateResult);
      return;
    }

    await this.updateState.completed();

    this.dialogRef.close(this.data.comment);
  }
}
