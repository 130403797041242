
<mat-sidenav-container
  [hasBackdrop]="appLayout.isMobile">

  <mat-sidenav #menu="matSidenav"
    [mode]="appLayout.isMobile ? 'over' : 'side'"
    [opened]="!appLayout.isMobile"
    [disableClose]="!appLayout.isMobile"
  >
    <ng-content select=".ui-menu-header"></ng-content>

    <ng-content select="ui-side-menu"></ng-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <section ui-toolbar>
      <ng-content select="[ui-toolbar-header]" ngProjectAs="[ui-toolbar-header]"></ng-content>
      <ng-content select="[ui-toolbar-actions]" ngProjectAs="[ui-toolbar-actions]"></ng-content>
    </section>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="progressIndicator.observable | async"
      color="warn">
    </mat-progress-bar>

    <main role="main">
      <router-outlet></router-outlet>
    </main>

    <footer ui-page-footer>
      <section class="app-version">
        <span i18n>גרסת מערכת</span>:
        {{ application?.version }}
      </section>

      <section *ngIf="!environment.isProduction()" class="dev-mode">
        <span>מצב פיתוח</span>
      </section>

      <ng-content select=".footer"></ng-content>
    </footer>

  </mat-sidenav-content>
</mat-sidenav-container>
