<div container>
  <ui-error [state]="loadState" [ignoreList]="[409]"></ui-error>
  <ui-alert type="info" *visible="loadState.error?.isEntityAlreadyExists">
    <div ui-body i18n>לידיעתך! שילמת כבר דמי רישום</div>
  </ui-alert>

  <section *visible="loadState.isCompleted">
    <mat-card class="mb-10">
      <h3 ui-header i18n>נרשמת למסלול הלימודים הבא</h3>

      <div ui-body>
        <table class="mb-10">
          <tbody>
            <tr>
              <th i18n style="width: 150px">סוג מסלול לימודים</th>
              <td>{{ mainStudentStudyTrack.academicStudyTypeName }}</td>
            </tr>

            <tr>
              <th i18n>מכון</th>
              <td>{{ mainStudentStudyTrack.instituteName }}</td>
            </tr>

            <tr>
              <th i18n>מחלקה</th>
              <td>{{ mainStudentStudyTrack.departmentName }}</td>
            </tr>

            <tr>
              <th i18n>חוג</th>
              <td>{{ mainStudentStudyTrack.trackName }}</td>
            </tr>

            <tr>
              <th i18n>שנה</th>
              <td>{{ mainStudentStudyTrack.academicYearTitle }}</td>
            </tr>
          </tbody>
        </table>

        <ui-alert type="warning" *visible="!isTrackOpenToRegistration">
          <h3 ui-header i18n>מסלול הלימודים שבחרת אינו פתוח לרישום</h3>

          <p ui-body i18n>
            עליך לבחור מסלול אחר
            <ui-link to="/fill-in/study-track"> כאן </ui-link>
          </p>
        </ui-alert>
      </div>
    </mat-card>

    <div *ngIf="isTrackOpenToRegistration">
      <p><span i18n>דמי רישום</span>: &#8362;{{ paymentDetails.baseAmount }}</p>

      <p *ngFor="let discount of paymentDetails?.discounts">
        <span i18n>
          {discount.discountReason, select, הנחת_רישום_מקוון {הנחת רישום מקוון}
          הנחת_תלמיד_חוזר {הנחת תלמיד חוזר} הנחת_יום_פתוח {הנחת יום פתוח}
          הנחת_נרשם_למכינה {הנחת נרשם למכינה}} </span
        >: &#8362;{{ discount?.discountAmount }}
      </p>

      <p>
        <strong>
          <span i18n>סה"כ לתשלום</span>: &#8362;{{
            paymentDetails?.calculatedAmount
          }}
        </strong>
      </p>

      <section
        *visible="showEnterCode()"
        style="font-size: initial"
        class="mb-10"
      >
        <p i18n>
          במידה ויש ברשותך קוד הנחה, אנא {gender, select, male {הזן} female
          {הזיני} other {הזינו}} אותו כעת
        </p>

        <!-- TODO: add form group -->
        <mat-form-field floatLabel="never">
          <mat-label i18n>קוד הנחה</mat-label>
          <input matInput [(ngModel)]="code" autocomplete="off" />
        </mat-form-field>

        <ui-progress-button
          color="accent"
          [state]="enterCodeState"
          class="mb-10"
          (progress)="enterOpenDayCode()"
          [disabled]="!code"
          i18n
        >
          לקליטת הקוד
        </ui-progress-button>

        <ui-error [state]="enterCodeState" [ignoreList]="[400]"></ui-error>

        <ui-alert type="error" *visible="enterCodeState.error?.isInvalidModel">
          <div ui-body i18n>קוד הנחה לא תקין או פג תוקפו</div>
        </ui-alert>
      </section>

      <p i18n>שים לב! ניתן לשלם אך ורק בכרטיס אשראי בעל תעודת זהות ישראלית</p>

      <ui-progress-button
        color="primary"
        [state]="beginPaymentState"
        (progress)="payAsync()"
        i18n
      >
        לביצוע התשלום
      </ui-progress-button>
    </div>
  </section>
</div>
