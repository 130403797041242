
import { Component, Input, ContentChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { SnackBarMessageComponent } from './snack-bar-message.component';
import { SnackBarActionComponent } from './snack-bar-action.component';

@Component({
  selector: 'ui-snack-bar',
  template: '<ng-content></ng-content>',
  exportAs: 'uiSnackBar',
})
export class SnackBarComponent {
  constructor(
    private snackBar: MatSnackBar)
  { }

  @Input()
  duration: number;

  @ContentChild(SnackBarMessageComponent)
  message!: SnackBarMessageComponent;

  @ContentChild(SnackBarActionComponent)
  action!: SnackBarActionComponent;

  show() {
    this.snackBar.open(
      this.message.content,
      this.action.content,
      {
        duration: 2000,
      });
  }
}
